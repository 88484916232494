
import React from 'react';
import Navigation from '@/shared/layout/Navigation';
import { Header } from '@/shared/layout/Header';
import { useAuth } from '@/features/auth/context/AuthContext';

const Layout = ({ children }) => {
  const { loading } = useAuth();

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="flex min-h-screen bg-gray-100">
      {/* Navigation is always present */}
      <div className="fixed left-0 top-0 bottom-0 w-64 z-30">
        <Navigation />
      </div>
      
      {/* Main Content Area */}
      <div className="flex flex-col min-h-screen pl-64 w-full">
        {/* Fixed Header */}
        <div className="fixed top-0 right-0 left-64 bg-white z-20">
          <Header />
        </div>
        
        {/* Scrollable Content Area */}
        <div className="flex-1 mt-[120px] relative">
          <div className="absolute inset-0 overflow-y-auto">
            <div className="p-6">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;