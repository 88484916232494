import React, { useState, useCallback, useEffect } from 'react';
import { Loader2 } from 'lucide-react';
import { supabase } from '@/lib/supabaseClient';
import '../styles/UsersPage.css';

const ITEMS_PER_PAGE = 10;

const STATES = [
  'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut',
  'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa',
  'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan',
  'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
  'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma',
  'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee',
  'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
];

const COUNTRIES = [
  'Albania', 'Belgium', 'Bulgaria', 'Canada', 'Croatia', 'Czech Republic', 'Denmark',
  'Estonia', 'France', 'Germany', 'Greece', 'Hungary', 'Iceland', 'Italy', 'Latvia',
  'Lithuania', 'Luxembourg', 'Montenegro', 'Netherlands', 'North Macedonia', 'Norway',
  'Philippines', 'Poland', 'Portugal', 'Romania', 'Russia', 'Slovakia', 'Slovenia',
  'Spain', 'Turkey', 'United Kingdom', 'United States'
];

const ROLES = [
  'Advisor', 'CEO', 'CIO', 'COO', 'Developer', 'Development Manager', 'Leadership',
  'Marketing Director', 'Member', 'Member Business Leader', 'Reviewer', 'Team Member'
];

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [providers, setProviders] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filters, setFilters] = useState({
    search: '',
    solved_role: '',
    provider: ''
  });

  const fetchProviders = async () => {
    try {
      const { data, error } = await supabase
        .from('user_additional_attributes')
        .select('provider')
        .not('provider', 'is', null);
      
      if (error) throw error;
      
      const uniqueProviders = [...new Set(data
        .map(item => item.provider)
        .filter(Boolean))];

      setProviders(uniqueProviders);
    } catch (err) {
      console.error('Error fetching providers:', err);
    }
  };

  const getPermissionsString = (user) => {
    const permissions = [];
    if (user.reviewer) permissions.push('Reviewer');
    if (user.administrator) permissions.push('Admin');
    if (user.configuration_manager) permissions.push('Config Manager');
    if (user.approver) permissions.push('Approver');
    if (user.validator) permissions.push('Validator');
    if (user.scenario_generator) permissions.push('Scenario Generator');
    if (user.admin_portal) permissions.push('Admin Portal');
    if (user.org_leader) permissions.push('Org Leader');
    return permissions.join(', ') || '-';
  };

  useEffect(() => {
    fetchProviders();
  }, []);

  const fetchUsers = useCallback(async () => {
    setIsLoading(true);
    try {
      const { count } = await supabase
        .from('user_additional_attributes')
        .select('*', { count: 'exact', head: true });

      if (count === 0) {
        setUsers([]);
        setTotalUsers(0);
        return;
      }

      let query = supabase
        .from('user_additional_attributes')
        .select(`
          fullname,
          email,
          solved_role,
          county,
          state,
          country,
          provider,
          user_tags,
          comments,
          reviewer,
          administrator,
          configuration_manager,
          approver,
          validator,
          scenario_generator,
          admin_portal,
          org_leader
        `);

      if (filters.search) {
        query = query.or(`email.ilike.%${filters.search}%,fullname.ilike.%${filters.search}%`);
      }

      if (filters.solved_role && filters.solved_role !== 'All') {
        query = query.eq('solved_role', filters.solved_role);
      }

      if (filters.provider) {
        query = query.eq('provider', filters.provider);
      }

      const from = (currentPage - 1) * ITEMS_PER_PAGE;
      const to = from + ITEMS_PER_PAGE - 1;

      const { data, error: fetchError } = await query
        .order('fullname')
        .range(from, to);

      if (fetchError) throw fetchError;

      setUsers((data || []).map(user => ({
        ...user,
        country: user.country || 'United States'
      })));
      setTotalUsers(count);
    } catch (err) {
      console.error('Error fetching users:', err);
      setError('Failed to load users');
      setUsers([]);
      setTotalUsers(0);
    } finally {
      setIsLoading(false);
    }
  }, [filters, currentPage]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
    setCurrentPage(1);
  };

  const clearFilters = () => {
    setFilters({
      search: '',
      solved_role: '',
      provider: ''
    });
    setCurrentPage(1);
  };

  const handleResetPassword = async (email) => {
    if (window.confirm('Are you sure you want to reset the password for this user?')) {
      try {
        const { error } = await supabase
          .from('user_additional_attributes')
          .update({ 
            password: 'defaultpassword',
            require_password_change: true 
          })
          .eq('email', email);

        if (error) throw error;
        alert('Password has been reset successfully');
      } catch (err) {
        console.error('Error resetting password:', err);
        setError('Failed to reset password');
      }
    }
  };

  return (
    <div className="users-page">
      <div className="header">
        <h1>User Management</h1>
      </div>

      {error && (
        <div className="error-message">
          {error}
        </div>
      )}

      <div className="filter-section">
        <div className="filter-grid">
          <div className="filter-group">
            <label>Search</label>
            <input
              type="text"
              name="search"
              value={filters.search}
              onChange={handleFilterChange}
              placeholder="Search by name or email..."
              className="form-control"
            />
          </div>

          <div className="filter-group">
            <label>Role</label>
            <select
              name="solved_role"
              value={filters.solved_role}
              onChange={handleFilterChange}
              className="form-control"
            >
              <option value="">All Roles</option>
              {ROLES.map(role => (
                <option key={role} value={role}>{role}</option>
              ))}
            </select>
          </div>

          <div className="filter-group">
            <label>Provider</label>
            <select
              name="provider"
              value={filters.provider}
              onChange={handleFilterChange}
              className="form-control"
            >
              <option value="">All Providers</option>
              {providers.map(provider => (
                <option key={provider} value={provider}>
                  {provider}
                </option>
              ))}
            </select>
          </div>
        </div>

        <button onClick={clearFilters} className="btn btn-secondary">
          Clear Filters
        </button>
      </div>

      <div className="table-container">
        {isLoading ? (
          <div className="loading-indicator">
            <Loader2 className="spinner" />
            <span>Loading...</span>
          </div>
        ) : users.length === 0 ? (
          <div className="empty-state">
            <p>No users found. {filters.search || filters.solved_role || filters.provider ? 'Try adjusting your filters.' : ''}</p>
          </div>
        ) : (
          <table className="users-table">
            <thead>
              <tr>
                <th>Actions</th>
                <th>Full Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>County</th>
                <th>State</th>
                <th>Country</th>
                <th>Provider</th>
                <th>Tags</th>
                <th>Permissions</th>
                <th>Portal Access</th>
                <th>Comments</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.email}>
                  <td>
                    <div className="action-buttons">
                      <button
                        onClick={() => setSelectedUser(user)}
                        className="btn btn-primary btn-sm"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleResetPassword(user.email)}
                        className="btn btn-secondary btn-sm"
                      >
                        Reset Password
                      </button>
                    </div>
                  </td>
                  <td>{user.fullname || '-'}</td>
                  <td>{user.email}</td>
                  <td>{user.solved_role || '-'}</td>
                  <td>{user.county || '-'}</td>
                  <td>{user.state || '-'}</td>
                  <td>{user.country || 'United States'}</td>
                  <td>{user.provider || '-'}</td>
                  <td>{user.user_tags?.join(', ') || '-'}</td>
                  <td>{getPermissionsString(user)}</td>
                  <td>{user.admin_portal ? 'Yes' : 'No'}</td>
                  <td>{user.comments || '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {selectedUser && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Edit User</h2>
            </div>
            <form onSubmit={async (e) => {
              e.preventDefault();
              try {
                setIsLoading(true);
                const { error } = await supabase
                  .from('user_additional_attributes')
                  .update({
                    fullname: selectedUser.fullname,
                    solved_role: selectedUser.solved_role,
                    county: selectedUser.county,
                    state: selectedUser.state,
                    provider: selectedUser.provider,
                    user_tags: selectedUser.user_tags,
                    comments: selectedUser.comments,
                    reviewer: selectedUser.reviewer,
                    administrator: selectedUser.administrator,
                    configuration_manager: selectedUser.configuration_manager,
                    approver: selectedUser.approver,
                    validator: selectedUser.validator,
                    scenario_generator: selectedUser.scenario_generator,
                    admin_portal: selectedUser.admin_portal,
                    org_leader: selectedUser.org_leader
                  })
                  .eq('email', selectedUser.email);

                if (error) throw error;
                
                await fetchUsers();
                setSelectedUser(null);
                setError(null);
              } catch (err) {
                console.error('Error updating user:', err);
                setError('Failed to update user');
              } finally {
                setIsLoading(false);
              }
            }} className="modal-form">
              <div className="form-group">
                <label>Email</label>
                <input
                  type="text"
                  value={selectedUser.email}
                  disabled
                  className="form-control"
                />
              </div>

              <div className="form-group">
                <label>Full Name</label>
                <input
                  type="text"
                  value={selectedUser.fullname || ''}
                  onChange={(e) => setSelectedUser({
                    ...selectedUser,
                    fullname: e.target.value
                  })}
                  className="form-control"
                />
              </div>

              <div className="form-group">
                <label>Role</label>
                <select
                  value={selectedUser.solved_role || ''}
                  onChange={(e) => setSelectedUser({
                    ...selectedUser,
                    solved_role: e.target.value
                  })}
                  className="form-control"
                >
                  <option value="">Select Role</option>
                  {ROLES.map(role => (
                    <option key={role} value={role}>
                      {role}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label>County</label>
                <input
                  type="text"
                  value={selectedUser.county || ''}
                  onChange={(e) => setSelectedUser({
                    ...selectedUser,
                    county: e.target.value
                  })}
                  className="form-control"
                />
              </div>

              <div className="form-group">
                <label>State</label>
                <select
                  value={selectedUser.state || ''}
                  onChange={(e) => setSelectedUser({
                    ...selectedUser,
                    state: e.target.value
                  })}
                  className="form-control"
                  disabled={selectedUser.country !== 'United States'}
                >
                  <option value="">Select State</option>
                  {STATES.map(state => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label>Country</label>
                <select
                  value={selectedUser.country || 'United States'}
                  onChange={(e) => {
                    const newCountry = e.target.value;
                    setSelectedUser({
                      ...selectedUser,
                      country: newCountry,
                      state: newCountry !== 'United States' ? '' : selectedUser.state
                    });
                  }}
                  className="form-control"
                >
                  {COUNTRIES.map(country => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label>Provider</label>
                <select
                  value={selectedUser.provider || ''}
                  onChange={(e) => setSelectedUser({
                    ...selectedUser,
                    provider: e.target.value
                  })}
                  className="form-control"
                >
                  <option value="">Select Provider</option>
                  {providers.map(provider => (
                    <option key={provider} value={provider}>
                      {provider}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label>Tags (comma-separated)</label>
                <input
                  type="text"
                  value={selectedUser.user_tags?.join(', ') || ''}
                  onChange={(e) => setSelectedUser({
                    ...selectedUser,
                    user_tags: e.target.value.split(',').map(tag => tag.trim()).filter(Boolean)
                  })}
                  className="form-control"
                  placeholder="tag1, tag2, tag3"
                />
              </div>

              <div className="form-group">
                <label>Comments</label>
                <textarea
                  value={selectedUser.comments || ''}
                  onChange={(e) => setSelectedUser({
                    ...selectedUser,
                    comments: e.target.value
                  })}
                  className="form-textarea"
                  rows={3}
                />
              </div>

              <div className="form-group">
                <label>Portal Access & Roles</label>
                <div className="checkbox-group">
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedUser.admin_portal || false}
                      onChange={(e) => setSelectedUser({
                        ...selectedUser,
                        admin_portal: e.target.checked,
                        
                        ...(e.target.checked ? {} : {
                          reviewer: false,
                          administrator: false,
                          configuration_manager: false,
                          approver: false,
                          validator: false,
                          scenario_generator: false
                        })
                      })}
                    /> Admin Portal Access
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedUser.org_leader || false}
                      onChange={(e) => setSelectedUser({
                        ...selectedUser,
                        org_leader: e.target.checked
                      })}
                    /> Organization Leader
                  </label>
                </div>
              </div>

              <div className="form-group">
                <label>Additional Permissions</label>
                <div className="checkbox-group">
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedUser.reviewer || false}
                      onChange={(e) => setSelectedUser({
                        ...selectedUser,
                        reviewer: e.target.checked
                      })}
                      disabled={!selectedUser.admin_portal}
                    /> Reviewer
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedUser.administrator || false}
                      onChange={(e) => setSelectedUser({
                        ...selectedUser,
                        administrator: e.target.checked
                      })}
                      disabled={!selectedUser.admin_portal}
                    /> Administrator
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedUser.configuration_manager || false}
                      onChange={(e) => setSelectedUser({
                        ...selectedUser,
                        configuration_manager: e.target.checked
                      })}
                      disabled={!selectedUser.admin_portal}
                    /> Configuration Manager
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedUser.approver || false}
                      onChange={(e) => setSelectedUser({
                        ...selectedUser,
                        approver: e.target.checked
                      })}
                      disabled={!selectedUser.admin_portal}
                    /> Approver
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedUser.validator || false}
                      onChange={(e) => setSelectedUser({
                        ...selectedUser,
                        validator: e.target.checked
                      })}
                      disabled={!selectedUser.admin_portal}
                    /> Validator
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedUser.scenario_generator || false}
                      onChange={(e) => setSelectedUser({
                        ...selectedUser,
                        scenario_generator: e.target.checked
                      })}
                      disabled={!selectedUser.admin_portal}
                    /> Scenario Generator
                  </label>
                </div>
              </div>

              <div className="modal-actions">
                <button
                  type="button"
                  onClick={() => setSelectedUser(null)}
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
                <button 
                  type="submit" 
                  className="btn btn-primary"
                  disabled={isLoading}
                >
                  {isLoading ? 'Saving...' : 'Save Changes'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="pagination">
        <div className="pagination-info">
          Showing {users.length} of {totalUsers} users
        </div>
        <div className="pagination-buttons">
          <button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
            className="btn btn-secondary"
          >
            Previous
          </button>
          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === Math.ceil(totalUsers / ITEMS_PER_PAGE)}
            className="btn btn-secondary"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default UsersPage;