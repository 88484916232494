import React from 'react';
import { useAuth } from '@/features/auth/context/AuthContext';

export const Header = () => {
    const { user, signOut } = useAuth();

    const handleLogout = async () => {
        try {
            await signOut();
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    return (
        <header className="fixed top-0 right-0 left-64 bg-white border-b border-gray-200 py-4 px-6 z-10">
            <div className="flex justify-between items-center">
                <div className="flex flex-col items-center flex-grow text-center">
                    <h1 className="text-2xl font-bold text-[#1e2337] tracking-wide">
                        Solved Administration and Scenario Review App
                    </h1>
                    {user && (
                        <div className="text-sm text-gray-600 mt-1">
                            Welcome, *** {user.email}
                        </div>
                    )}
                </div>
                <button 
                    onClick={handleLogout}
                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors text-sm"
                >
                    Logout
                </button>
            </div>
        </header>
    );
};