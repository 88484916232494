import { supabase } from '../../../lib/supabaseClient'  

export const sendPasswordResetEmail = async (email) => {
    try {
        const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
            redirectTo: `${window.location.origin}/reset-password`,
        });
        
        if (error) throw error;
        return { data, error: null };
    } catch (error) {
        console.error('Password reset email error:', error);
        return { data: null, error };
    }
};

export const resetPassword = async (newPassword) => {
    try {
        const { data, error } = await supabase.auth.updateUser({
            password: newPassword
        });

        if (error) throw error;

        await supabase
            .from('audit_logs')
            .insert([{
                user_email: data.user.email,
                action: 'password_update',
                details: 'User updated their password'
            }]);

        return { data, error: null };
    } catch (error) {
        console.error('Password reset error:', error);
        return { data: null, error };
    }
};