import React from 'react';

const ValidationResults = ({ validationResult, isValidating }) => (
  <div className="validation-results">
    <div className="validation-header">
      <h3 className="text-lg font-medium">Validation Results</h3>
    </div>
    <div className="validation-content">
      {isValidating && <p>Validating scenario...</p>}
      {validationResult && <p>Validation results are available</p>}
    </div>
  </div>
);

export default ValidationResults;
