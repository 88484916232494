
import { useState, useCallback, useEffect, useRef } from 'react';
import { Loader2, ChevronLeft, ChevronRight, Edit2, Save, X, Upload, Trash2 } from 'lucide-react';
import { supabase } from '@/lib/supabaseClient';
import { useDebounce } from '@/hooks/useDebounce';
import { useAuth } from '@/features/auth/context/AuthContext';
import { useReferenceData } from '@/shared/hooks/useReferenceData';
import ScenarioFilters from '@/shared/components/ScenarioFilters';
import { createAuditLog, AuditComponents, AuditActionTypes } from '@/lib/auditLogger';
import { TagsInput } from '../components/TagsInput';

const ITEMS_PER_PAGE = 10;

export default function ScenarioEditsPage() {
  const { session } = useAuth();
  const scrollRef = useRef(null);
  const [allScenarios, setAllScenarios] = useState([]);
  const [selectedScenario, setSelectedScenario] = useState(null);
  const [selectedScenarioId, setSelectedScenarioId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [editedScenario, setEditedScenario] = useState({
    title: '',
    status: '',
    category: '',
    diagnosiscategory: '',
    userrole: '',
    patientreport: '',
    comments: '',
    questiondifficulty: '',
    complexity: '',
    image: null,
    tags: [],
  });
  
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  
  const {
    status: statuses,
    categories,
    diagnosiscategory: diagnosisCategories,
    questiondifficulty: difficulties,
    complexity: complexities,
    roles,
    loading: referenceLoading,
    error: referenceError
  } = useReferenceData();
  useEffect(() => {
    console.log('Reference Data in ScenarioEditsPage:', {
      difficulties,
      complexities,
      selectedScenario,
      editedScenario
    });
  }, [difficulties, complexities, selectedScenario, editedScenario]);
  
  const [filters, setFilters] = useState({
    status: 'All',
    category: 'All',
    diagnosisCategory: 'All',
    difficulty: 'All',
    complexity: 'All',
    role: 'All'
  });

  const handleFilterChange = useCallback((e) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
    setCurrentPage(1);
  }, []);

  const clearFilters = useCallback(() => {
    setSearchTerm('');
    setFilters({
      status: 'All',
      category: 'All',
      diagnosisCategory: 'All',
      difficulty: 'All',
      complexity: 'All',
      role: 'All'
    });
    setCurrentPage(1);
    setSelectedScenarioId(null);
  }, []);

  
  const handleScroll = (event) => {
    const container = event.target;
    const scrollPercentage = (container.scrollLeft + container.clientWidth) / container.scrollWidth;
    
    if (scrollPercentage > 0.75) {
      setCurrentPage(prev => Math.min(prev + 1, Math.ceil(allScenarios.length / ITEMS_PER_PAGE)));
    }
  };

  const handleScrollButton = (direction) => {
    if (scrollRef.current) {
      const scrollAmount = direction === 'left' ? -300 : 300;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  
  const handleImageUpload = async (event) => {
    try {
      const file = event.target.files[0];
      if (!file) return;

      setIsUploading(true);
      setError(null);

      const maxSize = 5 * 1024 * 1024;
      if (file.size > maxSize) {
        throw new Error('File must be less than 5MB');
      }

      const fileExt = file.name.split('.').pop().toLowerCase();
      if (!['jpg', 'jpeg', 'png', 'gif'].includes(fileExt)) {
        throw new Error('Invalid file type');
      }

      if (selectedScenario?.image) {
        const oldFilePath = selectedScenario.image.split('/').slice(-2).join('/');
        await supabase.storage.from('scenario_images').remove([oldFilePath]);
      }

      const fileName = `${selectedScenario.scenarioid}-${Date.now()}.${fileExt}`;
      const filePath = `scenarios/${fileName}`;

      const { error: uploadError } = await supabase.storage
        .from('scenario_images')
        .upload(filePath, file);

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from('scenario_images')
        .getPublicUrl(filePath);

      setEditedScenario(prev => ({...prev, image: publicUrl}));

    } catch (err) {
      setError(err.message);
    } finally {
      setIsUploading(false);
    }
  };

  const handleImageRemove = async () => {
    if (!window.confirm('Remove this image?')) return;
    
    try {
      setIsUploading(true);
      if (selectedScenario?.image) {
        const filePath = selectedScenario.image.split('/').slice(-2).join('/');
        await supabase.storage.from('scenario_images').remove([filePath]);
      }
      setEditedScenario(prev => ({...prev, image: null}));
    } catch (err) {
      setError(err.message);
    } finally {
      setIsUploading(false);
    }
  };

  
  const fetchAllScenarios = useCallback(async () => {
    setIsLoading(true);
    try {
      let query = supabase
      .from('scenarios')
      .select('scenarioid, title, status, category, diagnosiscategory, userrole, image, patientreport, comments, questiondifficulty, complexity, tags')
      .order('scenarioid', { ascending: true }); 
  
      if (debouncedSearchTerm) {
        const searchTermLower = debouncedSearchTerm.toLowerCase();
        query = query.or(
          `title.ilike.%${searchTermLower}%,` +
          `category.ilike.%${searchTermLower}%,` +
          `diagnosiscategory.ilike.%${searchTermLower}%`
        );
      }
  
      Object.entries(filters).forEach(([key, value]) => {
        if (value && value !== 'All') {
          const fieldName = key.toLowerCase() === 'diagnosiscategory' ? 'diagnosiscategory' : 
                         key.toLowerCase() === 'role' ? 'userrole' : 
                         key.toLowerCase() === 'difficulty' ? 'questiondifficulty' : 
                         key.toLowerCase();
          query = query.eq(fieldName, value);
        }
      });
  
      const { data, error } = await query;
      if (error) throw error;
  
      const uniqueScenarios = data.reduce((acc, scenario) => {
        if (!acc[scenario.scenarioid] || scenario.order < acc[scenario.scenarioid].order) {
          acc[scenario.scenarioid] = scenario;
        }
        return acc;
      }, {});

      setAllScenarios(Object.values(uniqueScenarios));
      
    } catch (err) {
      console.error('Error fetching scenario data:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [debouncedSearchTerm, filters]);

  const handleScenarioUpdate = async (updatedScenario) => {
    try {
      setIsLoading(true);
      const updatePayload = {
        title: updatedScenario.title,
        status: updatedScenario.status,
        patientreport: updatedScenario.patientreport,
        userrole: updatedScenario.userrole,
        category: updatedScenario.category,
        diagnosiscategory: updatedScenario.diagnosiscategory,
        complexity: updatedScenario.complexity,
        questiondifficulty: updatedScenario.questiondifficulty,
        comments: updatedScenario.comments,
        tags: updatedScenario.tags,
        updated_at: new Date().toISOString()
      };

      if (updatedScenario.image !== undefined) {
        updatePayload.image = updatedScenario.image;
      }

      const { error: updateError } = await supabase
        .from('scenarios')
        .update(updatePayload)
        .eq('scenarioid', updatedScenario.scenarioid);

      if (updateError) throw updateError;

      await createAuditLog({
        component: AuditComponents.SCENARIOS_PAGE,
        action: AuditActionTypes.UPDATE,
        entityType: 'scenario',
        entityId: updatedScenario.scenarioid?.toString(),
        oldValues: selectedScenario,
        newValues: updatedScenario,
        userId: session?.user?.email || 'unknown',
        details: `Updated scenario ${updatedScenario.scenarioid}`
      });

      setSelectedScenario(updatedScenario);
      await fetchAllScenarios();
      setIsEditing(false);
      
    } catch (err) {
      console.error('Error updating scenario:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleScenarioSelect = useCallback((scenarioId) => {
    setSelectedScenarioId(scenarioId);
    const scenarioDetails = allScenarios.find(s => s.scenarioid === scenarioId);
    console.log(scenarioDetails)
    setSelectedScenario(scenarioDetails);
    setEditedScenario(scenarioDetails);
    setIsEditing(false);
  }, [allScenarios]);

  const handleScenarioDelete = async (scenarioId) => {
    if (!scenarioId) return;
    
    try {
      setIsLoading(true);
      
      const { data: scenarioToDelete, error: fetchError } = await supabase
        .from('scenarios')
        .select('*')
        .eq('scenarioid', scenarioId);
        
      if (fetchError) throw fetchError;
  
      if (selectedScenario?.image) {
        const filePath = selectedScenario.image.split('/').slice(-2).join('/');
        await supabase.storage.from('scenario_images').remove([filePath]);
      }
  
      const { error: deleteError } = await supabase
        .from('scenarios')
        .delete()
        .eq('scenarioid', scenarioId);
  
      if (deleteError) throw deleteError;
  
      await createAuditLog({
        component: AuditComponents.SCENARIOS_PAGE,
        action: AuditActionTypes.DELETE,
        entityType: 'scenario',
        entityId: scenarioId.toString(),
        oldValues: {
          scenarioId,
          scenario: scenarioToDelete
        },
        newValues: null,
        userId: session?.user?.email || 'unknown',
        details: `Deleted scenario ${scenarioId}`
      });
  
      setSelectedScenario(null);
      setSelectedScenarioId(null);
      await fetchAllScenarios();
      
    } catch (err) {
      console.error('Error deleting scenario:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  
  useEffect(() => {
    fetchAllScenarios();
  }, [fetchAllScenarios]);

  if (error || referenceError) {
    return (
      <div className="p-4 bg-red-50 border border-red-500 text-red-700 rounded-lg">
        {error || referenceError}
      </div>
    );
  }

  if (referenceLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <Loader2 className="animate-spin h-8 w-8" />
        <span className="ml-2">Loading reference data...</span>
      </div>
    );
  }

  return (
    <div className="h-screen flex flex-col overflow-hidden bg-gray-100">
      <div className="p-4">
        <div className="bg-white rounded-lg shadow p-6 mb-4">
          <h1 className="text-2xl font-bold">Scenario Editor</h1>
        </div>
      </div>

      <div className="flex-1 overflow-y-auto px-4 pb-4">
        <ScenarioFilters
          selectedStatus={filters.status}
          selectedCategory={filters.category}
          selectedDiagnosisCategory={filters.diagnosisCategory}
          selectedDifficulty={filters.difficulty}
          selectedComplexity={filters.complexity}
          selectedRole={filters.role}
          searchText={searchTerm}
          onStatusChange={(e) => handleFilterChange({ target: { name: 'status', value: e.target.value }})}
          onCategoryChange={(e) => handleFilterChange({ target: { name: 'category', value: e.target.value }})}
          onDiagnosisCategoryChange={(e) => handleFilterChange({ target: { name: 'diagnosisCategory', value: e.target.value }})}
          onDifficultyChange={(e) => handleFilterChange({ target: { name: 'difficulty', value: e.target.value }})}
          onComplexityChange={(e) => handleFilterChange({ target: { name: 'complexity', value: e.target.value }})}
          onRoleChange={(e) => handleFilterChange({ target: { name: 'role', value: e.target.value }})}
          onSearchChange={setSearchTerm}
          onClearFilters={clearFilters}
        />

        {/* Horizontal Scrolling Tile Selector */}
        <div className="relative bg-white rounded-lg shadow p-4 mb-4">
          <div className="flex items-center">
            <button
              onClick={() => handleScrollButton('left')}
              className="flex-none p-2 rounded-full bg-gray-100 hover:bg-gray-200 mr-2"
            >
              <ChevronLeft className="h-6 w-6" />
            </button>

            <div 
              ref={scrollRef}
              onScroll={handleScroll}
              className="flex-1 overflow-x-auto flex gap-4 px-2 py-1 scroll-smooth"
              style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
            >
              {allScenarios.map((scenario) => (
                <div
                  key={scenario.scenarioid}
                  onClick={() => handleScenarioSelect(scenario.scenarioid)}
                  className={`
                    flex-none cursor-pointer p-4 rounded-lg border-2 w-64
                    transition-all duration-200 hover:shadow-md
                    ${selectedScenarioId === scenario.scenarioid 
                      ? 'border-blue-500 bg-blue-50' 
                      : 'border-gray-200 hover:border-blue-300'}
                  `}
                >
                  <div className="mb-2 flex items-start justify-between">
                    <div>
                      <span className="text-sm text-gray-500">ID: {scenario.scenarioid}</span>
                      <h3 className="font-semibold text-gray-900 line-clamp-1">
                        {scenario.title || 'Untitled Scenario'}
                      </h3>
                    </div>
                    {scenario.status && (
                      <span className="px-2 py-1 text-xs rounded-full bg-yellow-100 text-yellow-800">
                        {scenario.status}
                      </span>
                    )}
                  </div>
                  
                  <div className="flex gap-2 flex-wrap">
                    {scenario.category && (
                      <span className="px-2 py-1 text-xs rounded-full bg-blue-100 text-blue-800">
                        {scenario.category}
                      </span>
                    )}
                    {scenario.diagnosiscategory && (
                      <span className="px-2 py-1 text-xs rounded-full bg-green-100 text-green-800">
                        {scenario.diagnosiscategory}
                      </span>
                    )}
                  </div>

                  <div className="mt-2 text-sm text-gray-600 line-clamp-2">
                    {scenario.patientreport || 'No description available'}
                  </div>
                </div>
              ))}
            </div>

            <button
              onClick={() => handleScrollButton('right')}
              className="flex-none p-2 rounded-full bg-gray-100 hover:bg-gray-200 ml-2"
            >
              <ChevronRight className="h-6 w-6" />
            </button>
          </div>
        </div>

        {/* Scenario Details Section */}
        {selectedScenario && (
          <div className="bg-white rounded-lg shadow p-6 mb-4">
            <div className="flex justify-between mb-6">
              <h2 className="text-xl font-semibold">
                {isEditing ? `Editing Scenario ${selectedScenario.scenarioid}` : `Scenario ${selectedScenario.scenarioid}`}
              </h2>
              <div className="flex gap-2">
                {isEditing ? (
                  <>
                    <button
                      onClick={() => handleScenarioUpdate(editedScenario)}
                      className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 flex items-center gap-2"
                    >
                      <Save className="h-4 w-4" />
                      Save
                    </button>
                    <button
                      onClick={() => {
                        setIsEditing(false);
                        setEditedScenario(selectedScenario);
                      }}
                      className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 flex items-center gap-2"
                    >
                      <X className="h-4 w-4" />
                      Cancel
                    </button>
                  </>
                ) : (
                  <div className="flex gap-2">
                    <button
                      onClick={() => setIsEditing(true)}
                      className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 flex items-center gap-2"
                    >
                      <Edit2 className="h-4 w-4" />
                      Edit
                    </button>
                    <button
                      onClick={() => {
                        if (window.confirm('Are you sure you want to delete this scenario and all its questions? This action cannot be undone.')) {
                          handleScenarioDelete(selectedScenario.scenarioid);
                        }
                      }}
                      className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 flex items-center gap-2"
                    >
                      <Trash2 className="h-4 w-4" />
                      Delete
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="flex gap-6">
              {/* Image Section */}
              <div className="w-64 flex-shrink-0 relative">
                <div className="relative w-full h-64">
                  {isUploading && (
                    <div className="absolute inset-0 bg-white/80 flex flex-col items-center justify-center z-10">
                      <Loader2 className="animate-spin h-6 w-6 text-blue-600 mb-2" />
                    </div>
                  )}
                  {isEditing ? (
                    editedScenario?.image ? (
                      <div className="relative group w-full h-full">
                        <img 
                          src={editedScenario.image}
                          alt={`Scenario ${selectedScenario.scenarioid}`}
                          className="w-full h-full object-contain rounded-lg"
                          onError={() => setError('Image failed to load')}
                        />
                        <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-30 transition-opacity rounded-lg" />
                        <div className="absolute top-2 right-2 flex gap-2 opacity-0 group-hover:opacity-100 transition-opacity">
                          <label className="p-1 bg-blue-500 text-white rounded-full cursor-pointer hover:bg-blue-600">
                            <Upload className="h-4 w-4" />
                            <input
                              type="file"
                              className="hidden"
                              accept="image/jpeg,image/png,image/gif"
                              onChange={handleImageUpload}
                              disabled={isUploading}
                            />
                          </label>
                          <button
                            onClick={handleImageRemove}
                            disabled={isUploading}
                            className="p-1 bg-red-500 text-white rounded-full hover:bg-red-600 disabled:bg-gray-400"
                          >
                            <X className="h-4 w-4" />
                          </button>
                        </div>
                      </div>
                    ) : (
                      <label className="flex flex-col items-center justify-center w-full h-full bg-gray-100 rounded-lg border-2 border-dashed border-gray-300 cursor-pointer hover:bg-gray-50">
                        <Upload className="h-6 w-6 text-gray-400" />
                        <span className="mt-2 text-sm text-gray-500">Click to upload image</span>
                        <span className="mt-1 text-xs text-gray-400">Max 5MB - JPEG, PNG, GIF</span>
                        <input
                          type="file"
                          className="hidden"
                          accept="image/jpeg,image/png,image/gif"
                          onChange={handleImageUpload}
                          disabled={isUploading}
                        />
                      </label>
                    )
                  ) : (
                    selectedScenario.image ? (
                      <img 
                        src={selectedScenario.image}
                        alt={`Scenario ${selectedScenario.scenarioid}`}
                        className="w-full h-full object-contain rounded-lg"
                      />
                    ) : (
                      <div className="w-full h-full bg-gray-100 rounded-lg flex items-center justify-center">
                        <span className="text-gray-400">No image</span>
                      </div>
                    )
                  )}
                </div>
              </div>

              {/* Form Fields */}
              <div className="flex-1">
                <div className="grid grid-cols-3 gap-6">
                  {isEditing ? (
                    <>
                      <div className="col-span-3">
                        <label className="block text-sm font-medium mb-1">Title</label>
                        <input
                          type="text"
                          value={editedScenario?.title || ''}
                          onChange={(e) => setEditedScenario(prev => ({...prev, title: e.target.value}))}
                          className="w-full rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      </div>
                      <div className="col-span-3">
                        <label className="block text-sm font-medium mb-1">Tags</label>
                        <TagsInput tags={editedScenario?.tags || []} onChange={tags => setEditedScenario(prev => ({...prev, tags }))} />
                      </div>
                      <div>
                        <label className="block text-sm font-medium mb-1">Status</label>
                        <select
                          value={editedScenario?.status || ''}
                          onChange={(e) => setEditedScenario(prev => ({...prev, status: e.target.value}))}
                          className="w-full rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                          <option value="">Select Status</option>
                          {statuses.map(status => (
                            <option key={status} value={status}>{status}</option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <label className="block text-sm font-medium mb-1">Category</label>
                        <select
                          value={editedScenario?.category || ''}
                          onChange={(e) => setEditedScenario(prev => ({...prev, category: e.target.value}))}
                          className="w-full rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                          <option value="">Select Category</option>
                          {categories.map(category => (
                            <option key={category} value={category}>{category}</option>
                          ))}
                        </select>
                      </div>

                      <div>
                        <label className="block text-sm font-medium mb-1">Diagnosis Category</label>
                        <select
                          value={editedScenario?.diagnosiscategory || ''}
                          onChange={(e) => setEditedScenario(prev => ({...prev, diagnosiscategory: e.target.value}))}
                          className="w-full rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                          <option value="">Select Diagnosis Category</option>
                          {diagnosisCategories.map(category => (
                            <option key={category} value={category}>{category}</option>
                          ))}
                        </select>
                      </div>

                      <div>
                        <label className="block text-sm font-medium mb-1">Role</label>
                        <select
                          value={editedScenario?.userrole || ''}
                          onChange={(e) => setEditedScenario(prev => ({...prev, userrole: e.target.value}))}
                          className="w-full rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                          <option value="">Select Role</option>
                          {roles.map(role => (
                            <option key={role} value={role}>{role}</option>
                          ))}
                        </select>
                      </div>

                      <div>
                        <label className="block text-sm font-medium mb-1">Complexity</label>
                        <select
                          value={editedScenario?.complexity || ''}
                          onChange={(e) => setEditedScenario(prev => ({...prev, complexity: e.target.value}))}
                          className="w-full rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                          <option value="">Select Complexity</option>
                          {complexities.map(complexity => (
                            <option key={complexity} value={complexity}>{complexity}</option>
                          ))}
                        </select>
                      </div>

                      <div>
                        <label className="block text-sm font-medium mb-1">Difficulty</label>
                        <select
                          value={editedScenario?.questiondifficulty || ''}
                          onChange={(e) => setEditedScenario(prev => ({...prev, questiondifficulty: e.target.value}))}
                          className="w-full rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                          <option value="">Select Difficulty</option>
                          {difficulties?.map(diff => (
                            <option key={diff.level} value={diff.level}>{diff.display_name}</option>
                          ))}
                        </select>
                      </div>

                      <div className="col-span-3">
                        <label className="block text-sm font-medium mb-1">Patient Record</label>
                        <textarea
                          value={editedScenario?.patientreport || ''}
                          onChange={(e) => setEditedScenario(prev => ({...prev, patientreport: e.target.value}))}
                          rows={4}
                          className="w-full rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      </div>

                      <div className="col-span-3">
                        <label className="block text-sm font-medium mb-1">Comments</label>
                        <textarea
                          value={editedScenario?.comments || ''}
                          onChange={(e) => setEditedScenario(prev => ({...prev, comments: e.target.value}))}
                          rows={4}
                          className="w-full rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-span-3">
                        <h3 className="font-semibold mb-1">Difficulty</h3>
                        <p className="text-gray-700">{selectedScenario.questiondifficulty || 'Not set'}</p>
                      </div>

                      <div className="col-span-3">
                        <h3 className="font-semibold mb-1">Patient Record</h3>
                        <p className="text-gray-700 whitespace-pre-wrap">{selectedScenario.patientreport || 'No patient record'}</p>
                      </div>

                      <div className="col-span-3">
                        <h3 className="font-semibold mb-1">Comments</h3>
                        <p className="text-gray-700 whitespace-pre-wrap">{selectedScenario.comments || 'No comments'}</p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

         {!selectedScenario && (
          <div className="bg-white rounded-lg shadow p-8 mb-4">
            <h2 className="text-2xl font-semibold mb-4">
              Welcome to the Scenario Editor
            </h2>
            
            <div className="space-y-6 text-gray-700">
              <p className="mb-4">Please follow these steps:</p>
              
              <ol className="list-decimal pl-6 space-y-4">
                <li>Use the filters above to narrow down the scenarios you want to work with.</li>
                <li>Select a scenario from the scrolling tiles. You can use the arrow buttons to navigate through the list.</li>
                <li>Review and edit the scenario details using the edit button.</li>
                <li>For question management, please use the Question Editor page.</li>
              </ol>

              <p className="mt-6">
                If you encounter any issues, please report them in the slack channel <span className="font-semibold">#defect_reporting_admin_portal</span>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}