
import React, { useState, useEffect, useCallback } from 'react';
import { fetchUtilizationData, handleError } from '../services/utilizationService';
import MemberMap from '../components/MemberMap';
import MemberMetrics from '../components/MemberMetrics';
import MemberDetails from '../components/MemberDetails';
import UtilizationFilters from '../components/UtilizationFilters';

const UtilizationDashboard = () => {
  console.log('UtilizationDashboard component mounting');
  
  const [activeView, setActiveView] = useState('metrics');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [utilizationData, setUtilizationData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(new Date());

  useEffect(() => {
    console.log('UtilizationDashboard useEffect running');
    let isMounted = true;

    const fetchData = async () => {
      console.log('Starting data fetch');
      try {
        setLoading(true);
        setError(null);
        const data = await fetchUtilizationData();
        
        if (isMounted) {
          console.log('Data fetched successfully:', data?.length, 'records');
          setUtilizationData(data);
          setFilteredData(data);
          setLastUpdated(new Date());
        }
      } catch (err) {
        console.error('Error in UtilizationDashboard:', err);
        if (isMounted) {
          setError(handleError(err));
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();
    
    const interval = setInterval(fetchData, 300000);
    
    return () => {
      console.log('UtilizationDashboard cleanup');
      isMounted = false;
      clearInterval(interval);
    };
  }, []);

  const handleFiltersChange = useCallback((newFilters) => {
    if (utilizationData) {
      const filtered = utilizationData.filter(user => {
        const roleMatch = !newFilters.role || user.role === newFilters.role;
        const stateMatch = !newFilters.state || user.state === newFilters.state;
        const countyMatch = !newFilters.county || user.county === newFilters.county;
        return roleMatch && stateMatch && countyMatch;
      });
      setFilteredData(filtered);
    }
  }, [utilizationData]);

  if (loading && !filteredData) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg text-gray-600">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500 mb-4 mx-auto"></div>
          Loading utilization data...
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="max-w-lg text-center">
          <h2 className="text-xl font-bold text-red-600 mb-2">Error Loading Dashboard</h2>
          <p className="text-gray-700">{error}</p>
          <button 
            onClick={() => window.location.reload()} 
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  if (!filteredData?.length) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg text-gray-600">No user data available</div>
      </div>
    );
  }

  const viewComponents = {
    metrics: <MemberMetrics data={filteredData} />,
    details: <MemberDetails data={filteredData} />,
    map: <MemberMap data={filteredData} />
  };

  const views = [
    { id: 'metrics', label: 'Main Metrics', description: 'Overview of key metrics and recent activity' },
    { id: 'details', label: 'Member Details', description: 'Detailed member information and statistics' },
    { id: 'map', label: 'User Map', description: 'Geographic distribution of members' }
  ];

  return (
    <div className="p-4 min-h-screen bg-gray-50">
      <div className="bg-white rounded-lg shadow-md border border-gray-200 p-6 mb-6">
        <div className="flex justify-between items-center mb-6">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">EMTeam Utilization Dashboard</h1>
            <p className="text-sm text-gray-500 mt-1">
              Monitoring member activity and engagement
            </p>
          </div>
          <div className="text-right">
            <div className="text-sm text-gray-500">
              Last updated: {lastUpdated.toLocaleString()}
            </div>
            {loading && (
              <div className="text-xs text-blue-500 mt-1">
                Refreshing data...
              </div>
            )}
          </div>
        </div>

        <UtilizationFilters onFiltersChange={handleFiltersChange} />
      </div>
      
      <div className="mb-6 border-b border-gray-200 bg-white rounded-t-lg shadow-sm">
        <div className="flex space-x-2 px-4">
          {views.map((view) => (
            <button
              key={view.id}
              className={`px-4 py-3 transition-colors relative ${
                activeView === view.id 
                  ? 'text-blue-600 border-b-2 border-blue-600' 
                  : 'text-gray-600 hover:text-gray-800 hover:bg-gray-50'
              }`}
              onClick={() => setActiveView(view.id)}
              title={view.description}
            >
              {view.label}
            </button>
          ))}
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-md border border-gray-200">
        <div className="p-6">
          {viewComponents[activeView]}
        </div>
      </div>
    </div>
  );
};

export default UtilizationDashboard;