
import { useState, useCallback, useEffect } from 'react';
import { Loader2, Edit2, Save, X, Trash2, Search } from 'lucide-react';
import { supabase } from '@/lib/supabaseClient';
import { useDebounce } from '@/hooks/useDebounce';
import { useAuth } from '@/features/auth/context/AuthContext';
import { useReferenceData } from '@/shared/hooks/useReferenceData';
import { createAuditLog, AuditComponents, AuditActionTypes } from '@/lib/auditLogger';

export default function QuestionEditsPage() {
  const { session } = useAuth();
  const [scenarios, setScenarios] = useState([]);
  const [allScenarios, setAllScenarios] = useState([]);
  const [selectedScenario, setSelectedScenario] = useState(null);
  const [selectedScenarioId, setSelectedScenarioId] = useState(null);
  const [editingQuestionId, setEditingQuestionId] = useState(null);
  const [editedQuestion, setEditedQuestion] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  
  const {
    status: statuses,
    categories,
    diagnosiscategory: diagnosisCategories,
    questiondifficulty: difficulties,
    complexity: complexities,
    roles,
    loading: referenceLoading,
    error: referenceError
  } = useReferenceData();

  
  useEffect(() => {
    console.log('Debug - Current state:', {
      selectedScenarioId,
      scenariosLength: scenarios.length,
      scenariosData: scenarios,
      isLoading
    });
  }, [selectedScenarioId, scenarios, isLoading]);

  const [filters, setFilters] = useState({
    status: 'All',
    category: 'All',
    diagnosisCategory: 'All',
    difficulty: 'All',
    complexity: 'All',
    role: 'All'
  });

  const handleFilterChange = useCallback((e) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  }, []);

  const clearFilters = useCallback(() => {
    setSearchTerm('');
    setFilters({
      status: 'All',
      category: 'All',
      diagnosisCategory: 'All',
      difficulty: 'All',
      complexity: 'All',
      role: 'All'
    });
    setSelectedScenarioId(null);
  }, []);
  const fetchAllScenarios = useCallback(async () => {
    setIsLoading(true);
    try {
      let query = supabase
        .from('scenarios')
        .select('scenarioid, title, status, category, diagnosiscategory, userrole, complexity, questiondifficulty')
        .order('scenarioid', { ascending: true });
  
      if (debouncedSearchTerm) {
        const searchTermLower = debouncedSearchTerm.toLowerCase();
        query = query.or(
          `title.ilike.%${searchTermLower}%,` +
          `category.ilike.%${searchTermLower}%,` +
          `diagnosiscategory.ilike.%${searchTermLower}%`
        );
      }
  
      Object.entries(filters).forEach(([key, value]) => {
        if (value && value !== 'All') {
          const fieldName = key.toLowerCase() === 'diagnosiscategory' ? 'diagnosiscategory' : 
                         key.toLowerCase() === 'role' ? 'userrole' : 
                         key.toLowerCase() === 'difficulty' ? 'questiondifficulty' : 
                         key.toLowerCase();
          query = query.eq(fieldName, value);
        }
      });
  
      const { data, error } = await query;
      if (error) throw error;
  
      const uniqueScenarios = {};
      data.forEach(scenario => {
        if (!uniqueScenarios[scenario.scenarioid]) {
          uniqueScenarios[scenario.scenarioid] = scenario;
        }
      });

      setAllScenarios(Object.values(uniqueScenarios));
      
    } catch (err) {
      console.error('Error fetching scenario data:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [debouncedSearchTerm, filters]);

  const fetchScenarioQuestions = useCallback(async () => {
    if (!selectedScenarioId) {
      console.log('No scenario ID selected');
      return;
    }
    
    setIsLoading(true);
    try {
      console.log('Fetching questions for scenario:', selectedScenarioId);
      
      const { data, error: fetchError } = await supabase
        .from('scenarios')
        .select('*')
        .eq('scenarioid', selectedScenarioId)
        .order('sequence_order', { ascending: true });

      if (fetchError) throw fetchError;
      
      console.log('Raw data from database:', data);

      const validQuestions = data?.filter(question => 
        question && 
        question.scenarioid === selectedScenarioId &&
        typeof question.sequence_order === 'number'
      ) || [];

      console.log('Valid questions after filtering:', validQuestions);
      
      if (validQuestions.length === 0) {
        console.log('No valid questions found after filtering');
      } else {
        console.log('First valid question:', validQuestions[0]);
      }

      setScenarios(validQuestions);
      
      if (validQuestions.length > 0) {
        const firstQuestion = validQuestions[0];
        setSelectedScenario({
          scenarioid: firstQuestion.scenarioid,
          title: firstQuestion.title,
          status: firstQuestion.status,
          category: firstQuestion.category,
          diagnosiscategory: firstQuestion.diagnosiscategory,
          userrole: firstQuestion.userrole,
          complexity: firstQuestion.complexity,
          questiondifficulty: firstQuestion.questiondifficulty
        });
      }
      
    } catch (err) {
      console.error('Error in fetchScenarioQuestions:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [selectedScenarioId]);
  const handleQuestionEdit = (question) => {
    const questionCopy = {
      ...JSON.parse(JSON.stringify(question)),
      sequence_order: question.sequence_order,
      status: question.status 
    };
    setEditedQuestion(questionCopy);
    setEditingQuestionId(`${question.scenarioid}-${question.sequence_order}`);
  };

  const handleQuestionSave = async () => {
    try {
      setIsLoading(true);
      
      if (!editedQuestion?.scenarioid || !selectedScenarioId) {
        throw new Error('Invalid scenario ID');
      }
  
      const sequenceOrderValue = parseInt(editedQuestion.sequence_order, 10);
      if (isNaN(sequenceOrderValue)) {
        throw new Error('Question sequence number must be a valid number');
      }
  
      const { data: existingQuestions, error: checkError } = await supabase
        .from('scenarios')
        .select('scenarioid, sequence_order')
        .eq('scenarioid', selectedScenarioId)
        .eq('sequence_order', sequenceOrderValue)
        .neq('scenarioid', editedQuestion.scenarioid);
  
      if (checkError) throw checkError;
  
      if (existingQuestions?.length > 0) {
        throw new Error(`Question sequence number ${sequenceOrderValue} is already in use in this scenario`);
      }
  
      
      const updatePayload = {
        ...editedQuestion,
        sequence_order: sequenceOrderValue,
        status: editedQuestion.status, 
        updated_at: new Date().toISOString()
      };
  
      const { error: updateError } = await supabase
        .from('scenarios')
        .update(updatePayload)
        .eq('scenarioid', editedQuestion.scenarioid)
        .eq('sequence_order', editedQuestion.sequence_order)
        .select();
  
      if (updateError) throw updateError;
  
      await createAuditLog({
        component: AuditComponents.SCENARIOS_PAGE,
        action: AuditActionTypes.UPDATE,
        entityType: 'question',
        entityId: `${editedQuestion.scenarioid}-${editedQuestion.sequence_order}`,
        oldValues: scenarios.find(s => s.id === editedQuestion.id),
        newValues: updatePayload,
        userId: session?.user?.email || 'unknown',
        details: `Updated question ${editedQuestion.sequence_order} in scenario ${editedQuestion.scenarioid}, including status change to ${editedQuestion.status}`
      });
  
      await fetchScenarioQuestions();
      
      setEditingQuestionId(null);
      setEditedQuestion(null);
  
    } catch (err) {
      console.error('Error updating question:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleQuestionDelete = async (questionId) => {
    try {
      setIsLoading(true);
      
      const questionToDelete = scenarios.find(s => s.id === questionId);
      if (!questionToDelete) throw new Error('Question not found');
  
      const remainingQuestions = scenarios.filter(s => 
        s.scenarioid === questionToDelete.scenarioid && s.id !== questionId
      );
  
      if (remainingQuestions.length === 0) {
        if (!window.confirm(
          'This is the last question in the scenario. Are you sure you want to delete it?'
        )) {
          return;
        }
      }
  
      const { error: deleteError } = await supabase
        .from('scenarios')
        .delete()
        .eq('id', questionId);
  
      if (deleteError) throw deleteError;
  
      await createAuditLog({
        component: AuditComponents.SCENARIOS_PAGE,
        action: AuditActionTypes.DELETE,
        entityType: 'question',
        entityId: `${questionToDelete.scenarioid}-${questionToDelete.sequence_order}`,
        oldValues: questionToDelete,
        newValues: null,
        userId: session?.user?.email || 'unknown',
        details: `Deleted question ${questionToDelete.sequence_order} from scenario ${questionToDelete.scenarioid}`
      });
  
      setScenarios(prevScenarios => 
        prevScenarios.filter(s => s.id !== questionId)
      );
  
      if (remainingQuestions.length === 0) {
        setSelectedScenarioId(null);
        setSelectedScenario(null);
        await fetchAllScenarios();
      }
  
    } catch (err) {
      console.error('Error deleting question:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchAllScenarios();
  }, [fetchAllScenarios]);

  useEffect(() => {
    if (selectedScenarioId) {
      fetchScenarioQuestions();
    }
  }, [selectedScenarioId, fetchScenarioQuestions]);
  if (error || referenceError) {
    return (
      <div className="p-4 bg-red-50 border border-red-500 text-red-700 rounded-lg">
        {error || referenceError}
      </div>
    );
  }

  if (referenceLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <Loader2 className="animate-spin h-8 w-8" />
        <span className="ml-2">Loading reference data...</span>
      </div>
    );
  }
  return (
    <div className="h-screen flex flex-col overflow-hidden bg-gray-100">
      <div className="p-4">
        <div className="bg-white rounded-lg shadow p-6 mb-4">
          <h1 className="text-2xl font-bold">Question Editor</h1>
        </div>
      </div>

      <div className="flex-1 overflow-y-auto px-4 pb-4">
      <div className="bg-white rounded-lg shadow p-6 mb-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Filters</h2>
            <button 
              onClick={clearFilters}
              className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors"
            >
              Clear Filters
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Status</label>
              <select
                value={filters.status}
                onChange={(e) => handleFilterChange({ target: { name: 'status', value: e.target.value }})}
                className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="All">All Statuses</option>
                {statuses.map((status) => (
                  <option key={status || 'none'} value={status || ''}>
                    {status || '(No Status)'}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Category</label>
              <select
                value={filters.category}
                onChange={(e) => handleFilterChange({ target: { name: 'category', value: e.target.value }})}
                className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="All">All Categories</option>
                {categories.map((category) => (
                  <option key={category} value={category}>{category}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Diagnosis Category</label>
              <select
                value={filters.diagnosisCategory}
                onChange={(e) => handleFilterChange({ target: { name: 'diagnosisCategory', value: e.target.value }})}
                className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="All">All Diagnosis Categories</option>
                {diagnosisCategories.map((category) => (
                  <option key={category} value={category}>{category}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Question Difficulty</label>
              <select
                value={filters.difficulty}
                onChange={(e) => handleFilterChange({ target: { name: 'difficulty', value: e.target.value }})}
                className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="All">All Difficulties</option>
                {difficulties.map((diff) => (
                  <option key={`${diff.level}-${diff.id}`} value={diff.level}>
                    {diff.display_name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Complexity</label>
              <select
                value={filters.complexity}
                onChange={(e) => handleFilterChange({ target: { name: 'complexity', value: e.target.value }})}
                className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="All">All Complexities</option>
                {complexities.map((complexity) => (
                  <option key={complexity} value={complexity}>{complexity}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">User Role</label>
              <select
                value={filters.role}
                onChange={(e) => handleFilterChange({ target: { name: 'role', value: e.target.value }})}
                className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="All">All Roles</option>
                {roles.map((role) => (
                  <option key={role} value={role}>{role}</option>
                ))}
              </select>
            </div>

            <div className="md:col-span-2 lg:col-span-3">
              <label className="block text-sm font-medium text-gray-700 mb-1">Search</label>
              <div className="relative">
                <input
                  type="search"
                  value={searchTerm}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!value || /^[\w\s-]*$/.test(value)) {
                      setSearchTerm(value);
                    }
                  }}
                  placeholder="Search..."
                  className="w-full border rounded-md p-2 pr-10 focus:border-blue-500 focus:ring-blue-500"
                />
                <div className="absolute right-3 top-2.5 text-gray-400">
                  <Search className="h-5 w-5" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Scenario Selection */}
        <div className="bg-white rounded-lg shadow p-4 mb-4">
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">Select Scenario</label>
            <select
              value={selectedScenarioId || ''}
              onChange={(e) => {
                const value = e.target.value;
                setSelectedScenarioId(value ? parseInt(value, 10) : null);
              }}
              className="w-full rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Choose a scenario...</option>
              {allScenarios.map((scenario) => (
                <option 
                  key={scenario.scenarioid} 
                  value={scenario.scenarioid}
                >
                  {`ID: ${scenario.scenarioid} - ${scenario.title || 'Untitled'}`}
                </option>
              ))}
            </select>
          </div>

          {/* Selected Scenario Info */}
          {selectedScenario && (
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4 bg-gray-50 rounded-lg">
              <div>
                <h3 className="text-sm font-medium text-gray-500">Status</h3>
                <p className="mt-1">{selectedScenario.status || 'Not set'}</p>
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-500">Category</h3>
                <p className="mt-1">{selectedScenario.category || 'Not set'}</p>
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-500">Diagnosis Category</h3>
                <p className="mt-1">{selectedScenario.diagnosiscategory || 'Not set'}</p>
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-500">User Role</h3>
                <p className="mt-1">{selectedScenario.userrole || 'Not set'}</p>
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-500">Complexity</h3>
                <p className="mt-1">{selectedScenario.complexity || 'Not set'}</p>
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-500">Difficulty</h3>
                <p className="mt-1">{selectedScenario.questiondifficulty || 'Not set'}</p>
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-500">Total Questions</h3>
                <p className="mt-1">{scenarios.length}</p>
              </div>
            </div>
          )}
        </div>
        {/* Questions Table or Welcome Message */}
        {isLoading ? (
          <div className="bg-white rounded-lg shadow p-8 text-center">
            <Loader2 className="animate-spin h-8 w-8 mx-auto" />
            <p className="mt-2">Loading questions...</p>
          </div>
        ) : selectedScenarioId && Array.isArray(scenarios) && scenarios.length > 0 ? (
          <div className="bg-white rounded-lg shadow">
            <div className="p-4 border-b">
              <h2 className="text-lg font-semibold">Questions</h2>
              <p className="text-sm text-gray-600">
                {scenarios.length} question{scenarios.length !== 1 ? 's' : ''} in this scenario
              </p>
            </div>

            <div className="overflow-auto" style={{ maxHeight: 'calc(100vh - 380px)' }}>
              <table className="w-full border-collapse whitespace-nowrap">
                <thead>
                  <tr>
                    <th className="sticky top-0 left-0 bg-gray-50 z-20 px-4 py-2 border">Actions</th>
                    <th className="sticky top-0 bg-gray-50 z-10 px-4 py-2 border">Sequence</th>
                    <th className="sticky top-0 bg-gray-50 z-10 px-4 py-2 border">Status</th>
                    <th className="sticky top-0 bg-gray-50 z-10 px-4 py-2 border text-left">Question</th>
                    <th className="sticky top-0 bg-gray-50 z-10 px-4 py-2 border text-left">Option 1</th>
                    <th className="sticky top-0 bg-gray-50 z-10 px-4 py-2 border text-left">Option 2</th>
                    <th className="sticky top-0 bg-gray-50 z-10 px-4 py-2 border text-left">Option 3</th>
                    <th className="sticky top-0 bg-gray-50 z-10 px-4 py-2 border text-left">Option 4</th>
                    <th className="sticky top-0 bg-gray-50 z-10 px-4 py-2 border">Answer</th>
                    <th className="sticky top-0 bg-gray-50 z-10 px-4 py-2 border text-left">Explanation 1</th>
                    <th className="sticky top-0 bg-gray-50 z-10 px-4 py-2 border text-left">Explanation 2</th>
                    <th className="sticky top-0 bg-gray-50 z-10 px-4 py-2 border text-left">Explanation 3</th>
                    <th className="sticky top-0 bg-gray-50 z-10 px-4 py-2 border text-left">Explanation 4</th>
                  </tr>
                </thead>
                <tbody>
                  {scenarios.map(question => {
                    const questionId = `${question.scenarioid}-${question.sequence_order}`;
                    return (
                      <tr key={questionId} className="hover:bg-gray-50">
                        <td className="sticky left-0 bg-white z-10 px-4 py-2 border">
                          <div className="flex space-x-2">
                            {editingQuestionId === questionId ? (
                              <>
                                <button
                                  onClick={handleQuestionSave}
                                  className="p-1 text-green-600 hover:bg-green-50 rounded"
                                  title="Save changes"
                                >
                                  <Save className="h-4 w-4" />
                                </button>
                                <button
                                  onClick={() => {
                                    setEditingQuestionId(null);
                                    setEditedQuestion(null);
                                  }}
                                  className="p-1 text-gray-600 hover:bg-gray-50 rounded"
                                  title="Cancel"
                                >
                                  <X className="h-4 w-4" />
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  onClick={() => handleQuestionEdit(question)}
                                  className="p-1 text-blue-600 hover:bg-blue-50 rounded"
                                  title="Edit question"
                                >
                                  <Edit2 className="h-4 w-4" />
                                </button>
                                <button
                                  onClick={() => {
                                    if (window.confirm('Are you sure you want to delete this question? This cannot be undone.')) {
                                      handleQuestionDelete(question.id);
                                    }
                                  }}
                                  className="p-1 text-red-600 hover:bg-red-50 rounded"
                                  title="Delete question"
                                >
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              </>
                            )}
                          </div>
                        </td>

                        <td className="px-4 py-2 border">
                          {editingQuestionId === questionId ? (
                            <input
                              type="number"
                              value={editedQuestion?.sequence_order ?? ''}
                              onChange={(e) => setEditedQuestion(prev => ({...prev, sequence_order: e.target.value}))}
                              className="w-20 border rounded p-1"
                            />
                          ) : (
                            <div className="text-center">{question.sequence_order}</div>
                          )}
                        </td>
                        <td className="px-4 py-2 border">
                          {editingQuestionId === questionId ? (
                            <select
                              value={editedQuestion?.status ?? ''}
                              onChange={(e) => setEditedQuestion(prev => ({...prev, status: e.target.value}))}
                              className="w-full border rounded p-1"
                            >
                              <option value="">Select Status</option>
                              {statuses.map(status => (
                                <option key={status} value={status}>{status}</option>
                              ))}
                            </select>
                          ) : (
                            <div className="text-center">
                              <span className={`inline-block px-2 py-1 rounded-full text-xs
                                ${question.status === 'Draft' ? 'bg-yellow-100 text-yellow-800' :
                                  question.status === 'Published' ? 'bg-green-100 text-green-800' :
                                  question.status === 'Review' ? 'bg-blue-100 text-blue-800' :
                                  'bg-gray-100 text-gray-800'}`}>
                                {question.status || 'Not set'}
                              </span>
                            </div>
                          )}
                        </td>
                        <td className="px-4 py-2 border">
                          {editingQuestionId === questionId ? (
                            <textarea
                              value={editedQuestion?.question ?? ''}
                              onChange={(e) => setEditedQuestion(prev => ({...prev, question: e.target.value}))}
                              className="w-full min-w-[200px] border rounded p-1"
                              rows={3}
                            />
                          ) : (
                            <div className="whitespace-pre-wrap min-w-[200px] max-w-md">
                              {question.question}
                            </div>
                          )}
                        </td>

                        {[1, 2, 3, 4].map(num => (
                          <td key={`option-${num}`} className="px-4 py-2 border">
                            {editingQuestionId === questionId ? (
                              <textarea
                                value={editedQuestion?.[`option${num}`] ?? ''}
                                onChange={(e) => setEditedQuestion(prev => ({
                                  ...prev,
                                  [`option${num}`]: e.target.value
                                }))}
                                className="w-full min-w-[200px] border rounded p-1"
                                rows={3}
                              />
                            ) : (
                              <div className="whitespace-pre-wrap min-w-[200px] max-w-md">
                                {question[`option${num}`]}
                              </div>
                            )}
                          </td>
                        ))}

                        <td key={`answer`} className="px-4 py-2 border">
                          {editingQuestionId === questionId ? (
                            <input
                              type="number"
                              value={editedQuestion?.answer ?? ''}
                              onChange={(e) => setEditedQuestion(prev => ({...prev, answer: e.target.value}))}
                              className="w-20 border rounded p-1"
                            />
                          ) : (
                            <div className="text-center">{question.answer}</div>
                          )}
                        </td>

                        {[1, 2, 3, 4].map(num => (
                          <td key={`explanation-${num}`} className="px-4 py-2 border">
                            {editingQuestionId === questionId ? (
                              <textarea
                                value={editedQuestion?.[`explanation${num}`] ?? ''}
                                onChange={(e) => setEditedQuestion(prev => ({
                                  ...prev,
                                  [`explanation${num}`]: e.target.value
                                }))}
                                className="w-full min-w-[200px] border rounded p-1"
                                rows={3}
                              />
                            ) : (
                              <div className="whitespace-pre-wrap min-w-[200px] max-w-md">
                                {question[`explanation${num}`]}
                              </div>
                            )}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : selectedScenarioId ? (
          <div className="bg-white rounded-lg shadow p-8 text-center">
            <p>No questions found for this scenario.</p>
          </div>
        ) : (
          <div className="bg-white rounded-lg shadow p-8 mb-4">
            <h2 className="text-2xl font-semibold mb-4">
              Welcome to the Question Editor
            </h2>
              
            <div className="space-y-6 text-gray-700">
              <p className="mb-4">Please follow these steps:</p>
              
              <ol className="list-decimal pl-6 space-y-4">
                <li>Use the filters above to narrow down the scenarios you want to work with.</li>
                <li>Select a scenario from the dropdown menu.</li>
                <li>Review and edit the questions using the questions table below.</li>
                <li>For scenario details management, please use the Scenario Editor page.</li>
              </ol>

              <p className="mt-6">
                If you encounter any issues, please report them in the slack channel <span className="font-semibold">#defect_reporting_admin_portal</span>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}