import { useEffect, useState } from 'react';
import { supabase } from '@/lib/supabaseClient';

const DEFAULT_DATA = {
  status: [],
  categories: [],
  diagnosiscategory: [],
  questiondifficulty: [],
  complexity: ['Easy', 'Moderate', 'Complex'],
  roles: []
};

export const useReferenceData = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [referenceData, setReferenceData] = useState(DEFAULT_DATA);

  useEffect(() => {
    const fetchReferenceData = async () => {
      try {
        setLoading(true);
        setError(null);

        
        const { data: diagnosisCategoriesData, error: diagnosisCategoriesError } = await supabase
          .from('reference_categories')
          .select('category')
          .eq('active', true)
          .order('category');

        if (diagnosisCategoriesError) {
          console.error('Diagnosis categories error:', diagnosisCategoriesError);
          throw diagnosisCategoriesError;
        }

        
        const { data: scenariosData, error: scenariosError } = await supabase
          .from('scenarios')
          .select('status, category');

        if (scenariosError) {
          console.error('Scenarios error:', scenariosError);
          throw scenariosError;
        }

        
        const { data: rolesData, error: rolesError } = await supabase
          .from('reference_roles')
          .select('role')
          .eq('active', true)
          .order('role');

        if (rolesError) {
          console.error('Roles error:', rolesError);
          throw rolesError;
        }

        
        const { data: difficultyData, error: difficultyError } = await supabase
          .from('reference_difficulty')
          .select('level, display_name')
          .eq('active', true)
          .order('level');

        if (difficultyError) {
          console.error('Difficulty error:', difficultyError);
          throw difficultyError;
        }

        
        const newData = {
          categories: [...new Set(scenariosData
            .map(item => item.category)
            .filter(Boolean))]
            .sort(),

          diagnosiscategory: diagnosisCategoriesData
            .map(item => item.category)
            .sort(),

          status: [...new Set(scenariosData
            .map(item => item.status)
            .filter(Boolean))]
            .sort(),

          questiondifficulty: difficultyData,
          complexity: DEFAULT_DATA.complexity,
          roles: rolesData.map(item => item.role)
        };

        console.log('Processed reference data:', {
          diagnosisCategories: newData.diagnosiscategory,
          categories: newData.categories,
          status: newData.status,
          roles: newData.roles,
          difficulties: newData.questiondifficulty
        });

        setReferenceData(newData);

      } catch (err) {
        console.error('Error in useReferenceData:', err);
        setError(err.message || 'Failed to load reference data');
      } finally {
        setLoading(false);
      }
    };

    fetchReferenceData();
  }, []);

  return {
    ...referenceData,
    loading,
    error: typeof error === 'string' ? error : null
  };
};