import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const RouteLogger = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    console.log('Current route:', location.pathname);
    console.log('Route state:', location.state);
  }, [location]);

  return <>{children}</>;
};

export default RouteLogger;