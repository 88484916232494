
import React, { useMemo } from 'react';

const UsersByStateCountyTable = ({ data }) => {
  const groupedData = useMemo(() => {
    const grouped = {};
    data.forEach(user => {
      const state = user.state || 'Unknown';
      const county = user.county || 'Unknown';
      const role = user.role || 'Unknown';

      if (!grouped[state]) grouped[state] = {};
      if (!grouped[state][county]) grouped[state][county] = {};
      if (!grouped[state][county][role]) grouped[state][county][role] = 0;

      grouped[state][county][role]++;
    });
    return grouped;
  }, [data]);

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200 mt-6">
      <div className="border-b border-gray-200 bg-[#004175] text-white px-4 py-2 font-semibold">
        Users by State/County with Roles
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full">
          <thead className="bg-[#004175] text-white">
            <tr>
              <th className="px-4 py-2 text-left text-sm font-semibold">State</th>
              <th className="px-4 py-2 text-left text-sm font-semibold">County</th>
              <th className="px-4 py-2 text-left text-sm font-semibold">Role</th>
              <th className="px-4 py-2 text-left text-sm font-semibold">Total Users</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {Object.entries(groupedData).map(([state, counties]) =>
              Object.entries(counties).map(([county, roles]) =>
                Object.entries(roles).map(([role, count], idx) => (
                  <tr key={`${state}-${county}-${role}`} className="hover:bg-gray-50">
                    <td className="px-4 py-2 text-sm">{state}</td>
                    <td className="px-4 py-2 text-sm">{county}</td>
                    <td className="px-4 py-2 text-sm">{role}</td>
                    <td className="px-4 py-2 text-sm">{count}</td>
                  </tr>
                ))
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const MemberDetails = ({ data }) => {
  const metrics = useMemo(() => {
    const totalUsers = data.length;
    const activeUsers = data.filter(u => u.last_seen_bucket === '72 Hours').length;
    const recentUsers = data.filter(u => {
      const created = new Date(u.usercreated);
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
      return created >= sevenDaysAgo;
    }).length;

    return {
      totalUsers,
      activeUsers,
      recentUsers,
      activePercentage: ((activeUsers / totalUsers) * 100).toFixed(1),
      recentPercentage: ((recentUsers / totalUsers) * 100).toFixed(1)
    };
  }, [data]);

  return (
    <div>
      {/* Summary Stats */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 bg-[#B7C9D3] p-4 rounded-lg">
        <div className="bg-white rounded-lg shadow-sm p-4 border border-gray-200">
          <div className="text-sm text-gray-600 mb-1">Total Users</div>
          <div className="text-2xl font-bold">{metrics.totalUsers}</div>
        </div>
        <div className="bg-white rounded-lg shadow-sm p-4 border border-gray-200">
          <div className="text-sm text-gray-600 mb-1">Active Users (Last 72h)</div>
          <div className="text-2xl font-bold">{metrics.activeUsers}</div>
          <div className="text-xs text-gray-500 mt-1">
            {metrics.activePercentage}% of total
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-sm p-4 border border-gray-200">
          <div className="text-sm text-gray-600 mb-1">New Users (Last 7 days)</div>
          <div className="text-2xl font-bold">{metrics.recentUsers}</div>
          <div className="text-xs text-gray-500 mt-1">
            {metrics.recentPercentage}% of total
          </div>
        </div>
      </div>

      {/* Main User Table */}
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 mt-6">
        <div className="border-b border-gray-200 bg-[#004175] text-white px-4 py-2 font-semibold">
          Member Details
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead className="bg-[#004175] text-white">
              <tr>
                <th className="px-4 py-2 text-left text-sm font-semibold">Full Name</th>
                <th className="px-4 py-2 text-left text-sm font-semibold">Email</th>
                <th className="px-4 py-2 text-left text-sm font-semibold">Role</th>
                <th className="px-4 py-2 text-left text-sm font-semibold">County</th>
                <th className="px-4 py-2 text-left text-sm font-semibold">State</th>
                <th className="px-4 py-2 text-left text-sm font-semibold">Last Seen</th>
                <th className="px-4 py-2 text-left text-sm font-semibold">Streak</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {data.map((member, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="px-4 py-2 text-sm">{member.fullName}</td>
                  <td className="px-4 py-2 text-sm">{member.email}</td>
                  <td className="px-4 py-2 text-sm">{member.role}</td>
                  <td className="px-4 py-2 text-sm">{member.county}</td>
                  <td className="px-4 py-2 text-sm">{member.state}</td>
                  <td className="px-4 py-2 text-sm">
                    {member.lastseen ? new Date(member.lastseen).toLocaleDateString() : 'Never'}
                  </td>
                  <td className="px-4 py-2 text-sm">{member.streak}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="px-4 py-2 border-t border-gray-200 text-sm text-gray-500 text-right">
          1-15 / {data.length}
        </div>
      </div>

      {/* Users by State/County Table */}
      <UsersByStateCountyTable data={data} />
    </div>
  );
};

export default MemberDetails;