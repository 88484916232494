
import React, { useState, useEffect } from 'react';
import { Search, Loader2 } from 'lucide-react';
import { supabase } from '@/lib/supabaseClient';
import ScenarioImage from '@/features/review/components/ScenarioImage';
import { useDebounce } from '@/hooks/useDebounce';  

const ScenarioReviewForm = ({ 
  scenario, 
  onUpdate, 
  onNext, 
  onPrevious,
  onDelete,
  
  filters,
  onFilterChange,
  searchText,
  onSearchChange
}) => {
  
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editingScenario, setEditingScenario] = useState(null);

  
  
  const [statuses, setStatuses] = useState(['All Statuses']);
  const [categories, setCategories] = useState(['All Categories']);
  const [diagnosisCategories, setDiagnosisCategories] = useState(['All Diagnosis Categories']);

  
  
  useEffect(() => {
    fetchFilterOptions();
    
    return () => {
      setEditMode(false);
      setIsLoading(false);
      setEditingScenario(null);
    };
  }, []);

  
      
  
  useEffect(() => {
    if (editMode && scenario) {
      setEditingScenario({
        ...scenario,
        scenarioid: scenario.scenarioid || '',
        sequence_order: scenario.sequence_order || '',
        category: scenario.category || '',
        diagnosiscategory: scenario.diagnosiscategory || '',
        title: scenario.title || '',
        image: scenario.image || '',
        patientreport: scenario.patientreport || '',
        userrole: scenario.userrole || '',
        question: scenario.question || '',
        option1: scenario.option1 || '',
        option2: scenario.option2 || '',
        option3: scenario.option3 || '',
        option4: scenario.option4 || '',
        explanation1: scenario.explanation1 || '',
        explanation2: scenario.explanation2 || '',
        explanation3: scenario.explanation3 || '',
        explanation4: scenario.explanation4 || '',
        answer: scenario.answer || '',
        meds: scenario.meds || '',
        exam: scenario.exam || '',
        physicalexam: scenario.physicalexam || '',
        comments: scenario.comments || '',
        tags: scenario.tags || ''
      });
    } else {
      setEditingScenario(null);
    }
  }, [editMode, scenario]);


  
  const fetchFilterOptions = async () => {
    try {
      setIsLoading(true);
      
      
      const { data: statusData, error: statusError } = await supabase
        .from('scenarios')
        .select('status')
        .not('status', 'is', null);
      
      if (statusError) throw statusError;
      
      
      const { data: categoryData, error: categoryError } = await supabase
        .from('scenarios')
        .select('category')
        .not('category', 'is', null);
      
      if (categoryError) throw categoryError;
      
      
      const { data: diagnosisData, error: diagnosisError } = await supabase
        .from('scenarios')
        .select('diagnosiscategory')
        .not('diagnosiscategory', 'is', null);
      
      if (diagnosisError) throw diagnosisError;

      
      setStatuses(['All Statuses', ...new Set(statusData.map(s => s.status))]);
      setCategories(['All Categories', ...new Set(categoryData.map(c => c.category))]);
      setDiagnosisCategories(['All Diagnosis Categories', ...new Set(diagnosisData.map(d => d.diagnosiscategory))]);
      
    } catch (error) {
      console.error('Error fetching filter options:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditingScenario(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      await onUpdate(editingScenario);
      setEditMode(false);
    } catch (error) {
      console.error('Error saving scenario:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageUpload = async (file) => {
    if (!file) return;
    
    setIsLoading(true);
    try {
      const fileExt = file.name.split('.').pop();
      const fileName = `${editingScenario.scenarioid}-${Date.now()}.${fileExt}`;
      
      const { error: uploadError } = await supabase.storage
        .from('scenario-images')
        .upload(fileName, file);

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from('scenario-images')
        .getPublicUrl(fileName);

      handleInputChange({
        target: {
          name: 'image',
          value: publicUrl
        }
      });
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCurrentValue = (fieldName) => {
    if (editMode) {
      return editingScenario?.[fieldName] || '';
    }
    return scenario?.[fieldName] || '';
  };

  return (
    <div className="p-4">
      {/* Title */}
      <h2 className="text-2xl font-bold mb-4">
        Scenario {scenario?.scenarioid || ''} Question {scenario?.order || ''}
      </h2>

      {/* Filters Section */}
      <div className="bg-white rounded-lg shadow p-4 mb-6">
        {/* Status Filter */}
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Filter by Status:</label>
          <select 
            value={filters.status}
            onChange={(e) => onFilterChange({ ...filters, status: e.target.value })}
            className="w-full border rounded-md p-2"
          >
            {statuses.map((status, index) => (
              <option key={index} value={status}>{status}</option>
            ))}
          </select>
        </div>

        <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            <label className="block text-sm font-medium mb-1">Category</label>
            <select 
              value={filters.category}
              onChange={(e) => onFilterChange({ ...filters, category: e.target.value })}
              className="w-full border rounded-md p-2"
            >
              {categories.map((category, index) => (
                <option key={index} value={category}>{category}</option>
              ))}
            </select>
          </div>
          
          <div>
            <label className="block text-sm font-medium mb-1">Diagnosis Category</label>
            <select 
              value={filters.diagnosisCategory}
              onChange={(e) => onFilterChange({ ...filters, diagnosisCategory: e.target.value })}
              className="w-full border rounded-md p-2"
            >
              {diagnosisCategories.map((category, index) => (
                <option key={index} value={category}>{category}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium mb-1">Difficulty</label>
            <select 
              value={filters.difficulty}
              onChange={(e) => onFilterChange({ ...filters, difficulty: e.target.value })}
              className="w-full border rounded-md p-2"
            >
              <option value="All">All</option>
              {[...Array(10)].map((_, i) => (
                <option key={i + 1} value={i + 1}>{i + 1}</option>
              ))}
            </select>
          </div>
          
          <div>
            <label className="block text-sm font-medium mb-1">Complexity</label>
            <select 
              value={filters.complexity}
              onChange={(e) => onFilterChange({ ...filters, complexity: e.target.value })}
              className="w-full border rounded-md p-2"
            >
              <option value="All">All</option>
              <option value="Easy">Easy</option>
              <option value="Moderate">Moderate</option>
              <option value="Complex">Complex</option>
            </select>
          </div>



<div>
  <label className="block text-sm font-medium mb-1">Search</label>
  <div className="relative">
    <input
      type="search"  
      value={searchText}
      onChange={(e) => {
        const value = e.target.value;
        if (!value || /^[\w\s-]*$/.test(value)) {
          onSearchChange(value);
        }
      }}
      placeholder="Enter at least 2 characters to search..."
      className="w-full border rounded-md p-2 pr-10"
      disabled={isLoading}
      autoComplete="off"  
      autoCorrect="off"   
      spellCheck="false"  
      aria-label="Search scenarios"  
      data-form-type="other"  
    />
    <div className="absolute right-3 top-2.5 text-gray-400">
      {isLoading ? (
        <Loader2 className="animate-spin h-5 w-5" />
      ) : (
        <Search className="h-5 w-5" />
      )}
    </div>
  </div>
  {searchText && searchText.length === 1 && (
    <div className="mt-1 text-sm text-gray-500">
      Enter one more character to start searching...
    </div>
  )}
  {isLoading && searchText.length >= 2 && (
    <div className="mt-1 text-sm text-gray-500">
      Searching...
    </div>
  )}
</div>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex items-center gap-4 mb-6 bg-white p-4 rounded-lg shadow">
        <div className="flex gap-2">
          <button onClick={onPrevious} className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600">
            Previous
          </button>
          <button onClick={onNext} className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600">
            Next
          </button>
          <button className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600">
            Add Question
          </button>
          <button onClick={onDelete} className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600">
            Delete Question
          </button>
        </div>

        <select
          value={getCurrentValue('status')}
          onChange={(e) => handleInputChange({ target: { name: 'status', value: e.target.value }})}
          className="border rounded-md p-2"
        >
          {statuses.filter(s => s !== 'All Statuses').map((status, index) => (
            <option key={index} value={status}>{status}</option>
          ))}
        </select>
        
        <button 
          onClick={handleSave}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          Update Status
        </button>
        
        <button 
          onClick={() => setEditMode(!editMode)}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 ml-auto"
        >
          {editMode ? 'Cancel Edit' : 'Edit'}
        </button>
      </div>

      {/* Main Form Content */}
      <div className="bg-white rounded-lg shadow">
        <div className="max-h-[calc(100vh-300px)] overflow-y-auto p-6">
          <form onSubmit={(e) => e.preventDefault()} className="space-y-6">
            {/* Basic Info */}
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-1">Scenario ID</label>
                <input
                  type="text"
                  name="scenarioid"
                  value={getCurrentValue('scenarioid')}
                  onChange={handleInputChange}
                  readOnly={!editMode}
                  className="w-full border rounded-md p-2 bg-gray-50"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Order</label>
                <input
                  type="number"
                  name="sequence_order"
                  value={getCurrentValue('sequence_order')}
                  onChange={handleInputChange}
                  readOnly={!editMode}
                  className="w-full border rounded-md p-2"
                />
              </div>
            </div>


{/* Title */}
<div>
              <label className="block text-sm font-medium mb-1">Title</label>
              <input
                type="text"
                name="title"
                value={getCurrentValue('title')}
                onChange={handleInputChange}
                readOnly={!editMode}
                className="w-full border rounded-md p-2"
              />
            </div>



            {/* Image field */}
            <div className="mb-6">
              <label className="block text-sm font-medium mb-1">Image</label>
              <div className="w-72 h-72">
                {/* Note the change from scenarioId to scenarioid */}
                <ScenarioImage 
                  scenarioid={scenario?.scenarioid} 
                  imageUrl={scenario?.image}
                  onImageUpdate={async (newImageUrl) => {
                    console.log('ScenarioReviewForm - Image update requested:', {
                      scenarioid: scenario?.scenarioid,
                      newImageUrl
                    });
                    
                    handleInputChange({
                      target: {
                        name: 'image',
                        value: newImageUrl
                      }
                    });
                  }}
                />
              </div>
            </div>

            {/* Patient Record */}
            <div>
              <label className="block text-sm font-medium mb-1">Patient Record</label>
              <textarea
                name="patientreport"
                value={getCurrentValue('patientreport')}
                onChange={handleInputChange}
                readOnly={!editMode}
                rows={4}
                className="w-full border rounded-md p-2 resize-y"
              />
            </div>

            {/* Category and Diagnosis Category */}
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-1">Category</label>
                <input
                  type="text"
                  name="category"
                  value={getCurrentValue('category')}
                  onChange={handleInputChange}
                  readOnly={!editMode}
                  className="w-full border rounded-md p-2"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Diagnosis Category</label>
                <input
                  type="text"
                  name="diagnosiscategory"
                  value={getCurrentValue('diagnosiscategory')}
                  onChange={handleInputChange}
                  readOnly={!editMode}
                  className="w-full border rounded-md p-2"
                />
              </div>
            </div>

            {/* Question */}
            <div>
              <label className="block text-sm font-medium mb-1">Question</label>
              <textarea
                name="question"
                value={getCurrentValue('question')}
                onChange={handleInputChange}
                readOnly={!editMode}
                rows={3}
                className="w-full border rounded-md p-2 resize-y"
              />
            </div>

            {/* Options and Explanations */}
            {[1, 2, 3, 4].map((num) => (
              <div 
                key={num}
                className={`border rounded-lg p-4 ${
                  getCurrentValue('answer') === num.toString() 
                    ? 'border-green-500 bg-green-50'
                    : 'border-gray-200'
                }`}
              >
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium mb-1">Option {num}</label>
                    <textarea
                      name={`option${num}`}
                      value={getCurrentValue(`option${num}`)}
                      onChange={handleInputChange}
                      readOnly={!editMode}
                      rows={2}
                      className="w-full border rounded-md p-2 resize-y"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1">Explanation {num}</label>
                    <textarea
                      name={`explanation${num}`}
                      value={getCurrentValue(`explanation${num}`)}
                      onChange={handleInputChange}
                      readOnly={!editMode}
                      rows={2}
                      className="w-full border rounded-md p-2 resize-y"
                    />
                  </div>
                </div>
              </div>
            ))}

            {/* Answer */}
            <div>
              <label className="block text-sm font-medium mb-1">Answer (1-4)</label>
              <select
                name="answer"
                value={getCurrentValue('answer')}
                onChange={handleInputChange}
                disabled={!editMode}
                className="w-full border rounded-md p-2"
              >
                <option value="">Select Answer</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </select>
            </div>

{/* User Role */}
<div>
              <label className="block text-sm font-medium mb-1">User Role</label>
              <select
                name="userrole"
                value={getCurrentValue('userrole')}
                onChange={handleInputChange}
                disabled={!editMode}
                className="w-full border rounded-md p-2"
              >
                <option value="">Select Role</option>
                <option value="EMT">EMT</option>
                <option value="EMTA">EMTA</option>
                <option value="Paramedic">Paramedic</option>
                <option value="Medical School">Medical School</option>
              </select>
            </div>

            {/* Tags */}
            <div>
              <label className="block text-sm font-medium mb-1">Tags</label>
              <input
                type="text"
                name="tags"
                value={getCurrentValue('tags')}
                onChange={handleInputChange}
                readOnly={!editMode}
                className="w-full border rounded-md p-2"
                placeholder="Comma-separated tags"
              />
            </div>

            {/* Medications */}
            <div>
              <label className="block text-sm font-medium mb-1">Medications</label>
              <textarea
                name="meds"
                value={getCurrentValue('meds')}
                onChange={handleInputChange}
                readOnly={!editMode}
                rows={3}
                className="w-full border rounded-md p-2 resize-y"
              />
            </div>

            {/* Exam */}
            <div>
              <label className="block text-sm font-medium mb-1">Exam</label>
              <textarea
                name="exam"
                value={getCurrentValue('exam')}
                onChange={handleInputChange}
                readOnly={!editMode}
                rows={3}
                className="w-full border rounded-md p-2 resize-y"
              />
            </div>

            {/* Physical Exam */}
            <div>
              <label className="block text-sm font-medium mb-1">Physical Exam</label>
              <textarea
                name="physicalexam"
                value={getCurrentValue('physicalexam')}
                onChange={handleInputChange}
                readOnly={!editMode}
                rows={3}
                className="w-full border rounded-md p-2 resize-y"
              />
            </div>

            {/* Comments */}
            <div>
              <label className="block text-sm font-medium mb-1">Comments</label>
              <textarea
                name="comments"
                value={getCurrentValue('comments')}
                onChange={handleInputChange}
                readOnly={!editMode}
                rows={3}
                className="w-full border rounded-md p-2 resize-y"
              />
            </div>

            {/* Form Actions */}
            {editMode && (
              <div className="flex justify-end gap-4 pt-6 border-t">
                <button
                  type="button"
                  onClick={() => setEditMode(false)}
                  className="px-4 py-2 text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
                  disabled={isLoading}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleSave}
                  className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 disabled:opacity-50"
                  disabled={isLoading}
                >
                  {isLoading ? 'Saving...' : 'Save Changes'}
                </button>
              </div>
            )}
          </form>
        </div>
      </div>

      {/* Loading Overlay */}
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg flex items-center gap-3">
            <Loader2 className="animate-spin h-5 w-5" />
            <span>Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
};
export default ScenarioReviewForm;