import React, { useEffect, useState } from "react";
import { supabase } from "@/lib/supabaseClient";

const MedTeamValidationDashboard = () => {
  const [validations, setValidations] = useState([]);

  useEffect(() => {
    const fetchValidations = async () => {
      let { data, error } = await supabase.from("scenario_validations").select("*");
      if (!error) setValidations(data);
    };

    fetchValidations();
  }, []);

  return (
    <div className="medteam-container">
      <h2>MedTeam Validations</h2>
      {validations.length > 0 ? (
        <ul>
          {validations.map((validation) => (
            <li key={validation.id}>
              <p>{validation.validation_result}</p>
              <p>Status: {validation.is_valid ? "Valid" : "Needs Review"}</p>
            </li>
          ))}
        </ul>
      ) : (
        <p>No validations found.</p>
      )}
    </div>
  );
};

export default MedTeamValidationDashboard;
