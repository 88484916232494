import { useState, useEffect } from 'react';
import { supabase } from '@/lib/supabaseClient';

export const useAuth = () => {
  const [user, setUser] = useState(null);
  const [userRoles, setUserRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    
    const session = supabase.auth.getSession();
    setUser(session?.user || null);

    
    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        console.log('Auth state changed:', event);
        setUser(session?.user || null);
        
        if (session?.user) {
          const { data: roles, error } = await supabase
            .from('user_roles')
            .select('role')
            .eq('user_id', session.user.id);

          if (error) {
            console.error('Error fetching roles:', error);
          } else {
            
            if (session.user.email === 'jim.bressoud@gmail.com') {
              setUserRoles(['super_admin', 'admin', ...roles.map(r => r.role)]);
            } else {
              setUserRoles(roles.map(r => r.role));
            }
          }
        } else {
          setUserRoles([]);
        }
        
        setIsLoading(false);
      }
    );

    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  return {
    user,
    userRoles,
    isLoading,
    isAuthenticated: !!user,
    isAdmin: userRoles.includes('admin') || userRoles.includes('super_admin')
  };
};
