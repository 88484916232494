
import { useState, useEffect, useCallback } from 'react';
import { supabase } from '@/lib/supabaseClient';
import { BASE_INSTRUCTIONS } from '../constants/baseInstructions';

export const useScenarioData = () => {
  const [protocols, setProtocols] = useState([]);
  const [displayedProtocols, setDisplayedProtocols] = useState([]);
  const [categories, setCategories] = useState([]);
  const [diagnosisCategories, setDiagnosisCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  
  const filterProtocols = useCallback((term) => {
    if (!term.trim()) {
      setDisplayedProtocols(protocols);
      return;
    }
    
    const filtered = protocols.filter(protocol => 
      protocol.title.toLowerCase().includes(term.toLowerCase()) ||
      protocol.content.toLowerCase().includes(term.toLowerCase())
    );
    setDisplayedProtocols(filtered);
  }, [protocols]);

  
  useEffect(() => {
    filterProtocols(searchTerm);
  }, [searchTerm, filterProtocols]);

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        
        const [protocolsResponse, scenariosResponse] = await Promise.all([
          supabase.from('protocols').select('id, title, content'),
          supabase.from('scenarios').select('category, diagnosiscategory')
        ]);

        
        if (protocolsResponse.error) throw protocolsResponse.error;
        if (scenariosResponse.error) throw scenariosResponse.error;

        
        setProtocols(protocolsResponse.data || []);
        setDisplayedProtocols(protocolsResponse.data || []);

        
        const uniqueCategories = [...new Set(scenariosResponse.data
          .map(s => s.category)
          .filter(Boolean))]
          .sort();
        setCategories(uniqueCategories);

        
        const uniqueDiagnosisCategories = [...new Set(scenariosResponse.data
          .map(s => s.diagnosiscategory)
          .filter(Boolean))]
          .sort();
        setDiagnosisCategories(uniqueDiagnosisCategories);

      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return {
    protocols,
    displayedProtocols,
    categories,
    diagnosisCategories,
    baseInstructions: BASE_INSTRUCTIONS,
    loading,
    error,
    searchTerm,
    setSearchTerm
  };
};