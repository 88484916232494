import React, { useState, useRef, useEffect } from 'react';
import { Loader2 } from 'lucide-react';

const FeedbackDialog = ({ 
  isOpen, 
  onClose, 
  onSubmit, 
  questionIndex,
  questionData,
  isProcessing = false,
  title,
  submitButtonText,
  submitButtonClass = "approve"
}) => {
  const [feedback, setFeedback] = useState('');
  const dialogRef = useRef(null);
  const textareaRef = useRef(null);

  
  useEffect(() => {
    if (isOpen && textareaRef.current) {
      setTimeout(() => {
        textareaRef.current.focus();
      }, 100);
    }
  }, [isOpen]);

  
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape' && isOpen) {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>{title || "Provide Feedback"}</h2>
          <button 
            onClick={onClose}
            className="close-button"
            aria-label="Close dialog"
          >
            ×
          </button>
        </div>
        <div className="modal-body">
          <div className="mb-4 p-3 bg-gray-50 rounded text-sm max-h-32 overflow-y-auto">
            <strong>Question {questionIndex + 1}:</strong> {questionData?.question}
          </div>
          
          <textarea 
            ref={textareaRef}
            className="form-textarea w-full"
            placeholder="Enter feedback for this question"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            rows="4"
            disabled={isProcessing}
          />
        </div>
        <div className="modal-footer">
          <button 
            onClick={() => onSubmit(feedback)}
            className={`action-button ${submitButtonClass}`}
            disabled={isProcessing || !feedback.trim()}
          >
            {isProcessing ? (
              <>
                <Loader2 className="animate-spin mr-2 h-4 w-4" />
                Processing...
              </>
            ) : submitButtonText || 'Submit Feedback'}
          </button>
          <button 
            onClick={onClose} 
            className="action-button cancel"
            disabled={isProcessing}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeedbackDialog;
