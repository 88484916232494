
import { useState, useEffect } from 'react';
import { supabase } from '@/lib/supabaseClient';

export const useUserRoles = () => {
  const [roles, setRoles] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        
        console.log('useUserRoles - Current session:', session);
        
        if (sessionError) {
          console.error('useUserRoles - Session error:', sessionError);
          setRoles(null);
          return;
        }

        if (!session?.user?.email) {
          console.log('useUserRoles - No user email in session');
          setRoles(null);
          return;
        }

        console.log('useUserRoles - Fetching roles for user:', session.user.email);

        
        const { data: userRoles, error: rolesError } = await supabase
          .from('user_additional_attributes')
          .select(`
            email,
            administrator,
            reviewer,
            org_leader,
            admin_portal
          `)
          .eq('email', session.user.email)
          .single();

        if (rolesError) {
          console.error('useUserRoles - Roles fetch error:', rolesError);
          setRoles(null);
          return;
        }

        console.log('useUserRoles - Fetched roles data:', userRoles);

        
        setRoles({
          isAdmin: userRoles?.administrator || userRoles?.admin_portal || false,
          isReviewer: userRoles?.reviewer || false,
          isOrgLeader: userRoles?.org_leader || false
        });

      } catch (error) {
        console.error('useUserRoles - Unexpected error:', error);
        setRoles(null);
      } finally {
        setLoading(false);
      }
    };

    
    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      console.log('useUserRoles - Auth state changed:', event, session?.user?.email);
      fetchRoles();
    });

    
    fetchRoles();

    
    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  console.log('useUserRoles - Current roles state:', roles);
  console.log('useUserRoles - Loading state:', loading);

  return { roles, loading };
};

export default useUserRoles;