import { supabaseClient as supabase } from '../../../lib/supabaseClient';

export const trainingService = {
 async getTrainingPlans(filters = {}) {
   try {
     const { data, error } = await supabase
       .from('plans')
       .select(`
         id,
         title,
         description,
         required_hours,
         valid_from,
         valid_until,
         certification_level:certification_levels!certification_level_id(id,name)
       `)
       .order('id', { ascending: false });
     
     if (error) throw error;
     return data || [];
   } catch (error) {
     console.error('Error in getTrainingPlans:', error);
     throw error;
   }
 },

 async getCategories() {
   try {
     const { data, error } = await supabase
       .from('scenarios')
       .select('category')
       .not('category', 'is', null)
       .order('category');
     
     if (error) throw error;
     const uniqueCategories = [...new Set(data.map(item => item.category))];
     return uniqueCategories.map(category => ({
       id: category,
       name: category
     }));
   } catch (error) {
     console.error('Error in getCategories:', error);
     throw error;
   }
 },

 async getCertificationLevels() {
   try {
     const { data, error } = await supabase
       .from('certification_levels')
       .select('*')
       .order('name');
     
     if (error) throw error;
     return data || [];
   } catch (error) {
     console.error('Error in getCertificationLevels:', error);
     throw error;
   }
 },

 async createTrainingPlan(planData) {
   try {
     const { data, error } = await supabase
       .from('plans')
       .insert([planData])
       .select()
       .single();
     
     if (error) throw error;
     return data;
   } catch (error) {
     console.error('Error in createTrainingPlan:', error);
     throw error;
   }
 }
};