import React from 'react';

const ScenarioMatrixView = ({ data, title }) => {
  if (!data || Object.keys(data).length === 0) {
    return <div>No data available</div>;
  }

  const statuses = [...new Set(
    Object.values(data).flatMap(category => Object.keys(category))
  )].sort();

  return (
    <div className="bg-white rounded-lg shadow-lg p-4">
      <h2 className="text-xl font-semibold mb-4">{title}</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full border-collapse">
          <thead>
            <tr>
              <th className="border p-2 bg-gray-50">Diagnosis Category</th>
              {statuses.map(status => (
                <th key={status} className="border p-2 bg-gray-50">
                  {status}
                </th>
              ))}
              <th className="border p-2 bg-gray-50">Total</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(data).map(([category, statusCounts]) => {
              const rowTotal = Object.values(statusCounts).reduce((a, b) => a + b, 0);
              return (
                <tr key={category}>
                  <td className="border p-2 font-medium">{category}</td>
                  {statuses.map(status => (
                    <td key={status} className="border p-2 text-center">
                      {statusCounts[status] || 0}
                    </td>
                  ))}
                  <td className="border p-2 text-center font-bold">
                    {rowTotal}
                  </td>
                </tr>
              );
            })}
            <tr className="bg-gray-50 font-bold">
              <td className="border p-2">Total</td>
              {statuses.map(status => {
                const columnTotal = Object.values(data)
                  .reduce((sum, category) => sum + (category[status] || 0), 0);
                return (
                  <td key={status} className="border p-2 text-center">
                    {columnTotal}
                  </td>
                );
              })}
              <td className="border p-2 text-center">
                {Object.values(data)
                  .flatMap(category => Object.values(category))
                  .reduce((a, b) => a + b, 0)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ScenarioMatrixView;