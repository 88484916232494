import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react";
import { useEffect, useState } from "react";
import { X } from "lucide-react";
import { supabase } from "@/lib/supabaseClient";

interface Props {
  tags: string[];
  onChange: (tags: string[]) => void;
}

export const TagsInput: React.FC<Props> = ({ tags, onChange }) => {
  const [type, setType] = useState<string>();
  const [types, setTypes] = useState([])
  const [query, setQuery] = useState("");
  const [displayedTags, setDisplayedTags] = useState([]);

  const removeTag = (tag: string) => {
    const newTags = tags.filter((t) => t !== tag);
    onChange(newTags);
  };

  useEffect(() => {
    supabase
      .from("hashtags_types")
      .select("type")
      .then(({ data, error }) => {
        if (error) {
          console.error("Error fetching tags:", error.message);
          return;
        }
        setTypes(data);
      });
  }, [])

  useEffect(() => {
    supabase
      .from("hashtags")
      .select("hashtag, type")
      .ilike("hashtag", `%${query}%`)
      .eq("status", "active")
      .eq("type", type)
      .then(({ data, error }) => {
        if (error) {
          console.error("Error fetching tags:", error.message);
          return;
        }
        setDisplayedTags(data);
      });
  }, [tags, query, type]);

  return (
    <>
      <Combobox value={tags || []} onChange={onChange} multiple>
        <div className="relative w-full">
          <div className="w-full flex items-center">
            <select
              value={type || ""}
              onChange={(e) =>
                setType(e.target.value,)
              }
              className="rounded-l-md border border-gray-300 p-2.5 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Select Type</option>
              {types.map((type) => (
                <option key={type.type} value={type.type}>
                  {type.type}
                </option>
              ))}
            </select>
            <ComboboxInput
              className="flex-grow -ml-1 py-2 rounded-r-md border border-gray-300 px-2 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500"
              value={query}
              onChange={(event) => setQuery(event.target.value)}
              placeholder="Add tags"
            />
          </div>

          <ComboboxOptions className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {displayedTags.length === 0 && query !== "" ? (
              <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                Nothing found.
              </div>
            ) : (
              displayedTags.map((tag) => (
                <ComboboxOption
                  key={tag}
                  className={({ focus }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      focus ? "bg-blue-600 text-white" : "text-gray-900"
                    }`
                  }
                  value={tag.hashtag}
                >
                  {({ selected, focus }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {tag.hashtag}
                      </span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                            focus ? "text-white" : "text-blue-600"
                          }`}
                        >
                          ✓
                        </span>
                      ) : null}
                    </>
                  )}
                </ComboboxOption>
              ))
            )}
          </ComboboxOptions>
        </div>
      </Combobox>
      <div className="w-full flex items-center gap-x-2 mt-2">
        {tags.map((t) => (
          <span
            key={t}
            className="flex items-center gap-x-1 rounded-full bg-blue-500 pl-3 pr-2 py-1 text-sm text-white"
          >
            {t}
            <X
              className="cursor-pointer"
              size={16}
              onClick={() => removeTag(t)}
            />
          </span>
        ))}
      </div>
    </>
  );
};
