

export const BASE_INSTRUCTIONS = `
Generate a medical scenario following these strict requirements:

PATIENT REPORT REQUIREMENTS:
- Include complete vital signs (HR/P, RR, BP, SpO2)
- Include relevant medical history
- Include meds if applicable
- Include physical examination findings
- Structure in clear paragraphs
- Age and gender must be explicitly stated

QUESTION REQUIREMENTS:
- Each question must be clearly related to the scenario
- Questions should test clinical decision making
- Questions should be appropriate for the specified user role
- Include a mix of assessment, intervention, and management questions

OPTION REQUIREMENTS (STRICT):
- Each option MUST be 2-3 complete sentences
- First sentence: State the clinical action/decision
- Second sentence: Provide clinical reasoning or rationale
- Third sentence: Include evidence basis or expected outcome
- Options must be realistic and clinically appropriate
- Options must be distinctly different from each other
- Avoid obvious incorrect options

EXPLANATION REQUIREMENTS (STRICT):
- Each explanation MUST be 4-5 complete sentences
- For correct answer:
  1. State why the option is optimal
  2. Provide evidence/guideline support
  3. Explain clinical reasoning
  4. Discuss expected outcomes
  5. Address monitoring/follow-up
- For incorrect answers:
  1. Acknowledge why it might seem reasonable
  2. Explain why it's not optimal
  3. Discuss potential risks/issues
  4. Reference evidence against this approach
  5. Suggest what would be better

FORMAT REQUIREMENTS:
- Title should be concise but descriptive
- Patient report should be 150-300 words
- Questions should be clear and unambiguous
- All text should use professional medical terminology appropriate for the user role
- No abbreviations unless universally accepted

COMPLEXITY ALIGNMENT:
Easy:
- Focus on core concepts and basic protocols
- Clear presentation and straightforward decision-making
- Standard treatment protocols

Moderate:
- Include some complicating factors
- Require weighing multiple options
- Consider risk-benefit analysis

Complex:
- Multiple interacting factors
- Complicated medical history
- Atypical presentations
- Challenging decision-making scenarios

Response Format:
{
  "title": "string",
  "patientreport": "string",
  "questions": [
    {
      "question": "string",
      "option1": "2-3 sentences",
      "option2": "2-3 sentences",
      "option3": "2-3 sentences",
      "option4": "2-3 sentences",
      "answer": number,
      "explanation1": "4-5 sentences",
      "explanation2": "4-5 sentences",
      "explanation3": "4-5 sentences",
      "explanation4": "4-5 sentences"
    }
  ]
}`;