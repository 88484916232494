import { useState, useEffect, useRef } from 'react';
import { ChevronLeft, ChevronRight, AlertCircle, CheckCircle, Loader2 } from 'lucide-react';
import { supabase } from '@/lib/supabaseClient';
import { useAuth } from '@/features/auth/context/AuthContext';
import { useReferenceData } from '@/shared/hooks/useReferenceData';
import InlineScenarioEditor from '@/features/scenarios/components/InlineScenarioEditor';
import StandardizedResultsTable from '@/features/scenarios/components/StandardizedResultsTable';

export default function ScenarioPromotionPage() {
  const { session } = useAuth();
  const scrollRef = useRef(null);
  const { status: statuses, diagnosiscategory: diagnosisCategories } = useReferenceData();
  const [validationSteps, setValidationSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(null);
  const [validationResults, setValidationResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editingQuestionId, setEditingQuestionId] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('Reviewed');
  const [selectedDiagnosisCategory, setSelectedDiagnosisCategory] = useState('');

  useEffect(() => {
    const fetchValidationSteps = async () => {
      try {
        const { data, error } = await supabase
          .from('validation_steps')
          .select('*')
          .eq('is_active', true)
          .order('order_num');
    
        if (error) throw error;
        setValidationSteps(data || []);
      } catch (err) {
        console.error('Error fetching validation steps:', err);
        setError(err.message);
        setValidationSteps([]);
      }
    };

    fetchValidationSteps();
  }, []);

  useEffect(() => {
    if (activeStep) {
      const step = validationSteps.find(s => s.id === activeStep);
      if (step) {
        runValidation(step);
      }
    }
  }, [selectedStatus, selectedDiagnosisCategory]);

  const handleScrollButton = (direction) => {
    if (scrollRef.current) {
      const scrollAmount = direction === 'left' ? -300 : 300;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const handleStepClick = async (step) => {
    setActiveStep(step.id);
    await runValidation(step);
  };

  const handleClearFilters = () => {
    setSelectedStatus('Reviewed');
    setSelectedDiagnosisCategory('');
  };

  const handleEdit = (result) => {
    setEditingQuestionId(result.id || result.scenarioid);
  };

  const handleDelete = async (result) => {
    const idToDelete = result.id || result.scenarioid;
    if (window.confirm('Are you sure you want to delete this item? This action cannot be undone.')) {
      try {
        setIsLoading(true);
        const { error } = await supabase
          .from('scenarios')
          .delete()
          .eq('id', idToDelete);

        if (error) throw error;

        setValidationResults(prev => 
          prev.filter(item => (item.id || item.scenarioid) !== idToDelete)
        );
      } catch (err) {
        console.error('Error deleting item:', err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleQuestionUpdate = async (updatedQuestion) => {
    try {
      setIsLoading(true);
      
      const { error } = await supabase
        .from('scenarios')
        .update(updatedQuestion)
        .eq('id', updatedQuestion.id);

      if (error) throw error;

      setValidationResults(prev => 
        prev.map(result => 
          (result.id || result.scenarioid) === updatedQuestion.id 
            ? { ...result, ...updatedQuestion } 
            : result
        )
      );

      setEditingQuestionId(null);
    } catch (err) {
      console.error('Error updating question:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const runValidation = async (step) => {
    setIsLoading(true);
    setError(null);
    try {
      if (step.validation_type === 'sql') {
        let query = supabase
          .from('scenarios')
          .select('*')
          .eq('status', selectedStatus);

        if (selectedDiagnosisCategory) {
          query = query.eq('diagnosiscategory', selectedDiagnosisCategory);
        }

        const { data: scenarios, error } = await query;

        if (error) throw error;

        const { data: validationResults } = await supabase
          .from('validation_steps')
          .select('validation_query')
          .eq('id', step.id)
          .single();

        if (!scenarios || scenarios.length === 0) {
          setValidationResults([]);
          return;
        }

        
        const filteredResults = scenarios.filter(scenario => {
          try {
            return eval(validationResults.validation_query);
          } catch (e) {
            console.error('Validation evaluation error:', e);
            return false;
          }
        });

        setValidationResults(filteredResults);

        if (session?.user?.email) {
          await supabase.from('validation_results').insert({
            step_id: step.id,
            status: filteredResults.length === 0 ? 'passed' : 'failed',
            error_details: filteredResults,
            validated_by: session.user.email
          });
        }
      }
    } catch (err) {
      console.error('Validation error:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="h-screen flex flex-col overflow-hidden bg-gray-100">
      <div className="p-4">
        <div className="bg-white rounded-lg shadow p-6 mb-4">
          <h1 className="text-2xl font-bold">Scenario Promotion Manager</h1>
        </div>

        <div className="bg-white rounded-lg shadow p-6 mb-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Filters</h2>
            <button 
              onClick={handleClearFilters}
              className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors"
            >
              Clear Filters
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Status Filter */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Status</label>
              <select
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
                className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="">All Statuses</option>
                {(statuses || []).map((status) => (
                  <option key={status || 'none'} value={status || ''}>
                    {status || '(No Status)'}
                  </option>
                ))}
              </select>
            </div>

            {/* Diagnosis Category Filter */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Diagnosis Category</label>
              <select
                value={selectedDiagnosisCategory}
                onChange={(e) => setSelectedDiagnosisCategory(e.target.value)}
                className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="">All Diagnosis Categories</option>
                {(diagnosisCategories || []).map((category) => (
                  <option key={category} value={category}>{category}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-1 overflow-y-auto px-4 pb-4">
        <div className="relative bg-white rounded-lg shadow p-4 mb-4">
          <div className="flex items-center">
            <button
              onClick={() => handleScrollButton('left')}
              className="flex-none p-2 rounded-full bg-gray-100 hover:bg-gray-200 mr-2"
            >
              <ChevronLeft className="h-6 w-6" />
            </button>
  
            <div 
              ref={scrollRef}
              className="flex-1 overflow-x-auto flex gap-4 px-2 py-1 scroll-smooth"
              style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
            >
              {validationSteps.map((step) => (
                <div
                  key={step.id}
                  onClick={() => handleStepClick(step)}
                  className={`
                    flex-none cursor-pointer p-4 rounded-lg border-2 w-64
                    transition-all duration-200 hover:shadow-md
                    ${activeStep === step.id 
                      ? 'border-blue-500 bg-blue-50' 
                      : 'border-gray-200 hover:border-blue-300'}
                  `}
                >
                  <div className="mb-2">
                    <div className="flex items-center justify-between">
                      <span className="text-sm text-gray-500">Step {step.order_num}</span>
                      {step.status === 'complete' && (
                        <CheckCircle className="h-5 w-5 text-green-500" />
                      )}
                    </div>
                    <h3 className="font-semibold text-gray-900">{step.title}</h3>
                  </div>
                  <p className="text-sm text-gray-600">{step.description}</p>
                </div>
              ))}
            </div>
  
            <button
              onClick={() => handleScrollButton('right')}
              className="flex-none p-2 rounded-full bg-gray-100 hover:bg-gray-200 ml-2"
            >
              <ChevronRight className="h-6 w-6" />
            </button>
          </div>
        </div>
  
        <div className="relative">
          {isLoading ? (
            <div className="flex items-center justify-center p-8">
              <Loader2 className="animate-spin h-8 w-8" />
              <span className="ml-2">Running validation...</span>
            </div>
          ) : error ? (
            <div className="p-4 bg-red-50 border border-red-500 text-red-700 rounded-lg">
              {error}
            </div>
          ) : validationResults && (
            <div className="bg-white rounded-lg shadow">
              <div className="p-4 border-b">
                <div className="flex items-center gap-2">
                  <AlertCircle className={`h-5 w-5 ${
                    validationResults.length > 0 ? 'text-red-500' : 'text-green-500'
                  }`} />
                  <h2 className="text-lg font-semibold">
                    {validationResults.length === 0 
                      ? 'All scenarios passed validation!'
                      : `Found ${validationResults.length} scenarios with issues`}
                  </h2>
                </div>
              </div>

              {validationResults.length > 0 && (
                <StandardizedResultsTable
                  results={validationResults}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                  onViewDetails={(row) => {
                    console.log('View details for:', row);
                  }}
                />
              )}
            </div>
          )}

          {editingQuestionId && (
            <InlineScenarioEditor
              questionId={editingQuestionId}
              onSave={handleQuestionUpdate}
              onCancel={() => setEditingQuestionId(null)}
            />
          )}
        </div>
      </div>
    </div>
  );
}