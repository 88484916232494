
import { supabase } from '@/lib/supabaseClient';

const calculateLastSeenBucket = (lastSeen) => {
  if (!lastSeen) return 'Beyond';
  
  const now = new Date();
  const lastSeenDate = new Date(lastSeen);
  const hoursDiff = (now - lastSeenDate) / (1000 * 60 * 60);

  if (hoursDiff <= 72) return '72 Hours';
  if (hoursDiff <= 168) return '7 Days';
  if (hoursDiff <= 720) return '1 Month';
  return 'Beyond';
};
const standardizeRole = (role) => {
  if (!role) return null;
  
  const roleMap = {
    'emt': 'EMT',
    'aemt': 'AEMT',
    'Paramedic': 'Paramedic',
    'medical_school': 'Medical School',
    'medical school': 'Medical School'
  };

  const normalizedRole = role.toLowerCase().trim();
  return roleMap[normalizedRole] || role;
};
export const fetchUtilizationData = async () => {
  try {
    
    const { data: viewData, error: viewError } = await supabase
      .from('member_utilization_view')
      .select('*');

    if (viewError) {
      console.log('View query failed, falling back to direct queries');
      
      
      const { data: userData, error: userError } = await supabase
        .from('user_details')
        .select('*');

      if (userError) {
        console.error('User details query failed:', userError);
        throw userError;
      }

      const { data: attributesData, error: attributesError } = await supabase
        .from('user_additional_attributes')
        .select('*');

      if (attributesError) {
        console.error('User attributes query failed:', attributesError);
        throw attributesError;
      }

      
      const { data: sessionData, error: sessionError } = await supabase
        .rpc('get_user_sessions');

      if (sessionError) {
        console.error('Session query failed:', sessionError);
        
      }

      
      const attributesMap = new Map(
        attributesData?.map(attr => [attr.email, attr]) || []
      );
      
      const sessionMap = new Map(
        sessionData?.map(session => [session.user_id, session.refreshed_at]) || []
      );

      console.log('Transforming data for', userData.length, 'users');

      
      const transformedData = userData.map(user => ({
        systemid: user.id,
        email: user.email,
        fullName: user.fullName || user.user_additional_attributes?.[0]?.fullname,
        role: standardizeRole(user.role),
        streak: user.streak || 0,
        county: user.county || user.user_additional_attributes?.[0]?.county,
        state: user.state || user.user_additional_attributes?.[0]?.state,
        usercreated: user.confirmed_at,
        lastseen: sessionMap.get(user.id),
        provider: user.provider,
        solved_role: user.user_additional_attributes?.[0]?.solved_role,
        user_tags: user.user_additional_attributes?.[0]?.user_tags,
        last_seen_bucket: calculateLastSeenBucket(sessionMap.get(user.id))
      }));

      console.log('Returning transformed data:', transformedData.length, 'records');
      return transformedData;
    }

    return viewData;
  } catch (error) {
    console.error('Error in fetchUtilizationData:', error);
    throw error;
  }
};

export const handleError = (error) => {
  if (error.message?.includes('relation "member_utilization_view" does not exist')) {
    return 'The utilization report is currently being set up. Showing fallback data.';
  }
  
  if (error.code === '42501') {
    return 'You do not have permission to view this data. Please contact your administrator.';
  }
  
  if (error.code === 'PGRST301' || error.code === '42P01') {
    return 'There was an issue accessing the database. Please try again later.';
  }
  
  return `Unable to load user data: ${error.message}`;
};