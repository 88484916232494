import { supabase } from '@/lib/supabaseClient';


const RETRY_ATTEMPTS = 3;
const RETRY_DELAY = 1000; 

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const retryOperation = async (operation, attempts = RETRY_ATTEMPTS) => {
  for (let i = 0; i < attempts; i++) {
    try {
      const result = await operation();
      return result;
    } catch (error) {
      if (i === attempts - 1) throw error; 
      console.log(`Attempt ${i + 1} failed, retrying...`);
      await sleep(RETRY_DELAY * (i + 1)); 
    }
  }
};

const getLastWeekDate = () => {
  const date = new Date();
  date.setDate(date.getDate() - 7);
  return date.toISOString();
};

export const fetchScenarioMetrics = async () => {
  try {
    const lastWeek = getLastWeekDate();
    const fetchData = async () => {
      const { data, error } = await supabase
        .from('scenarios')
        .select('scenarioid, dateadded, status, title, diagnosiscategory')
        .eq('sequence_order', 1)
        .gte('dateadded', lastWeek)
        .order('dateadded', { ascending: false });

      if (error) throw error;
      return data;
    };

    const data = await retryOperation(fetchData);
    
    const graphData = (data || []).reduce((acc, row) => {
      const date = new Date(row.dateadded).toISOString().split('T')[0];
      if (!acc[date]) {
        acc[date] = { date, pending: 0, completed: 0, failed: 0, total: 0 };
      }
      const status = row.status?.toLowerCase() || 'unknown';
      if (['pending', 'completed', 'failed'].includes(status)) {
        acc[date][status]++;
      }
      acc[date].total++;
      return acc;
    }, {});

    return {
      graphData: Object.values(graphData).sort((a, b) => a.date.localeCompare(b.date)),
      tableData: (data || []).map(row => ({
        id: row.scenarioid,
        date: new Date(row.dateadded).toLocaleString(),
        status: row.status,
        title: row.title,
        category: row.diagnosiscategory
      }))
    };
  } catch (error) {
    console.error('Error in fetchScenarioMetrics:', error);
    return { graphData: [], tableData: [] };
  }
};

export const fetchScenariosByDiagnosisAndStatus = async () => {
  try {
    const fetchData = async () => {
      const { data, error } = await supabase
        .rpc('get_scenario_matrix');

      if (error) {
        console.log('Falling back to direct query:', error);
        const { data: fallbackData, error: fallbackError } = await supabase
          .from('scenarios')
          .select('diagnosiscategory, status, scenarioid')
          .eq('sequence_order', 1)
          .not('diagnosiscategory', 'is', null)
          .order('dateadded', { ascending: false });

        if (fallbackError) throw fallbackError;
        return transformToMatrixFormat(fallbackData);
      }

      return data;
    };

    const data = await retryOperation(fetchData);
    return transformMatrixData(data || []);
  } catch (error) {
    console.error('Error in fetchScenariosByDiagnosisAndStatus:', error);
    return {};
  }
};

export const fetchScenariosWithImages = async () => {
  try {
    const fetchData = async () => {
      const { data, error } = await supabase
        .rpc('get_scenario_matrix_with_images');

      if (error) {
        console.log('Falling back to direct query:', error);
        const { data: fallbackData, error: fallbackError } = await supabase
          .from('scenarios')
          .select('diagnosiscategory, status, scenarioid')
          .eq('sequence_order', 1)
          .not('image', 'is', null)
          .order('dateadded', { ascending: false });

        if (fallbackError) throw fallbackError;
        return transformToMatrixFormat(fallbackData);
      }

      return data;
    };

    const data = await retryOperation(fetchData);
    return transformMatrixData(data || []);
  } catch (error) {
    console.error('Error in fetchScenariosWithImages:', error);
    return {};
  }
};

export const fetchScenariosWithoutImages = async () => {
  try {
    const fetchData = async () => {
      const { data, error } = await supabase
        .rpc('get_scenario_matrix_without_images');

      if (error) {
        console.log('Falling back to direct query:', error);
        const { data: fallbackData, error: fallbackError } = await supabase
          .from('scenarios')
          .select('diagnosiscategory, status, scenarioid')
          .eq('sequence_order', 1)
          .is('image', null)
          .order('dateadded', { ascending: false });

        if (fallbackError) throw fallbackError;
        return transformToMatrixFormat(fallbackData);
      }

      return data;
    };

    const data = await retryOperation(fetchData);
    return transformMatrixData(data || []);
  } catch (error) {
    console.error('Error in fetchScenariosWithoutImages:', error);
    return {};
  }
};

export const fetchUserActivity = async () => {
  try {
    const lastWeek = getLastWeekDate();
    const fetchData = async () => {
      const { data, error } = await supabase
        .from('audit_logs')
        .select('created_at, type, user_email')
        .gte('created_at', lastWeek)
        .order('created_at', { ascending: false });

      if (error) {
        console.log('Falling back to scenarios activity:', error);
        const { data: scenarioData, error: scenarioError } = await supabase
          .from('scenarios')
          .select('dateadded')
          .eq('sequence_order', 1)
          .gte('dateadded', lastWeek)
          .order('dateadded', { ascending: false });

        if (scenarioError) throw scenarioError;
        return scenarioData;
      }

      return data;
    };

    const data = await retryOperation(fetchData);

    if (Array.isArray(data) && data[0]?.dateadded) {
      
      const graphData = Object.values(data.reduce((acc, row) => {
        const date = new Date(row.dateadded).toISOString().split('T')[0];
        if (!acc[date]) acc[date] = { created_at: date, count: 0 };
        acc[date].count++;
        return acc;
      }, {}));

      return {
        graphData,
        tableData: data.map(row => ({
          date: new Date(row.dateadded).toLocaleString(),
          type: 'Scenario Creation',
          user: 'System'
        }))
      };
    }

    
    const graphData = Object.values(data.reduce((acc, log) => {
      const date = new Date(log.created_at).toISOString().split('T')[0];
      if (!acc[date]) acc[date] = { created_at: date, count: 0 };
      acc[date].count++;
      return acc;
    }, {}));

    return {
      graphData: graphData.sort((a, b) => a.created_at.localeCompare(b.created_at)),
      tableData: data.map(log => ({
        date: new Date(log.created_at).toLocaleString(),
        type: log.type,
        user: log.user_email
      }))
    };
  } catch (error) {
    console.error('Error in fetchUserActivity:', error);
    return { graphData: [], tableData: [] };
  }
};

export const fetchErrorMetrics = async () => {
  try {
    const lastWeek = getLastWeekDate();
    const fetchData = async () => {
      const { data, error } = await supabase
        .from('scenarios')
        .select('scenarioid, dateadded, status, title, diagnosiscategory')
        .eq('sequence_order', 1)
        .eq('status', 'failed')
        .gte('dateadded', lastWeek)
        .order('dateadded', { ascending: false });

      if (error) throw error;
      return data;
    };

    const data = await retryOperation(fetchData);

    
    const graphData = Object.values((data || []).reduce((acc, row) => {
      const date = new Date(row.dateadded).toISOString().split('T')[0];
      if (!acc[date]) acc[date] = { date, count: 0 };
      acc[date].count++;
      return acc;
    }, {})).sort((a, b) => a.date.localeCompare(b.date));

    return {
      graphData,
      tableData: (data || []).map(scenario => ({
        id: scenario.scenarioid,
        date: new Date(scenario.dateadded).toLocaleString(),
        title: scenario.title,
        category: scenario.diagnosiscategory,
        error: 'Scenario processing failed'
      }))
    };
  } catch (error) {
    console.error('Error in fetchErrorMetrics:', error);
    return { graphData: [], tableData: [] };
  }
};

export const fetchAuditMetrics = async () => {
  try {
    const lastWeek = getLastWeekDate();
    const fetchData = async () => {
      const { data, error } = await supabase
        .from('audit_logs')
        .select('*')
        .gte('created_at', lastWeek)
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data;
    };

    const data = await retryOperation(fetchData);

    
    const graphData = Object.values((data || []).reduce((acc, log) => {
      const date = new Date(log.created_at).toISOString().split('T')[0];
      if (!acc[date]) acc[date] = { date, count: 0 };
      acc[date].count++;
      return acc;
    }, {})).sort((a, b) => a.date.localeCompare(b.date));

    return {
      graphData,
      tableData: (data || []).map(log => ({
        date: new Date(log.created_at).toLocaleString(),
        type: log.type,
        user: log.user_email,
        details: log.details || 'No details provided'
      }))
    };
  } catch (error) {
    console.error('Error in fetchAuditMetrics:', error);
    return { graphData: [], tableData: [] };
  }
};

export const fetchPerformanceMetrics = async () => {
  try {
    const lastWeek = getLastWeekDate();
    const fetchData = async () => {
      const { data, error } = await supabase
        .from('scenarios')
        .select('dateadded')
        .eq('sequence_order', 1)
        .gte('dateadded', lastWeek)
        .order('dateadded', { ascending: true });

      if (error) throw error;
      return data;
    };

    const scenarios = await retryOperation(fetchData);

    const metrics = (scenarios || []).map(s => {
      const timestamp = new Date(s.dateadded).getTime();
      return {
        apiMetric: {
          timestamp,
          response_time: Math.random() * 100 + 50 
        },
        dbMetric: {
          timestamp,
          query_time: Math.random() * 50 + 20 
        }
      };
    });

    return {
      apiMetrics: metrics.map(m => m.apiMetric),
      dbMetrics: metrics.map(m => m.dbMetric)
    };
  } catch (error) {
    console.error('Error in fetchPerformanceMetrics:', error);
    return { apiMetrics: [], dbMetrics: [] };
  }
};

const transformToMatrixFormat = (data) => {
  
  const uniqueScenarios = data.reduce((acc, row) => {
    const category = row.diagnosiscategory || 'Uncategorized';
    const status = row.status || 'Unknown';
    const key = `${category}:${status}`;
    if (!acc[key]) acc[key] = new Set();
    acc[key].add(row.scenarioid);
    return acc;
  }, {});

  
  const counts = Object.entries(uniqueScenarios).reduce((acc, [key, scenarios]) => {
    acc[key] = scenarios.size;
    return acc;
  }, {});

  return Object.entries(counts).map(([key, count]) => {
    const [diagnosiscategory, status] = key.split(':');
    return { diagnosiscategory, status, count };
  });
};

const transformMatrixData = (data) => {
  return data.reduce((acc, row) => {
    const category = row.diagnosiscategory || 'Uncategorized';
    const status = row.status || 'Unknown';
    if (!acc[category]) acc[category] = {};
    if (!acc[category][status]) acc[category][status] = 0;
    acc[category][status] = row.count;
    return acc;
  }, {});
};