
import { Navigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { supabase } from '@/lib/supabaseClient';
import { useUserRoles } from '@/features/auth/hooks/useUserRoles';

const ProtectedRoute = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { roles, loading: rolesLoading } = useUserRoles();
  const location = useLocation();

  useEffect(() => {
    const checkUser = async () => {
      const { data: { user: currentUser } } = await supabase.auth.getUser();
      setUser(currentUser);
      setLoading(false);
    };

    checkUser();

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user ?? null);
    });

    return () => subscription.unsubscribe();
  }, []);

  if (loading || rolesLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  
  if (roles?.isAdmin) {
    return children;
  }

  
  const reviewerPaths = ['/', '/scenarios', '/scenarioreview', '/scenarios/editor', '/reporting/utilization'];
  const orgLeaderPaths = ['/', '/reporting/utilization'];

  
  const currentPath = location.pathname;
  
  if (roles?.isReviewer && reviewerPaths.some(path => currentPath.startsWith(path))) {
    return children;
  }
  
  if (roles?.isOrgLeader && orgLeaderPaths.some(path => currentPath.startsWith(path))) {
    return children;
  }

  
  return <Navigate to="/unauthorized" replace />;
};

export default ProtectedRoute;