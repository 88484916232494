import React, { useState } from 'react';
import ProtectedRoute from '@/features/auth/components/ProtectedRoute';

const ScenarioCreationReportContent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  return (
    <div className="w-full h-full bg-white p-4">
      <h1 className="text-2xl font-bold mb-4">Scenario Creation Report</h1>
      
      {isLoading && (
        <div className="w-full h-full flex items-center justify-center">
          <div className="text-gray-600">Loading report...</div>
        </div>
      )}
      
      {error && (
        <div className="w-full h-full flex items-center justify-center">
          <div className="text-red-600">Error loading report: {error}</div>
        </div>
      )}
      
      <div className="w-full h-[calc(100vh-12rem)]">
        <iframe 
          width="100%" 
          height="100%" 
          src="https://example.com//lookerstudio.google.com/embed/reporting/c95f5015-bea2-401e-939d-2bc3e19eeb6f/page/smycE" 
          frameBorder="0" 
          style={{ border: 0 }} 
          allowFullScreen
          sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
          onLoad={() => setIsLoading(false)}
          onError={(e) => {
            setError('Failed to load report');
            setIsLoading(false);
          }}
        />
      </div>
    </div>
  );
};

// Wrap the component with ProtectedRoute
const ScenarioCreationReport = () => (
  <ProtectedRoute requiredRoles={['administrator', 'scenario_generator']}>
    <ScenarioCreationReportContent />
  </ProtectedRoute>
);

export default ScenarioCreationReport;
