
import React from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const MemberMap = ({ data }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY
  });

  const mapRef = React.useRef(null);
  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const onUnmount = React.useCallback(() => {
    mapRef.current = null;
  }, []);

  if (!isLoaded) return <div>Loading map...</div>;

  return (
    <div className="h-[600px] w-full">
      <GoogleMap
        mapContainerClassName="w-full h-full"
        center={{ lat: 39.8283, lng: -98.5795 }}
        zoom={4}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {/* Add markers for each user location */}
      </GoogleMap>
    </div>
  );
};

export default MemberMap;