import React, { useState } from 'react';
import { supabase } from '@/lib/supabaseClient';
import mammoth from 'mammoth';

const ProtocolParser = () => {
  const [file, setFile] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [statePrefix, setStatePrefix] = useState('');
  const [year, setYear] = useState('');
  const [progress, setProgress] = useState(0);

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setFile(selectedFile);
      
      
      const yearMatch = selectedFile.name.match(/\d{4}/);
      if (yearMatch) {
        setYear(yearMatch[0]);
      }
      
      setError(null);
    } else {
      setError('Please select a valid .docx file');
      setFile(null);
    }
  };

  const processDocument = async () => {
    if (!file || !statePrefix) {
      setError('Please select a file and enter the state prefix');
      return;
    }

    setProcessing(true);
    setError(null);
    setSuccess(false);
    setProgress(0);

    try {
      const arrayBuffer = await file.arrayBuffer();
      console.log('File read successfully');

      const result = await mammoth.convertToHtml({ 
        arrayBuffer,
        includeDefaultStyleMap: true
      });

      console.log('Document converted to HTML');
      
      
      const fileName = file.name.split('/').pop().replace(/\.docx$/, '');
      
      
      const processedContent = '<?xml version="1.0" encoding="UTF-8"?>\n<protocols></protocols>';
      console.log('XML structure created');

      const { data, error: uploadError } = await supabase
        .from('protocols')
        .insert({
          title: fileName,
          content: processedContent,
          year: year,
          state: statePrefix,
          keywords: [`#${statePrefix.toLowerCase()}`],
          created_at: new Date().toISOString()
        })
        .select();

      if (uploadError) throw uploadError;

      console.log('Protocol saved successfully');
      setSuccess(true);
      setFile(null);
      setYear('');
      document.getElementById('file-upload').value = '';
      
    } catch (err) {
      console.error('Error processing document:', err);
      if (err.message?.includes('duplicate key')) {
        setError('A protocol with this title already exists');
      } else if (err.code) {
        setError(`Database error: ${err.message}`);
      } else {
        setError(`Error: ${err.message || 'Unknown error occurred'}`);
      }
    } finally {
      setProcessing(false);
      setProgress(0);
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-6">
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-2xl font-bold mb-4">Protocol Document Parser</h2>
        
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              State Prefix
            </label>
            <input
              type="text"
              placeholder="e.g., ME, NY, TX"
              value={statePrefix}
              onChange={(e) => setStatePrefix(e.target.value.toUpperCase())}
              className="w-full max-w-xs px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Protocol Year
            </label>
            <input
              type="text"
              placeholder="e.g., 2024"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              className="w-full max-w-xs px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Protocol Document (.docx)
            </label>
            <input
              id="file-upload"
              type="file"
              accept=".docx"
              onChange={handleFileChange}
              className="w-full max-w-md"
            />
          </div>

          {processing && (
            <div className="space-y-2">
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div 
                  className="bg-blue-600 h-2.5 rounded-full transition-all duration-300"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
              <div className="text-sm text-gray-600">
                Processing document...
              </div>
            </div>
          )}

          {error && (
            <div className="bg-red-50 border-l-4 border-red-400 p-4">
              <p className="text-red-700">{error}</p>
            </div>
          )}

          {success && (
            <div className="bg-green-50 border-l-4 border-green-400 p-4">
              <p className="text-green-700">
                Protocol document successfully processed and saved!
              </p>
            </div>
          )}

          <button 
            onClick={processDocument} 
            disabled={!file || processing || !statePrefix}
            className={`w-full max-w-xs px-4 py-2 text-white rounded-md ${
              !file || processing || !statePrefix
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-blue-600 hover:bg-blue-700'
            }`}
          >
            {processing ? (
              <span className="flex items-center justify-center">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg//www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Processing...
              </span>
            ) : (
              'Process Document'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProtocolParser;
