

// ValidationDashboard.jsx
import React, { useState, useEffect } from 'react';
import { Loader2, Filter, RefreshCcw, Plus, Pencil, Trash2 } from 'lucide-react';
import { supabase } from '@/lib/supabaseClient';
import ValidationResults from '@/features/scenarios/components/ScenarioGenerator/components/ScenarioGenerator/components/validation/ValidationResults';

// CodeSection component for displaying code snippets
const CodeSection = ({ title, children }) => {
  return (
    <div className="border rounded-lg overflow-hidden mb-4">
      <div className="bg-gray-100 px-4 py-2 font-medium border-b">
        {title}
      </div>
      <div className="bg-gray-50 p-4 overflow-x-auto">
        <pre className="text-sm font-mono whitespace-pre-wrap">{children}</pre>
      </div>
    </div>
  );
};

const ValidationDashboard = () => {
  // State management
  const [activeTab, setActiveTab] = useState('generations');
  const [validations, setValidations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    status: 'all',
    timeframe: '7days'
  });

  // Base instructions state
  const [baseInstructions, setBaseInstructions] = useState([]);
  const [showInstructionModal, setShowInstructionModal] = useState(false);
  const [editingInstruction, setEditingInstruction] = useState(null);

  // System prompts state
  const [systemPrompts, setSystemPrompts] = useState([]);
  const [showSystemPromptModal, setShowSystemPromptModal] = useState(false);
  const [editingSystemPrompt, setEditingSystemPrompt] = useState(null);

  // Rule management state
  const [validationRules, setValidationRules] = useState([]);
  const [showRuleModal, setShowRuleModal] = useState(false);
  const [editingRule, setEditingRule] = useState(null);
  
  // Initial states for instructions, system prompts and rules
  const initialInstructionState = {
    content: '',
    testing: true
  };

  const initialSystemPromptState = {
    content: '',
    is_default: true,
    description: ''
  };

  const initialRuleState = {
    rule_name: '',
    rule_description: '',
    active: true,
    testing: true
  };

  const [newInstruction, setNewInstruction] = useState(initialInstructionState);
  const [newSystemPrompt, setNewSystemPrompt] = useState(initialSystemPromptState);
  const [newRule, setNewRule] = useState(initialRuleState);

  // Fetch Functions
  const fetchValidations = async () => {
    try {
      setLoading(true);
      setError(null);

      let query = supabase
        .from('scenario_validations_v3')
        .select(`
          *,
          scenario_generations_v3 (
            id,
            title,
            scenario_topic,
            category,
            complexity
          )
        `)
        .order('validated_at', { ascending: false });

      if (filters.timeframe !== 'all') {
        const daysAgo = {
          '7days': 7,
          '30days': 30,
          '90days': 90
        }[filters.timeframe];

        if (daysAgo) {
          const cutoffDate = new Date();
          cutoffDate.setDate(cutoffDate.getDate() - daysAgo);
          query = query.gte('validated_at', cutoffDate.toISOString());
        }
      }

      if (filters.status !== 'all') {
        query = query.eq('validation_result->>isValid', filters.status === 'valid');
      }

      const { data, error: fetchError } = await query;

      if (fetchError) throw fetchError;
      setValidations(data || []);
    } catch (err) {
      console.error('Error fetching validations:', err);
      setError('Failed to load validation data: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchValidationRules = async () => {
    try {
      const { data, error } = await supabase
        .from('validation_rules')
        .select('*')
        .order('created_at', { ascending: true });

      if (error) throw error;
      setValidationRules(data || []);
    } catch (err) {
      console.error('Error fetching validation rules:', err);
      setError('Failed to load validation rules: ' + err.message);
    }
  };

  const fetchBaseInstructions = async () => {
    try {
      const { data, error } = await supabase
        .from('base_instructions')
        .select('*')
        .order('updated_at', { ascending: false });

      if (error) throw error;
      setBaseInstructions(data || []);
    } catch (err) {
      console.error('Error fetching base instructions:', err);
      setError('Failed to load base instructions: ' + err.message);
    }
  };

  const fetchSystemPrompts = async () => {
    try {
      const { data, error } = await supabase
        .from('system_prompts')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      
      // If no data exists yet, create an initial system prompt
      if (!data || data.length === 0) {
        const defaultPrompt = "Create a realistic medical training scenario with clinically meaningful details. Include a patient report with SPECIFIC vital signs and assessment findings. Use appropriate medical terminology, medication names, and numerical values for vital signs. AVOID generic placeholders like 'appropriate to the condition' or 'vital signs as expected'. Generate the EXACT number of questions requested in the prompt - no more and no fewer. Return a complete, valid JSON object only.";
        
        const { data: newPromptData, error: insertError } = await supabase
          .from('system_prompts')
          .insert([{
            content: defaultPrompt,
            is_default: true,
            description: 'Default system prompt from index.ts'
          }])
          .select();
          
        if (insertError) throw insertError;
        setSystemPrompts(newPromptData || []);
      } else {
        setSystemPrompts(data);
      }
    } catch (err) {
      console.error('Error fetching system prompts:', err);
      setError('Failed to load system prompts: ' + err.message);
    }
  };

  useEffect(() => {
    fetchValidations();
    fetchValidationRules();
    fetchBaseInstructions();
    fetchSystemPrompts();
  }, [filters]);

  // Handler Functions
  const handleSaveInstruction = async () => {
    try {
      const instructionData = {
        content: newInstruction.content || '',
        testing: newInstruction.testing ?? true
      };

      let response;
      if (editingInstruction) {
        response = await supabase
          .from('base_instructions')
          .update(instructionData)
          .eq('id', editingInstruction.id);
      } else {
        response = await supabase
          .from('base_instructions')
          .insert([instructionData]);
      }

      if (response.error) throw response.error;

      fetchBaseInstructions();
      setShowInstructionModal(false);
      setEditingInstruction(null);
      setNewInstruction(initialInstructionState);
    } catch (err) {
      console.error('Error saving instruction:', err);
      setError('Failed to save instruction: ' + err.message);
    }
  };

  const handleDeleteInstruction = async (instructionId) => {
    if (window.confirm('Are you sure you want to delete this instruction?')) {
      try {
        const { error } = await supabase
          .from('base_instructions')
          .delete()
          .eq('id', instructionId);

        if (error) throw error;
        fetchBaseInstructions();
      } catch (err) {
        console.error('Error deleting instruction:', err);
        setError('Failed to delete instruction: ' + err.message);
      }
    }
  };

  const handleSaveSystemPrompt = async () => {
    try {
      const promptData = {
        content: newSystemPrompt.content || '',
        is_default: newSystemPrompt.is_default ?? true,
        description: newSystemPrompt.description || ''
      };

      // If setting this as default, update all other prompts to not be default
      if (promptData.is_default) {
        await supabase
          .from('system_prompts')
          .update({ is_default: false })
          .neq('id', editingSystemPrompt ? editingSystemPrompt.id : -1);
      }

      let response;
      if (editingSystemPrompt) {
        response = await supabase
          .from('system_prompts')
          .update(promptData)
          .eq('id', editingSystemPrompt.id);
      } else {
        response = await supabase
          .from('system_prompts')
          .insert([promptData]);
      }

      if (response.error) throw response.error;

      fetchSystemPrompts();
      setShowSystemPromptModal(false);
      setEditingSystemPrompt(null);
      setNewSystemPrompt(initialSystemPromptState);
    } catch (err) {
      console.error('Error saving system prompt:', err);
      setError('Failed to save system prompt: ' + err.message);
    }
  };

  const handleDeleteSystemPrompt = async (promptId) => {
    if (window.confirm('Are you sure you want to delete this system prompt?')) {
      try {
        const promptToDelete = systemPrompts.find(p => p.id === promptId);
        
        // Don't delete if it's the only prompt or if it's the default prompt
        if (systemPrompts.length <= 1) {
          alert("Cannot delete the only system prompt. At least one must exist.");
          return;
        }
        
        if (promptToDelete.is_default) {
          alert("Cannot delete the default system prompt. Set another prompt as default first.");
          return;
        }
        
        const { error } = await supabase
          .from('system_prompts')
          .delete()
          .eq('id', promptId);

        if (error) throw error;
        fetchSystemPrompts();
      } catch (err) {
        console.error('Error deleting system prompt:', err);
        setError('Failed to delete system prompt: ' + err.message);
      }
    }
  };

  const handleSetDefaultSystemPrompt = async (promptId) => {
    try {
      // Update all prompts to not be default
      await supabase
        .from('system_prompts')
        .update({ is_default: false })
        .neq('id', promptId);
      
      // Set the selected prompt as default
      const { error } = await supabase
        .from('system_prompts')
        .update({ is_default: true })
        .eq('id', promptId);

      if (error) throw error;
      fetchSystemPrompts();
    } catch (err) {
      console.error('Error setting default system prompt:', err);
      setError('Failed to set default prompt: ' + err.message);
    }
  };

  const handleSaveRule = async () => {
    try {
      const ruleData = {
        rule_name: newRule.rule_name || '',
        rule_description: newRule.rule_description || '',
        active: newRule.active ?? true,
        testing: newRule.testing ?? true
      };

      let response;
      if (editingRule) {
        response = await supabase
          .from('validation_rules')
          .update(ruleData)
          .eq('id', editingRule.id);
      } else {
        response = await supabase
          .from('validation_rules')
          .insert([ruleData]);
      }

      if (response.error) throw response.error;

      fetchValidationRules();
      setShowRuleModal(false);
      setEditingRule(null);
      setNewRule(initialRuleState);
    } catch (err) {
      console.error('Error saving rule:', err);
      setError('Failed to save rule: ' + err.message);
    }
  };

  const handleDeleteRule = async (ruleId) => {
    if (window.confirm('Are you sure you want to delete this rule?')) {
      try {
        const { error } = await supabase
          .from('validation_rules')
          .delete()
          .eq('id', ruleId);

        if (error) throw error;
        fetchValidationRules();
      } catch (err) {
        console.error('Error deleting rule:', err);
        setError('Failed to delete rule: ' + err.message);
      }
    }
  };

  const getStatusStyle = (isValid) => {
    return isValid
      ? 'bg-green-100 text-green-800'
      : 'bg-red-100 text-red-800';
  };

  // Loading State
  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loader2 className="animate-spin h-8 w-8 text-blue-600" />
      </div>
    );
  }

  // Main Render
  return (
    <div className="p-6 max-w-7xl mx-auto">
      {/* Tab Navigation */}
      <div className="border-b border-gray-200 mb-6">
        <div className="flex space-x-4">
          <button
            className={`py-2 px-4 text-sm font-medium ${
              activeTab === 'generations'
                ? 'border-b-2 border-blue-500 text-blue-600'
                : 'text-gray-500 hover:text-gray-700'
            }`}
            onClick={() => setActiveTab('generations')}
          >
            Validation Generations
          </button>
          <button
            className={`py-2 px-4 text-sm font-medium ${
              activeTab === 'instructions'
                ? 'border-b-2 border-blue-500 text-blue-600'
                : 'text-gray-500 hover:text-gray-700'
            }`}
            onClick={() => setActiveTab('instructions')}
          >
            Base Instructions
          </button>
          <button
            className={`py-2 px-4 text-sm font-medium ${
              activeTab === 'system_prompts'
                ? 'border-b-2 border-blue-500 text-blue-600'
                : 'text-gray-500 hover:text-gray-700'
            }`}
            onClick={() => setActiveTab('system_prompts')}
          >
            System Prompts
          </button>
          <button
            className={`py-2 px-4 text-sm font-medium ${
              activeTab === 'rules'
                ? 'border-b-2 border-blue-500 text-blue-600'
                : 'text-gray-500 hover:text-gray-700'
            }`}
            onClick={() => setActiveTab('rules')}
          >
            Validation Rules
          </button>
        </div>
      </div>

      {/* Error Display */}
      {error && (
        <div className="mb-6 p-4 bg-red-50 border border-red-200 text-red-700 rounded-lg">
          {error}
        </div>
      )}

      {/* Validation Generations Tab */}
      {activeTab === 'generations' && (
        <div>
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold">Scenario Validation Results</h2>
            
            <div className="flex gap-4">
              <div className="flex items-center gap-2">
                <Filter className="h-5 w-5 text-gray-500" />
                <select
                  value={filters.timeframe}
                  onChange={(e) => setFilters(prev => ({ ...prev, timeframe: e.target.value }))}
                  className="border rounded-lg px-3 py-2 text-sm"
                >
                  <option value="7days">Last 7 Days</option>
                  <option value="30days">Last 30 Days</option>
                  <option value="90days">Last 90 Days</option>
                  <option value="all">All Time</option>
                </select>
                
                <select
                  value={filters.status}
                  onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}
                  className="border rounded-lg px-3 py-2 text-sm"
                >
                  <option value="all">All Status</option>
                  <option value="valid">Valid</option>
                  <option value="invalid">Invalid</option>
                </select>
              </div>

              <button
                onClick={fetchValidations}
                className="flex items-center gap-2 px-4 py-2 text-sm bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100"
              >
                <RefreshCcw className="h-4 w-4" />
                Refresh
              </button>
            </div>
          </div>

          <div className="grid gap-6">
            {validations.map((validation) => (
              <div key={validation.id} className="bg-white rounded-lg shadow-sm border p-6">
                <div className="flex justify-between items-start mb-4">
                  <div>
                    <h3 className="text-lg font-semibold">
                      {validation.scenario_generations_v3?.title || 'Untitled Scenario'}
                    </h3>
                    <p className="text-gray-600">
                      {validation.scenario_generations_v3?.scenario_topic || 'No topic specified'}
                    </p>
                  </div>
                  <div className={`px-3 py-1 rounded-full text-sm font-medium ${
                    getStatusStyle(validation.validation_result.isValid)
                  }`}>
                    {validation.validation_result.isValid ? 'Valid' : 'Invalid'}
                  </div>
                </div>

                <ValidationResults 
                  validationResult={validation.validation_result}
                  isValidating={false}
                />

                <div className="mt-4 text-sm text-gray-500">
                  Validated at: {new Date(validation.validated_at).toLocaleString()}
                </div>
              </div>
            ))}

            {validations.length === 0 && (
              <div className="text-center py-12 bg-gray-50 rounded-lg">
                <p className="text-gray-600">No validation results found for the selected filters.</p>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Base Instructions Tab */}
      {activeTab === 'instructions' && (
        <div>
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold">Base Instructions</h2>
            
            <button
              onClick={() => {
                setEditingInstruction(null);
                setNewInstruction(initialInstructionState);
                setShowInstructionModal(true);
              }}
              className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              <Plus className="h-4 w-4" />
              Add New Instruction
            </button>
          </div>

          <div className="bg-white rounded-lg shadow-sm border overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Content
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Testing
                  </th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {baseInstructions.map((instruction) => (
                  <tr key={instruction.id}>
                    <td className="px-6 py-4 text-sm text-gray-500">
                      <div className="max-w-xs truncate">
                        {instruction.content}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                        instruction.testing
                          ? 'bg-yellow-100 text-yellow-800'
                          : 'bg-gray-100 text-gray-800'
                      }`}>
                        {instruction.testing ? 'Dev/Test' : 'Production'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button
                        onClick={() => {
                          setEditingInstruction(instruction);
                          setNewInstruction(instruction);
                          setShowInstructionModal(true);
                        }}
                        className="text-blue-600 hover:text-blue-900 mr-3"
                      >
                        <Pencil className="h-4 w-4" />
                      </button>
                      <button
                        onClick={() => handleDeleteInstruction(instruction.id)}
                        className="text-red-600 hover:text-red-900"
                      >
                        <Trash2 className="h-4 w-4" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* System Prompts Tab */}
      {activeTab === 'system_prompts' && (
        <div>
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold">System Prompts</h2>
            
            <button
              onClick={() => {
                setEditingSystemPrompt(null);
                setNewSystemPrompt(initialSystemPromptState);
                setShowSystemPromptModal(true);
              }}
              className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              <Plus className="h-4 w-4" />
              Add New System Prompt
            </button>
          </div>

          <div className="mb-8 bg-white rounded-lg shadow-sm border overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Description
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Content
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {systemPrompts.map((prompt) => (
                  <tr key={prompt.id}>
                    <td className="px-6 py-4 text-sm font-medium text-gray-900">
                      {prompt.description || 'No description'}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500">
                      <div className="max-w-xs truncate">
                        {prompt.content}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                        prompt.is_default
                          ? 'bg-green-100 text-green-800'
                          : 'bg-gray-100 text-gray-800'
                      }`}>
                        {prompt.is_default ? 'Default' : 'Alternative'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      {!prompt.is_default && (
                        <button
                          onClick={() => handleSetDefaultSystemPrompt(prompt.id)}
                          className="text-green-600 hover:text-green-900 mr-3"
                          title="Set as Default"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg//www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                        </button>
                      )}
                      <button
                        onClick={() => {
                          setEditingSystemPrompt(prompt);
                          setNewSystemPrompt(prompt);
                          setShowSystemPromptModal(true);
                        }}
                        className="text-blue-600 hover:text-blue-900 mr-3"
                      >
                        <Pencil className="h-4 w-4" />
                      </button>
                      <button
                        onClick={() => handleDeleteSystemPrompt(prompt.id)}
                        className="text-red-600 hover:text-red-900"
                        disabled={prompt.is_default || systemPrompts.length <= 1}
                      >
                        <Trash2 className={`h-4 w-4 ${(prompt.is_default || systemPrompts.length <= 1) ? 'opacity-50' : ''}`} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mt-10">
            <h3 className="text-lg font-semibold mb-4">Code Snippets from index.ts</h3>
            <p className="text-sm text-gray-600 mb-6">These code sections show the actual implementation and logic used in generating scenarios.</p>
            
            <CodeSection title="Default System Prompt">
              {"// Get system prompt for Claude\nconst getSystemPrompt = () => {\n  return \"Create a realistic medical training scenario with clinically meaningful details. Include a patient report with SPECIFIC vital signs and assessment findings. Use appropriate medical terminology, medication names, and numerical values for vital signs. AVOID generic placeholders like 'appropriate to the condition' or 'vital signs as expected'. Generate the EXACT number of questions requested in the prompt - no more and no fewer. Return a complete, valid JSON object only.\";\n};"}
            </CodeSection>

            <CodeSection title="Anthropic API Call">
              {"// Call Claude API with improved instructions for clinical detail\nconsole.log('Calling Anthropic API with enhanced clinical prompt');\nconst response = await anthropic.messages.create({\n  model: \"claude-3-sonnet-20240229\",\n  max_tokens: 4000,\n  temperature: 0.7,\n  system: getSystemPrompt() + ` <!-- DEBUG_FLAG: SYSTEM_PROMPT_FOR_${formData.scenario_topic} -->`,\n  messages: [{\n    role: \"user\",\n    content: prompt\n  }]\n});"}
            </CodeSection>

            <CodeSection title="Patient Report Generation: Dispatch Details">
{`// Select appropriate fallback dispatch details
let dispatchDetails = '';
switch (clinicalFocus) {
  case 'cardiac':
    dispatchDetails = \`Called to residential address at \${Math.random() > 0.5 ? 'evening' : 'afternoon'} for \${age}yo \${gender} experiencing chest pain and shortness of breath for past \${Math.floor(Math.random() * 60) + 15} minutes.\`;
    break;
  case 'respiratory':
    dispatchDetails = \`Dispatched to \${Math.random() > 0.5 ? 'apartment complex' : 'single-family home'} for \${age}yo \${gender} with difficulty breathing ongoing for \${Math.floor(Math.random() * 120) + 30} minutes, worsening in last 10 minutes.\`;
    break;
  case 'trauma':
    dispatchDetails = \`Dispatched to \${Math.random() > 0.5 ? 'residential address' : 'public park'} for \${age}yo \${gender} who fell down \${Math.random() > 0.5 ? 'stairs' : 'from standing height'} with reported head injury approximately \${Math.floor(Math.random() * 30) + 5} minutes ago.\`;
    break;
  case 'neurological':
    dispatchDetails = \`Emergency response requested to \${Math.random() > 0.5 ? 'residence' : 'workplace'} for \${age}yo \${gender} with sudden onset of \${Math.random() > 0.5 ? 'facial drooping and slurred speech' : 'unilateral weakness and confusion'} beginning \${Math.floor(Math.random() * 45) + 10} minutes ago.\`;
    break;
  case 'diabetic':
    dispatchDetails = \`Called to \${Math.random() > 0.5 ? 'residential address' : 'restaurant'} for \${age}yo \${gender} with altered mental status, history of diabetes, last medication \${Math.floor(Math.random() * 12) + 2} hours ago.\`;
    break;
  default:
    dispatchDetails = \`Dispatched to residential address for \${age}yo \${gender} with \${Math.random() > 0.5 ? 'sudden onset of severe abdominal pain' : 'general weakness and dizziness'} for approximately \${Math.floor(Math.random() * 180) + 30} minutes.\`;
}`}
            </CodeSection>



            <CodeSection title="Patient Report Generation: Scene Details">
{`// Generate scene details
let sceneDetails = '';
if (dispatchDetails.includes('residential')) {
  sceneDetails = \`\${Math.random() > 0.5 ? 'Well-maintained' : 'Cluttered'} \${Math.random() > 0.5 ? 'single-story' : 'two-story'} home, \${Math.random() > 0.5 ? 'well-lit' : 'dimly lit'}, with \${Math.random() > 0.5 ? 'spouse' : 'adult child'} present expressing \${Math.random() > 0.5 ? 'significant concern' : 'moderate anxiety'}.\`;
} else if (dispatchDetails.includes('apartment')) {
  sceneDetails = \`\${Math.random() > 0.5 ? '3rd' : '2nd'} floor unit with \${Math.random() > 0.5 ? 'narrow stairwell' : 'elevator access'}, \${Math.random() > 0.5 ? 'multiple family members' : 'roommate'} present providing history of current illness.\`;
} else if (dispatchDetails.includes('workplace')) {
  sceneDetails = \`\${Math.random() > 0.5 ? 'Office environment' : 'Retail location'} with \${Math.random() > 0.5 ? 'adequate space for assessment' : 'limited room to work'}, \${Math.random() > 0.5 ? 'coworkers' : 'manager'} on scene providing medical history.\`;
} else {
  sceneDetails = \`\${Math.random() > 0.5 ? 'Public location' : 'Home environment'} with \${Math.random() > 0.5 ? 'good access' : 'challenging access'}, \${Math.random() > 0.5 ? 'family member' : 'bystander'} on scene who witnessed event.\`;
}`}
            </CodeSection>

            <CodeSection title="Patient Report Generation: Patient Details">
{`// Generate patient presentation details
let patientDetails = '';
switch (clinicalFocus) {
  case 'cardiac':
    patientDetails = \`\${age}yo \${gender} found \${Math.random() > 0.5 ? 'seated upright on couch appearing anxious' : 'sitting in recliner clutching chest'}, alert and oriented x\${Math.random() > 0.5 ? '3' : '4'}, skin \${Math.random() > 0.5 ? 'pale and diaphoretic' : 'pale and cool to touch'}.\`;
    break;
  case 'respiratory':
    patientDetails = \`\${age}yo \${gender} found \${Math.random() > 0.5 ? 'seated upright in tripod position' : 'sitting on edge of bed leaning forward'}, alert but \${Math.random() > 0.5 ? 'speaking in 3-4 word sentences' : 'unable to complete full sentences'}, visible \${Math.random() > 0.5 ? 'accessory muscle use' : 'intercostal retractions'}.\`;
    break;
  case 'trauma':
    patientDetails = \`\${age}yo \${gender} found \${Math.random() > 0.5 ? 'supine on floor' : 'seated against wall'}, \${Math.random() > 0.5 ? 'GCS 14 (E4V4M6)' : 'alert and oriented x3'}, \${Math.random() > 0.5 ? '3cm laceration to occipital scalp with controlled bleeding' : 'contusion to right temple'}.\`;
    break;
  case 'neurological':
    patientDetails = \`\${age}yo \${gender} found \${Math.random() > 0.5 ? 'seated in chair with right-sided facial droop' : 'lying in bed with left arm weakness'}, \${Math.random() > 0.5 ? 'speech slurred but understandable' : 'mild dysarthria present'}, \${Math.random() > 0.5 ? 'able to follow commands' : 'follows simple instructions'}.\`;
    break;
  case 'diabetic':
    patientDetails = \`\${age}yo \${gender} found \${Math.random() > 0.5 ? 'seated at kitchen table appearing confused' : 'lying on couch in altered mental state'}, \${Math.random() > 0.5 ? 'diaphoretic with cool skin' : 'warm and dry to touch'}, \${Math.random() > 0.5 ? 'responding to verbal stimuli' : 'lethargic but arousable'}.\`;
    break;
  default:
    patientDetails = \`\${age}yo \${gender} found \${Math.random() > 0.5 ? 'seated appearing uncomfortable' : 'lying in bed in moderate distress'}, alert and oriented \${Math.random() > 0.5 ? 'x3' : 'to person, place, and time'}, skin \${Math.random() > 0.5 ? 'normal color and moisture' : 'flushed but dry'}.\`;
}`}
            </CodeSection>

            <CodeSection title="Patient Report Generation: Vital Signs">
{`// Generate appropriate vital signs based on the clinical focus
let vitalSigns = '';
switch (clinicalFocus) {
  case 'cardiac':
    vitalSigns = \`HR \${Math.floor(Math.random() * 40) + 90} \${Math.random() > 0.5 ? 'regular' : 'irregular'}, BP \${Math.floor(Math.random() * 60) + 140}/\${Math.floor(Math.random() * 20) + 80}, RR \${Math.floor(Math.random() * 8) + 18} \${Math.random() > 0.5 ? 'labored' : 'non-labored'}, SpO2 \${Math.floor(Math.random() * 8) + 92}% on RA, temp \${(Math.floor(Math.random() * 10) + 360)/10}°C, pain \${Math.floor(Math.random() * 4) + 6}/10 \${Math.random() > 0.5 ? 'crushing substernal' : 'pressure-like radiating to left arm'}.\`;
    break;
  case 'respiratory':
    vitalSigns = \`HR \${Math.floor(Math.random() * 40) + 100} regular, BP \${Math.floor(Math.random() * 30) + 130}/\${Math.floor(Math.random() * 15) + 75}, RR \${Math.floor(Math.random() * 12) + 24} labored with \${Math.random() > 0.5 ? 'accessory muscle use' : 'bilateral wheezing on auscultation'}, SpO2 \${Math.floor(Math.random() * 10) + 84}% on RA, temp \${(Math.floor(Math.random() * 14) + 370)/10}°C.\`;
    break;
  case 'trauma':
    vitalSigns = \`HR \${Math.floor(Math.random() * 40) + 90} regular, BP \${Math.floor(Math.random() * 20) + 120}/\${Math.floor(Math.random() * 15) + 70}, RR \${Math.floor(Math.random() * 6) + 18} regular, SpO2 \${Math.floor(Math.random() * 5) + 95}% on RA, pupils \${Math.random() > 0.5 ? 'PERRL' : 'equal and reactive'}, temp 36.8°C, pain \${Math.floor(Math.random() * 3) + 6}/10 \${Math.random() > 0.5 ? 'at injury site' : 'with movement'}.\`;
    break;
  case 'neurological':
    vitalSigns = \`HR \${Math.floor(Math.random() * 25) + 70} regular, BP \${Math.floor(Math.random() * 30) + 160}/\${Math.floor(Math.random() * 20) + 85}, RR \${Math.floor(Math.random() * 6) + 16} non-labored, SpO2 \${Math.floor(Math.random() * 4) + 96}% on RA, BGL \${Math.floor(Math.random() * 40) + 100} mg/dL, Cincinnati Stroke Scale \${Math.random() > 0.5 ? 'positive for facial droop' : 'positive for arm drift'}.\`;
    break;
  case 'diabetic':
    vitalSigns = \`HR \${Math.floor(Math.random() * 30) + 90} regular, BP \${Math.floor(Math.random() * 20) + 115}/\${Math.floor(Math.random() * 15) + 65}, RR \${Math.floor(Math.random() * 8) + 16} regular, SpO2 \${Math.floor(Math.random() * 5) + 94}% on RA, BGL \${Math.random() > 0.5 ? Math.floor(Math.random() * 30) + 35 : Math.floor(Math.random() * 200) + 300} mg/dL, skin \${Math.random() > 0.5 ? 'cool and clammy' : 'warm and dry'}.\`;
    break;
  default:
    vitalSigns = \`HR \${Math.floor(Math.random() * 30) + 80} regular, BP \${Math.floor(Math.random() * 30) + 125}/\${Math.floor(Math.random() * 15) + 75}, RR \${Math.floor(Math.random() * 8) + 16} non-labored, SpO2 \${Math.floor(Math.random() * 6) + 94}% on RA, temp 37.1°C, \${Math.random() > 0.5 ? 'capillary refill <2 seconds' : 'skin warm and dry to touch'}.\`;
}`}
            </CodeSection>

<CodeSection title="Patient Report Generation: Medical History">
{`// Generate relevant medical history
let medicalHistory = '';
switch (clinicalFocus) {
  case 'cardiac':
    medicalHistory = \`History of \${Math.random() > 0.5 ? 'hypertension, hyperlipidemia, and prior MI 3 years ago' : 'CAD, CHF, and CABG 5 years ago'}. Current medications include \${Math.random() > 0.5 ? 'metoprolol, aspirin, and atorvastatin' : 'lisinopril, atorvastatin, and clopidogrel'}. \${Math.random() > 0.5 ? 'Similar pain 2 days ago that resolved with rest' : 'No prior episodes of this chest pain'}.\`;
    break;
  case 'respiratory':
    medicalHistory = \`History of \${Math.random() > 0.5 ? 'COPD with 2 exacerbations in past year' : 'asthma since childhood with multiple hospitalizations'}. Takes \${Math.random() > 0.5 ? 'albuterol, budesonide/formoterol, and montelukast' : 'fluticasone/salmeterol and tiotropium'}. \${Math.random() > 0.5 ? 'Recent URI symptoms 1 week ago' : 'No recent respiratory infections'}.\`;
    break;
  case 'trauma':
    medicalHistory = \`\${Math.random() > 0.5 ? 'No significant past medical history' : 'History of hypertension and osteoarthritis'}. Current medications include \${Math.random() > 0.5 ? 'none' : 'lisinopril and acetaminophen'}. \${Math.random() > 0.5 ? 'No loss of consciousness reported' : 'Brief LOC according to witnesses'}, no anticoagulant use.\`;
    break;
  case 'neurological':
    medicalHistory = \`History of \${Math.random() > 0.5 ? 'hypertension, atrial fibrillation, and prior TIA 2 years ago' : 'hypertension, hyperlipidemia, and type 2 diabetes'}. Takes \${Math.random() > 0.5 ? 'apixaban, metoprolol, and atorvastatin' : 'lisinopril, metformin, and aspirin'}. \${Math.random() > 0.5 ? 'Symptoms began suddenly while watching TV' : 'Noticed symptoms upon waking this morning'}.\`;
    break;
  case 'diabetic':
    medicalHistory = \`History of \${Math.random() > 0.5 ? 'type 1 diabetes for 15 years' : 'type 2 diabetes for 8 years'} and \${Math.random() > 0.5 ? 'hypertension' : 'CKD stage 2'}. Takes \${Math.random() > 0.5 ? 'insulin glargine and lispro' : 'metformin and glipizide'}. \${Math.random() > 0.5 ? 'Missed last meal' : 'Recent change in medication dosage'}, last insulin \${Math.floor(Math.random() * 12) + 2} hours ago.\`;
    break;
  default:
    medicalHistory = \`History of \${Math.random() > 0.5 ? 'hypertension and GERD' : 'migraines and anxiety'}. Current medications include \${Math.random() > 0.5 ? 'lisinopril and omeprazole' : 'sumatriptan and sertraline'}. \${Math.random() > 0.5 ? 'Symptoms began after eating dinner' : 'Gradual onset of symptoms over past 24 hours'}.\`;
}`}
            </CodeSection>

            <CodeSection title="Patient Report Format">
{`// Combine all sections into a complete patient report
const completeReport = \`DISPATCH: \${dispatchDetails}
SCENE: \${sceneDetails}
PATIENT: \${patientDetails}
INITIAL OBSERVATIONS: \${vitalSigns}
HISTORY: \${medicalHistory}\`;`}
            </CodeSection>

            <CodeSection title="Validation Logic">
{`// Validate patient report structure and clinical meaningfulness
const validatePatientReport = (report) => {
  if (!report) return false;
  
  // Check for required sections (relaxed to detect even abbreviated sections)
  const requiredSections = [
    /DISPATCH|dispatch/i,
    /SCENE|scene/i, 
    /PATIENT|patient/i,
    /OBSERVATION|observation/i,
    /HISTORY|history/i
  ];
  
  // Count how many sections we found
  const foundSections = requiredSections.filter(section => section.test(report));
  
  // Check for specific clinical details that indicate a meaningful report
  const hasVitalSigns = /\\bBP\\s+\\d+\\/\\d+\\b|\\bHR\\s+\\d+\\b|\\bRR\\s+\\d+\\b|\\bSpO2\\s+\\d+%\\b|\\bGCS\\s+\\d+\\b|\\btemp\\s+\\d+\\.\\d+\\b/i.test(report);
  const hasNumericValues = /\\d+yo|\\d+\\s+year|age\\s+\\d+|\\d+\\/\\d+|\\d+%|\\d+\\s+mg|\\d+\\s+min|duration\\s+\\d+|[0-9]\\/10\\s+pain/.test(report);
  
  // Check for generic placeholder content that we want to avoid
  const hasGenericContent = /appropriate for this scenario|standard scene|as appropriate|standard protocol|requiring assessment|as needed|relevant medical history|demographics are appropriate/i.test(report);
  
  // For a fully valid report we need:
  // 1. At least 3 of 5 required sections
  // 2. Presence of vital signs
  // 3. Presence of numeric values (ages, measurements, etc.)
  // 4. No generic placeholder content
  return foundSections.length >= 3 && hasVitalSigns && hasNumericValues && !hasGenericContent;
};`}
            </CodeSection>

            <CodeSection title="Base Instructions Logic">
{`// Build the main prompt, prioritizing instructions from the base_instructions table
const buildPrompt = (formData) => {
  // Get expected question count
  const questionCount = parseInt(formData.number_of_questions) || 4;
  
  // Use base instructions as is, without adding structure requirements
  const baseInstructions = formData.baseInstructions || '';
  
  if (baseInstructions && baseInstructions.trim().length > 0) {
    console.log('Using base instructions from database directly');
    
    // Only add the return format requirements
    return \`\${baseInstructions}

IMPORTANT: Create EXACTLY \${questionCount} questions, no more and no fewer.

Return in this JSON format:
{
  "patientreport": "Complete patient report based on the scenario",
  "questions": [
    {
      "question": "Question text",
      "answers": [
        {
          "answer": "Option text",
          "correct": boolean
        },
        ... (4 options total)
      ]
    },
    ... (exactly \${questionCount} questions)
  ]
}\`;
  }
  
  // Provide a minimal fallback prompt if no base instructions
  console.log('WARNING: No base_instructions found, using minimal fallback prompt');
  
  return \`Generate a medical training scenario with EXACTLY \${questionCount} questions about \${formData.scenario_topic || 'medical assessment'}.

- Follow current protocols for \${formData.userrole || 'healthcare providers'}
- Include realistic clinical details and vital signs
- Each question should have 4 options with exactly one correct answer

Return in this JSON format:
{
  "patientreport": "Complete patient report based on the scenario",
  "questions": [
    {
      "question": "Question text",
      "answers": [
        {
          "answer": "Option text",
          "correct": boolean
        },
        ... (4 options total)
      ]
    },
    ... (exactly \${questionCount} questions)
  ]
}\`;
};`}
            </CodeSection>
          </div>
        </div>
      )}

      {/* Validation Rules Tab */}
      {activeTab === 'rules' && (
        <div>
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold">Validation Rules</h2>
            
            <button
              onClick={() => {
                setEditingRule(null);
                setNewRule(initialRuleState);
                setShowRuleModal(true);
              }}
              className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              <Plus className="h-4 w-4" />
              Add New Rule
            </button>
          </div>

          <div className="bg-white rounded-lg shadow-sm border overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Rule Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Description
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Testing
                  </th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {validationRules.map((rule) => (
                  <tr key={rule.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {rule.rule_name}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500">
                      {rule.rule_description}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                        rule.active 
                          ? 'bg-green-100 text-green-800' 
                          : 'bg-gray-100 text-gray-800'
                      }`}>
                        {rule.active ? 'Active' : 'Inactive'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                        rule.testing
                          ? 'bg-yellow-100 text-yellow-800'
                          : 'bg-gray-100 text-gray-800'
                      }`}>
                        {rule.testing ? 'Dev/Test' : 'Production'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button
                        onClick={() => {
                          setEditingRule(rule);
                          setNewRule(rule);
                          setShowRuleModal(true);
                        }}
                        className="text-blue-600 hover:text-blue-900 mr-3"
                      >
                        <Pencil className="h-4 w-4" />
                      </button>
                      <button
                        onClick={() => handleDeleteRule(rule.id)}
                        className="text-red-600 hover:text-red-900"
                      >
                        <Trash2 className="h-4 w-4" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Base Instruction Modal */}
      {showInstructionModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h3 className="text-lg font-semibold mb-4">
              {editingInstruction ? 'Edit Instruction' : 'Add New Instruction'}
            </h3>
            
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Content
                </label>
                <textarea
                  value={newInstruction.content || ''}
                  onChange={(e) => setNewInstruction(prev => ({ ...prev, content: e.target.value }))}
                  rows={4}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={newInstruction.testing ?? true}
                    onChange={(e) => setNewInstruction(prev => ({ ...prev, testing: e.target.checked }))}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="text-sm font-medium text-gray-700">Dev/Test Mode</span>
                </label>
              </div>
            </div>

            <div className="mt-6 flex justify-end space-x-3">
              <button
                onClick={() => setShowInstructionModal(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveInstruction}
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700"
              >
                {editingInstruction ? 'Save Changes' : 'Add Instruction'}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* System Prompt Modal */}
      {showSystemPromptModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-3xl w-full max-h-[90vh] overflow-y-auto">
            <h3 className="text-lg font-semibold mb-4">
              {editingSystemPrompt ? 'Edit System Prompt' : 'Add New System Prompt'}
            </h3>
            
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Description
                </label>
                <input
                  type="text"
                  value={newSystemPrompt.description || ''}
                  onChange={(e) => setNewSystemPrompt(prev => ({ ...prev, description: e.target.value }))}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="E.g., Main system prompt for Claude"
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Content
                </label>
                <textarea
                  value={newSystemPrompt.content || ''}
                  onChange={(e) => setNewSystemPrompt(prev => ({ ...prev, content: e.target.value }))}
                  rows={12}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 font-mono text-sm"
                />
                <p className="mt-1 text-xs text-gray-500">System prompts for Claude are used to guide the model's response. Use proper formatting and be specific.</p>
              </div>

              <div>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={newSystemPrompt.is_default ?? true}
                    onChange={(e) => setNewSystemPrompt(prev => ({ ...prev, is_default: e.target.checked }))}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="text-sm font-medium text-gray-700">Set as Default System Prompt</span>
                </label>
                <p className="mt-1 text-xs text-gray-500 ml-6">Only one system prompt can be the default at a time. Setting this as default will change all other prompts to non-default.</p>
              </div>
            </div>

            <div className="mt-6 flex justify-end space-x-3">
              <button
                onClick={() => setShowSystemPromptModal(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveSystemPrompt}
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700"
              >
                {editingSystemPrompt ? 'Save Changes' : 'Add System Prompt'}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Rule Modal */}
      {showRuleModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h3 className="text-lg font-semibold mb-4">
              {editingRule ? 'Edit Validation Rule' : 'Add New Validation Rule'}
            </h3>
            
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Rule Name
                </label>
                <input
                  type="text"
                  value={newRule.rule_name || ''}
                  onChange={(e) => setNewRule(prev => ({ ...prev, rule_name: e.target.value }))}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Description
                </label>
                <textarea
                  value={newRule.rule_description || ''}
                  onChange={(e) => setNewRule(prev => ({ ...prev, rule_description: e.target.value }))}
                  rows={3}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div className="flex space-x-4">
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={newRule.active ?? true}
                    onChange={(e) => setNewRule(prev => ({ ...prev, active: e.target.checked }))}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="text-sm font-medium text-gray-700">Active</span>
                </label>

                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={newRule.testing ?? true}
                    onChange={(e) => setNewRule(prev => ({ ...prev, testing: e.target.checked }))}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="text-sm font-medium text-gray-700">Dev/Test Mode</span>
                </label>
              </div>
            </div>

            <div className="mt-6 flex justify-end space-x-3">
              <button
                onClick={() => setShowRuleModal(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveRule}
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700"
              >
                {editingRule ? 'Save Changes' : 'Add Rule'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ValidationDashboard;
