import React, { useState, useEffect } from 'react';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { 
  fetchScenarioMetrics, 
  fetchUserActivity, 
  fetchPerformanceMetrics, 
  fetchErrorMetrics,
  fetchScenariosByDiagnosisAndStatus,
  fetchScenariosWithImages,
  fetchScenariosWithoutImages,
  fetchAuditMetrics
} from '../services/reportingService';
import ScenarioMatrixView from '../components/ScenarioMatrixView';


const DataTable = ({ data, columns }) => (
  <div className="mt-4 overflow-x-auto">
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          {columns.map(col => (
            <th
              key={col.key}
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {col.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {data.map((row, idx) => (
          <tr key={idx} className="hover:bg-gray-50">
            {columns.map(col => (
              <td key={col.key} className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row[col.key]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const ReportingDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [metrics, setMetrics] = useState({
    scenarios: { graphData: [], tableData: [] },
    userActivity: { graphData: [], tableData: [] },
    performance: { api: [], db: [] },
    errors: { graphData: [], tableData: [] },
    auditMetrics: { graphData: [], tableData: [] }
  });

  const [matrixData, setMatrixData] = useState({
    scenarios: {},
    scenariosWithImages: {},
    scenariosWithoutImages: {}
  });

  const [activeTab, setActiveTab] = useState('matrices');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const [scenarios, userActivity, performance, errors, auditMetrics] = await Promise.all([
          fetchScenarioMetrics(),
          fetchUserActivity(),
          fetchPerformanceMetrics(),
          fetchErrorMetrics(),
          fetchAuditMetrics()
        ]);

        setMetrics({
          scenarios: scenarios || { graphData: [], tableData: [] },
          userActivity: userActivity || { graphData: [], tableData: [] },
          performance: {
            api: performance?.apiMetrics || [],
            db: performance?.dbMetrics || []
          },
          errors: errors || { graphData: [], tableData: [] },
          auditMetrics: auditMetrics || { graphData: [], tableData: [] }
        });
      } catch (error) {
        console.error('Error fetching metrics:', error);
        setError('Failed to load metrics data');
      }
    };

    const fetchMatrixData = async () => {
      try {
        const [scenariosMatrix, imagesMatrix, noImagesMatrix] = await Promise.all([
          fetchScenariosByDiagnosisAndStatus(),
          fetchScenariosWithImages(),
          fetchScenariosWithoutImages()
        ]);
        setMatrixData({
          scenarios: scenariosMatrix || {},
          scenariosWithImages: imagesMatrix || {},
          scenariosWithoutImages: noImagesMatrix || {}
        });
      } catch (error) {
        console.error('Error fetching matrix data:', error);
        setError('Failed to load matrix data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    fetchMatrixData();
    const interval = setInterval(() => {
      fetchData();
      fetchMatrixData();
    }, 300000); 
    
    return () => clearInterval(interval);
  }, []);

  const renderScenariosTab = () => (
    <div className="bg-white rounded-lg shadow-lg p-4">
      <h2 className="text-xl font-semibold mb-4">Scenario Creation Trends</h2>
      {metrics.scenarios.graphData.length > 0 ? (
        <>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={metrics.scenarios.graphData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="completed" stackId="a" fill="#82ca9d" name="Completed" />
                <Bar dataKey="pending" stackId="a" fill="#8884d8" name="Pending" />
                <Bar dataKey="failed" stackId="a" fill="#ff8042" name="Failed" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <DataTable 
            data={metrics.scenarios.tableData}
            columns={[
              { key: 'date', label: 'Date' },
              { key: 'title', label: 'Title' },
              { key: 'category', label: 'Category' },
              { key: 'status', label: 'Status' }
            ]}
          />
        </>
      ) : (
        <div className="text-center py-8 text-gray-600">No scenario data available</div>
      )}
    </div>
  );

  const renderUsersTab = () => (
    <div className="bg-white rounded-lg shadow-lg p-4">
      <h2 className="text-xl font-semibold mb-4">User Activity</h2>
      {metrics.userActivity.graphData.length > 0 ? (
        <>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={metrics.userActivity.graphData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="created_at" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="count" stroke="#82ca9d" name="Activity Count" />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <DataTable 
            data={metrics.userActivity.tableData}
            columns={[
              { key: 'date', label: 'Date' },
              { key: 'type', label: 'Activity Type' },
              { key: 'user', label: 'User' }
            ]}
          />
        </>
      ) : (
        <div className="text-center py-8 text-gray-600">No user activity data available</div>
      )}
    </div>
  );

  const renderErrorsTab = () => (
    <div className="bg-white rounded-lg shadow-lg p-4">
      <h2 className="text-xl font-semibold mb-4">Error Trends</h2>
      {metrics.errors.graphData.length > 0 ? (
        <>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={metrics.errors.graphData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="count" stroke="#ff8042" name="Error Count" />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <DataTable 
            data={metrics.errors.tableData}
            columns={[
              { key: 'date', label: 'Date' },
              { key: 'title', label: 'Scenario' },
              { key: 'category', label: 'Category' },
              { key: 'error', label: 'Error' }
            ]}
          />
        </>
      ) : (
        <div className="text-center py-8 text-gray-600">No error data available</div>
      )}
    </div>
  );
  const renderAuditTab = () => (
    <div className="bg-white rounded-lg shadow-lg p-4">
      <h2 className="text-xl font-semibold mb-4">Audit Activity</h2>
      {metrics.auditMetrics.graphData.length > 0 ? (
        <>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={metrics.auditMetrics.graphData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="count" stroke="#8884d8" name="Activity Count" />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <DataTable 
            data={metrics.auditMetrics.tableData}
            columns={[
              { key: 'date', label: 'Date' },
              { key: 'type', label: 'Action Type' },
              { key: 'user', label: 'User' },
              { key: 'details', label: 'Details' }
            ]}
          />
        </>
      ) : (
        <div className="text-center py-8 text-gray-600">No audit data available</div>
      )}
    </div>
  );

  const renderPerformanceTab = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div className="bg-white rounded-lg shadow-lg p-4">
        <h2 className="text-xl font-semibold mb-4">API Response Times</h2>
        {metrics.performance.api.length > 0 ? (
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={metrics.performance.api}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="timestamp"
                  tickFormatter={(timestamp) => new Date(timestamp).toLocaleTimeString()}
                />
                <YAxis />
                <Tooltip 
                  labelFormatter={(label) => new Date(label).toLocaleString()}
                  formatter={(value) => `${value.toFixed(2)}ms`}
                />
                <Legend />
                <Line 
                  type="monotone" 
                  dataKey="response_time" 
                  stroke="#8884d8" 
                  name="Response Time (ms)"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <div className="text-center py-8 text-gray-600">No API metrics available</div>
        )}
      </div>

      <div className="bg-white rounded-lg shadow-lg p-4">
        <h2 className="text-xl font-semibold mb-4">Database Query Times</h2>
        {metrics.performance.db.length > 0 ? (
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={metrics.performance.db}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="timestamp"
                  tickFormatter={(timestamp) => new Date(timestamp).toLocaleTimeString()}
                />
                <YAxis />
                <Tooltip 
                  labelFormatter={(label) => new Date(label).toLocaleString()}
                  formatter={(value) => `${value.toFixed(2)}ms`}
                />
                <Legend />
                <Line 
                  type="monotone" 
                  dataKey="query_time" 
                  stroke="#82ca9d" 
                  name="Query Time (ms)"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <div className="text-center py-8 text-gray-600">No database metrics available</div>
        )}
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg text-gray-600">Loading dashboard data...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg text-red-600">{error}</div>
      </div>
    );
  }

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-6">System Metrics Dashboard</h1>
      
      <div className="flex mb-4 space-x-2 border-b">
        {[
          { id: 'matrices', label: 'Scenario Creation' },
          { id: 'scenarios', label: 'Scenarios' },
          { id: 'users', label: 'Users' },
          { id: 'performance', label: 'Performance' },
          { id: 'errors', label: 'Errors' },
          { id: 'audit', label: 'Audit' }
        ].map((tab) => (
          <button
            key={tab.id}
            className={`px-4 py-2 rounded-t-lg ${
              activeTab === tab.id 
                ? 'bg-blue-500 text-white' 
                : 'hover:bg-blue-100'
            }`}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>

      <div className="space-y-4">
        {activeTab === 'matrices' && (
          <div className="space-y-6">
            <ScenarioMatrixView 
              data={matrixData.scenarios} 
              title="Scenarios by Diagnosis Category and Status" 
            />
            <ScenarioMatrixView 
              data={matrixData.scenariosWithImages} 
              title="Scenarios by Diagnosis Category and Status with Images" 
            />
            <ScenarioMatrixView 
              data={matrixData.scenariosWithoutImages} 
              title="Scenarios by Diagnosis Category and Status without Images" 
            />
          </div>
        )}
        {activeTab === 'scenarios' && renderScenariosTab()}
        {activeTab === 'users' && renderUsersTab()}
        {activeTab === 'performance' && renderPerformanceTab()}
        {activeTab === 'errors' && renderErrorsTab()}
        {activeTab === 'audit' && renderAuditTab()}
      </div>
    </div>
  );
};

export default ReportingDashboard;