import React, { useEffect } from 'react';
import { TrainingPlanList } from '../components/admin/TrainingPlanList';

function TrainingPlanManagement() {
  useEffect(() => {
    console.log('TrainingPlanManagement component mounted');
  }, []);

  console.log('TrainingPlanManagement component rendering');
  
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="bg-white rounded-lg shadow p-6">
        <h1 className="text-2xl font-semibold text-gray-900 mb-6">Training Plan Management</h1>
        <TrainingPlanList />
      </div>
    </div>
  );
}

export default TrainingPlanManagement;