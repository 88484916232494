import React from "react";
import GenerateMedTeamScenario from "../components/GenerateMedTeamScenario";

const GenerateScenarioPage = () => {
  return (
    <div>
      <h1>Generate MedTeam Scenario</h1>
      <GenerateMedTeamScenario />
    </div>
  );
};

export default GenerateScenarioPage;
