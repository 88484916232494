import React from "react";
import { Routes, Route } from "react-router-dom";
import GenerateScenarioPage from "../features/medteam/pages/GenerateScenarioPage";
import ValidationDashboard from "../features/medteam/pages/ValidationDashboard";

const MedTeamRoutes = () => {
  return (
    <Routes>
      <Route path="generate-scenario" element={<GenerateScenarioPage />} />
      <Route path="validations" element={<ValidationDashboard />} />
    </Routes>
  );
};

export default MedTeamRoutes;
