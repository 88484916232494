import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Loader2 } from "lucide-react";
import { supabase } from "../../../lib/supabaseClient";
import "../styles/HashtagsPage.css";
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react";

const ITEMS_PER_PAGE = 10;

const HashtagsPage = () => {
  const [hashtags, setHashtags] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedHashtag, setSelectedHashtag] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalHashtags, setTotalHashtags] = useState(0);
  const [filters, setFilters] = useState({
    search: "",
    status: "",
  });

  const [types, setTypes] = useState([]);

  const displayedTypes = useMemo(() => {
    return types.filter((tag) => tag.type.toLowerCase().includes(selectedHashtag?.type?.toLowerCase() || ""));
  }, [types, selectedHashtag?.type]);

  useEffect(() => {
    supabase
      .from("hashtags_types")
      .select("type")
      .then(({ data, error }) => {
        if (error) {
          console.error("Error fetching tags:", error.message);
          return;
        }
        setTypes(data);
      });
  }, []);

  const fetchHashtags = useCallback(async () => {
    setIsLoading(true);
    try {
      const { count } = await supabase
        .from("hashtags")
        .select("*", { count: "exact", head: true });

      if (count === 0) {
        setHashtags([]);
        setTotalHashtags(0);
        return;
      }

      let query = supabase.from("hashtags").select("hashtag, type, status, comments");

      if (filters.search) {
        query = query.ilike("hashtag", `%${filters.search.toLowerCase()}%`);
      }

      if (filters.status) {
        query = query.eq("status", filters.status);
      }

      const from = (currentPage - 1) * ITEMS_PER_PAGE;
      const to = from + ITEMS_PER_PAGE - 1;

      const { data, error: fetchError } = await query
        .order("hashtag")
        .range(from, to);

      if (fetchError) throw fetchError;

      setHashtags(data || []);
      setTotalHashtags(count);
    } catch (err) {
      console.error("Error fetching hashtags:", err);
      setError("Failed to load hashtags");
      setHashtags([]);
      setTotalHashtags(0);
    } finally {
      setIsLoading(false);
    }
  }, [filters, currentPage]);

  useEffect(() => {
    fetchHashtags();
  }, [fetchHashtags]);

  const handleAddHashtag = async (newHashtag) => {
    try {
      setIsLoading(true);
      const formattedHashtag = newHashtag.hashtag.startsWith("#")
        ? newHashtag.hashtag.toLowerCase()
        : `#${newHashtag.hashtag.toLowerCase()}`;

      const { data: existing } = await supabase
        .from("hashtags")
        .select("hashtag")
        .eq("hashtag", formattedHashtag)
        .single();

      if (existing) {
        setError("This hashtag already exists");
        return;
      }

      const { error } = await supabase.from("hashtags").insert([
        {
          hashtag: formattedHashtag,
          status: "proposed",
          comments: newHashtag.comments || "",
          type: newHashtag.type,
        },
      ]);

      if (error) throw error;

      await fetchHashtags();
      setSelectedHashtag(null);
      setError(null);
    } catch (err) {
      console.error("Error adding hashtag:", err);
      setError("Failed to add hashtag");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateHashtag = async (updatedHashtag) => {
    try {
      setIsLoading(true);
      const formattedHashtag = updatedHashtag.hashtag.startsWith("#")
        ? updatedHashtag.hashtag.toLowerCase()
        : `#${updatedHashtag.hashtag.toLowerCase()}`;

      const { error } = await supabase
        .from("hashtags")
        .update({
          hashtag: formattedHashtag,
          status: updatedHashtag.status,
          comments: updatedHashtag.comments || "",
          type: updatedHashtag.type,
        })
        .eq("hashtag", updatedHashtag.hashtag);

      if (error) throw error;

      await fetchHashtags();
      setSelectedHashtag(null);
      setError(null);
    } catch (err) {
      console.error("Error updating hashtag:", err);
      setError("Failed to update hashtag");
    } finally {
      setIsLoading(false);
    }
  };

  const handleArchiveHashtag = async (hashtag) => {
    if (window.confirm("Are you sure you want to archive this hashtag?")) {
      try {
        setIsLoading(true);
        const { error } = await supabase
          .from("hashtags")
          .update({ status: "archived" })
          .eq("hashtag", hashtag.hashtag);

        if (error) throw error;
        await fetchHashtags();
        setError(null);
      } catch (err) {
        console.error("Error archiving hashtag:", err);
        setError("Failed to archive hashtag");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
    setCurrentPage(1);
  };

  const clearFilters = () => {
    setFilters({
      search: "",
      status: "",
    });
    setCurrentPage(1);
  };

  return (
    <div className="hashtags-page">
      <div className="header">
        <h1>Hashtags Management</h1>
        <button
          onClick={() => setSelectedHashtag({ isNew: true })}
          className="btn btn-primary"
        >
          Add New Hashtag
        </button>
      </div>

      {error && <div className="error-message">{error}</div>}

      <div className="filter-section">
        <div className="filter-grid">
          <div className="filter-group">
            <label>Search</label>
            <input
              type="text"
              name="search"
              value={filters.search}
              onChange={handleFilterChange}
              placeholder="Search hashtags..."
              className="form-control"
            />
          </div>

          <div className="filter-group">
            <label>Status</label>
            <select
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
              className="form-control"
            >
              <option value="">All</option>
              <option value="active">Active</option>
              <option value="proposed">Proposed</option>
              <option value="archived">Archived</option>
            </select>
          </div>
        </div>

        <button onClick={clearFilters} className="btn btn-secondary">
          Clear Filters
        </button>
      </div>

      <div className="table-container">
        {isLoading ? (
          <div className="loading-indicator">
            <Loader2 className="spinner" />
            <span>Loading...</span>
          </div>
        ) : hashtags.length === 0 ? (
          <div className="empty-state">
            <p>
              No hashtags found.{" "}
              {filters.search || filters.status
                ? "Try adjusting your filters."
                : "Add your first hashtag!"}
            </p>
          </div>
        ) : (
          <table className="hashtags-table">
            <thead>
              <tr>
                <th>Actions</th>
                <th>Hashtag</th>
                <th>Status</th>
                <th>Comments</th>
              </tr>
            </thead>
            <tbody>
              {hashtags.map((hashtag) => (
                <tr key={hashtag.hashtag}>
                  <td>
                    <div className="action-buttons">
                      <button
                        onClick={() => setSelectedHashtag(hashtag)}
                        className="btn btn-primary btn-sm"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleArchiveHashtag(hashtag)}
                        className="btn btn-danger btn-sm"
                      >
                        Archive
                      </button>
                    </div>
                  </td>
                  <td>{hashtag.hashtag}</td>
                  <td>{hashtag.status}</td>
                  <td>{hashtag.comments || "-"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      <div className="pagination">
        <div className="pagination-info">
          Showing {hashtags.length} of {totalHashtags} hashtags
        </div>
        <div className="pagination-buttons">
          <button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
            className="btn btn-secondary"
          >
            Previous
          </button>
          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === Math.ceil(totalHashtags / ITEMS_PER_PAGE)}
            className="btn btn-secondary"
          >
            Next
          </button>
        </div>
      </div>

      {selectedHashtag && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h2>
                {selectedHashtag.isNew ? "Add New Hashtag" : "Edit Hashtag"}
              </h2>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (selectedHashtag.isNew) {
                  handleAddHashtag(selectedHashtag);
                } else {
                  handleUpdateHashtag(selectedHashtag);
                }
              }}
              className="modal-form"
            >
              <div className="form-group">
                <label>Hashtag</label>
                <input
                  type="text"
                  value={selectedHashtag.hashtag || ""}
                  onChange={(e) =>
                    setSelectedHashtag({
                      ...selectedHashtag,
                      hashtag: e.target.value.toLowerCase(),
                    })
                  }
                  className="form-control"
                  placeholder="Enter hashtag (e.g., #example)"
                />
                <small className="form-helper-text">
                  Single hashtag only, will be converted to lowercase
                </small>
              </div>

              <div className="form-group">
                <label>Type</label>
                <Combobox value={displayedTypes || []} onChange={value => setSelectedHashtag({
                      ...selectedHashtag,
                      type: value,
                    })}>
                  <div className="relative w-full">
                    <ComboboxInput
                      className="w-full py-2 rounded-md border border-gray-300 px-2 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500"
                      value={selectedHashtag.type || ""}
                      onChange={(event) => setSelectedHashtag({
                        ...selectedHashtag,
                        type: event.target.value,
                      })}
                      placeholder="Add tags"
                    />

                    <ComboboxOptions className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {displayedTypes.length === 0 && selectedHashtag.type !== "" ? (
                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                          New type ({ selectedHashtag.type }) will be created when you save
                        </div>
                      ) : (
                        displayedTypes.map((tag) => (
                          <ComboboxOption
                            key={tag}
                            className={({ focus }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                focus
                                  ? "bg-blue-600 text-white"
                                  : "text-gray-900"
                              }`
                            }
                            value={tag.type}
                          >
                            {({ selected, focus }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {tag.type}
                                </span>
                                {selected ? (
                                  <span
                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                      focus ? "text-white" : "text-blue-600"
                                    }`}
                                  >
                                    ✓
                                  </span>
                                ) : null}
                              </>
                            )}
                          </ComboboxOption>
                        ))
                      )}
                    </ComboboxOptions>
                  </div>
                </Combobox>
              </div>

              {!selectedHashtag.isNew && (
                <div className="form-group">
                  <label>Status</label>
                  <select
                    value={selectedHashtag.status || "proposed"}
                    onChange={(e) =>
                      setSelectedHashtag({
                        ...selectedHashtag,
                        status: e.target.value,
                      })
                    }
                    className="form-control"
                  >
                    <option value="active">Active</option>
                    <option value="proposed">Proposed</option>
                    <option value="archived">Archived</option>
                  </select>
                </div>
              )}

              <div className="form-group">
                <label>Comments</label>
                <textarea
                  value={selectedHashtag.comments || ""}
                  onChange={(e) =>
                    setSelectedHashtag({
                      ...selectedHashtag,
                      comments: e.target.value,
                    })
                  }
                  className="form-textarea"
                  rows={3}
                  placeholder="Enter any comments"
                />
              </div>

              <div className="modal-actions">
                <button
                  type="button"
                  onClick={() => setSelectedHashtag(null)}
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!selectedHashtag.hashtag?.trim()}
                >
                  {selectedHashtag.isNew ? "Add Hashtag" : "Save Changes"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default HashtagsPage;
