
import React, { useState, useEffect, useCallback } from 'react';
import { supabase } from '@/lib/supabaseClient';
import ScenarioReviewForm from '../components/ScenarioReviewForm';
import { useDebounce } from '@/hooks/useDebounce'; 
import { 
  createAuditLog, 
  logStatusChange,
  AuditComponents, 
  AuditActionTypes 
} from '@/lib/auditLogger';
import { useAuth } from '@/features/auth/context/AuthContext';




export default function ScenarioReview() {
  const { session } = useAuth();  
  const [currentScenario, setCurrentScenario] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    status: 'All Statuses',
    category: 'All Categories',
    diagnosisCategory: 'All Diagnosis Categories',
    difficulty: 'All',
    complexity: 'All',
  });
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 500);

  const fetchCurrentScenario = useCallback(async (mounted = true) => {
    try {
      if (!mounted) return;
      setLoading(true);
      
      console.log('Fetching scenario with filters:', filters);
  
      let query = supabase
      .from('scenarios')
      .select('*')
      .order('scenarioid', { ascending: true })
      .order('sequence_order', { ascending: true }); 
  
      
      if (filters.status !== 'All Statuses') {
        query = query.eq('status', filters.status);
      }
      if (filters.category !== 'All Categories') {
        query = query.eq('category', filters.category);
      }
      if (filters.diagnosisCategory !== 'All Diagnosis Categories') {
        query = query.eq('diagnosiscategory', filters.diagnosisCategory);
      }
      if (filters.difficulty !== 'All') {
        query = query.eq('difficulty', filters.difficulty);
      }
      if (filters.complexity !== 'All') {
        query = query.eq('complexity', filters.complexity);
      }
  
      
      if (debouncedSearchText && debouncedSearchText.length >= 2) {
        const searchTerms = debouncedSearchText.split(' ').filter(Boolean);
        
        if (searchTerms.length > 0) {
          const searchQueries = searchTerms.map(term => {
            
            const escapedTerm = term.replace(/[%_]/g, '\\$&');
            return `or(
              title.ilike.%${escapedTerm}%,
              patientreport.ilike.%${escapedTerm}%,
              question.ilike.%${escapedTerm}%,
              category.ilike.%${escapedTerm}%,
              diagnosiscategory.ilike.%${escapedTerm}%
            )`;
          });
          
          console.log('Applying search terms:', searchTerms);
          query = query.or(searchQueries.join(','));
        }
      }
  
      const { data, error: fetchError } = await query.limit(1);
      
      if (!mounted) return;
  
      if (fetchError) {
        console.error('Error fetching scenario:', fetchError);
        throw fetchError;
      }
  
      if (!data || data.length === 0) {
        console.log('No scenarios found matching criteria');
        setCurrentScenario(null);
      } else {
        console.log('Found scenario:', {
          scenarioid: data[0].scenarioid,
          order: data[0].order
        });
        setCurrentScenario(data[0]);
      }
      
      setError(null);
    } catch (error) {
      if (!mounted) return;
      console.error('Error in fetchCurrentScenario:', error);
      setError(error.message);
    } finally {
      if (mounted) {
        setLoading(false);
      }
    }
  }, [filters, debouncedSearchText]);

  useEffect(() => {
    let mounted = true;
    fetchCurrentScenario(mounted);
    
    return () => {
      mounted = false;
    };
  }, [fetchCurrentScenario]);

  const handleFilterChange = useCallback((newFilters) => {
    console.log('Filters updated:', newFilters);
    setFilters(newFilters);
  }, []);

  const handleSearchChange = useCallback((text) => {
    const sanitizedText = text.replace(/[^\w\s-]/g, '').trim();
    setSearchText(sanitizedText);
  }, []);

  const handleScenarioUpdate = useCallback(async (updates) => {
    try {
      setLoading(true);
      
      
      console.log('Attempting to update scenario:', {
        scenarioid: currentScenario?.scenarioid,
        sequence_order: currentScenario?.sequence_order
      });
  
      const cleanUpdates = {
        ...updates,
        image: updates.image || null
      };
  
      const relevantFields = [
        'title', 'category', 'diagnosiscategory', 'userrole', 'status',
        'patientreport', 'question', 'option1', 'option2', 'option3', 'option4',
        'explanation1', 'explanation2', 'explanation3', 'explanation4',
        'answer', 'meds',
        'exam', 'physicalexam', 'comments',
        'questiondifficulty', 'complexity', 'image', 'tags'
      ];
  
      const oldValues = {};
      const newValues = {};
      
      relevantFields.forEach(field => {
        if (currentScenario?.[field] !== undefined) {
          oldValues[field] = currentScenario[field];
        }
        if (cleanUpdates[field] !== undefined) {
          newValues[field] = cleanUpdates[field];
        }
      });
  
      
      if (!currentScenario?.scenarioid || currentScenario?.sequence_order === undefined) {
        throw new Error('Missing required scenario identifiers');
      }
  
      
      await createAuditLog({
        component: AuditComponents.SCENARIO_REVIEW,
        action: AuditActionTypes.UPDATE,
        entityType: 'scenario',
        entityId: currentScenario.scenarioid.toString(),
        oldValues,
        newValues,
        userId: session?.user?.email || 'unknown',
        details: `Updated scenario ${currentScenario.scenarioid} from scenario review`,
        changes: JSON.stringify(
          Object.keys(newValues).reduce((acc, key) => {
            if (oldValues[key] !== newValues[key]) {
              acc[key] = {
                from: oldValues[key],
                to: newValues[key]
              };
            }
            return acc;
          }, {})
        )
      });
  
      
      const { data, error: updateError } = await supabase
      .from('scenarios')
      .update(cleanUpdates)
      .eq('scenarioid', currentScenario.scenarioid)
      .eq('sequence_order', currentScenario.sequence_order) 
      .select();
  
      if (updateError) {
        console.error('Update error:', updateError);
        throw updateError;
      }
  
      if (!data || data.length === 0) {
        throw new Error('No scenario was updated - record may not exist');
      }
  
      
      if (cleanUpdates.status !== currentScenario.status) {
        await logStatusChange({
          component: AuditComponents.SCENARIO_REVIEW,
          entityType: 'scenario',
          entityId: currentScenario.scenarioid.toString(),
          oldStatus: currentScenario.status,
          newStatus: cleanUpdates.status,
          userId: session?.user?.email || 'unknown',
          details: `Status changed from ${currentScenario.status || 'none'} to ${cleanUpdates.status}`
        });
      }
  
      console.log('Successfully updated scenario:', {
        scenarioid: currentScenario.scenarioid,
        order: currentScenario.sequence_order
      });
  
      await fetchCurrentScenario();
    } catch (error) {
      console.error('Error updating scenario:', error);
      setError(error.message);
      throw error; 
    } finally {
      setLoading(false);
    }
  }, [currentScenario, fetchCurrentScenario, session?.user?.email]);


  const handleNavigate = useCallback(async (direction) => {
    try {
      setLoading(true);
      let query = supabase.from('scenarios').select('*');
  
      
      if (filters.status !== 'All Statuses') {
        query = query.eq('status', filters.status);
      }
      if (filters.category !== 'All Categories') {
        query = query.eq('category', filters.category);
      }
      if (filters.diagnosisCategory !== 'All Diagnosis Categories') {
        query = query.eq('diagnosiscategory', filters.diagnosisCategory);
      }
      if (filters.difficulty !== 'All') {
        query = query.eq('difficulty', filters.difficulty);
      }
      if (filters.complexity !== 'All') {
        query = query.eq('complexity', filters.complexity);
      }
  
      
      if (searchText) {
        query = query.or(
          `title.ilike.%${searchText}%,` +
          `patientreport.ilike.%${searchText}%,` +
          `question.ilike.%${searchText}%,` +
          `category.ilike.%${searchText}%,` +
          `diagnosiscategory.ilike.%${searchText}%`
        );
      }
  
      
      if (currentScenario) {
        if (direction === 'next') {
          query = query.or(
            `scenarioid.gt.${currentScenario.scenarioid},` +
            `and(scenarioid.eq.${currentScenario.scenarioid},sequence_order.gt.${currentScenario.sequence_order})`  
          )
          .order('scenarioid', { ascending: true })
          .order('sequence_order', { ascending: true });  
        } else {
          query = query.or(
            `scenarioid.lt.${currentScenario.scenarioid},` +
            `and(scenarioid.eq.${currentScenario.scenarioid},sequence_order.lt.${currentScenario.sequence_order})`  
          )
          .order('scenarioid', { ascending: false })
          .order('sequence_order', { ascending: false });  
        }
      }
  
      const { data, error: navError } = await query.limit(1);
  
      if (navError) {
        console.error('Navigation error:', navError);
        throw navError;
      }
      
      if (data?.[0]) {
        setCurrentScenario(data[0]);
      } else {
        console.log('No more scenarios found in this direction');
      }
    } catch (error) {
      console.error('Error:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [currentScenario, filters, searchText]);

  return (
    <div className="min-h-screen bg-gray-50">
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
      ) : error ? (
        <div className="p-4 text-red-600">{error}</div>
      ) : (
        <ScenarioReviewForm 
          scenario={currentScenario}
          onUpdate={handleScenarioUpdate}
          onNext={() => handleNavigate('next')}
          onPrevious={() => handleNavigate('previous')}
          filters={filters}
          onFilterChange={handleFilterChange}
          searchText={searchText}
          onSearchChange={handleSearchChange}
        />
      )}
    </div>
  );
}