
import React, { useMemo } from 'react';
import { Edit2, Trash2, Eye, ChevronDown } from 'lucide-react';

const StandardizedResultsTable = ({ results, onEdit, onDelete, onViewDetails }) => {
  
  const groupedResults = useMemo(() => {
    const groups = {};
    results.forEach(row => {
      if (!groups[row.scenarioid]) {
        groups[row.scenarioid] = [];
      }
      groups[row.scenarioid].push(row);
    });
    return groups;
  }, [results]);

  const columns = [
    {
      key: 'image',
      label: 'Image',
      render: (value) => value ? (
        <img 
          src={value} 
          alt="Scenario" 
          className="w-16 h-16 object-cover rounded"
        />
      ) : null
    },
    {
      key: 'action_items',
      label: 'Actions',
      render: (_, row) => (
        <div className="flex gap-2 justify-end">
          <button
            onClick={() => onViewDetails(row)}
            className="p-1 text-blue-600 hover:text-blue-900"
            title="View Details"
          >
            <Eye className="h-5 w-5" />
          </button>
          <button
            onClick={() => onEdit(row)}
            className="p-1 text-blue-600 hover:text-blue-900"
            title="Edit"
          >
            <Edit2 className="h-5 w-5" />
          </button>
          <button
            onClick={() => onDelete(row)}
            className="p-1 text-red-600 hover:text-red-900"
            title="Delete"
          >
            <Trash2 className="h-5 w-5" />
          </button>
        </div>
      )
    },
    {
      key: 'validation_issue',
      label: 'Validation Issues',
      render: (value) => (
        <div className="max-w-xs whitespace-normal">
          <span className="text-sm text-red-600">
            {value || '-'}
          </span>
        </div>
      )
    },
    { key: 'scenarioid', label: 'Scenario ID' },
    { key: 'order', label: 'Order' },
    { key: 'title', label: 'Title' },
    { key: 'category', label: 'Category' },
    { key: 'diagnosiscategory', label: 'Diagnosis Category' },
    { key: 'userrole', label: 'User Role' },
    { key: 'complexity', label: 'Complexity' },
    { key: 'questiondifficulty', label: 'Question Difficulty' },
    { key: 'patient record', label: 'Patient Record' },
    { key: 'question', label: 'Question' },
    { key: 'option1', label: 'Option 1' },
    { key: 'option2', label: 'Option 2' },
    { key: 'option3', label: 'Option 3' },
    { key: 'option4', label: 'Option 4' },
    { key: 'answer', label: 'Answer' },
    { key: 'explanation1', label: 'Explanation 1' },
    { key: 'explanation2', label: 'Explanation 2' },
    { key: 'explanation3', label: 'Explanation 3' },
    { key: 'explanation4', label: 'Explanation 4' },
    { key: 'tags', label: 'Tags' },
    { key: 'comments', label: 'Comments' }
  ];

  const formatCellContent = (value) => {
    if (value === null || value === undefined) return '-';
    if (Array.isArray(value)) return value.join(', ');
    if (typeof value === 'boolean') return value ? 'Yes' : 'No';
    if (typeof value === 'object') return JSON.stringify(value);
    return value.toString();
  };

  return (
    <div className="overflow-x-auto">
      <table className="w-full border-collapse">
        <thead className="bg-gray-50">
          <tr>
            {columns.map(({ key, label }) => (
              <th 
                key={key}
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b"
              >
                {label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {Object.entries(groupedResults).map(([scenarioId, scenarioRows], groupIndex) => (
            <React.Fragment key={scenarioId}>
              {/* Scenario Header */}
              <tr className="bg-blue-50">
                <td 
                  colSpan={columns.length}
                  className="px-6 py-3 text-sm font-semibold text-blue-900 border-t border-b border-blue-200"
                >
                  <div className="flex items-center gap-2">
                    <ChevronDown className="h-4 w-4" />
                    <span>Scenario {scenarioId}</span>
                    <span className="text-blue-600">
                      ({scenarioRows.length} {scenarioRows.length === 1 ? 'Question' : 'Questions'})
                    </span>
                  </div>
                </td>
              </tr>
              {/* Scenario Rows */}
              {scenarioRows
                .sort((a, b) => a.order - b.order)
                .map((row, rowIndex) => (
                <tr 
                  key={`${row.scenarioid}-${rowIndex}`} 
                  className={`
                    hover:bg-gray-50
                    ${rowIndex === scenarioRows.length - 1 ? 'border-b-4 border-gray-200' : ''}
                  `}
                >
                  {columns.map(({ key, render }) => (
                    <td 
                      key={key} 
                      className={`px-6 py-4 text-sm text-gray-500 ${
                        key === 'validation_issue' ? 'whitespace-normal' : 'whitespace-nowrap'
                      }`}
                    >
                      {render ? 
                        render(row[key], row) : 
                        formatCellContent(row[key])
                      }
                    </td>
                  ))}
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StandardizedResultsTable;