import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader2, AlertCircle, CheckCircle } from 'lucide-react';
import './ScenarioGenerator.css';

import { useAuth } from '../../../../features/auth/hooks/useAuth';
import { useScenarioData } from '../../../../features/scenarios/hooks/useScenarioData';
import { useReferenceData } from '../../../../shared/hooks/useReferenceData';
import { supabase } from '../../../../lib/supabaseClient';

// Import the ScenarioGenerationService functions directly
import {
  buildPrompt,
  getSystemPrompt,
  transformResponse,
  validatePatientReport,
  extractBasicContent,
  extractLocationTag,
  updatePatientRecordForQuestion
} from '../../../../services/ScenarioGenerationService';

import BatchValidationResults from './BatchValidationResults';

const DEFAULT_VALUES = {
  scenario_topic: '',
  initial_prompts: '',
  context_details: '',
  category: '',
  diagnosiscategory: '',
  userrole: '',
  complexity: '',
  questiondifficulty: '',
  number_of_questions: 4,
  number_of_scenarios: 1,
  protocol_contents: [],
  training_designation: '',
  ai_model: 'anthropic'
};

const REQUIRED_FIELDS = [
  'initial_prompts', 
  'category', 
  'diagnosiscategory', 
  'userrole', 
  'complexity', 
  'questiondifficulty', 
  'number_of_questions', 
  'number_of_scenarios',
  'protocol_contents'
];

export default function BatchScenarioGenerator() {
  const navigate = useNavigate();
  
  const { session, loading: authLoading } = useAuth();
  
  const [touchedFields, setTouchedFields] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [progress, setProgress] = useState(0);
  const [formData, setFormData] = useState(DEFAULT_VALUES);
  const [generationQueue, setGenerationQueue] = useState([]);
  const [currentGeneration, setCurrentGeneration] = useState(null);
  const [completedScenarios, setCompletedScenarios] = useState([]);
  const [validationResults, setValidationResults] = useState({});
  const [totalScenarios, setTotalScenarios] = useState(0);
  const [displayProgress, setDisplayProgress] = useState(false);
  
  const [generationStatus, setGenerationStatus] = useState({
    current: 0,
    total: 0,
    state: 'idle', 
    scenarioProgress: 0,
    scenarioValidationStatus: 'pending', 
    currentScenarioId: null
  });
  
  const {
    protocols,
    displayedProtocols,
    categories,
    loading: dataLoading,
    error: dataError,
    searchTerm,
    setSearchTerm
  } = useScenarioData();

  const {
    complexity: complexityLevels,
    questiondifficulty: difficulties,
    roles,
    diagnosiscategory: diagnosisCategories,
    loading: referenceDataLoading,
  } = useReferenceData();
  
  useEffect(() => {
    const checkFormValidity = () => {
      const isValid = REQUIRED_FIELDS.every(field => {
        if (field === 'protocol_contents') {
          return formData[field].length > 0;
        }
        return formData[field] !== '';
      });
      setIsFormValid(isValid);
    };
    checkFormValidity();
  }, [formData]);
  
  useEffect(() => {
    const processQueue = async () => {
      if (generationStatus.current >= generationStatus.total) {
        console.log("All scenarios generated, stopping at index:", generationStatus.current);
        setGenerationStatus(prev => ({
          ...prev,
          state: 'completed'
        }));
        setDisplayProgress(false);
        setLoading(false);
        setSuccess(`Successfully generated ${completedScenarios.length} scenarios with ${completedScenarios.reduce((acc, scenario) => acc + scenario.questionCount, 0)} total questions.`);
        return;
      }
      
      if (generationQueue.length > 0 && !currentGeneration) {
        const nextItem = generationQueue[0];
        setCurrentGeneration(nextItem);
        setGenerationQueue(prev => prev.slice(1));
        
        console.log("Processing scenario", nextItem.scenarioIndex, "of", generationStatus.total);
        
        try {
          const processingTimeout = setTimeout(() => {
            console.error(`Processing timeout for scenario ${nextItem.scenarioIndex}`);
            setError(`Processing timeout for scenario ${nextItem.scenarioIndex + 1}. Continuing with next scenario.`);
            setCurrentGeneration(null);
            setGenerationStatus(prev => ({
              ...prev,
              current: prev.current + 1
            }));
          }, 120000); 
          
          await generateSingleScenario(nextItem, nextItem.scenarioIndex);
          
          clearTimeout(processingTimeout);
        } catch (error) {
          console.error("Error generating scenario:", error);
          setError(`Failed to generate scenario ${nextItem.scenarioIndex + 1}: ${error.message}`);
          
          setGenerationStatus(prev => ({
            ...prev,
            state: 'error'
          }));
        } finally {
          setCurrentGeneration(null);
          
          if (generationQueue.length > 0) {
            setGenerationStatus(prev => ({
              ...prev,
              current: prev.current + 1
            }));
          } else if (generationStatus.current < generationStatus.total - 1) {
            setGenerationStatus(prev => ({
              ...prev,
              current: prev.current + 1
            }));
          } else {
            setGenerationStatus(prev => ({
              ...prev,
              state: 'completed'
            }));
            setDisplayProgress(false);
            setLoading(false);
            setSuccess(`Successfully generated ${completedScenarios.length} scenarios with ${completedScenarios.reduce((acc, scenario) => acc + scenario.questionCount, 0)} total questions.`);
          }
        }
      }
    };

    processQueue();
  }, [generationQueue, currentGeneration, generationStatus.current, generationStatus.total, completedScenarios]);
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setTouchedFields(prev => ({ ...prev, [name]: true }));
  };

  const isFieldInvalid = (field) => {
    if (!touchedFields[field]) return false;
    
    if (field === 'protocol_contents') {
      return formData[field].length === 0;
    }
    return !formData[field];
  };

  const getFieldClassName = (field, type = 'input') => {
    const baseClass = `${type === 'select' ? 'form-select' : type === 'textarea' ? 'form-textarea' : 'form-input'}`;
    return `${baseClass} ${isFieldInvalid(field) ? 'form-field-invalid' : ''}`;
  };
  
  const getRequiredLabel = (label, isRequired = true) => (
    <label className={`form-label ${isFieldInvalid(label.toLowerCase()) ? 'form-label-invalid' : ''}`}>
      {label} {isRequired && <span className="required-field-indicator">*</span>}
    </label>
  );

  const handleProtocolToggle = (protocolId) => {
    setFormData(prev => {
      const isSelected = prev.protocol_contents.includes(protocolId);
      if (isSelected) {
        return {
          ...prev,
          protocol_contents: prev.protocol_contents.filter(id => id !== protocolId)
        };
      } else {
        return {
          ...prev,
          protocol_contents: [...prev.protocol_contents, protocolId]
        };
      }
    });
    setTouchedFields(prev => ({ ...prev, protocol_contents: true }));
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleResetForm = () => {
    setFormData(DEFAULT_VALUES);
    setSearchTerm('');
    setError(null);
    setSuccess(null);
    setGenerationQueue([]);
    setCurrentGeneration(null);
    setCompletedScenarios([]);
    setValidationResults({});
    setGenerationStatus({
      current: 0,
      total: 0,
      state: 'idle',
      scenarioProgress: 0,
      scenarioValidationStatus: 'pending',
      currentScenarioId: null
    });
    setTouchedFields({});
    setDisplayProgress(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (loading || !isFormValid) {
      console.log('Form is invalid or processing is in progress');
      return;
    }

    try {
      setLoading(true);
      setError(null);
      setSuccess(null);
      setCompletedScenarios([]);
      setValidationResults({});
      setDisplayProgress(true);
      
      setGenerationStatus({
        current: 0,
        total: parseInt(formData.number_of_scenarios),
        state: 'preparing',
        scenarioProgress: 0,
        scenarioValidationStatus: 'pending',
        currentScenarioId: null
      });
      
      const numScenarios = parseInt(formData.number_of_scenarios);
      const queueItems = [];
      
      console.log("Preparing to generate", numScenarios, "scenarios");
      
      for (let i = 0; i < numScenarios; i++) {
        queueItems.push({
          ...formData,
          scenarioIndex: i
        });
      }
      
      setTotalScenarios(numScenarios);
      setGenerationQueue(queueItems);
      
    } catch (err) {
      console.error('Error preparing generation:', err);
      setError(`Failed to prepare generation: ${err.message}`);
      setLoading(false);
      setDisplayProgress(false);
    }
  };
// Add this to BatchScenarioGenerator.jsx
const generateWithRetry = async (scenarioData, scenarioIndex) => {
  const maxRetries = 3;
  let attempts = 0;
  let lastError = null;
  
  while (attempts < maxRetries) {
    try {
      attempts++;
      
      // Add a delay between attempts
      if (attempts > 1) {
        // Exponential backoff: 2s, 4s, 8s
        const delayMs = 1000 * Math.pow(2, attempts - 1);
        console.log(`Retry attempt ${attempts}, waiting ${delayMs}ms before retrying...`);
        await new Promise(resolve => setTimeout(resolve, delayMs));
      }
      
      console.log(`Generating scenario ${scenarioIndex + 1}, attempt ${attempts}`);
      
      const { data } = await supabase.functions.invoke(
        'generate-scenario-anthropic', // Use Anthropic endpoint which is more stable
        {
          body: { 
            formData: enhancedRequestData,
            prompt,
            systemPrompt
          }
        }
      );
      
      if (!data?.success) {
        throw new Error(data?.details || 'Generation failed');
      }
      
      return data;
    } catch (err) {
      lastError = err;
      console.error(`Attempt ${attempts} failed:`, err);
      
      // If this was the last attempt, throw the error
      if (attempts >= maxRetries) {
        throw new Error(`All ${maxRetries} attempts failed: ${lastError.message}`);
      }
    }
  }
};
  const generateSingleScenario = async (scenarioData, scenarioIndex) => {
    try {
      setGenerationStatus(prev => ({
        ...prev,
        current: scenarioIndex,
        state: 'generating',
        scenarioProgress: 10
      }));
      
      const { data } = await supabase.auth.getSession();
      const userEmail = data?.session?.user?.email || 'system@solvedhealth.ai';
      
      if (!userEmail) {
        throw new Error('User email not found. Please ensure you are logged in.');
      }
      
      let scenarioTopic = scenarioData.scenario_topic?.trim();
      if (!scenarioTopic) {
        scenarioTopic = `Generated Scenario ${scenarioIndex + 1}`;
      }
      
      setGenerationStatus(prev => ({
        ...prev,
        scenarioProgress: 30,
        state: 'generating'
      }));
      
      console.log('Generating scenario', scenarioIndex + 1, 'with topic:', scenarioTopic);
      
      // Use the ScenarioGenerationService to build prompt
      const prompt = buildPrompt({
        ...scenarioData,
        scenario_topic: scenarioTopic
      });
      
      // Get the system prompt from the service
      const systemPrompt = getSystemPrompt();
      
      // Prepare the enhanced request data
      const enhancedRequestData = {
        ...scenarioData,
        scenario_topic: scenarioTopic,
        userEmail: userEmail
      };
      
      const { data: generatedData, error: generationError } = await supabase.functions.invoke(
        'generate-scenario-anthropic',
        {
          body: { 
            formData: enhancedRequestData,
            prompt,
            systemPrompt
          }
        }
      );

      if (generationError) throw generationError;
      if (!generatedData?.success) {
        throw new Error(generatedData?.details || 'Generation failed');
      }

      const scenarioResult = generatedData.data;
      
      // Use the service to validate the patient record
      const recordValidation = validatePatientReport(scenarioResult.patientreport);
      if (!recordValidation) {
        console.warn(`Patient record validation failed for scenario ${scenarioIndex + 1}`);
        
        const validationIssues = ["Patient record does not meet expected format"];
        scenarioResult.patientRecordValidationIssues = validationIssues;
      }
      
      setGenerationStatus(prev => ({
        ...prev,
        scenarioProgress: 50,
        state: 'validating',
        scenarioValidationStatus: 'inProgress',
        currentScenarioId: scenarioResult.scenarioid
      }));
      
      const validationResult = await validateScenario(scenarioResult.scenarioid);
      
      setGenerationStatus(prev => ({
        ...prev,
        scenarioProgress: 75,
        scenarioValidationStatus: validationResult ? 'completed' : 'failed'
      }));
      
      setValidationResults(prev => ({
        ...prev,
        [scenarioResult.scenarioid]: validationResult?.data
      }));
      
      // Extract location tag from protocol
      const extractLocationTagFromProtocol = async (protocol_contents) => {
        if (!protocol_contents || protocol_contents.length === 0) {
          return null;
        }
        
        try {
          const { data, error } = await supabase
            .from('protocols')
            .select('title')
            .eq('id', protocol_contents[0])
            .single();
          
          if (error || !data) {
            console.error('Error fetching protocol:', error);
            return null;
          }
          
          // Use the service to extract location tag
          return extractLocationTag(data);
          
        } catch (error) {
          console.error('Failed to extract location tag:', error);
          return null;
        }
      };

      const locationTag = await extractLocationTagFromProtocol(scenarioData.protocol_contents);
      console.log('Extracted location tag:', locationTag);

      // Transform the scenario result using the service
      const transformedScenario = transformResponse(
        scenarioResult,
        scenarioTopic,
        scenarioData
      );

      // Process the questions with the transformed data
      const processedQuestions = transformedScenario.questions.map((question, index) => {
        // Create tags for question
        const tags = [
          formData.userrole ? `#${formData.userrole}` : '#Paramedic'
        ];
        
        // Add location tag if available
        if (locationTag) {
          tags.push(`#${locationTag}`);
        }
        
        const formattedTags = JSON.stringify(tags);
        
        // For the first question, use the original patient report
        if (index === 0) {
          return {
            ...question,
            patientreport: transformedScenario.patientreport, 
            title: transformedScenario.title,
            status: 'Pending Review',
            tags: formattedTags 
          };
        }
        
        // For subsequent questions, update the patient report based on previous answer
        const prevQuestion = transformedScenario.questions[index - 1];
        const correctAnswerNum = prevQuestion.answer;
        const correctExplanation = prevQuestion[`explanation${correctAnswerNum}`];
        
        // Use the service to update the patient record
        const updatedPatientReport = updatePatientRecordForQuestion(
          transformedScenario.patientreport,
          index,
          correctExplanation
        );
        
        return {
          ...question,
          patientreport: updatedPatientReport,
          title: transformedScenario.title,
          status: 'Pending Review',
          tags: formattedTags 
        };
      });
      
      // Finalize the scenario
      await finalizeScenario(scenarioResult, processedQuestions, userEmail);
      
      // Update completed scenarios
      setCompletedScenarios(prev => [...prev, {
        id: scenarioResult.scenarioid,
        title: transformedScenario.title,
        questionCount: processedQuestions.length,
        validationStatus: validationResult?.data?.isValid ? 'Valid' : 'Has Issues',
        patientRecordValidation: recordValidation ? 'Valid' : 'Has Issues',
        patientRecordIssues: scenarioResult.patientRecordValidationIssues || []
      }]);
      
      setGenerationStatus(prev => ({
        ...prev,
        scenarioProgress: 100,
      }));
      
      return scenarioResult;
      
    } catch (err) {
      console.error('Error in scenario generation:', err);
      throw err;
    }
  };

  const validateScenario = async (scenarioid) => {
    console.log(`Starting validation for scenario ID: ${scenarioid}`);
    
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 15000); 
    
    try {
      const validationPromise = supabase.functions.invoke(
        'validate-scenario',
        {
          body: { scenarioid }
        }
      );
      
      const timeoutPromise = new Promise((_, reject) =>
        setTimeout(() => reject(new Error('Validation timeout after 15 seconds')), 15000)
      );
      
      const { data: validationData, error: validationError } = await Promise.race([
        validationPromise,
        timeoutPromise
      ]);

      clearTimeout(timeoutId); 
      
      if (validationError) {
        console.error(`Validation error for scenario ${scenarioid}:`, validationError);
        throw validationError;
      }
      
      console.log(`Validation completed for scenario ${scenarioid}:`, validationData);
      return validationData;
    } catch (err) {
      clearTimeout(timeoutId); 
      console.error(`Validation error for scenario ${scenarioid}:`, err);
      
      if (err.name === 'AbortError' || (err.message && err.message.includes('timeout'))) {
        console.log(`Skipping validation for scenario ${scenarioid} due to timeout`);
        
        return { 
          success: true, 
          data: { 
            isValid: true, 
            issues: [], 
            metadata: { validationModel: 'skipped', reason: 'timeout' }
          } 
        };
      }
      
      setError(`Validation for scenario ${scenarioid} could not be completed. Continuing with generation.`);
      
      return {
        success: true,
        data: {
          isValid: true,
          issues: [],
          metadata: { validationModel: 'skipped', reason: 'error' }
        }
      };
    }
  };

  const finalizeScenario = async (scenarioData, questions, userEmail) => {
    try {
      console.log('Starting finalizeScenario with data:', { 
        scenarioId: scenarioData.scenarioid, 
        title: scenarioData.title,
        questionCount: questions.length
      });
      
      if (!session || !session.user) {
        console.warn('Session or user is undefined, using provided email');
      }
      
      const emailToUse = session?.user?.email || userEmail || 'system@solvedhealth.ai';
      
      const { data: latestScenario, error: idError } = await supabase
        .from('scenarios')
        .select('scenarioid')
        .order('scenarioid', { ascending: false })
        .limit(1)
        .single();
  
      if (idError) {
        console.error('Error fetching latest scenario ID:', idError);
        throw idError;
      }
      
      const scenarioid = (latestScenario?.scenarioid || 0) + 1;
      console.log('Next scenario ID will be:', scenarioid);
  
      let contextDetails = formData.context_details?.trim() || '';
      if (formData.training_designation) {
        contextDetails = contextDetails ? 
          `Training Designation: ${formData.training_designation}\n\n${contextDetails}` : 
          `Training Designation: ${formData.training_designation}`;
      }
  
      const scriptData = {
        id: crypto.randomUUID(),
        scenarioid,
        scenario_topic: formData.scenario_topic?.trim() || `Generated Scenario ${generationStatus.current + 1}`,
        questiondifficulty: parseInt(formData.questiondifficulty) || 6,
        number_of_questions: parseInt(formData.number_of_questions) || 4,
        actual_questions: questions.length,
        category: formData.category?.trim(),
        diagnosiscategory: formData.diagnosiscategory?.trim(),
        complexity: formData.complexity?.toLowerCase()?.trim() || 'Moderate',
        userrole: formData.userrole?.trim(),
        context_details: contextDetails,
        created_at: new Date().toISOString(),
        created_by: emailToUse,
        is_generated: true,
        generation_status: 'completed',
        error_message: '',
        initial_prompts: formData.initial_prompts?.trim(),
        title: scenarioData.title,
        base_scenario: scenarioData.patientreport,
        protocol_contents: formData.protocol_contents,
        attached_documents: []
      };

      console.log('Saving script data to claudescenarioscript table');
      const { error: scriptError } = await supabase
        .from('claudescenarioscript')
        .insert([scriptData]);
  
      if (scriptError) {
        console.error('Error saving script data:', scriptError);
        throw scriptError;
      } else {
        console.log('Script data saved successfully');
      }
  
      if (validationResults[scenarioData.scenarioid]) {
        console.log('Saving validation results');
        const { error: validationError } = await supabase
          .from('scenario_validations')
          .insert([{
            scenarioid,
            validation_result: validationResults[scenarioData.scenarioid],
            validated_at: new Date().toISOString(),
            validation_model: validationResults[scenarioData.scenarioid]?.metadata?.validationModel || 'unknown'
          }]);
  
        if (validationError) {
          console.error('Failed to save validation results:', validationError);
        } else {
          console.log('Validation results saved successfully');
        }
      }
  
      const questionsToSave = questions.map((question, index) => ({
        sequence_order: index + 1,
        order: index + 1, 
        scenarioid,
        title: question.title,
        category: formData.category,
        diagnosiscategory: formData.diagnosiscategory,
        patientreport: question.patientreport,
        question: question.question,
        option1: question.option1,
        option2: question.option2,
        option3: question.option3,
        option4: question.option4,
        answer: question.answer,
        explanation1: question.explanation1,
        explanation2: question.explanation2,
        explanation3: question.explanation3,
        explanation4: question.explanation4,
        userrole: formData.userrole,
        questiondifficulty: formData.questiondifficulty,
        status: 'Pending Review',
        dateadded: new Date().toISOString().split('T')[0],
        created_by: emailToUse,
        protocol_contents: formData.protocol_contents,
        context_details: contextDetails,
        complexity: formData.complexity,
        tags: question.tags,
        scenario_topic: formData.scenario_topic || `Generated Scenario ${generationStatus.current + 1}`
      }));
  
      console.log(`Saving ${questionsToSave.length} questions to scenarios table`);
      
      try {
        console.log('Questions to be saved to database:', JSON.stringify(questionsToSave[0], null, 2));

        const { data, error: questionsError } = await supabase
          .from('scenarios')
          .insert(questionsToSave);
    
        if (questionsError) {
          console.error('Error saving questions:', questionsError);
          throw questionsError;
        } else {
          console.log('Questions saved successfully to scenarios table');
        }
      } catch (error) {
        console.error('Exception when saving questions:', error);
        throw error;
      }
      
      return true;
    } catch (err) {
      console.error('Error in finalizeScenario:', err);
      throw err;
    }
  };
  
  const calculateTotalProgress = () => {
    if (generationStatus.state === 'idle' || totalScenarios === 0) return 0;
    
    const scenarioWeight = 100 / totalScenarios;
    
    const completedWeight = generationStatus.current * scenarioWeight;
    
    const currentWeight = (generationStatus.scenarioProgress / 100) * scenarioWeight;
    
    return Math.min(Math.round(completedWeight + currentWeight), 100);
  };

  const getProgressMessage = () => {
    if (generationStatus.state === 'idle') return '';
    if (generationStatus.state === 'preparing') return 'Preparing scenarios...';
    
    const scenarioNum = Math.min(generationStatus.current + 1, generationStatus.total);
    const totalNum = generationStatus.total;
    
    if (generationStatus.state === 'generating') {
      return `Generating scenario ${scenarioNum} of ${totalNum}...`;
    }
    
    if (generationStatus.state === 'validating') {
      return `Validating scenario ${scenarioNum} of ${totalNum}...`;
    }
    
    if (generationStatus.state === 'completed') {
      return 'All scenarios generated successfully!';
    }
    
    if (generationStatus.state === 'error') {
      return `Error in scenario ${scenarioNum} - continuing with next scenario...`;
    }
    
    return `Processing scenario ${scenarioNum} of ${totalNum}...`;
  };

  const SkipValidationButton = () => (
    <button
      onClick={() => {
        setGenerationStatus(prev => ({
          ...prev,
          scenarioProgress: 75,
          scenarioValidationStatus: 'completed',
          state: 'generating'
        }));
        
        setValidationResults(prev => ({
          ...prev,
          [generationStatus.currentScenarioId]: {
            isValid: true,
            issues: [],
            metadata: { validationModel: 'skipped' }
          }
        }));
        
        console.log('Validation manually skipped by user');
      }}
      className="px-3 py-2 bg-yellow-600 text-white text-base rounded-lg hover:bg-yellow-700 font-medium"
    >
      Skip Validation (If Hanging)
    </button>
  );
  
  if (authLoading || dataLoading || referenceDataLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loader2 className="animate-spin h-8 w-8 text-blue-600" />
      </div>
    );
  }

  return (
    <div className="scenario-generator">
      {/* Header */}
      <div className="page-header">
        <h1 className="page-title">Batch Generate Scenarios</h1>
      </div>

      {/* Main Content */}
      <div className="main-content">
        {error && (
          <div className="bg-red-50 border border-red-500 text-red-700 p-4 rounded-lg mb-6 flex items-center gap-2">
            <AlertCircle size={18} />
            <span>{error}</span>
          </div>
        )}

        {success && (
          <div className="bg-green-50 border border-green-500 text-green-700 p-4 rounded-lg mb-6 flex items-center gap-2">
            <CheckCircle size={18} />
            <span>{success}</span>
          </div>
        )}

        <div className="form-container">
          <div className="form-grid">
            {/* Protocols Section */}
            <div className="protocols-section">
              <div className="protocols-header">
                {getRequiredLabel('Select Reference Protocols')}
                <p className={`protocols-subtitle ${
                  isFieldInvalid('protocol_contents') ? 'text-red-500' : ''
                }`}>
                  Choose at least one protocol to guide scenario generation
                </p>
              </div>

              <div className="protocols-search">
                <input
                  type="text"
                  placeholder="Search protocols..."
                  className={`form-input ${
                    isFieldInvalid('protocol_contents') ? 'border-red-500' : ''
                  }`}
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>

              <div className={`protocols-list ${
                isFieldInvalid('protocol_contents') ? 'border-red-500' : ''
              }`}>
                {displayedProtocols.map(protocol => (
                  <div key={protocol.id} className="protocol-item">
                    <label className="flex items-start gap-2">
                      <input
                        type="checkbox"
                        checked={formData.protocol_contents.includes(protocol.id)}
                        onChange={() => handleProtocolToggle(protocol.id)}
                        className="mt-1"
                      />
                      <div>
                        <div className="font-medium">{protocol.title}</div>
                        <div className="text-sm text-gray-600">
                          {protocol.content.slice(0, 100)}
                          {protocol.content.length > 100 ? '...' : ''}
                        </div>
                      </div>
                    </label>
                  </div>
                ))}
              </div>
            </div>
            {/* Form Fields */}
            <div className="form-fields">
              {/* Batch Generation Fields */}
              <div className="form-group">
                {getRequiredLabel('Input Prompts')}
                <textarea
                  id="initial_prompts"
                  name="initial_prompts"
                  value={formData.initial_prompts}
                  onChange={handleInputChange}
                  className={getFieldClassName('initial_prompts', 'textarea')}
                  rows="4"
                  placeholder="Enter initial prompts for all scenarios..."
                  required
                />
              </div>

              {/* Category */}
              <div className="form-group">
                {getRequiredLabel('Category')}
                <select
                  id="category"
                  name="category"
                  value={formData.category}
                  onChange={handleInputChange}
                  className={getFieldClassName('category', 'select')}
                  required
                >
                  <option value="">Select Category</option>
                  {categories.map(category => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>

              {/* Diagnosis Category */}
              <div className="form-group">
                {getRequiredLabel('Diagnosis Category')}
                <select
                  id="diagnosiscategory"
                  name="diagnosiscategory"
                  value={formData.diagnosiscategory}
                  onChange={handleInputChange}
                  className={getFieldClassName('diagnosiscategory', 'select')}
                  required
                >
                  <option value="">Select Diagnosis Category</option>
                  {diagnosisCategories.map(category => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>

              {/* User Role */}
              <div className="form-group">
                {getRequiredLabel('User Role')}
                <select
                  id="userrole"
                  name="userrole"
                  value={formData.userrole}
                  onChange={handleInputChange}
                  className={getFieldClassName('userrole', 'select')}
                  required
                >
                  <option value="">Select Role</option>
                  {roles.map(role => (
                    <option key={role} value={role}>{role}</option>
                  ))}
                </select>
              </div>

              {/* Complexity */}
              <div className="form-group">
                {getRequiredLabel('Complexity')}
                <select
                  id="complexity"
                  name="complexity"
                  value={formData.complexity}
                  onChange={handleInputChange}
                  className={getFieldClassName('complexity', 'select')}
                  required
                >
                  <option value="">Select Complexity</option>
                  {complexityLevels.map(level => (
                    <option key={level} value={level}>{level}</option>
                  ))}
                </select>
              </div>
              {/* Question Difficulty */}
              <div className="form-group">
                {getRequiredLabel('Question Difficulty')}
                <select
                  id="questiondifficulty"
                  name="questiondifficulty"
                  value={formData.questiondifficulty}
                  onChange={handleInputChange}
                  className={getFieldClassName('questiondifficulty', 'select')}
                  required
                >
                  <option value="">Select Difficulty</option>
                  {difficulties.map(diff => (
                    <option key={diff.level} value={diff.level}>
                      {diff.display_name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Number of Questions per Scenario */}
              <div className="form-group">
                {getRequiredLabel('Number of Questions per Scenario')}
                <input
                  type="number"
                  id="number_of_questions"
                  name="number_of_questions"
                  min="1"
                  max="10"
                  value={formData.number_of_questions}
                  onChange={handleInputChange}
                  className={getFieldClassName('number_of_questions')}
                  required
                />
              </div>

              {/* Number of Scenarios */}
              <div className="form-group">
                {getRequiredLabel('Number of Scenarios')}
                <input
                  type="number"
                  id="number_of_scenarios"
                  name="number_of_scenarios"
                  min="1"
                  max="50"
                  value={formData.number_of_scenarios}
                  onChange={handleInputChange}
                  className={getFieldClassName('number_of_scenarios')}
                  required
                />
                <p className="text-sm text-gray-500 mt-1">
                  Will generate {formData.number_of_scenarios || 0} scenarios with a total of {(formData.number_of_scenarios || 0) * (formData.number_of_questions || 0)} questions
                </p>
              </div>

              {/* Training Designation */}
              <div className="form-group">
                <label htmlFor="training_designation">Training Designation (Optional)</label>
                <input
                  type="text"
                  id="training_designation"
                  name="training_designation"
                  value={formData.training_designation}
                  onChange={handleInputChange}
                  className="form-input"
                  placeholder="e.g., ACLS, PALS, BLS, etc."
                />
                <p className="text-sm text-gray-500 mt-1">
                  This will be added as a prefix to all scenario titles
                </p>
              </div>

              {/* Context Details */}
              <div className="form-group">
                <label htmlFor="context_details">Context Details (Optional)</label>
                <textarea
                  id="context_details"
                  name="context_details"
                  value={formData.context_details}
                  onChange={handleInputChange}
                  className="form-textarea w-full"
                  rows="4"
                  placeholder="Enter additional context for all scenarios..."
                />
              </div>

              {/* Action Buttons */}
              <div className="action-buttons">
                <button
                  className="action-button generate"
                  onClick={handleSubmit}
                  disabled={loading || !isFormValid}
                >
                  {loading ? (
                    <span className="flex items-center">
                      <Loader2 className="animate-spin -ml-1 mr-2 h-4 w-4" />
                      Generating...
                    </span>
                  ) : `Generate ${formData.number_of_scenarios || 0} Scenarios`}
                </button>
                <button
                  className="action-button cancel"
                  onClick={handleResetForm}
                  disabled={loading}
                >
                  Reset Form
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Completed Scenarios Table */}
        {completedScenarios.length > 0 && (
          <div className="mt-8">
            <h2 className="text-xl font-semibold mb-4">Generated Scenarios</h2>
            <div className="table-container">
              <table className="datasheet-table">
                <thead>
                  <tr>
                    <th className="sticky-header">Scenario ID</th>
                    <th className="sticky-header">Title</th>
                    <th className="sticky-header">Questions</th>
                    <th className="sticky-header">Validation Status</th>
                    <th className="sticky-header">Patient Record</th>
                  </tr>
                </thead>
                <tbody>
                  {completedScenarios.map((scenario) => (
                    <tr key={scenario.id}>
                      <td>{scenario.id}</td>
                      <td>{scenario.title}</td>
                      <td>{scenario.questionCount}</td>
                      <td>
                        <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                          scenario.validationStatus === 'Valid' 
                            ? 'bg-green-100 text-green-800' 
                            : 'bg-yellow-100 text-yellow-800'
                        }`}>
                          {scenario.validationStatus}
                        </span>
                      </td>
                      <td>
                        <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                          scenario.patientRecordValidation === 'Valid' 
                            ? 'bg-green-100 text-green-800' 
                            : 'bg-yellow-100 text-yellow-800'
                        }`}>
                          {scenario.patientRecordValidation || 'Not Validated'}
                          {scenario.patientRecordIssues && scenario.patientRecordIssues.length > 0 && (
                            <span className="ml-1 text-xs text-yellow-700 cursor-help" title={scenario.patientRecordIssues.join('\n')}>
                              (Issues)
                            </span>
                          )}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        
        {/* Batch Validation Results Summary */}
        {Object.keys(validationResults).length > 0 && (
          <BatchValidationResults validationResults={validationResults} />
        )}
      </div>

      {/* Progress Overlay */}
      {displayProgress && (
        <div className="progress-overlay">
          <div className="text-white text-xl mb-4 font-medium">
            {getProgressMessage()}
          </div>
          <div className="progress-container">
            <div 
              className="progress-bar"
              style={{ width: `${calculateTotalProgress()}%` }}
            >
              <span className="progress-text">{calculateTotalProgress()}%</span>
            </div>
          </div>
          
          {generationStatus.state !== 'completed' && generationStatus.state !== 'idle' && (
            <div className="bg-gray-800 p-4 rounded-lg mt-6 max-w-lg text-center">
              {generationStatus.state === 'validating' && (
                <div className="mb-3 flex justify-center">
                  <SkipValidationButton />
                </div>
              )}
              <div className="text-white text-sm mb-2">
                Scenario {Math.min(generationStatus.current + 1, generationStatus.total)} of {generationStatus.total}
                {generationStatus.state === 'error' && " (Retrying...)"}
              </div>
              <div className="progress-container h-2 bg-gray-600">
                <div 
                  className="progress-bar"
                  style={{ width: `${generationStatus.scenarioProgress}%` }}
                />
              </div>
              <div className="text-gray-300 text-sm mt-2">
                {generationStatus.scenarioProgress < 30 ? "Initializing..." : 
                generationStatus.scenarioProgress < 50 ? "Generating scenario..." :
                generationStatus.scenarioProgress < 75 ? "Validating scenario..." :
                "Saving to database..."}
              </div>
              
              {/* Add an emergency cancel button if things get stuck */}
              {(generationStatus.scenarioProgress === 50 || generationStatus.state === 'validating') && 
              generationStatus.scenarioValidationStatus === 'inProgress' && (
                <div className="mt-4 border-t border-gray-700 pt-4">
                  <p className="text-gray-400 text-sm mb-2">
                    If validation has been stuck for more than 30 seconds:
                  </p>
                  <button
                    onClick={() => {
                      setGenerationStatus(prev => ({
                        ...prev,
                        scenarioProgress: 75,
                        scenarioValidationStatus: 'completed',
                        state: 'generating'
                      }));
                      
                      setValidationResults(prev => ({
                        ...prev,
                        [generationStatus.currentScenarioId]: {
                          isValid: true,
                          issues: [],
                          metadata: { validationModel: 'skipped', reason: 'user_emergency_skip' }
                        }
                      }));
                      
                      console.log('Emergency validation skip activated by user');
                    }}
                    className="px-3 py-2 bg-red-600 text-white text-base rounded-lg hover:bg-red-700"
                  >
                    Emergency Skip (Validation Hung)
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}