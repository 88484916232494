
import { supabase } from './supabaseClient';

export const AuditActionTypes = {
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
  STATUS_CHANGE: 'status_change',
  REVIEW: 'review',
  GENERATE: 'generate',
};

export const AuditComponents = {
  SCENARIO_GENERATOR: 'scenario_generator',
  SCENARIO_EDITOR: 'scenario_editor',
  SCENARIOS_PAGE: 'scenarios_page',
  SCENARIO_REVIEW: 'scenario_review',
};

/**
 * Creates a detailed audit log entry
 * @param {Object} params Audit log parameters
 * @param {string} params.component The component where the action occurred
 * @param {string} params.action The type of action performed
 * @param {string} params.entityType The type of entity being modified (e.g., 'scenario', 'question')
 * @param {string} params.entityId The ID of the entity being modified
 * @param {Object} params.oldValues Previous values before change
 * @param {Object} params.newValues New values after change
 * @param {string} params.userId User who performed the action
 * @param {string} [params.details] Additional context or details
 * @returns {Promise<Object>} The created audit log entry
 */
export const createAuditLog = async ({
  component,
  action,
  entityType,
  entityId,
  oldValues,
  newValues,
  userId,
  details = ''
}) => {
  try {
    console.group('Audit Log Creation');
    console.log('Creating audit log with params:', {
      component, action, entityType, entityId, oldValues, newValues, userId, details
    });

    
    const changes = {};
    const allKeys = new Set([...Object.keys(oldValues || {}), ...Object.keys(newValues || {})]);
    
    for (const key of allKeys) {
      const oldValue = oldValues?.[key];
      const newValue = newValues?.[key];
      
      if (oldValue !== newValue) {
        changes[key] = {
          old: oldValue,
          new: newValue
        };
      }
    }

    
    const { data: sessionData } = await supabase.auth.getSession();

    const logEntry = {
      component,
      action,
      entity_type: entityType,
      entity_id: entityId,
      changes: JSON.stringify(changes),
      user_id: userId,
      details,
      created_at: new Date().toISOString(),
      ip_address: window.clientIp || null,
      user_agent: navigator.userAgent,
      session_id: sessionData?.session?.access_token || null
    };

    console.log('Attempting to insert log entry:', logEntry);

    const { data, error } = await supabase
      .from('audit_logs')
      .insert([logEntry])
      .select()
      .single();

    if (error) {
      console.error('Error inserting audit log:', error);
      throw error;
    }

    console.log('Successfully created audit log:', data);
    console.groupEnd();
    return data;

  } catch (error) {
    console.error('Full error in createAuditLog:', error);
    console.groupEnd();
    
    return null;
  }
};


export const logScenarioChange = async ({
  component,
  action,
  scenarioId,
  oldScenario,
  newScenario,
  userId,
  details = ''
}) => {
  try {
    console.group('Scenario Change Logging');
    console.log('Scenario change params:', {
      component, action, scenarioId, oldScenario, newScenario, userId, details
    });

    const result = await createAuditLog({
      component,
      action,
      entityType: 'scenario',
      entityId: scenarioId?.toString(),
      oldValues: oldScenario,
      newValues: newScenario,
      userId,
      details
    });

    console.log('Scenario change logged:', result);
    console.groupEnd();
    return result;
  } catch (error) {
    console.error('Error in logScenarioChange:', error);
    console.groupEnd();
    return null;
  }
};


export const logDeletion = async ({
  component,
  entityType,
  entityId,
  deletedEntity,
  userId,
  details = ''
}) => {
  try {
    console.group('Deletion Logging');
    console.log('Deletion params:', {
      component, entityType, entityId, deletedEntity, userId, details
    });

    const result = await createAuditLog({
      component,
      action: AuditActionTypes.DELETE,
      entityType,
      entityId,
      oldValues: deletedEntity,
      newValues: null,
      userId,
      details
    });

    console.log('Deletion logged:', result);
    console.groupEnd();
    return result;
  } catch (error) {
    console.error('Error in logDeletion:', error);
    console.groupEnd();
    return null;
  }
};


export const logStatusChange = async ({
  component,
  entityType,
  entityId,
  oldStatus,
  newStatus,
  userId,
  details = ''
}) => {
  try {
    console.group('Status Change Logging');
    console.log('Status change params:', {
      component, entityType, entityId, oldStatus, newStatus, userId, details
    });

    const result = await createAuditLog({
      component,
      action: AuditActionTypes.STATUS_CHANGE,
      entityType,
      entityId,
      oldValues: { status: oldStatus },
      newValues: { status: newStatus },
      userId,
      details
    });

    console.log('Status change logged:', result);
    console.groupEnd();
    return result;
  } catch (error) {
    console.error('Error in logStatusChange:', error);
    console.groupEnd();
    return null;
  }
};