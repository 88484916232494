
import React from 'react';
import ScenarioGeneratorRouter from '../components/ScenarioGenerator/ScenarioGeneratorRouter';

const BatchGeneratorPage = () => {
  return (
    <div className="p-6">
      <ScenarioGeneratorRouter />
    </div>
  );
};

export default BatchGeneratorPage;