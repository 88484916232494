import { createContext, useContext, useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '@/lib/supabaseClient';

const AuthContext = createContext({});

function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const initialSignInComplete = useRef(false);

  
  useEffect(() => {
    let mounted = true;

    async function checkSession() {
      try {
        console.log('Checking session...');
        const { data: { session }, error } = await supabase.auth.getSession();
        
        if (!mounted) return;

        if (error) {
          console.error('Session error:', error);
          setLoading(false);
          return;
        }

        console.log('Session result:', session ? 'Active' : 'None');

        
        if (session?.user) {
          setUser({
            ...session.user,
            administrator: true  
          });
          setSession(session);
        } else {
          setUser(null);
          setSession(null);
        }

        setLoading(false);

        
        if (!session && location.pathname !== '/login' && !initialSignInComplete.current) {
          navigate('/login', { replace: true });
        }
      } catch (error) {
        console.error('Session check failed:', error);
        setLoading(false);
      }
    }

    checkSession();

    return () => {
      mounted = false;
    };
  }, [navigate, location.pathname]);

  
  useEffect(() => {
    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      console.log('Auth state changed:', event);
      
      if (session?.user) {
        setUser({
          ...session.user,
          administrator: true  
        });
        setSession(session);
        
        if (event === 'SIGNED_IN' && !initialSignInComplete.current) {
          navigate('/', { replace: true });
          initialSignInComplete.current = true;
        }
      } else {
        setUser(null);
        setSession(null);
        
        if (event === 'SIGNED_OUT') {
          navigate('/login', { replace: true });
          initialSignInComplete.current = false;
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [navigate]);

  const signIn = async ({ email, password }) => {
    try {
      setLoading(true);
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password
      });
      if (error) throw error;
      return { data };
    } catch (error) {
      return { error };
    } finally {
      setLoading(false);
    }
  };

  const signOut = async () => {
    try {
      setLoading(true);
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
    } catch (error) {
      return { error };
    } finally {
      setLoading(false);
    }
  };

  console.log('Current auth state:', { hasUser: !!user, loading });

  return (
    <AuthContext.Provider value={{ user, session, loading, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthProvider, useAuth };