import React from 'react';
import { NavLink } from 'react-router-dom';
import { useUserRoles } from '@/features/auth/hooks/useUserRoles';
import { supabase } from '@/lib/supabaseClient';

const Navigation = () => {
  const { roles, loading } = useUserRoles();
  const [userEmail, setUserEmail] = React.useState(null);

  React.useEffect(() => {
    const getUserEmail = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setUserEmail(user?.email);
    };
    getUserEmail();
  }, []);
  
  if (loading) {
    return (
      <nav className="h-full bg-gray-900 text-white">
        <div className="p-4">
          <img src="/solved-health-logo.png" alt="Solved Health Logo" className="w-full" />
          <div className="text-sm text-gray-400 mt-2">Loading...</div>
        </div>
      </nav>
    );
  }

  const navItems = [
    {
      section: 'MAIN',
      items: [
        { 
          path: '/', 
          label: 'Welcome', 
          visible: () => true
        },
        { 
          path: '/scenario-edits', 
          label: 'Scenario Edits',
          visible: () => roles?.isAdmin || roles?.isReviewer
        },
        { 
          path: '/question-edits', 
          label: 'Question Edits',
          visible: () => roles?.isAdmin || roles?.isReviewer
        },
        { 
          path: '/users', 
          label: 'Users', 
          visible: () => roles?.isAdmin
        },
        { 
          path: '/hashtags', 
          label: 'Hashtags', 
          visible: () => roles?.isAdmin
        },
        { 
          path: '/categories', 
          label: 'Categories', 
          visible: () => roles?.isAdmin
        }
      ]
    },
    {
      section: 'REPORTING',
      items: [
        { 
          path: '/reporting/utilization', 
          label: 'Member Utilization', 
          visible: () => roles?.isAdmin || roles?.isReviewer || roles?.isOrgLeader
        },
        { 
          path: '/reporting/dashboard', 
          label: 'System Metrics', 
          visible: () => roles?.isAdmin
        }
      ]
    },
    {
      section: 'TRAINING',
      items: [
        { 
          path: '/training/plans', 
          label: 'Training Plans', 
          visible: () => userEmail === 'jim.bressoud@gmail.com'
        },
        { 
          path: '/training/sessions', 
          label: 'Training Sessions', 
          visible: () => userEmail === 'jim.bressoud@gmail.com'
        },
        { 
          path: '/training/certifications', 
          label: 'Certifications', 
          visible: () => userEmail === 'jim.bressoud@gmail.com'
        }
      ]
    },
    {
      section: 'MEDTEAM',
      items: [
        { 
          path: '/medteam/generate-scenario',
          label: 'Generate MedTeam Scenario',
          visible: () => roles?.isAdmin || roles?.isReviewer
        },
        { 
          path: '/medteam/validations',
          label: 'MedTeam Validations',
          visible: () => roles?.isAdmin || roles?.isReviewer
        }
      ]
    },
    {
      section: 'ADMIN',
      items: [
        { 
          path: '/admin/batch-scenario-generator',
          label: 'Generate Scenario(s)', 
          visible: () => roles?.isAdmin
        },
        { 
          path: '/admin/protocols', 
          label: 'Manage Protocols', 
          visible: () => roles?.isAdmin
        },
        { 
          path: '/admin/protocol-parser', 
          label: 'Protocol Parser', 
          visible: () => roles?.isAdmin
        },
        { 
          path: '/admin/tag-scenarios', 
          label: 'Tag Scenarios', 
          visible: () => roles?.isAdmin
        },
        { 
          path: '/admin/scenario-promotion', 
          label: 'Scenario Promotion', 
          visible: () => roles?.isAdmin
        },
        { 
          path: '/admin/scenario-validation', 
          label: 'Validation Dashboard', 
          visible: () => roles?.isAdmin
        }
      ]
    }
  ];

  
  const filteredNavItems = navItems
    .map(section => ({
      ...section,
      items: section.items.filter(item => item.visible())
    }))
    .filter(section => section.items.length > 0);

  return (
    <nav className="h-full bg-gray-900 text-white overflow-y-auto">
      <div className="p-4">
        <img src="/solved-health-logo.png" alt="Solved Health Logo" className="w-full" />
        <div className="text-sm text-gray-400 mt-2">
          Role: {[
            roles?.isAdmin && 'Administrator',
            roles?.isReviewer && 'Reviewer',
            roles?.isOrgLeader && 'Org Leader'
          ].filter(Boolean).join(', ') || 'No roles'}
        </div>
      </div>

      {filteredNavItems.map((section) => (
        <div key={section.section} className="mb-6">
          <h3 className="px-4 py-2 text-sm font-semibold text-gray-400">
            {section.section}
          </h3>
          {section.items.map((item) => (
            <NavLink
              key={item.path}
              to={item.path}
              className={({ isActive }) =>
                `block px-4 py-2 text-sm ${
                  isActive ? 'bg-blue-600' : 'hover:bg-gray-800'
                }`
              }
            >
              {item.label}
            </NavLink>
          ))}
        </div>
      ))}
    </nav>
  );
};

export default Navigation;
