import React from "react";
import MedTeamValidationDashboard from "../components/MedTeamValidationDashboard";

const ValidationDashboard = () => {
  return (
    <div>
      <h1>MedTeam Scenario Validations</h1>
      <MedTeamValidationDashboard />
    </div>
  );
};

export default ValidationDashboard;
