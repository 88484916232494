export const regenerateQuestion = async ({ scenarioId, questionIndex, feedback, formData, generatedScenario }) => {
  console.log('Regenerating question', { scenarioId, questionIndex, feedback });
  
  try {
    
    const params = {
      scenarioId,
      questionIndex,
      feedback,
      formData,
      generatedScenario
    };
    
    
    const response = await fetch(`${import.meta.env.VITE_SUPABASE_URL}/functions/v1/regenerate-question-v4`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${import.meta.env.VITE_SUPABASE_ANON_KEY}`
      },
      body: JSON.stringify(params)
    });
    
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to regenerate question');
    }
    
    const result = await response.json();
    
    if (!result.success) {
      throw new Error(result.message || 'Failed to regenerate question');
    }
    
    
    return {
      ...result.data,
      patientreport: generatedScenario?.patientreport || 'Sample report',
      title: generatedScenario?.title || 'Sample title',
      status: 'pending_validation'
    };
  } catch (error) {
    console.error('Error regenerating question:', error);
    throw error;
  }
};

export const logQuestionFeedback = async (scenarioId, questionIndex, feedback, feedbackType = 'general') => {
  console.log('Logging feedback', { scenarioId, questionIndex, feedback, feedbackType });
  
  try {
    
    const response = await fetch(`${import.meta.env.VITE_SUPABASE_URL}/rest/v1/question_feedback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${import.meta.env.VITE_SUPABASE_ANON_KEY}`,
        'apikey': import.meta.env.VITE_SUPABASE_ANON_KEY,
        'Prefer': 'return=minimal'
      },
      body: JSON.stringify({
        scenarioid: scenarioId,
        question_index: questionIndex,
        feedback: feedback,
        feedback_type: feedbackType,
        created_at: new Date().toISOString()
      })
    });
    
    if (!response.ok) {
      console.warn('Failed to log feedback, but continuing operation');
    }
    
    return true;
  } catch (error) {
    console.error('Error logging feedback:', error);
    
    return false;
  }
};


export const editQuestionWithFeedback = async (scenarioId, questionIndex, question, feedback) => {
  console.log('Editing question with feedback', { scenarioId, questionIndex, feedback });
  
  try {
    
    await logQuestionFeedback(scenarioId, questionIndex, feedback, 'edit');
    
    
    return {
      ...question,
      status: 'edited_with_feedback'
    };
  } catch (error) {
    console.error('Error in editQuestionWithFeedback:', error);
    throw error;
  }
};