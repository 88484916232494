import React, { useState, useEffect } from 'react';
import { Tab } from '@headlessui/react';
import { ChevronRight } from 'lucide-react';


import ScenarioGenerator from './ScenarioGenerator';
import BatchScenarioGenerator from './BatchScenarioGenerator';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ScenarioGeneratorRouter() {
  
  const [selectedIndex, setSelectedIndex] = useState(0);
  
  return (
    <div className="scenario-generator-router px-6 py-6">
      <div className="flex items-center mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Scenario Generator</h1>
        <ChevronRight className="mx-2 text-gray-400" size={20} />
        <h2 className="text-xl font-medium text-gray-600">
          {selectedIndex === 0 ? 'Single Mode' : 'Batch Mode'}
        </h2>
      </div>
      
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <Tab.List className="flex space-x-2 rounded-xl bg-blue-50 p-1 mb-6">
          <Tab
            className={({ selected }) =>
              classNames(
                'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                selected
                  ? 'bg-white shadow text-blue-700'
                  : 'text-blue-500 hover:bg-white/[0.12] hover:text-blue-600'
              )
            }
          >
            Single Scenario
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                selected
                  ? 'bg-white shadow text-blue-700'
                  : 'text-blue-500 hover:bg-white/[0.12] hover:text-blue-600'
              )
            }
          >
            Batch Generation
          </Tab>
        </Tab.List>
        
        <Tab.Panels>
          <Tab.Panel>
            <ScenarioGenerator />
          </Tab.Panel>
          <Tab.Panel>
            <BatchScenarioGenerator />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}

