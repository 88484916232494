export const extractLocationTag = (protocol) => {
  if (!protocol || !protocol.title) return null;
  
  const title = protocol.title;
  
  const firstWord = title.split(' ')[0];
  
  if (firstWord.length <= 3 || /^[A-Z]{2,3}$/.test(firstWord)) {
    return firstWord;
  }
  
  return title.substring(0, 2);
};
  
export const generateMeaningfulTitle = (patientReport, scenarioTopic) => {
  try {
    const ageMatch = patientReport.match(/(\d+)\s*y(?:ear)?[s-]?(?:old)?/i);
    const genderMatch = patientReport.match(/\b(male|female)\b/i);
    
    const symptomPatterns = [
      {
        pattern: /(?:chest\s+(?:pain|discomfort|tightness|pressure)|(?:myocardial|cardiac)\s+(?:infarction|arrest)|heart\s+attack)/i,
        priority: 10,
        type: 'cardiac'
      },
      {
        pattern: /(?:difficulty|trouble)\s+breathing|shortness\s+of\s+breath|dyspnea|respiratory\s+distress/i,
        priority: 10,
        type: 'respiratory'
      },
      {
        pattern: /(?:fall(?:en|s)?|fell)|(?:trauma|crash|collision|accident)/i,
        priority: 9,
        type: 'trauma'
      },
      {
        pattern: /(?:fracture[ds]?|broke[n]?)\s+(?:leg|arm|hip|wrist|ankle)/i,
        priority: 8,
        type: 'trauma'
      },
      {
        pattern: /bleeding|laceration|(?:head|neck|back)\s+injury/i,
        priority: 9,
        type: 'trauma'
      },
      {
        pattern: /seizure|convulsion|stroke|CVA|cerebrovascular|TIA/i,
        priority: 10,
        type: 'neuro'
      },
      {
        pattern: /(?:altered|decreased)\s+(?:mental\s+status|consciousness|cognition)|confusion/i,
        priority: 9,
        type: 'neuro'
      },
      {
        pattern: /syncope|faint(?:ing|ed)|collapse[d]?|unresponsive/i,
        priority: 9,
        type: 'neuro'
      },
      {
        pattern: /hypothermia|heat\s+(?:stroke|exhaustion)|burn[ed]?|drowning/i,
        priority: 8,
        type: 'environmental'
      },
      {
        pattern: /(?:hypo|hyper)glycemi[ac]|diabetic\s+(?:emergency|crisis)|glucose/i,
        priority: 8,
        type: 'metabolic'
      },
      {
        pattern: /overdose|poisoning|intoxication|ingestion/i,
        priority: 9,
        type: 'toxicology'
      },
      {
        pattern: /(?:allergic\s+reaction|anaphyla[xc](?:tic|is))/i,
        priority: 10,
        type: 'allergic'
      },
      {
        pattern: /complaining\s+of\s+([^,.;]+)/i,
        priority: 5,
        type: 'general',
        captureGroup: 1
      },
      {
        pattern: /presents?\s+with\s+([^,.;]+)/i,
        priority: 5,
        type: 'general',
        captureGroup: 1
      },
      {
        pattern: /reports?\s+([^,.;]+)/i,
        priority: 4,
        type: 'general',
        captureGroup: 1
      }
    ];
    
    const contextPatterns = [
      {
        pattern: /(?:vehicle|car|motorcycle|bicycle)\s+(?:crash|accident|collision)/i,
        priority: 8,
        context: 'MVC'
      },
      {
        pattern: /found\s+(?:unconscious|unresponsive)\s+(?:at|in|on)\s+([^,.;]+)/i,
        priority: 7,
        context: 'Found unconscious',
        captureGroup: 1
      },
      {
        pattern: /collapse[d]?\s+(?:while|during|after)\s+([^,.;]+)/i,
        priority: 7,
        context: 'Collapsed during activity',
        captureGroup: 1
      },
      {
        pattern: /(?:at|in|on)\s+(?:work(?:place)?|job\s+site|construction)/i,
        priority: 6,
        context: 'at workplace'
      },
      {
        pattern: /(?:home|residence|house|apartment)/i,
        priority: 6,
        context: 'at home'
      },
      {
        pattern: /public\s+(?:place|area|location|venue)|(?:restaurant|store|mall|theater)/i,
        priority: 6,
        context: 'in public place'
      }
    ];
    
    const clinicalDetailsPatterns = [
      {
        pattern: /(?:labored|difficulty)\s+breathing/i,
        detail: 'with labored breathing'
      },
      {
        pattern: /diaphoretic|profuse(?:ly)?\s+sweat(?:ing)?/i,
        detail: 'diaphoretic'
      },
      {
        pattern: /severe\s+pain/i,
        detail: 'in severe pain'
      },
      {
        pattern: /sudden\s+onset/i,
        detail: 'with sudden onset'
      },
      {
        pattern: /unable\s+to\s+(?:walk|stand|move)/i,
        detail: 'unable to walk'
      },
      {
        pattern: /GCS\s+(?:score\s+)?(?:of\s+)?([3-9]|1[0-5])/i,
        captureGroup: 1,
        detail: 'GCS %s'
      },
      {
        pattern: /SpO2\s+(?:of\s+)?(?:<|less\s+than\s+)(\d{2})%/i,
        captureGroup: 1,
        detail: 'SpO2 <$s%'
      },
      {
        pattern: /(?:massive|severe|significant)\s+bleeding/i,
        detail: 'with significant bleeding'
      }
    ];

    let primarySymptom = null;
    let highestPriority = 0;
    for (const pattern of symptomPatterns) {
      const match = patientReport.match(pattern.pattern);
      if (match && pattern.priority > highestPriority) {
        if (pattern.captureGroup !== undefined && match[pattern.captureGroup]) {
          primarySymptom = match[pattern.captureGroup].trim();
        } else {
          primarySymptom = match[0].trim();
        }
        highestPriority = pattern.priority;
      }
    }
    
    let context = null;
    let contextPriority = 0;
    for (const pattern of contextPatterns) {
      const match = patientReport.match(pattern.pattern);
      if (match && pattern.priority > contextPriority) {
        if (pattern.captureGroup !== undefined && match[pattern.captureGroup]) {
          context = `${pattern.context} ${match[pattern.captureGroup].trim()}`;
        } else {
          context = pattern.context;
        }
        contextPriority = pattern.priority;
      }
    }
    
    let clinicalDetail = null;
    for (const pattern of clinicalDetailsPatterns) {
      const match = patientReport.match(pattern.pattern);
      if (match) {
        if (pattern.captureGroup !== undefined && match[pattern.captureGroup]) {
          clinicalDetail = pattern.detail.replace('%s', match[pattern.captureGroup].trim());
        } else {
          clinicalDetail = pattern.detail;
        }
        break; 
      }
    }
    
    if (!primarySymptom && scenarioTopic) {
      primarySymptom = scenarioTopic;
    }
    
    const components = [];
    
    if (ageMatch && genderMatch) {
      components.push(`${ageMatch[1]}yo ${genderMatch[1]}`);
    } else {
      components.push('Patient'); 
    }
    
    if (primarySymptom) {
      const symptomText = primarySymptom.replace(/(?:with|having|experiencing|complaining of|presents with)\s+/i, '').replace(/\s+(?:who|that|and)\s+/i, ' ').trim();
      components.push(symptomText);
    } else {
      components.push('medical emergency'); 
    }
    
    if (context) {
      components.push(context);
    }
    
    if (clinicalDetail) {
      components.push(clinicalDetail);
    }
    
    let title = components.join(' - ');
    
    title = title.split(' ').map((word, i) => {
      if (i === 0 || title[i - 2] === '-') {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
      return word;
    }).join(' ');
    return title;
  } catch (error) {
    console.error("Error generating title:", error);
    
    const ageMatch = patientReport.match(/(\d+)\s*y(?:ear)?[s-]?(?:old)?/i);
    const genderMatch = patientReport.match(/\b(male|female)\b/i);
    if (ageMatch && genderMatch) {
      return `${ageMatch[1]}yo ${genderMatch[1]} - ${scenarioTopic || 'medical emergency'}`;
    }
    return scenarioTopic || "Patient emergency case";
  }
};
  
export const determineFallbackFocus = (scenarioTopic) => {
  if (!scenarioTopic) return 'general';
  const topic = scenarioTopic.toLowerCase();
  if (/cardiac|chest pain|heart|mi|infarct|angina/i.test(topic)) {
    return 'cardiac';
  } else if (/respiratory|breath|asthma|copd|lung|airway|pneumonia/i.test(topic)) {
    return 'respiratory';
  } else if (/trauma|injury|fracture|wound|crash|fall/i.test(topic)) {
    return 'trauma';
  } else if (/neuro|stroke|seizure|cva|headache|consciousness|altered/i.test(topic)) {
    return 'neurological';
  } else if (/diabetes|diabetic|glucose|sugar|bgl/i.test(topic)) {
    return 'diabetic';
  }
  return 'general';
};

export const createClinicalPatientReport = (scenarioTopic) => {
  const clinicalFocus = determineFallbackFocus(scenarioTopic);
  console.log(`Creating fallback report with focus: ${clinicalFocus}`);
  
  const age = Math.floor(Math.random() * (85 - 18) + 18);
  const gender = Math.random() > 0.5 ? 'male' : 'female';
  
  let dispatchDetails = '';
  switch (clinicalFocus) {
    case 'cardiac':
      dispatchDetails = `Called to residential address at ${Math.random() > 0.5 ? 'evening' : 'afternoon'} for ${age}yo ${gender} experiencing chest pain and shortness of breath for past ${Math.floor(Math.random() * 60) + 15} minutes.`;
      break;
    case 'respiratory':
      dispatchDetails = `Dispatched to ${Math.random() > 0.5 ? 'apartment complex' : 'single-family home'} for ${age}yo ${gender} with difficulty breathing ongoing for ${Math.floor(Math.random() * 120) + 30} minutes, worsening in last 10 minutes.`;
      break;
    case 'trauma':
      dispatchDetails = `Dispatched to ${Math.random() > 0.5 ? 'residential address' : 'public park'} for ${age}yo ${gender} who fell down ${Math.random() > 0.5 ? 'stairs' : 'from standing height'} with reported head injury approximately ${Math.floor(Math.random() * 30) + 5} minutes ago.`;
      break;
    case 'neurological':
      dispatchDetails = `Emergency response requested to ${Math.random() > 0.5 ? 'residence' : 'workplace'} for ${age}yo ${gender} with sudden onset of ${Math.random() > 0.5 ? 'facial drooping and slurred speech' : 'unilateral weakness and confusion'} beginning ${Math.floor(Math.random() * 45) + 10} minutes ago.`;
      break;
    case 'diabetic':
      dispatchDetails = `Called to ${Math.random() > 0.5 ? 'residential address' : 'restaurant'} for ${age}yo ${gender} with altered mental status, history of diabetes, last medication ${Math.floor(Math.random() * 12) + 2} hours ago.`;
      break;
    default:
      dispatchDetails = `Dispatched to residential address for ${age}yo ${gender} with ${Math.random() > 0.5 ? 'sudden onset of severe abdominal pain' : 'general weakness and dizziness'} for approximately ${Math.floor(Math.random() * 180) + 30} minutes.`;
  }
  
  let sceneDetails = '';
  if (dispatchDetails.includes('residential')) {
    sceneDetails = `${Math.random() > 0.5 ? 'Well-maintained' : 'Cluttered'} ${Math.random() > 0.5 ? 'single-story' : 'two-story'} home, ${Math.random() > 0.5 ? 'well-lit' : 'dimly lit'}, with ${Math.random() > 0.5 ? 'spouse' : 'adult child'} present expressing ${Math.random() > 0.5 ? 'significant concern' : 'moderate anxiety'}.`;
  } else if (dispatchDetails.includes('apartment')) {
    sceneDetails = `${Math.random() > 0.5 ? '3rd' : '2nd'} floor unit with ${Math.random() > 0.5 ? 'narrow stairwell' : 'elevator access'}, ${Math.random() > 0.5 ? 'multiple family members' : 'roommate'} present providing history of current illness.`;
  } else if (dispatchDetails.includes('workplace')) {
    sceneDetails = `${Math.random() > 0.5 ? 'Office environment' : 'Retail location'} with ${Math.random() > 0.5 ? 'adequate space for assessment' : 'limited room to work'}, ${Math.random() > 0.5 ? 'coworkers' : 'manager'} on scene providing medical history.`;
  } else {
    sceneDetails = `${Math.random() > 0.5 ? 'Public location' : 'Home environment'} with ${Math.random() > 0.5 ? 'good access' : 'challenging access'}, ${Math.random() > 0.5 ? 'family member' : 'bystander'} on scene who witnessed event.`;
  }
  
  let patientDetails = '';
  switch (clinicalFocus) {
    case 'cardiac':
      patientDetails = `${age}yo ${gender} found ${Math.random() > 0.5 ? 'seated upright on couch appearing anxious' : 'sitting in recliner clutching chest'}, alert and oriented x${Math.random() > 0.5 ? '3' : '4'}, skin ${Math.random() > 0.5 ? 'pale and diaphoretic' : 'pale and cool to touch'}.`;
      break;
    case 'respiratory':
      patientDetails = `${age}yo ${gender} found ${Math.random() > 0.5 ? 'seated upright in tripod position' : 'sitting on edge of bed leaning forward'}, alert but ${Math.random() > 0.5 ? 'speaking in 3-4 word sentences' : 'unable to complete full sentences'}, visible ${Math.random() > 0.5 ? 'accessory muscle use' : 'intercostal retractions'}.`;
      break;
    case 'trauma':
      patientDetails = `${age}yo ${gender} found ${Math.random() > 0.5 ? 'supine on floor' : 'seated against wall'}, ${Math.random() > 0.5 ? 'GCS 14 (E4V4M6)' : 'alert and oriented x3'}, ${Math.random() > 0.5 ? '3cm laceration to occipital scalp with controlled bleeding' : 'contusion to right temple'}.`;
      break;
    case 'neurological':
      patientDetails = `${age}yo ${gender} found ${Math.random() > 0.5 ? 'seated in chair with right-sided facial droop' : 'lying in bed with left arm weakness'}, ${Math.random() > 0.5 ? 'speech slurred but understandable' : 'mild dysarthria present'}, ${Math.random() > 0.5 ? 'able to follow commands' : 'follows simple instructions'}.`;
      break;
    case 'diabetic':
      patientDetails = `${age}yo ${gender} found ${Math.random() > 0.5 ? 'seated at kitchen table appearing confused' : 'lying on couch in altered mental state'}, ${Math.random() > 0.5 ? 'diaphoretic with cool skin' : 'warm and dry to touch'}, ${Math.random() > 0.5 ? 'responding to verbal stimuli' : 'lethargic but arousable'}.`;
      break;
    default:
      patientDetails = `${age}yo ${gender} found ${Math.random() > 0.5 ? 'seated appearing uncomfortable' : 'lying in bed in moderate distress'}, alert and oriented ${Math.random() > 0.5 ? 'x3' : 'to person, place, and time'}, skin ${Math.random() > 0.5 ? 'normal color and moisture' : 'flushed but dry'}.`;
  }
  
  let vitalSigns = '';
  switch (clinicalFocus) {
    case 'cardiac':
      vitalSigns = `HR ${Math.floor(Math.random() * 40) + 90} ${Math.random() > 0.5 ? 'regular' : 'irregular'}, BP ${Math.floor(Math.random() * 60) + 140}/${Math.floor(Math.random() * 20) + 80}, RR ${Math.floor(Math.random() * 8) + 18} ${Math.random() > 0.5 ? 'labored' : 'non-labored'}, SpO2 ${Math.floor(Math.random() * 8) + 92}% on RA, temp ${(Math.floor(Math.random() * 10) + 360) / 10}°C, pain ${Math.floor(Math.random() * 4) + 6}/10 ${Math.random() > 0.5 ? 'crushing substernal' : 'pressure-like radiating to left arm'}.`;
      break;
    case 'respiratory':
      vitalSigns = `HR ${Math.floor(Math.random() * 40) + 100} regular, BP ${Math.floor(Math.random() * 30) + 130}/${Math.floor(Math.random() * 15) + 75}, RR ${Math.floor(Math.random() * 12) + 24} labored with ${Math.random() > 0.5 ? 'accessory muscle use' : 'bilateral wheezing on auscultation'}, SpO2 ${Math.floor(Math.random() * 10) + 84}% on RA, temp ${(Math.floor(Math.random() * 14) + 370) / 10}°C.`;
      break;
    case 'trauma':
      vitalSigns = `HR ${Math.floor(Math.random() * 40) + 90} regular, BP ${Math.floor(Math.random() * 20) + 120}/${Math.floor(Math.random() * 15) + 70}, RR ${Math.floor(Math.random() * 6) + 18} regular, SpO2 ${Math.floor(Math.random() * 5) + 95}% on RA, pupils ${Math.random() > 0.5 ? 'PERRL' : 'equal and reactive'}, temp 36.8°C, pain ${Math.floor(Math.random() * 3) + 6}/10 ${Math.random() > 0.5 ? 'at injury site' : 'with movement'}.`;
      break;
    case 'neurological':
      vitalSigns = `HR ${Math.floor(Math.random() * 25) + 70} regular, BP ${Math.floor(Math.random() * 30) + 160}/${Math.floor(Math.random() * 20) + 85}, RR ${Math.floor(Math.random() * 6) + 16} non-labored, SpO2 ${Math.floor(Math.random() * 4) + 96}% on RA, BGL ${Math.floor(Math.random() * 40) + 100} mg/dL, Cincinnati Stroke Scale ${Math.random() > 0.5 ? 'positive for facial droop' : 'positive for arm drift'}.`;
      break;
    case 'diabetic':
      vitalSigns = `HR ${Math.floor(Math.random() * 30) + 90} regular, BP ${Math.floor(Math.random() * 20) + 115}/${Math.floor(Math.random() * 15) + 65}, RR ${Math.floor(Math.random() * 8) + 16} regular, SpO2 ${Math.floor(Math.random() * 5) + 94}% on RA, BGL ${Math.random() > 0.5 ? Math.floor(Math.random() * 30) + 35 : Math.floor(Math.random() * 200) + 300} mg/dL, skin ${Math.random() > 0.5 ? 'cool and clammy' : 'warm and dry'}.`;
      break;
    default:
      vitalSigns = `HR ${Math.floor(Math.random() * 30) + 80} regular, BP ${Math.floor(Math.random() * 30) + 125}/${Math.floor(Math.random() * 15) + 75}, RR ${Math.floor(Math.random() * 8) + 16} non-labored, SpO2 ${Math.floor(Math.random() * 6) + 94}% on RA, temp 37.1°C, ${Math.random() > 0.5 ? 'capillary refill <2 seconds' : 'skin warm and dry to touch'}.`;
  }
  
  let medicalHistory = '';
  switch (clinicalFocus) {
    case 'cardiac':
      medicalHistory = `History of ${Math.random() > 0.5 ? 'hypertension, hyperlipidemia, and prior MI 3 years ago' : 'CAD, CHF, and CABG 5 years ago'}. Current medications include ${Math.random() > 0.5 ? 'metoprolol, aspirin, and atorvastatin' : 'lisinopril, atorvastatin, and clopidogrel'}. ${Math.random() > 0.5 ? 'Similar pain 2 days ago that resolved with rest' : 'No prior episodes of this chest pain'}.`;
      break;
    case 'respiratory':
      medicalHistory = `History of ${Math.random() > 0.5 ? 'COPD with 2 exacerbations in past year' : 'asthma since childhood with multiple hospitalizations'}. Takes ${Math.random() > 0.5 ? 'albuterol, budesonide/formoterol, and montelukast' : 'fluticasone/salmeterol and tiotropium'}. ${Math.random() > 0.5 ? 'Recent URI symptoms 1 week ago' : 'No recent respiratory infections'}.`;
      break;
    case 'trauma':
      medicalHistory = `${Math.random() > 0.5 ? 'No significant past medical history' : 'History of hypertension and osteoarthritis'}. Current medications include ${Math.random() > 0.5 ? 'none' : 'lisinopril and acetaminophen'}. ${Math.random() > 0.5 ? 'No loss of consciousness reported' : 'Brief LOC according to witnesses'}, no anticoagulant use.`;
      break;
    case 'neurological':
      medicalHistory = `History of ${Math.random() > 0.5 ? 'hypertension, atrial fibrillation, and prior TIA 2 years ago' : 'hypertension, hyperlipidemia, and type 2 diabetes'}. Takes ${Math.random() > 0.5 ? 'apixaban, metoprolol, and atorvastatin' : 'lisinopril, metformin, and aspirin'}. ${Math.random() > 0.5 ? 'Symptoms began suddenly while watching TV' : 'Noticed symptoms upon waking this morning'}.`;
      break;
    case 'diabetic':
      medicalHistory = `History of ${Math.random() > 0.5 ? 'type 1 diabetes for 15 years' : 'type 2 diabetes for 8 years'} and ${Math.random() > 0.5 ? 'hypertension' : 'CKD stage 2'}. Takes ${Math.random() > 0.5 ? 'insulin glargine and lispro' : 'metformin and glipizide'}. ${Math.random() > 0.5 ? 'Missed last meal' : 'Recent change in medication dosage'}, last insulin ${Math.floor(Math.random() * 12) + 2} hours ago.`;
      break;
    default:
      medicalHistory = `History of ${Math.random() > 0.5 ? 'hypertension and GERD' : 'migraines and anxiety'}. Current medications include ${Math.random() > 0.5 ? 'lisinopril and omeprazole' : 'sumatriptan and sertraline'}. ${Math.random() > 0.5 ? 'Symptoms began after eating dinner' : 'Gradual onset of symptoms over past 24 hours'}.`;
  }

  const completeReport = `DISPATCH: ${dispatchDetails}\nSCENE: ${sceneDetails}\nPATIENT: ${patientDetails}\nINITIAL OBSERVATIONS: ${vitalSigns}\nHISTORY: ${medicalHistory}`;
  return completeReport;
};
  
export const generateDetailedExplanation = (rawContent, question, answerOption, optionIndex, isCorrect, clinicalFocus, userRole) => {
  const clinicalReasoningPatterns = {
    cardiac: {
      priorities: ["Rapid assessment", "Oxygen administration", "12-lead ECG", "Circulatory support", "Transport to PCI facility"],
      incorrect_reasons: [
        "Delays definitive care for a time-sensitive condition",
        "Does not address the primary cardiac concern",
        "Increases myocardial oxygen demand unnecessarily",
        "May worsen the hemodynamic status",
        "Not indicated without specific symptoms/signs"
      ],
      correct_principles: [
        "Follows AHA guidelines for suspected ACS",
        "Prioritizes reperfusion strategies appropriately",
        "Addresses both symptom management and underlying cause",
        "Maintains adequate tissue perfusion",
        "Minimizes risk of arrhythmias and cardiogenic shock"
      ]
    },
    respiratory: {
      priorities: ["Airway management", "Oxygenation", "Ventilation support", "Bronchodilator therapy", "Position for comfort"],
      incorrect_reasons: [
        "May worsen respiratory effort",
        "Does not address the hypoxemia",
        "Inappropriate without assessing SpO2 first",
        "Delays necessary respiratory support",
        "Could cause respiratory depression in this context"
      ],
      correct_principles: [
        "Addresses work of breathing appropriately",
        "Follows evidence-based asthma/COPD management protocols",
        "Improves ventilation/perfusion matching",
        "Provides appropriate oxygen therapy based on presentation",
        "Prevents respiratory failure progression"
      ]
    },
    trauma: {
      priorities: ["Primary survey (ABCDE)", "Hemorrhage control", "C-spine immobilization", "Fluid resuscitation", "Rapid transport"],
      incorrect_reasons: [
        "Violates trauma care sequencing principles",
        "Delays identification of life threats",
        "Focuses on secondary injuries before primary",
        "May worsen hemodynamic status in trauma",
        "Could cause further injury without proper assessment"
      ],
      correct_principles: [
        "Follows ATLS/PHTLS guidelines appropriately",
        "Addresses life threats in correct sequence",
        "Provides appropriate hemorrhage control",
        "Prevents secondary injury",
        "Optimizes timing to definitive trauma care"
      ]
    },
    neurological: {
      priorities: ["Neurological assessment", "Airway protection", "Glucose check", "Seizure management", "Stroke scale assessment"],
      incorrect_reasons: [
        "Delays time-critical neurological intervention",
        "May worsen intracranial pressure",
        "Does not address potential hypoglycemia",
        "Could mask neurological findings",
        "Inappropriate without complete neuro assessment"
      ],
      correct_principles: [
        "Follows current stroke or seizure protocols",
        "Protects against secondary brain injury",
        "Addresses reversible causes first",
        "Optimizes time to definitive neurological care",
        "Maintains adequate cerebral perfusion"
      ]
    },
    diabetic: {
      priorities: ["Glucose assessment", "Hypoglycemia treatment", "Fluid resuscitation", "Electrolyte management", "Ketone assessment"],
      incorrect_reasons: [
        "Does not address the metabolic emergency",
        "May worsen the glycemic status",
        "Inappropriate without glucose assessment first",
        "Delays necessary diabetic emergency treatment",
        "Could cause electrolyte imbalance in this context"
      ],
      correct_principles: [
        "Follows diabetic emergency protocols",
        "Addresses both symptoms and underlying cause",
        "Prevents progression of DKA/HHS/hypoglycemia",
        "Maintains adequate hydration and electrolyte balance",
        "Provides appropriate glucose management"
      ]
    },
    general: {
      priorities: ["Primary assessment", "Vital signs", "History gathering", "Focused physical exam", "Transport decision"],
      incorrect_reasons: [
        "Does not follow appropriate assessment sequence",
        "May worsen the patient's condition",
        "Inappropriate without baseline assessment",
        "Delays necessary treatment or transport",
        "Not indicated based on the presenting symptoms"
      ],
      correct_principles: [
        "Follows standard EMS protocols",
        "Addresses both assessment and intervention needs",
        "Prioritizes life threats appropriately",
        "Provides appropriate symptom management",
        "Optimizes patient outcome through correct sequencing"
      ]
    },
    environmental: {
      priorities: ["Remove from environment", "Core temperature management", "Fluid status assessment", "Skin/tissue assessment", "Temperature monitoring"],
      incorrect_reasons: [
        "Does not address the environmental exposure",
        "May worsen heat loss/gain in this context",
"Inappropriate without assessing core temperature",
        "Delays necessary environmental intervention",
        "Could cause further tissue damage"
      ],
      correct_principles: [
        "Follows hypothermia/hyperthermia protocols",
        "Addresses both core temperature and secondary issues",
        "Prevents further heat loss/gain appropriately",
        "Provides appropriate rewarming/cooling techniques",
        "Monitors for complications of environmental emergency"
      ]
    },
    toxicological: {
      priorities: ["ABCs", "Antidote consideration", "Decontamination", "Supportive care", "Poison control consult"],
      incorrect_reasons: [
        "Does not address the toxic exposure",
        "May worsen absorption of the toxin",
        "Inappropriate without identifying the substance",
        "Delays necessary toxicological intervention",
        "Could interact dangerously with the toxin"
      ],
      correct_principles: [
        "Follows toxicological emergency protocols",
        "Addresses life threats while managing toxin",
        "Implements appropriate decontamination",
        "Provides specific antidote when indicated",
        "Prevents further toxin effects through correct management"
      ]
    },
    obstetric: {
      priorities: ["Maternal assessment", "Fetal assessment", "Positioning", "Bleeding control", "Delivery preparation"],
      incorrect_reasons: [
        "May compromise maternal or fetal well-being",
        "Does not address obstetric emergency priorities",
        "Inappropriate intervention for pregnancy",
        "Delays necessary obstetric care",
        "Could worsen potential complications"
      ],
      correct_principles: [
        "Follows obstetric emergency protocols",
        "Addresses both maternal and fetal needs",
        "Prevents complications through proper positioning",
        "Prepares appropriately for potential delivery",
        "Manages obstetric bleeding or hypertension correctly"
      ]
    }
  };

  const roleConsiderations = {
    "EMT": {
      scope: "basic life support interventions",
      limitations: ["advanced airways", "IV access", "cardiac monitoring", "medication administration (except for a few)"],
      appropriate: ["oxygen administration", "bleeding control", "BLS airway management", "CPR", "AED use"]
    },
    "AEMT": {
      scope: "advanced EMT interventions",
      limitations: ["RSI", "certain cardiac medications", "complex medical decision-making"],
      appropriate: ["IV access", "limited medication administration", "supraglottic airways", "cardiac monitoring"]
    },
    "Paramedic": {
      scope: "advanced life support interventions",
      limitations: ["surgical procedures", "definitive care"],
      appropriate: ["advanced cardiac monitoring/ECG interpretation", "medication administration", "advanced airway management"]
    },
    "First Responder": {
      scope: "initial emergency care",
      limitations: ["most medical interventions", "transport decisions", "medication administration"],
      appropriate: ["scene safety", "initial assessment", "basic first aid", "CPR", "AED use"]
    }
  };

  const role = roleConsiderations[userRole] || roleConsiderations["Paramedic"];
  
  const clinicalReasoning = clinicalReasoningPatterns[clinicalFocus] || clinicalReasoningPatterns["general"];
  
  if (isCorrect) {
    const correctPrinciple = clinicalReasoning.correct_principles[optionIndex % clinicalReasoning.correct_principles.length];
    const priority = clinicalReasoning.priorities[optionIndex % clinicalReasoning.priorities.length];
    
    return `Correct: This is the most appropriate action for this ${clinicalFocus} presentation. ${correctPrinciple}. Initiating ${answerOption.answer.toLowerCase()} properly prioritizes ${priority} in this scenario, which is within the ${role.scope} scope. This intervention follows current protocols for ${userRole}s and provides the best chance for a positive patient outcome.`;
  } else {
    const incorrectReason = clinicalReasoning.incorrect_reasons[optionIndex % clinicalReasoning.incorrect_reasons.length];
    const betterApproach = clinicalReasoning.priorities[optionIndex % clinicalReasoning.priorities.length];
    
    let scopeIssue = "";
    if (role.limitations.some(limitation => answerOption.answer.toLowerCase().includes(limitation.toLowerCase()))) {
      scopeIssue = ` Additionally, this may be outside the scope of practice for a ${userRole}.`;
    }
    
    return `Incorrect: While this might seem reasonable, it is not the best course of action for this ${clinicalFocus} presentation. ${incorrectReason}.${scopeIssue} A better approach would prioritize ${betterApproach} according to current protocols for ${userRole}s.`;
  }
};

export const createClinicalQuestion = (index, scenarioTopic, userrole, formData = null) => {
  console.log(`Creating clinical question ${index + 1}`);
  return {
    question: `Question ${index + 1}`,
    answers: [
      {
        answer: "Option 1",
        correct: true
      },
      {
        answer: "Option 2",
        correct: false
      },
      {
        answer: "Option 3",
        correct: false
      },
      {
        answer: "Option 4",
        correct: false
      }
    ]
  };
};

export const extractBasicContent = (content, formData) => {
  console.log("Using enhanced content extraction");
  try {
    const expectedCount = parseInt(formData.number_of_questions) || 4;
    console.log(`Expected to generate ${expectedCount} questions`);
    
    const result = {
      questions: [],
      patientreport: createClinicalPatientReport(formData.scenario_topic)
    };
    
    let patientReportMatch = null;
    try {
      patientReportMatch = content.match(/"patientreport"\s*:\s*"([^"]*?)"/);
      if (!patientReportMatch) {
        patientReportMatch = content.match(/patientreport"?:\s*"([^"]*?)"/);
      }
    } catch (e) {
      console.error("Error matching patient report:", e);
    }
    if (patientReportMatch && patientReportMatch[1]) {
      result.patientreport = patientReportMatch[1].replace(/\\"/g, '"').replace(/\\n/g, '\n').trim();
    }
    
    try {
      const questionsMatch = content.match(/"questions"\s*:\s*\[(.*?)\]/s);
      if (questionsMatch && questionsMatch[1]) {
        console.log("Found questions array in JSON response");
        
        const fakeJsonString = `{"questions":[${questionsMatch[1]}]}`;
        const parsedQuestions = JSON.parse(fakeJsonString);
        if (parsedQuestions.questions && parsedQuestions.questions.length > 0) {
          result.questions = parsedQuestions.questions;
          console.log(`Extracted ${result.questions.length} questions from JSON`);
        }
      }
    } catch (e) {
      console.error("Error extracting questions:", e);
    }
    
    while (result.questions.length < expectedCount) {
      console.log(`Adding default question ${result.questions.length + 1}/${expectedCount}`);
      result.questions.push(createClinicalQuestion(result.questions.length, formData.scenario_topic, formData.userrole, formData));
    }
    console.log(`Final question count: ${result.questions.length}`);
    return result;
  } catch (e) {
    console.error("Enhanced extraction failed:", e);
    
    const expectedCount = parseInt(formData.number_of_questions) || 4;
    console.log(`Fallback: Creating ${expectedCount} default questions`);
    return {
      patientreport: createClinicalPatientReport(formData.scenario_topic),
      questions: Array(expectedCount).fill(null).map((_, i) => createClinicalQuestion(i, formData.scenario_topic, formData.userrole, formData))
    };
  }
};

export const validatePatientReport = (report) => {
  if (!report) return false;
  
  const requiredSections = [
    /DISPATCH|dispatch/i,
    /SCENE|scene/i,
    /PATIENT|patient/i,
    /OBSERVATION|observation/i,
    /HISTORY|history/i
  ];
  
  const foundSections = requiredSections.filter((section) => section.test(report));
  
  const hasVitalSigns = /\bBP\s+\d+\/\d+\b|\bHR\s+\d+\b|\bRR\s+\d+\b|\bSpO2\s+\d+%\b|\bGCS\s+\d+\b|\btemp\s+\d+\.\d+\b/i.test(report);
  const hasNumericValues = /\d+yo|\d+\s+year|age\s+\d+|\d+\/\d+|\d+%|\d+\s+mg|\d+\s+min|duration\s+\d+|[0-9]\/10\s+pain/.test(report);
  
  const hasGenericContent = /appropriate for this scenario|standard scene|as appropriate|standard protocol|requiring assessment|as needed|relevant medical history|demographics are appropriate/i.test(report);
  
  return foundSections.length >= 3 && hasVitalSigns && hasNumericValues && !hasGenericContent;
};
  
export const extractClinicalDetails = (patientReport, scenarioTopic) => {
  const fallbackFocus = determineFallbackFocus(scenarioTopic);
  
  try {
    const vitalsRegex = {
      bp: /(?:BP|blood pressure)\s*(?:of|:|is)?\s*(\d+\/\d+)/i,
      hr: /(?:HR|heart rate|pulse)\s*(?:of|:|is)?\s*(\d+)/i,
      rr: /(?:RR|respiratory rate|breathing rate)\s*(?:of|:|is)?\s*(\d+)/i,
      spo2: /(?:SpO2|O2 sat|oxygen saturation)\s*(?:of|:|is)?\s*(\d+%?)/i,
      gcs: /(?:GCS|Glasgow Coma Scale)\s*(?:of|:|is)?\s*(\d+|[Ee]\d[Vv]\d[Mm]\d)/i,
      temp: /(?:temp|temperature)\s*(?:of|:|is)?\s*(\d+\.?\d*)/i,
      glucose: /(?:BGL|blood glucose|glucose)\s*(?:of|:|is)?\s*(\d+)/i
    };
    
    const vitals = {};
    for (const [key, regex] of Object.entries(vitalsRegex)) {
      const match = patientReport.match(regex);
      if (match && match[1]) {
        vitals[key] = match[1];
      }
    }
    
    let chiefComplaint = null;
    const complaintRegex = /(?:complaining of|presents with|reports|chief complaint is)\s+([^,.;]+)/i;
    const complaintMatch = patientReport.match(complaintRegex);
    if (complaintMatch && complaintMatch[1]) {
      chiefComplaint = complaintMatch[1].trim();
    }
    
    let mechanism = null;
    const mechanismRegex = /(?:following|after|due to|result of|from)\s+([^,.;]+(?:fall|accident|crash|collision|incident|trauma))/i;
    const mechanismMatch = patientReport.match(mechanismRegex);
    if (mechanismMatch && mechanismMatch[1]) {
      mechanism = mechanismMatch[1].trim();
    }
    
    const medications = [];
    const medicationRegex = /(?:medications?|takes|prescribed|using)\s+([^,.;]+(?:aspirin|nitro|albuterol|insulin|metoprolol|lisinopril|atorvastatin|furosemide|lasix|epinephrine|epi|nitroglycerin|diltiazem|amiodarone|adenosine|atropine|morphine|fentanyl|ketamine|midazolam|versed|diazepam|lorazepam|ativan))/i;
    const medicationMatches = [...patientReport.matchAll(new RegExp(medicationRegex, 'gi'))];
    medicationMatches.forEach(match => {
      if (match && match[1]) {
        medications.push(match[1].trim());
      }
    });
    
    const visibleSigns = [];
    const signRegex = /(?:appears|presenting|exhibits|shows|with)\s+([^,.;]+(?:diaphoretic|pale|cyanotic|jaundiced|flushed|clammy|cold|warm|dry|tachypneic|tachycardic|bradycardic|distended|edema|swelling|bleeding|bruising|laceration|deformity))/i;
    const signMatches = [...patientReport.matchAll(new RegExp(signRegex, 'gi'))];
    signMatches.forEach(match => {
      if (match && match[1]) {
        visibleSigns.push(match[1].trim());
      }
    });
    
    const medicalHistory = [];
    const historyRegex = /(?:history of|hx of|past medical history|pmh)\s+([^,.;]+(?:hypertension|diabetes|asthma|copd|heart disease|stroke|mi|cad|chf|seizures|cancer|surgery|renal|liver|pulmonary|cardiac|neurological))/i;
    const historyMatches = [...patientReport.matchAll(new RegExp(historyRegex, 'gi'))];
    historyMatches.forEach(match => {
      if (match && match[1]) {
        medicalHistory.push(match[1].trim());
      }
    });
    
    let focus = fallbackFocus;
    
    const focusPatterns = [
      { pattern: /chest\s+pain|cardiac|heart|mi|infarct|stemi|nstemi|angina|palpitation/i, focus: 'cardiac' },
      { pattern: /breath|respiratory|asthma|copd|pneumonia|airway|stridor|wheez|dyspnea/i, focus: 'respiratory' },
      { pattern: /traum|injur|crash|fall|wound|laceration|fracture|amputation/i, focus: 'trauma' },
      { pattern: /stroke|cva|seizure|neuro|consciousness|altered|unresponsive|syncope/i, focus: 'neurological' },
      { pattern: /diabet|glucose|sugar|dka|hhs|insulin/i, focus: 'diabetic' },
      { pattern: /hypotherm|hypertherm|heat|cold|burn|frost|drowning/i, focus: 'environmental' },
      { pattern: /poison|overdose|tox|ingestion|intoxication|substance|drug/i, focus: 'toxicological' },
      { pattern: /pregnan|labor|birth|delivery|contraction|obstetric/i, focus: 'obstetric' }
    ];
    
    for (const { pattern, focus: patternFocus } of focusPatterns) {
      if (pattern.test(patientReport)) {
        focus = patternFocus;
        break;
      }
    }
    
    const ageMatch = patientReport.match(/(\d+)\s*y(?:ear)?[s-]?(?:old)?/i);
    const genderMatch = patientReport.match(/\b(male|female)\b/i);
    
    const aclsFlags = {
      unstable: /(?:unstable|hypotensive|shock|deteriorating|worsening|critical)/i.test(patientReport),
      stemi: /(?:stemi|st.elevation|st elevation|12.lead)/i.test(patientReport),
      highRisk: /(?:high.risk|severe|life.threatening|critical)/i.test(patientReport)
    };
    
    return {
      focus,
      vitals,
      chiefComplaint,
      mechanism,
      medications,
      visibleSigns,
      medicalHistory,
      age: ageMatch ? ageMatch[1] : null,
      gender: genderMatch ? genderMatch[1] : null,
      aclsFlags
    };
  } catch (error) {
    console.error('Error extracting clinical details:', error);
    return { focus: fallbackFocus };
  }
};

export const generateBasicExplanation = (optionIndex, isCorrect, clinicalFocus, userRole) => {
  if (isCorrect) {
    return `Correct: This is the most appropriate action for a ${clinicalFocus} presentation. Following ${userRole} protocols, this intervention addresses the patient's primary needs and follows current medical guidelines for this type of emergency.`;
  } else {
    const reasons = [
      "It fails to address the most critical aspect of the patient's condition",
      "It may delay more important interventions",
      "It focuses on a secondary concern before addressing primary issues",
      "It could potentially worsen the patient's condition"
    ];
    
    return `Incorrect: ${reasons[optionIndex % reasons.length]} in this ${clinicalFocus} scenario. Current ${userRole} protocols recommend prioritizing other interventions for better patient outcomes.`;
  }
};
  
export const transformResponse = (rawContent, scenarioTopic, formData) => {
  try {
    const expectedCount = parseInt(formData.number_of_questions) || 4;
    console.log(`Transform expecting ${expectedCount} questions`);
    
    const title = generateMeaningfulTitle(rawContent.patientreport, scenarioTopic);
    
    let patientreport = rawContent.patientreport || createClinicalPatientReport(scenarioTopic);
    
    patientreport = patientreport.replace(/(?:ASSESSMENT|CHALLENGES)[\s\S]*?((?=\n\s*[A-Z]+:)|$)/gi, '').trim();
    
    let questionsArray = rawContent.questions || [];
    console.log(`Got ${questionsArray.length} questions from response, expected ${expectedCount}`);
    
    while (questionsArray.length < expectedCount) {
      console.log(`Adding default question ${questionsArray.length + 1}`);
      questionsArray.push({
        question: `Question ${questionsArray.length + 1}`,
        answers: [
          { answer: "Option 1", correct: true },
          { answer: "Option 2", correct: false },
          { answer: "Option 3", correct: false },
          { answer: "Option 4", correct: false }
        ]
      });
    }
    
    if (questionsArray.length > expectedCount) {
      console.log(`Truncating from ${questionsArray.length} to ${expectedCount} questions`);
      questionsArray = questionsArray.slice(0, expectedCount);
    }
    
    const clinicalDetails = extractClinicalDetails(patientreport, scenarioTopic);
    
    const questions = questionsArray.map((q, index) => {
      console.log(`Processing question ${index + 1}/${expectedCount}`);
      
      if (!q.answers || !Array.isArray(q.answers)) {
        console.log(`Question ${index + 1} has no valid answers array, creating default answers`);
        
        q.answers = [
          { answer: "Option 1", correct: true },
          { answer: "Option 2", correct: false },
          { answer: "Option 3", correct: false },
          { answer: "Option 4", correct: false }
        ];
      }
      
      while (q.answers.length < 4) {
        console.log(`Question ${index + 1} adding answer option ${q.answers.length + 1}`);
        q.answers.push({
          answer: `Option ${q.answers.length + 1}`,
          correct: false
        });
      }
      
      if (q.answers.length > 4) {
        console.log(`Question ${index + 1} has ${q.answers.length} answers, truncating to 4`);
        q.answers = q.answers.slice(0, 4);
      }
      
      let correctIndex = q.answers.findIndex((a) => a.correct === true);
      if (correctIndex === -1) {
        console.log(`Question ${index + 1} has no marked correct answer, defaulting to first option`);
        correctIndex = 0;
        q.answers[0].correct = true;
      }
      
      const userRole = formData?.userrole || "Paramedic";
      return {
        question: q.question || `Question ${index + 1}`,
        option1: q.answers[0]?.answer || "Option 1",
        option2: q.answers[1]?.answer || "Option 2",
        option3: q.answers[2]?.answer || "Option 3",
        option4: q.answers[3]?.answer || "Option 4",
        answer: correctIndex + 1,
        explanation1: generateDetailedExplanation(rawContent, q, q.answers[0], 0, q.answers[0]?.correct, clinicalDetails.focus, userRole),
        explanation2: generateDetailedExplanation(rawContent, q, q.answers[1], 1, q.answers[1]?.correct, clinicalDetails.focus, userRole),
        explanation3: generateDetailedExplanation(rawContent, q, q.answers[2], 2, q.answers[2]?.correct, clinicalDetails.focus, userRole),
        explanation4: generateDetailedExplanation(rawContent, q, q.answers[3], 3, q.answers[3]?.correct, clinicalDetails.focus, userRole)
      };
    });
    
    console.log(`Final question count: ${questions.length}`);
    if (questions.length !== expectedCount) {
      console.warn(`WARNING: Expected ${expectedCount} questions but generated ${questions.length}`);
    }
    
    return {
      title,
      patientreport,
      questions
    };
  } catch (error) {
    console.error('Transform error:', error);
    
    const expectedCount = parseInt(formData.number_of_questions) || 4;
    console.log(`Error in transform, generating ${expectedCount} default questions`);
    
    return {
      title: `${scenarioTopic}`,
      patientreport: createClinicalPatientReport(scenarioTopic),
      questions: Array(expectedCount).fill(null).map((_, i) => {
        const clinicalQuestion = createClinicalQuestion(i, scenarioTopic, formData.userrole, formData);
        const correctIndex = clinicalQuestion.answers.findIndex((a) => a.correct === true);
        
        const clinicalFocus = determineFallbackFocus(scenarioTopic);
        
        return {
          question: clinicalQuestion.question,
          option1: clinicalQuestion.answers[0].answer,
          option2: clinicalQuestion.answers[1].answer,
          option3: clinicalQuestion.answers[2].answer,
          option4: clinicalQuestion.answers[3].answer,
          answer: correctIndex + 1,
          explanation1: generateBasicExplanation(0, clinicalQuestion.answers[0].correct, clinicalFocus, formData.userrole),
          explanation2: generateBasicExplanation(1, clinicalQuestion.answers[1].correct, clinicalFocus, formData.userrole),
          explanation3: generateBasicExplanation(2, clinicalQuestion.answers[2].correct, clinicalFocus, formData.userrole),
          explanation4: generateBasicExplanation(3, clinicalQuestion.answers[3].correct, clinicalFocus, formData.userrole)
        };
      })
    };
  }
};
  
export const buildPrompt = (formData) => {
  const questionCount = parseInt(formData.number_of_questions) || 4;
  
  const comprehensiveInstructions = `
    # Comprehensive Medical Scenario Generation Instructions

    ## General Requirements:
    1. Title structure should be "Age-year-old Gender - Short Description of Scenario". For example "61-year-old Male - Auto Accident".
    2. Patient Report Card should contain the following elements in this order - Dispatch:, Scene:, Patient:, and when available History:. Each of these should be a short description of only one sentence.
    3. Questions should include patient status changes, vitals (BP, HR, RR, SPO2, and any other needed), environmental changes, and observed behaviors based on the previous correct answer.
    4. Options: There should be 4 valid options for the question. One is correct, one is somewhat correct, one is somewhat incorrect, and one is incorrect.
    5. Explanation should have the following structure: Starts with "Correct", "Somewhat Correct", or "Incorrect". Next is medical explanation of why the option is correct or incorrect. Finally, medical impact of choosing the option.
    6. The title, patient record, and questions should never include a diagnosis until it has been discovered through a correct answer.
    7. Questions in the scenario should follow a sequential timeline.
    8. Questions and explanations should follow current protocols for each level of clinician (EMT, AEMT, or Paramedic).
    9. Options that are incorrect should contain a specific flaw that knowledgeable providers would recognize.
    10. Questions should only include relevant new information. Do not repeat the same wording in each question.
    11. All scenarios occur in the field, so a scenario should never start in the ER or Hospital.
    12. Patient Record and Question must be distinct.
    13. For both the questions and the explanation, there should be no letters or numbers at the beginning.
    14. Options should be in random order and less than 5 words long.
    15. No option should be outside the scope of work of the individual based on their role.
    16. Only provide options that a clinician would have the equipment to complete in the field.
    17. Do not repeat information across the Patient Record, Question, Options, or Explanation.
    18. Do not use actual names or addresses in any section.
    19. Questions should include the patient vitals including BP, HR, RR, SPO2, and any other needed, updated in each question if there are any changes.
    20. Questions should never have the ER department asking EMS for advice.

    ## Patient Record Card Structure:
    1. Dispatch: A short summary of the situation including the location, patient age and gender, and the medical situation. This should be the information that dispatch received from the caller.
    2. Scene: A brief description of where the patient is, such as a home, park, place of work, and any important elements about the environment.
    3. Patient: Information about how the patient presents such as consciousness, position, actions, or other condition indicators.
    4. History: Provided if there is someone familiar with the patient such as a family member, coworker, or the patient if conscious. Omit if only a bystander is available.

    ## Question Structure:
    Each question should start with patient presentation information and include updated vitals and any changes to the patient's condition based on previous correct actions.

    ## Option Structure:
    - Options should be less than 5 words long
    - No option should be outside the clinician's scope of practice
    - Options should only include actions that could be performed with equipment available in the field

    ## Explanation Structure:
    - Start with "Correct", "Somewhat Correct", or "Incorrect"
    - Include why the option was right or wrong
    - Explain the medical implications of choosing the option
    - Keep explanations clear and concise

    Generate EXACTLY ${questionCount} questions for a ${formData.scenario_topic} scenario, designed for ${formData.userrole || 'EMS providers'} with a ${formData.complexity || 'moderate'} complexity level. 
    
    Additional context: ${formData.initial_prompts || ''} 
    ${formData.context_details ? 'Context details: ' + formData.context_details : ''}
  `;

  return `${comprehensiveInstructions}

Return in this JSON format:
{
  "patientreport": "Complete patient report based on the scenario",
  "questions": [
    {
      "question": "Question text",
      "answers": [
        {
          "answer": "Option text",
          "correct": boolean
        },
        ... (4 options total)
      ]
    },
    ... (exactly ${questionCount} questions)
  ]
}`;
};

export const getSystemPrompt = () => {
  return "You are an expert medical training content creator specializing in EMS scenarios. Create realistic medical training scenarios with clinically meaningful details. Include a patient report with SPECIFIC vital signs and assessment findings. Use appropriate medical terminology, medication names, and numerical values for vital signs. AVOID generic placeholders like 'appropriate to the condition' or 'vital signs as expected'. Generate the EXACT number of questions requested - no more and no fewer. Return a complete, valid JSON object only.";
};

export const updatePatientRecordForQuestion = (patientReport, questionIndex, correctExplanation) => {
  try {
    if (!patientReport || questionIndex <= 0 || !correctExplanation) {
      return patientReport;
    }
    
    // Create a base for the updated report
    let updatedReport = patientReport;
    
    // Extract key status changes from the explanation
    const statusChangesRegex = /(patient|vital signs|condition)(?:\s+(?:now|has|shows|displays|exhibits))?\s+([^,.]+(?:improved|worsened|stabilized|changed|responded))/gi;
    const statusMatches = [...correctExplanation.matchAll(statusChangesRegex)];
    
    // Extract vital sign changes
    const vitalRegex = /(HR|BP|RR|SpO2|GCS|temp|temperature|pulse)\s+(?:is|of|now|has changed to)?\s+([^,.]+)/gi;
    const vitalMatches = [...correctExplanation.matchAll(vitalRegex)];
    
    // Create an update section if we found changes
    if (statusMatches.length > 0 || vitalMatches.length > 0) {
      let updateSection = `\nUPDATE AFTER INTERVENTION ${questionIndex}:\n`;
      
      // Add status changes
      if (statusMatches.length > 0) {
        statusMatches.forEach(match => {
          if (match && match[1] && match[2]) {
            updateSection += `- ${match[1].charAt(0).toUpperCase() + match[1].slice(1)} ${match[2].trim()}.\n`;
          }
        });
      }
      
      // Add vital sign changes
      if (vitalMatches.length > 0) {
        updateSection += "- Vital signs: ";
        vitalMatches.forEach((match, index) => {
          if (match && match[1] && match[2]) {
            updateSection += `${match[1]} ${match[2].trim()}${index < vitalMatches.length - 1 ? ', ' : ''}`;
          }
        });
        updateSection += '\n';
      }
      
      // If no specific changes were found, add a generic update
      if (statusMatches.length === 0 && vitalMatches.length === 0) {
        updateSection += "- Patient condition has been updated based on previous interventions.\n";
      }
      
      // Append update section to report
      updatedReport += updateSection;
    }
    
    return updatedReport;
  } catch (error) {
    console.error("Error updating patient record:", error);
    return patientReport;
  }
};