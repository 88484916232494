import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader2 } from 'lucide-react';
import '@/features/medteam/styles/MedTeamScenario.css';
import { useAuth } from '@/features/auth/hooks/useAuth';
import { supabase } from '@/lib/supabaseClient';

const DEFAULT_VALUES = {
  institution: '',
  curriculum: '',
  curriculum_year: '',
  class: '',
  topic: '',
  initial_prompts: '',
  category: '',
  complexity: '',
  questiondifficulty: '',
  number_of_questions: 4,
  context_details: '',
};
export default function GenerateMedTeamScenario() {
  const navigate = useNavigate();
  const { session } = useAuth();
  const [formData, setFormData] = useState(DEFAULT_VALUES);
  const [institutions, setInstitutions] = useState([]);
  const [curriculums, setCurriculums] = useState([]);
  const [years, setYears] = useState([]);
  const [classes, setClasses] = useState([]);
  const [topics, setTopics] = useState([]);
  const [categories, setCategories] = useState([]);
  const [complexities, setComplexities] = useState([]);
  const [difficulties, setDifficulties] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchInstitutions() {
      const { data } = await supabase.from('medteam_institutions').select('*');
      setInstitutions(data || []);
    }
    async function fetchReferenceData() {
      const categoryData = await supabase.from('medteam_reference_categories').select('*');
      const complexityData = await supabase.from('reference_complexity').select('*');
      const difficultyData = await supabase.from('reference_questiondifficulty').select('*');
      setCategories(categoryData.data || []);
      setComplexities(complexityData.data || []);
      setDifficulties(difficultyData.data || []);
    }
    fetchInstitutions();
    fetchReferenceData();
  }, []);

  const fetchCurriculums = async (institutionId) => {
    const { data } = await supabase.from('medteam_curriculums').select('*').eq('institution_id', institutionId);
    setCurriculums(data || []);
    setYears([]);
    setClasses([]);
    setTopics([]);
  };

  const fetchYears = async (curriculumId) => {
    const { data } = await supabase.from('medteam_curriculum_years').select('*').eq('curriculum_id', curriculumId);
    setYears(data || []);
    setClasses([]);
    setTopics([]);
  };

  const fetchClasses = async (yearId) => {
    const { data } = await supabase
      .from('public.medteam_classes')
      .select('*')
      .eq('curriculum_year_id', yearId);
    setClasses(data || []);
    setTopics([]);
  };

  const fetchTopics = async (classId) => {
    const { data } = await supabase
      .from('public.medteam_class_topics')
      .select('*')
      .eq('class_id', classId);
    setTopics(data || []);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data, error } = await supabase.functions.invoke('generate_scenario_medteam_v1', {
        body: formData,
      });
      if (error) throw error;
      console.log('Scenario generated:', data);
    } catch (err) {
      console.error('Error generating scenario:', err.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="scenario-generator">
      <div className="page-header">
        <h1 className="page-title">Generate MedTeam Scenario</h1>
      </div>
      <div className="form-container">
        <div className="form-grid">
          <div className="form-group">
            <label>Institution</label>
            <select name="institution" value={formData.institution} onChange={(e) => {
              handleInputChange(e);
              fetchCurriculums(e.target.value);
            }}>
              <option value="">Select Institution</option>
              {institutions.map(inst => (
                <option key={inst.id} value={inst.id}>{inst.name}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Curriculum</label>
            <select name="curriculum" value={formData.curriculum} onChange={(e) => {
              handleInputChange(e);
              fetchYears(e.target.value);
            }}>
              <option value="">Select Curriculum</option>
              {curriculums.map(curr => (
                <option key={curr.id} value={curr.id}>{curr.name}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Year</label>
            <select name="curriculum_year" value={formData.curriculum_year} onChange={(e) => {
              handleInputChange(e);
              fetchClasses(e.target.value);
            }}>
              <option value="">Select Year</option>
              {years.map(year => (
                <option key={year.id} value={year.id}>{year.year_name}</option>
              ))}
            </select>
          </div>
           {/* Class Selection */}
           <div className="form-group">
            <label>Class</label>
            <select
              name="class"
              value={formData.class}
              onChange={(e) => {
                handleInputChange(e);
                fetchTopics(e.target.value);
              }}
            >
              <option value="">Select Class</option>
              {classes.map((cls) => (
                <option key={cls.id} value={cls.id}>
                  {cls.name}
                </option>
              ))}
            </select>
          </div>

          {/* Topic Selection */}
          <div className="form-group">
            <label>Topic</label>
            <select name="topic" value={formData.topic} onChange={handleInputChange}>
              <option value="">Select Topic</option>
              {topics.map((topic) => (
                <option key={topic.id} value={topic.id}>
                  {topic.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Initial Prompts</label>
            <textarea name="initial_prompts" value={formData.initial_prompts} onChange={handleInputChange}></textarea>
          </div>
          <div className="form-group">
            <label>Category</label>
            <select name="category" value={formData.category} onChange={handleInputChange}>
              <option value="">Select Category</option>
              {categories.map(cat => (
                <option key={cat.id} value={cat.id}>{cat.name}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Complexity</label>
            <select name="complexity" value={formData.complexity} onChange={handleInputChange}>
              <option value="">Select Complexity</option>
              {complexities.map(comp => (
                <option key={comp.id} value={comp.id}>{comp.name}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Question Difficulty</label>
            <select name="questiondifficulty" value={formData.questiondifficulty} onChange={handleInputChange}>
              <option value="">Select Difficulty</option>
              {difficulties.map(diff => (
                <option key={diff.id} value={diff.id}>{diff.name}</option>
              ))}
            </select>
          </div>
          <button className="action-button generate" onClick={handleSubmit} disabled={loading}>
            {loading ? <Loader2 className="animate-spin" /> : 'Generate Scenario'}
          </button>
        </div>
      </div>
    </div>
  );
}
