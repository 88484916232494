
import React from 'react';
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const MetricCard = ({ title, value, trend, trendLabel }) => (
  <div className="bg-white rounded-lg border border-gray-200">
    <div className="px-4 py-2 text-sm">
      {title}
      <div className="text-2xl font-semibold mt-1">{value}</div>
      {trend && (
        <div className={`text-xs mt-1 ${trend >= 0 ? 'text-green-600' : 'text-red-600'}`}>
          {trendLabel && <span className="text-gray-500">{trendLabel}: </span>}
          {trend > 0 ? '+' : ''}{trend}%
        </div>
      )}
    </div>
  </div>
);

const UserTable = ({ title, data, columns }) => (
  <div className="bg-white rounded-lg border border-gray-200">
    <div className="bg-[#004175] text-white px-4 py-2 font-medium rounded-t-lg">
      {title}
    </div>
    <div className="overflow-x-auto">
      <table className="min-w-full">
        <thead className="bg-[#004175] text-white">
          <tr>
            {columns.map((col) => (
              <th key={col.key} className="px-4 py-2 text-left text-sm font-medium">
                {col.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {data.map((row, idx) => (
            <tr key={idx} className="hover:bg-gray-50">
              {columns.map((col) => (
                <td key={col.key} className="px-4 py-2 text-sm">
                  {col.format ? col.format(row[col.key]) : row[col.key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <div className="px-4 py-2 border-t border-gray-200 text-sm text-gray-600">
      1-5 / {data.length}
    </div>
  </div>
);

const MemberMetrics = ({ data }) => {
  const metrics = React.useMemo(() => {
    if (!data) return null;
    
    const now = new Date();
    const sevenDaysAgo = new Date(now - 7 * 24 * 60 * 60 * 1000);
    
    const newMembers = data.filter(u => new Date(u.usercreated) >= sevenDaysAgo).length;
    const activeUsers = data.filter(u => u.last_seen_bucket === '72 Hours').length;
    
    return {
      newMembers: {
        value: newMembers,
        trend: 20.0,
        trendLabel: 'vs last week'
      },
      totalMembers: {
        value: data.length
      },
      activeUsers: {
        value: activeUsers,
        trend: 128.6
      },
      recentActivity: {
        value: data.filter(u => u.last_seen_bucket === '7 Days').length
      },
      recentlyCreated: data
        .filter(u => new Date(u.usercreated) >= sevenDaysAgo)
        .slice(0, 5),
      recentlyActive: data
        .filter(u => u.lastseen)
        .sort((a, b) => new Date(b.lastseen) - new Date(a.lastseen))
        .slice(0, 5),
      missingInfo: data.filter(u => !u.role || !u.county || !u.state).slice(0, 5)
    };
  }, [data]);

  if (!metrics) return null;

  const COLORS = ['#004175', '#6BA5C1', '#FF69B4', '#32CD32'];

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 bg-[#B7C9D3] p-4 rounded-lg">
        <MetricCard 
          title="New Members" 
          value={metrics.newMembers.value} 
          trend={metrics.newMembers.trend}
          trendLabel="vs last week"
        />
        <MetricCard 
          title="Total Members" 
          value={metrics.totalMembers.value}
        />
        <MetricCard 
          title="Active Members" 
          value={metrics.activeUsers.value}
          trend={metrics.activeUsers.trend}
        />
        <MetricCard 
          title="Recent Users"
          value={metrics.recentActivity.value}
        />
      </div>

      <UserTable
        title="Recently Created Users (past 7 days)"
        data={metrics.recentlyCreated}
        columns={[
          { key: 'fullName', label: 'Full Name' },
          { key: 'email', label: 'Email' },
          { key: 'county', label: 'County' },
          { key: 'state', label: 'State' },
          { 
            key: 'usercreated', 
            label: 'Created',
            format: (value) => new Date(value).toLocaleDateString()
          }
        ]}
      />

      <UserTable
        title="Recently Active Users"
        data={metrics.recentlyActive}
        columns={[
          { key: 'fullName', label: 'Full Name' },
          { key: 'email', label: 'Email' },
          { key: 'county', label: 'County' },
          { key: 'state', label: 'State' },
          { 
            key: 'lastseen', 
            label: 'Last Seen (Date)',
            format: (value) => new Date(value).toLocaleDateString()
          }
        ]}
      />

      <UserTable
        title="Users Missing Information"
        data={metrics.missingInfo}
        columns={[
          { key: 'email', label: 'Email' },
          { key: 'role', label: 'Role' },
          { key: 'solved_role', label: 'Solved Role' },
          { key: 'county', label: 'County' },
          { key: 'state', label: 'State' }
        ]}
      />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white rounded-lg border border-gray-200">
          <div className="bg-[#004175] text-white px-4 py-2 font-medium rounded-t-lg">
            Activity in Past Time Periods
          </div>
          <div className="p-4">
            <PieChart width={400} height={280}>
              <Pie
                data={[
                  { name: 'Beyond', value: 80.4 },
                  { name: '1 Month', value: 8.7 },
                  { name: '7 Days', value: 6.1 },
                  { name: '72 Hours', value: 4.8 }
                ]}
                cx={200}
                cy={140}
                labelLine={false}
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
                label={({ name, value }) => `${name} (${value}%)`}
              >
                {COLORS.map((color, index) => (
                  <Cell key={`cell-${index}`} fill={color} />
                ))}
              </Pie>
              <Tooltip />
              <Legend verticalAlign="bottom" height={36}/>
            </PieChart>
          </div>
        </div>

        <div className="bg-white rounded-lg border border-gray-200">
          <div className="bg-[#004175] text-white px-4 py-2 font-medium rounded-t-lg">
            User Streaks (Active and Longest)
          </div>
          <div className="p-4">
            <BarChart
              width={500}
              height={280}
              data={data.slice(0, 10)}
              margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey="fullName" 
                angle={-45} 
                textAnchor="end" 
                height={80}
                interval={0}
                fontSize={12}
              />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="streak" name="Current Streak" fill="#004175" />
              <Bar dataKey="longest_streak" name="Longest Streak" fill="#6BA5C1" />
            </BarChart>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberMetrics;