import { useState } from 'react';
import { Save, X, Trash2 } from 'lucide-react';

export default function InlineScenarioEditor({
  question,
  onSave,
  onCancel,
  onDelete,
  isLoading
}) {
  const [editedQuestion, setEditedQuestion] = useState({ ...question });

  const handleFieldChange = (field, value) => {
    setEditedQuestion(prev => ({
      ...prev,
      [field]: value
    }));
  };

  return (
    <tr className="bg-blue-50">
      <td className="sticky left-0 bg-blue-50 z-10 px-4 py-2 border">
        <div className="flex space-x-2">
          <button
            onClick={() => onSave(editedQuestion)}
            disabled={isLoading}
            className="px-2 py-1 bg-green-500 text-white rounded hover:bg-green-600 flex items-center gap-1 disabled:opacity-50 disabled:cursor-not-allowed"
            title="Save changes"
          >
            <Save className="h-4 w-4" />
            Save
          </button>
          <button
            onClick={onCancel}
            disabled={isLoading}
            className="px-2 py-1 bg-gray-100 text-gray-700 rounded hover:bg-gray-200 flex items-center gap-1"
            title="Cancel editing"
          >
            <X className="h-4 w-4" />
            Cancel
          </button>
          <button
            onClick={onDelete}
            disabled={isLoading}
            className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600 flex items-center gap-1"
            title="Delete question"
          >
            <Trash2 className="h-4 w-4" />
            Delete
          </button>
        </div>
      </td>
      <td className="px-4 py-2 border">
        <input
          type="number"
          value={editedQuestion.order || ''}
          onChange={(e) => handleFieldChange('order', parseInt(e.target.value, 10) || '')}
          className="w-20 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          min="1"
        />
      </td>
      <td className="px-4 py-2 border">
        <textarea
          value={editedQuestion.question || ''}
          onChange={(e) => handleFieldChange('question', e.target.value)}
          rows={3}
          className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 min-w-[200px]"
          placeholder="Enter question text"
        />
      </td>
      {[1, 2, 3, 4].map(num => (
        <td key={`option-${num}`} className="px-4 py-2 border">
          <textarea
            value={editedQuestion[`option${num}`] || ''}
            onChange={(e) => handleFieldChange(`option${num}`, e.target.value)}
            rows={3}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 min-w-[200px]"
            placeholder={`Enter option ${num}`}
          />
        </td>
      ))}
      <td className="px-4 py-2 border">
        <select
          value={editedQuestion.answer || ''}
          onChange={(e) => handleFieldChange('answer', parseInt(e.target.value, 10))}
          className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        >
          <option value="">Select</option>
          {[1, 2, 3, 4].map(num => (
            <option key={num} value={num}>
              {num}
            </option>
          ))}
        </select>
      </td>
      {[1, 2, 3, 4].map(num => (
        <td key={`explanation-${num}`} className="px-4 py-2 border">
          <textarea
            value={editedQuestion[`explanation${num}`] || ''}
            onChange={(e) => handleFieldChange(`explanation${num}`, e.target.value)}
            rows={3}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 min-w-[200px]"
            placeholder={`Enter explanation ${num}`}
          />
        </td>
      ))}
    </tr>
  );
}