


import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader2 } from 'lucide-react';
import './ScenarioGenerator.css';


import { useAuth } from '../../../../features/auth/hooks/useAuth';
import { useScenarioData } from '../../../../features/scenarios/hooks/useScenarioData';
import { useReferenceData } from '../../../../shared/hooks/useReferenceData';
import { supabase } from '../../../../lib/supabaseClient';


import ValidationResults from './ValidationResults';
import FeedbackDialog from './FeedbackDialog';
import { regenerateQuestion, logQuestionFeedback, editQuestionWithFeedback } from './questionUtils';
const DebugValue = ({ value, label }) => {
  return (
    <div style={{ margin: '8px', padding: '8px', border: '1px solid red' }}>
      <p><strong>{label || 'Debug'}:</strong></p>
      <pre>{typeof value === 'object' ? JSON.stringify(value, null, 2) : value}</pre>
    </div>
  );
};

const DEFAULT_VALUES = {
  scenario_topic: '',
  initial_prompts: '',
  context_details: '',
  category: '',
  diagnosiscategory: '',
  userrole: '',
  complexity: '',
  questiondifficulty: '',
  number_of_questions: 4,
  protocol_contents: [],
  training_designation: '', 
  ai_model: 'anthropic' 
};

const REQUIRED_FIELDS = ['scenario_topic', 'initial_prompts', 'category', 'diagnosiscategory', 'userrole', 'complexity', 'questiondifficulty', 'number_of_questions', 'protocol_contents'];

export default function ScenarioGenerator() {
  const navigate = useNavigate();
  
  
  
  const modalRef = useRef(null);
  const dialogContentRef = useRef(null);
  const saveInProgress = useRef(false);
  
  
  const { session, loading: authLoading } = useAuth();

  
  const [error, setError] = useState(null);
  const [touchedFields, setTouchedFields] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [progress, setProgress] = useState(0);
  const [formData, setFormData] = useState(DEFAULT_VALUES);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [generatedScenario, setGeneratedScenario] = useState(null);
  const [generatedQuestions, setGeneratedQuestions] = useState([]);
  const [showQuestionDialog, setShowQuestionDialog] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedQuestion, setEditedQuestion] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [validationStatus, setValidationStatus] = useState('Pending Review');
  const [validationResult, setValidationResult] = useState(null);
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [feedbackType, setFeedbackType] = useState(null);
  const [feedbackQuestion, setFeedbackQuestion] = useState(null);
  const [feedbackQuestionIndex, setFeedbackQuestionIndex] = useState(null);
  const [isProcessingFeedback, setIsProcessingFeedback] = useState(false);
  
  
  const [comparisonMode, setComparisonMode] = useState(false);
  const [comparing, setComparing] = useState(false);
  const [comparisonResults, setComparisonResults] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);

  
  const {
    protocols,
    displayedProtocols,
    categories,
    loading: dataLoading,
    error: dataError,
    searchTerm,
    setSearchTerm
  } = useScenarioData();

  const {
    complexity: complexityLevels,
    questiondifficulty: difficulties,
    roles,
    diagnosiscategory: diagnosisCategories,
    loading: referenceDataLoading,
  } = useReferenceData();
  
  
  const ScenarioService = {
    buildPrompt: (formData) => {
      let prompt = `Create a medical scenario about ${formData.scenario_topic || 'a medical emergency'} 
        with ${formData.number_of_questions || 4} multiple choice questions.
        Category: ${formData.category || 'General'}
        Diagnosis: ${formData.diagnosiscategory || 'Unknown'}
        User Role: ${formData.userrole || 'Paramedic'}
        Complexity: ${formData.complexity || 'Medium'}
        Question Difficulty: ${formData.questiondifficulty || 'Medium'}
        
        Initial Details: ${formData.initial_prompts || ''}
        Additional Context: ${formData.context_details || ''}
        
        IMPORTANT MULTIPLE CHOICE INSTRUCTIONS:
        - NEVER use options like "All of the above" or any combination of 2 or more options (such as "Both A and B").
        - Each option must be distinct and standalone, not referring to other options.
        - No question should have two right answers.
        - Each wrong answer should have a clear reason someone experienced would recognize.`;
      
      // Add EMT-specific instructions
      if (formData.userrole === 'EMT') {
        prompt += `
        
        ADDITIONAL EMT-SPECIFIC INSTRUCTIONS:
        - When the userrole is EMT, do not include any terminology or options that an EMT Basic would not be able to understand or do, such as starting an IV or administering glucagon.
        - If the option is wrong, in the explanation tell why the option was wrong, what is the correct answer, and why it is correct.
        - All questions and answers must be within the scope of practice for an EMT Basic.`;
      }
      
      return prompt;
    },
    
    getSystemPrompt: () => {
      return `You are a medical scenario generator for emergency medical services training.
        Create realistic scenarios with patient backgrounds, symptoms, and multiple choice questions.
        Provide detailed explanations for each answer option.
        Format the output as a valid JSON object.`;
    },
    
    extractBasicContent: (content, formData) => {
      if (!content) {
        console.error('Content is undefined in extractBasicContent');
        return {
          title: formData.scenario_topic || 'Medical Scenario',
          patientreport: 'Patient information not available',
          questions: [{
            question: 'Default question',
            option1: 'Option A',
            option2: 'Option B',
            option3: 'Option C',
            option4: 'Option D',
            answer: 1,
            explanation1: 'Explanation for Option A',
            explanation2: 'Explanation for Option B',
            explanation3: 'Explanation for Option C',
            explanation4: 'Explanation for Option D'
          }]
        };
      }
      
      const lines = content.split('\n');
      let title = '';
      let patientReport = '';
      const questions = [];
      
      
      for (let i = 0; i < lines.length; i++) {
        const line = lines[i].trim();
        
        if (!title && line.length > 5 && line.length < 100) {
          title = line;
          continue;
        }
        
        if (line.startsWith('Patient:') || line.startsWith('Scenario:')) {
          let j = i + 1;
          while (j < lines.length && !lines[j].startsWith('Question') && !lines[j].includes('?')) {
            patientReport += lines[j] + '\n';
            j++;
          }
          i = j - 1;
          continue;
        }
        
        if (line.startsWith('Question') || (line.includes('?') && !line.startsWith('Option'))) {
          const question = {
            question: line,
            option1: 'Option A',
            option2: 'Option B',
            option3: 'Option C',
            option4: 'Option D',
            answer: 1,
            explanation1: 'Explanation for Option A',
            explanation2: 'Explanation for Option B',
            explanation3: 'Explanation for Option C',
            explanation4: 'Explanation for Option D'
          };
          
          
          let j = i + 1;
          while (j < lines.length && j < i + 20) {
            const optLine = lines[j].trim();
            
            if (optLine.startsWith('A)') || optLine.startsWith('1)') || optLine.startsWith('Option A')) {
              question.option1 = optLine.substring(optLine.indexOf(')') + 1).trim();
            } else if (optLine.startsWith('B)') || optLine.startsWith('2)') || optLine.startsWith('Option B')) {
              question.option2 = optLine.substring(optLine.indexOf(')') + 1).trim();
            } else if (optLine.startsWith('C)') || optLine.startsWith('3)') || optLine.startsWith('Option C')) {
              question.option3 = optLine.substring(optLine.indexOf(')') + 1).trim();
            } else if (optLine.startsWith('D)') || optLine.startsWith('4)') || optLine.startsWith('Option D')) {
              question.option4 = optLine.substring(optLine.indexOf(')') + 1).trim();
            } else if (optLine.includes('Correct Answer') || optLine.includes('Answer:')) {
              const answerText = optLine.toLowerCase();
              if (answerText.includes('a') || answerText.includes('1')) question.answer = 1;
              else if (answerText.includes('b') || answerText.includes('2')) question.answer = 2;
              else if (answerText.includes('c') || answerText.includes('3')) question.answer = 3;
              else if (answerText.includes('d') || answerText.includes('4')) question.answer = 4;
            }
            
            j++;
          }
          
          questions.push(question);
          i = j - 1;
        }
      }
      
      return {
        title: title || `${formData.scenario_topic || 'Medical'} Scenario`,
        patientreport: patientReport || 'Patient information not available',
        question: questions.length > 0 ? questions : [{
          question: 'Default question',
          option1: 'Option A',
          option2: 'Option B',
          option3: 'Option C',
          option4: 'Option D',
          answer: 1,
          explanation1: 'Explanation for Option A',
          explanation2: 'Explanation for Option B',
          explanation3: 'Explanation for Option C',
          explanation4: 'Explanation for Option D'
        }]
      };
    },
    
    transformResponse: (rawContent, defaultTitle, formData) => {
      // Existing code
      const title = rawContent.title || defaultTitle;
      const finalTitle = formData.training_designation 
        ? `[${formData.training_designation}] ${title}`
        : title;
          
      // Add normalization to ensure text fields are strings, not objects
      const normalizeField = (value) => {
        if (value === null || value === undefined) return '';
        if (typeof value === 'object') {
          // Handle the specific case we're seeing
          if (value.option !== undefined) return String(value.option);
          if (value.explanation !== undefined) return String(value.explanation);
          // Generic object handling
          return JSON.stringify(value);
        }
        return String(value);
      };
        
      // Map questions and normalize all fields
      const questions = (rawContent.questions || []).map((q, index) => {
        return {
          question: normalizeField(q.question) || `Question ${index + 1}`,
          option1: normalizeField(q.option1 || q.options?.[0]) || 'Option A',
          option2: normalizeField(q.option2 || q.options?.[1]) || 'Option B',
          option3: normalizeField(q.option3 || q.options?.[2]) || 'Option C',
          option4: normalizeField(q.option4 || q.options?.[3]) || 'Option D',
          answer: q.answer || q.correctAnswer || 1,
          explanation1: normalizeField(q.explanation1 || q.explanations?.[0]) || 'Explanation for Option A',
          explanation2: normalizeField(q.explanation2 || q.explanations?.[1]) || 'Explanation for Option B',
          explanation3: normalizeField(q.explanation3 || q.explanations?.[2]) || 'Explanation for Option C',
          explanation4: normalizeField(q.explanation4 || q.explanations?.[3]) || 'Explanation for Option D',
          
          order: index + 1,
          sequence_order: index + 1
        };
      });
      
      // Continue with existing return
      return {
        title: finalTitle,
        scenarioid: rawContent.scenarioid || Date.now(),
        patientreport: normalizeField(rawContent.patientreport || rawContent.patientReport) || 'No patient report available',
        category: formData.category || 'General',
        diagnosiscategory: formData.diagnosiscategory || 'Unknown',
        complexity: formData.complexity || 'Medium',
        questiondifficulty: formData.questiondifficulty || 'Medium',
        model_used: formData.ai_model,
        question: questions
      };
    }
  };

  

  
  useEffect(() => {
    
    if (authLoading) {
      console.log("Auth still loading, waiting...");
      return;
    }
  
    console.log("Session check running, current session:", session);
    
    
    if (session === undefined) {
      console.log("Session is undefined, waiting for it to initialize");
      return;
    }
    
    
    if (!session) {
      console.log("No session found, redirecting to login");
      setError('Please log in to generate scenarios');
      navigate('/login', { replace: true });
      return;
    }
    
    if (!session.user || !session.user.email) {
      console.log("Session exists but missing user email:", session);
      setError('User session is invalid. Please log in again.');
      navigate('/login', { replace: true });
      return;
    }
    
    console.log("Valid session found for:", session.user.email);
  }, [session, authLoading, navigate]);

  
  useEffect(() => {
    if (showQuestionDialog && modalRef.current) {
      modalRef.current.focus();
    }
  }, [showQuestionDialog]);
  
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (showQuestionDialog) {
        if (e.key === 'ArrowLeft') {
          const prevIndex = currentQuestionIndex - 1;
          if (prevIndex >= 0) {
            setCurrentQuestionIndex(prevIndex);
            setCurrentQuestion(generatedQuestions[prevIndex]);
          }
        } else if (e.key === 'ArrowRight') {
          const nextIndex = currentQuestionIndex + 1;
          if (nextIndex < generatedQuestions.length) {
            setCurrentQuestionIndex(nextIndex);
            setCurrentQuestion(generatedQuestions[nextIndex]);
          }
        }
      }
    };
  
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [currentQuestionIndex, generatedQuestions, showQuestionDialog]);
  
  
  useEffect(() => {
    const checkFormValidity = () => {
      const isValid = REQUIRED_FIELDS.every(field => {
        if (field === 'protocol_contents') {
          return formData[field].length > 0;
        }
        return formData[field] !== '';
      });
      setIsFormValid(isValid);
    };
    checkFormValidity();
  }, [formData]);

  
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setTouchedFields(prev => ({ ...prev, [name]: true }));
  };

  const isFieldInvalid = (field) => {
    if (!touchedFields[field]) return false;
    
    if (field === 'protocol_contents') {
      return formData[field].length === 0;
    }
    return !formData[field];
  };

  const getFieldClassName = (field, type = 'input') => {
    const baseClass = `${type === 'select' ? 'form-select' : type === 'textarea' ? 'form-textarea' : 'form-input'}`;
    return `${baseClass} ${isFieldInvalid(field) ? 'form-field-invalid' : ''}`;
  };
  
  const getRequiredLabel = (label, isRequired = true) => (
    <label className={`form-label ${isFieldInvalid(label.toLowerCase()) ? 'form-label-invalid' : ''}`}>
      {label} {isRequired && <span className="required-field-indicator">*</span>}
    </label>
  );

  const handleProtocolToggle = (protocolId) => {
    setFormData(prev => {
      const isSelected = prev.protocol_contents.includes(protocolId);
      if (isSelected) {
        return {
          ...prev,
          protocol_contents: prev.protocol_contents.filter(id => id !== protocolId)
        };
      } else {
        return {
          ...prev,
          protocol_contents: [...prev.protocol_contents, protocolId]
        };
      }
    });
    setTouchedFields(prev => ({ ...prev, protocol_contents: true }));
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleResetForm = () => {
    setFormData(DEFAULT_VALUES);
    setSearchTerm('');
    setError(null);
    setGeneratedScenario(null);
    setGeneratedQuestions([]);
    setValidationResult(null);
    setValidationStatus('pending');
    setTouchedFields({});
    setComparisonMode(false);
    setComparisonResults(null);
  };

  const handleGenerateAnother = () => {
    setFormData(prev => ({
      ...prev,
      scenario_topic: '',
      initial_prompts: '',
      context_details: ''
    }));
    setError(null);
    setGeneratedScenario(null);
    setGeneratedQuestions([]);
    setValidationResult(null);
    setValidationStatus('pending');
    setComparisonMode(false);
    setComparisonResults(null);
  };

  

  
  const handleOpenFeedbackDialog = (type, index) => {
    setFeedbackType(type);
    setFeedbackQuestionIndex(index);
    setFeedbackQuestion(generatedQuestions[index]);
    setShowFeedbackDialog(true);
    setShowQuestionDialog(false); 
  };

  
  const handleFeedbackSubmit = async (feedback) => {
    if (!feedback.trim() || feedbackQuestionIndex === null || !feedbackType) {
      return;
    }

    try {
      setIsProcessingFeedback(true);
      
      
      const questionToUpdate = generatedQuestions[feedbackQuestionIndex];
      
      
      const updatedQuestions = [...generatedQuestions];
      
      if (feedbackType === 'edit') {
        
        const updatedQuestion = await editQuestionWithFeedback(
          generatedScenario?.scenarioid,
          feedbackQuestionIndex,
          questionToUpdate,
          feedback
        );
        
        
        updatedQuestions[feedbackQuestionIndex] = updatedQuestion;
        
        
        setIsEditMode(true);
        setEditedQuestion({...updatedQuestion});
        setCurrentQuestionIndex(feedbackQuestionIndex);
        setCurrentQuestion(updatedQuestion);
        
      } else if (feedbackType === 'regenerate') {
        
        updatedQuestions[feedbackQuestionIndex] = {
          ...updatedQuestions[feedbackQuestionIndex],
          status: 'regenerating'
        };
        setGeneratedQuestions(updatedQuestions);
        
        
        const newQuestion = await regenerateQuestion({
          scenarioId: generatedScenario?.scenarioid,
          questionIndex: feedbackQuestionIndex,
          feedback,
          formData,
          generatedScenario
        });
        
        
        const regeneratedQuestions = [...updatedQuestions];
        regeneratedQuestions[feedbackQuestionIndex] = newQuestion;
        setGeneratedQuestions(regeneratedQuestions);
        
        
        setCurrentQuestionIndex(feedbackQuestionIndex);
        setCurrentQuestion(newQuestion);
        
      } else if (feedbackType === 'delete') {
        
        updatedQuestions[feedbackQuestionIndex] = {
          ...updatedQuestions[feedbackQuestionIndex],
          status: 'deleted'
        };
        setGeneratedQuestions(updatedQuestions);
        
        
        await logQuestionFeedback(
          generatedScenario?.scenarioid,
          feedbackQuestionIndex,
          feedback,
          'delete'
        );
        
        
        if (feedbackQuestionIndex < generatedQuestions.length - 1) {
          setCurrentQuestionIndex(feedbackQuestionIndex + 1);
          setCurrentQuestion(generatedQuestions[feedbackQuestionIndex + 1]);
        } else {
          setShowQuestionDialog(false);
        }
      }
      
    } catch (error) {
      console.error(`Error processing ${feedbackType} feedback:`, error);
      setError(`Failed to process feedback: ${error.message}`);
    } finally {
      setIsProcessingFeedback(false);
      setShowFeedbackDialog(false);
      setFeedbackType(null);
      setFeedbackQuestionIndex(null);
      setFeedbackQuestion(null);
      
      
      if (feedbackType !== 'delete') {
        setShowQuestionDialog(true);
      }
    }
  };

  

  
  const extractLocationTag = async (protocol_contents) => {
    if (!protocol_contents || protocol_contents.length === 0) {
      return null;
    }
    
    try {
      
      const { data, error } = await supabase
        .from('protocols')
        .select('title')
        .eq('id', protocol_contents[0])
        .single();
      
      if (error || !data) {
        console.error('Error fetching protocol:', error);
        return null;
      }
      
      const title = data.title || '';
      if (!title) return null;
      
      
      const firstWord = title.split(' ')[0];
      
      
      if (firstWord.length <= 3 || /^[A-Z]{2,3}$/.test(firstWord)) {
        return firstWord;
      }
      
      
      return title.substring(0, 2);
      
    } catch (error) {
      console.error('Failed to extract location tag:', error);
      return null;
    }
  };

  
  const calculateAvgExplanationLength = (scenario) => {
    if (!scenario?.questions || scenario.question.length === 0) {
      return 0;
    }
    
    const totalLength = scenario.question.reduce((sum, q) => {
      return sum + 
        (q.explanation1?.length || 0) +
        (q.explanation2?.length || 0) +
        (q.explanation3?.length || 0) +
        (q.explanation4?.length || 0);
    }, 0);
    
    return Math.round(totalLength / (scenario.question.length * 4));
  };
  
  
  const getNextScenarioId = async () => {
    try {
      
      const { data, error } = await supabase
        .from('scenarios')
        .select('scenarioid')
        .order('scenarioid', { ascending: false })
        .limit(1);
        
      if (error) throw error;
      
      let nextId = 1; 
      
      if (data && data.length > 0 && data[0].scenarioid) {
        nextId = data[0].scenarioid + 1;
        console.log(`Found maximum scenarioid: ${data[0].scenarioid}, next id will be: ${nextId}`);
      }
      
      
      if (nextId === 2989) {
        console.log("Detected problematic ID 2989, skipping to 2990");
        nextId = 2990;
      }
      
      return nextId;
    } catch (error) {
      console.error('Error getting next scenario ID:', error);
      
      return 5000 + Math.floor(Math.random() * 1000);
    }
  };
  
  


  const updateScenarioInDatabase = async (scenarioId, scenarioData, model) => {
    try {
      // Ensure question is an array before processing
      if (!scenarioData.question || !Array.isArray(scenarioData.question) || scenarioData.question.length === 0) {
        throw new Error('At least one question is required');
      }
      
      // Get common data
      const createdBy = session?.user?.email || formData?.userEmail || "jim@solvedhealth.ai";
      console.log('Using created_by value:', createdBy);
      
      // First check for existing records with this scenarioId and delete them
      const { data: existingRecords } = await supabase
        .from('scenarios')
        .select('id')
        .eq('scenarioid', scenarioId);
        
      if (existingRecords && existingRecords.length > 0) {
        console.log(`Found ${existingRecords.length} existing records with scenarioId ${scenarioId}, deleting them first`);
        await supabase
          .from('scenarios')
          .delete()
          .eq('scenarioid', scenarioId);
      }
      
      // Create an array of records with strictly only fields from the schema
      const scenarioRecords = scenarioData.question.map((questionData, index) => {
        // Create a record with only fields we know are in the schema
        const record = {
          // Required non-null fields
          scenarioid: scenarioId,
          order: index + 1,
          created_by: createdBy,
          
          // Question data
          question: questionData.question || `Question ${index + 1}`,
          option1: questionData.option1 || 'Option A',
          option2: questionData.option2 || 'Option B',
          option3: questionData.option3 || 'Option C',
          option4: questionData.option4 || 'Option D',
          answer: questionData.answer || 1,
          explanation1: questionData.explanation1 || '',
          explanation2: questionData.explanation2 || '',
          explanation3: questionData.explanation3 || '',
          explanation4: questionData.explanation4 || '',
          
          // Scenario data
          title: scenarioData.title || `Medical Scenario ${scenarioId}`,
          patientreport: scenarioData.patientreport || '',
          category: formData.category || 'General',
          diagnosiscategory: formData.diagnosiscategory || 'General',
          complexity: formData.complexity || 'Medium',
          questiondifficulty: parseInt(formData.questiondifficulty) || 5,
          userrole: formData.userrole || 'Paramedic',
          status: "draft",
          
          // Additional data
          sequence_order: index + 1,
          scenario_topic: formData.scenario_topic || '',
          initial_prompts: formData.initial_prompts || '',
          context_details: formData.context_details || '',
          
          // Date fields
          dateadded: new Date().toISOString().split('T')[0], // YYYY-MM-DD
          updated_at: new Date().toISOString(),
        };
        
        // Return only fields that exist in the schema
        return record;
      });
      
      console.log(`Saving ${scenarioRecords.length} question records for scenario ID ${scenarioId}`);
      
      // Insert records one by one
      let successCount = 0;
      
      for (let i = 0; i < scenarioRecords.length; i++) {
        try {
          console.log(`Inserting record ${i+1}/${scenarioRecords.length}`);
          
          const { data, error } = await supabase
            .from('scenarios')
            .insert(scenarioRecords[i]);
            
          if (error) {
            console.error(`Error inserting record ${i+1}:`, error);
          } else {
            successCount++;
            console.log(`Successfully inserted record ${i+1}`);
          }
        } catch (err) {
          console.error(`Exception inserting record ${i+1}:`, err);
        }
      }
      
      console.log(`Successfully inserted ${successCount}/${scenarioRecords.length} records`);
      
      if (successCount === 0) {
        throw new Error('Failed to insert any scenario records');
      }
      
      return true;
    } catch (error) {
      console.error('Error updating scenario in database:', error);
      throw error;
    }
  };

  // New function to store the complete scenario in the scenario_generations_v3 table
  const storeCompleteScenario = async (scenarioId, scenarioData, model, createdBy) => {
    try {
      const generationRecord = {
        scenarioid: scenarioId,
        title: scenarioData.title,
        content: scenarioData, // Store the complete data
        model_used: model,
        created_by: createdBy,
        created_at: new Date().toISOString()
      };
      
      const { error } = await supabase
        .from('scenario_generations_v3')
        .upsert(generationRecord);
        
      if (error) {
        console.error('Error storing complete scenario:', error);
        throw error;
      }
      
      return true;
    } catch (error) {
      console.error('Error in storeCompleteScenario:', error);
      // Don't throw here, we don't want to fail the main operation if this fails
      return false;
    }
  };
  
  
  const handleComparisonGenerate = async (e) => {
    e.preventDefault();
    
    try {
      
      const { data } = await supabase.auth.getSession();
      const userEmail = data?.session?.user?.email || session?.user?.email;
      
      if (!userEmail) {
        console.log("No valid user email found, redirecting to login");
        setError('Please log in to generate scenarios');
        navigate('/login', { replace: true });
        return;
      }
      
      
      if (loading || comparing || !isFormValid) {
        console.log('Form is invalid or processing is in progress');
        return;
      }

      setComparing(true);
      setError(null);
      
      try {
        
        const baseRequest = {
          ...formData,
          userEmail: userEmail
        };
        
        
        const scenarioId = await getNextScenarioId();
        
        
        const prompt = ScenarioService.buildPrompt(formData);
        const systemPrompt = ScenarioService.getSystemPrompt();
        
        
        console.log('Generating scenarios with both models for comparison');
        
        
        const anthropicPromise = supabase.functions.invoke(
          'generate-scenario-anthropic',
          {
            body: { 
              formData: {
                ...baseRequest,
                ai_model: 'anthropic'
              },
              prompt,
              systemPrompt,
              scenarioId,
              forceUniqueId: true
            }
          }
        );
        
        
        const geminiPromise = supabase.functions.invoke(
          'generate-scenario-anthropic',
          {
            body: { 
              formData: {
                ...baseRequest,
                ai_model: 'gemini'
              },
              prompt,
              systemPrompt,
              scenarioId: scenarioId + 1, 
              avoidId: 2989,  
              forceUniqueId: true
            }
          }
        );
        
        
        const [anthropicResponse, geminiResponse] = await Promise.all([
          anthropicPromise,
          geminiPromise
        ]);
        
        
        let anthropicResult;
        if (anthropicResponse.error) {
          anthropicResult = { error: anthropicResponse.error.message };
        } else {
          const content = anthropicResponse.data.content;
          
          let rawContent;
          try {
            
            const jsonStart = content.indexOf('{');
            const jsonEnd = content.lastIndexOf('}');
            let jsonString = content;
            
            if (jsonStart >= 0 && jsonEnd > jsonStart) {
              jsonString = content.slice(jsonStart, jsonEnd + 1);
            }
            
            jsonString = jsonString.replace(/[\u0000-\u001F\u007F-\u009F]/g, '');
            rawContent = JSON.parse(jsonString);
          } catch (parseError) {
            console.error('Anthropic JSON parse failed:', parseError);
            rawContent = ScenarioService.extractBasicContent(content, formData);
          }
          
          
          const scenarioData = ScenarioService.transformResponse(
            rawContent, 
            formData.scenario_topic || 'Medical Scenario', 
            formData
          );
        }
          
        
        let geminiResult;
        if (geminiResponse.error) {
          geminiResult = { error: geminiResponse.error.message };
        } else {
          const content = geminiResponse.data.content;
          
          let rawContent;
          try {
            
            const jsonStart = content.indexOf('{');
            const jsonEnd = content.lastIndexOf('}');
            let jsonString = content;
            
            if (jsonStart >= 0 && jsonEnd > jsonStart) {
              jsonString = content.slice(jsonStart, jsonEnd + 1);
            }
            
            jsonString = jsonString.replace(/[\u0000-\u001F\u007F-\u009F]/g, '');
            rawContent = JSON.parse(jsonString);
          } catch (parseError) {
            console.error('Gemini JSON parse failed:', parseError);
            rawContent = ScenarioService.extractBasicContent(content, formData);
          }
          
          
          geminiResult = ScenarioService.transformResponse(
            rawContent, 
            formData.scenario_topic || 'Medical Scenario', 
            { ...formData, ai_model: 'gemini' }
          );
        }
        
        
        setComparisonResults({
          anthropic: anthropicResult,
          gemini: geminiResult
        });
        
        
        setComparisonMode(true);
        
      } catch (genErr) {
        console.error('Error in comparison generation:', genErr);
        if (genErr.message.includes('Gemini API error') || 
            genErr.message.includes('GoogleGenerativeAI Error') || 
            genErr.message.includes('gemini-pro')) {
          setError(`Google Gemini API is currently unavailable. Please try using only the Anthropic model for now.`);
        } else {
          setError(`Failed to generate comparison: ${genErr.message}`);
        }
      } finally {
        setComparing(false);
      }
    } catch (sessionErr) {
      console.error("Session verification error:", sessionErr);
      setError('Authentication error. Please log in again.');
      navigate('/login', { replace: true });
    }
  };

  
  const handleSelectScenario = (scenario) => {
    
    setComparisonMode(false);
    
    setComparisonMode(false);
    
    
    setGeneratedScenario(scenario);
    setGeneratedQuestions(scenario.question || []);
    setCurrentQuestion(scenario.question?.[0] || null);
    setCurrentQuestionIndex(0);
    
    
    setFormData(prev => ({
      ...prev,
      ai_model: scenario.model_used
    }));
    
    
    setSelectedModel(scenario.model_used);
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Check authentication first
      const { data } = await supabase.auth.getSession();
      console.log("Session check:", data?.session ? "Valid" : "Invalid");
      
      // Get user email from session
      const userEmail = data?.session?.user?.email || session?.user?.email;
      
      if (!userEmail) {
        console.log("No valid user email found, redirecting to login");
        setError('Please log in to generate scenarios');
        navigate('/login', { replace: true });
        return;
      }
      
      // Validate form state
      if (loading || isGenerating) {
        console.log('Processing already in progress:', { loading, isGenerating });
        return;
      }
      
      if (!isFormValid) {
        console.log('Form validation failed');
        
        // Check which fields are invalid
        const invalidFields = REQUIRED_FIELDS.filter(field => {
          if (field === 'protocol_contents') {
            return formData[field].length === 0;
          }
          return formData[field] === '';
        });
        
        setError(`Please complete the following required fields: ${invalidFields.join(', ')}`);
        return;
      }
      
      // Set initial state for generation
      setIsGenerating(true);
      setLoading(true);
      setError(null);
      setProgress(10); // Start progress at 10%
      setGeneratedQuestions([]);
      setCurrentQuestionIndex(0);
      setValidationStatus('Pending');
      setValidationResult(null);
  
      // Test Supabase connection with a simple query
      try {
        const { data: testData, error: testError } = await supabase
          .from('protocols')
          .select('id')
          .limit(1);
          
        if (testError) {
          console.error('Supabase test connection failed:', testError);
          throw new Error('Database connection test failed. Please try again.');
        }
        
        console.log('Supabase connection test successful');
        setProgress(20); // Update progress
      } catch (connectionErr) {
        throw new Error(`Connection error: ${connectionErr.message}`);
      }
      
      // Build prompt and get scenario ID
      const prompt = ScenarioService.buildPrompt(formData);
      const systemPrompt = ScenarioService.getSystemPrompt();
      const scenarioId = await getNextScenarioId();
      
      // Prepare request data
      const enhancedRequest = {
        ...formData,
        userEmail: userEmail
      };
  
      console.log(`Generating scenario using ${formData.ai_model} model`);
      setProgress(30); // Update progress
      
      // Determine endpoint based on model
      const endpoint = formData.ai_model === 'anthropic' 
        ? 'generate-scenario-anthropic'  
        : 'generate-scenario-anthropic'; 
  
      console.log('Using endpoint:', endpoint);
      
      // Set a timeout for the function call
      const timeoutPromise = new Promise((_, reject) => 
        setTimeout(() => reject(new Error('Request timed out after 60 seconds')), 60000)
      );
      
      // Make the API call with timeout
      let generatedData;
      let generationError;
      
      try {
        setProgress(40); // Update progress before API call
        
        const result = await Promise.race([
          supabase.functions.invoke(endpoint, {
            body: { 
              formData: enhancedRequest,
              prompt,
              systemPrompt,
              scenarioId,
              avoidId: 2989,  
              forceUniqueId: true
            }
          }),
          timeoutPromise
        ]);
        
        generatedData = result.data;
        generationError = result.error;
        
        setProgress(70); // Update progress after API call
      } catch (apiError) {
        console.error('API call failed or timed out:', apiError);
        throw new Error(`Request failed: ${apiError.message}`);
      }
  
      // Handle API errors
      if (generationError) {
        if (formData.ai_model === 'gemini' && generationError.message && 
            (generationError.message.includes('GoogleGenerativeAI Error') || 
            generationError.message.includes('gemini-pro'))) {
          throw new Error('Gemini API error. Please try using the Anthropic model instead.');
        }
        throw generationError;
      }
      
      // Validate response structure
      if (!generatedData?.success) {
        throw new Error(generatedData?.details || 'Generation failed');
      }
      
      if (!generatedData || !generatedData.data) {
        console.error('Invalid response structure:', generatedData);
        throw new Error('Invalid response from generation service');
      }
      
      // Extract content from response
      const content = generatedData.data.content;
      
      if (!content) {
        console.error('No content in response:', generatedData);
        throw new Error('No content received from generation service. Please try again.');
      }
      
      setProgress(80); // Update progress
      
      // Parse content to extract structured data
      let rawContent;
      try {
        // Try to find JSON object in response
        const jsonStart = content.indexOf('{');
        const jsonEnd = content.lastIndexOf('}');
        let jsonString = content;
        
        if (jsonStart >= 0 && jsonEnd > jsonStart) {
          jsonString = content.slice(jsonStart, jsonEnd + 1);
          console.log('Found JSON content');
        } else {
          console.log('No JSON object detected, using fallback parsing');
        }
        
        // Clean string of control characters
        jsonString = jsonString.replace(/[\u0000-\u001F\u007F-\u009F]/g, '');
        
        try {
          // Parse JSON
          rawContent = JSON.parse(jsonString);
          console.log('Successfully parsed JSON content');
        } catch (jsonError) {
          console.error('JSON parsing failed:', jsonError);
          
          // Use fallback content extractor
          rawContent = ScenarioService.extractBasicContent(content, formData);
          console.log('Used fallback content extractor');
        }
      } catch (parseError) {
        console.error('Content processing failed:', parseError);
        
        // Use fallback content extractor
        rawContent = ScenarioService.extractBasicContent(content, formData);
        console.log('Used fallback content extractor after parsing error');
      }
      
      // Ensure we have questions
      if (!rawContent.questions || !Array.isArray(rawContent.questions) || rawContent.questions.length === 0) {
        console.error('No valid questions in content, adding default question');
        rawContent.questions = [{
          question: `Question about ${formData.scenario_topic || 'medical scenario'}`,
          option1: 'Option A',
          option2: 'Option B',
          option3: 'Option C',
          option4: 'Option D',
          answer: 1,
          explanation1: 'Explanation for Option A',
          explanation2: 'Explanation for Option B',
          explanation3: 'Explanation for Option C',
          explanation4: 'Explanation for Option D'
        }];
      }
      
      setProgress(85); // Update progress
      
      // Transform raw content into structured scenario data
      const scenarioData = ScenarioService.transformResponse(
        rawContent, 
        formData.scenario_topic || 'Medical Scenario', 
        formData
      );
      
      // IMPORTANT: Override any timestamp scenarioId with the correct integer ID
      scenarioData.scenarioid = scenarioId; // Use the integer ID we generated earlier
      
      // Save to database
      try {
        await updateScenarioInDatabase(scenarioId, scenarioData, formData.ai_model);
        console.log('Scenario saved to database');
      } catch (dbError) {
        console.error('Database save error:', dbError);
        // Continue processing even if save fails
      }
      
      // Update state with generated scenario - now with correct ID
      setGeneratedScenario({
        ...scenarioData,
        scenarioid: scenarioId, // Ensure correct ID is used
        model_used: formData.ai_model
      });
      
      // Extract location tag for questions
      const locationTag = await extractLocationTag(formData.protocol_contents);
      console.log('Extracted location tag:', locationTag);
      
      setProgress(90); // Update progress
      
      // Process questions for display
      let processedQuestions = [];
      
      // Defensive checks for where to get questions from
      let questionsToProcess = [];
      
      // First check the question field from transformed data
      if (scenarioData.question && Array.isArray(scenarioData.question)) {
        questionsToProcess = scenarioData.question;
      } 
      // Then check the questions field in case it wasn't transformed
      else if (scenarioData.questions && Array.isArray(scenarioData.questions)) {
        questionsToProcess = scenarioData.questions;
      } 
      // Finally fall back to a default if needed
      else {
        console.warn('No valid questions found in scenario data, creating default');
        questionsToProcess = [{
          question: `Question about ${formData.scenario_topic || 'medical scenario'}`,
          option1: 'Option A',
          option2: 'Option B',
          option3: 'Option C',
          option4: 'Option D',
          answer: 1,
          explanation1: 'Explanation for Option A',
          explanation2: 'Explanation for Option B',
          explanation3: 'Explanation for Option C',
          explanation4: 'Explanation for Option D'
        }];
      }
      
      // Now safely map over the questions array
      processedQuestions = questionsToProcess.map((question, index) => {
        // Create tags for question
        const tags = [
          formData.userrole ? `#${formData.userrole}` : '#Paramedic'
        ];
        
        // Add location tag if available
        if (locationTag) {
          tags.push(`#${locationTag}`);
        }
        
        const formattedTags = JSON.stringify(tags);
        
        return {
          ...question,
          patientreport: scenarioData.patientreport,
          title: scenarioData.title,
          status: 'pending_validation',
          tags: formattedTags, 
          order: index + 1,
          sequence_order: index + 1 
        };
      });
      
      // Update state with generated scenario
      setGeneratedScenario({
        ...scenarioData,
        model_used: formData.ai_model
      });
      
      // Set selected model
      setSelectedModel(formData.ai_model);
      
      // Complete generation process
      setProgress(100); // Update progress to 100%
      setValidationStatus('completed'); 
      setValidationResult({ isValid: true }); 
      
      // Update UI with generated questions
      setGeneratedQuestions(processedQuestions);
      setCurrentQuestion(processedQuestions[0]);
      setShowQuestionDialog(true);
      
    } catch (error) {
      // Handle any errors
      console.error('Error in scenario generation:', error);
      
      // Set appropriate error message based on error type
      if (error.message.includes('Gemini API error')) {
        setError(`${error.message} The Gemini API may be temporarily unavailable.`);
      } else {
        setError(`Failed to generate scenario: ${error.message}`);
      }
      
      // Reset state on error
      setGeneratedScenario(null);
      setGeneratedQuestions([]);
      setCurrentQuestion(null);
      setCurrentQuestionIndex(0);
      setValidationResult(null);
    } finally {
      // Always reset loading states
      setLoading(false);
      setProgress(0);
      setIsGenerating(false);
    }
  };
  

  const handleValidation = async (scenarioid) => {
    try {
      setValidationStatus('inProgress');
      
      const { data: validationData, error: validationError } = await supabase.functions.invoke(
        'validate-scenario',
        {
          body: { scenarioid }
        }
      );
  
      if (validationError) throw validationError;
      
      setValidationResult(validationData.data);
      setValidationStatus('completed');
      
      if (!validationData.data.isValid) {
        setError('Validation completed with issues. Please review the validation results.');
      }
      
      return validationData;
    } catch (err) {
      console.error('Validation error:', err);
      setValidationStatus('failed');
      setError('Validation failed: ' + err.message);
      return null;
    }
  };

  const handleQuestionReview = async (action) => {
    if (isSaving || saveInProgress.current) {
      console.log('Save already in progress, preventing duplicate action');
      return;
    }
  
    if (action === 'cancel') {
      setShowQuestionDialog(false);
      return;
    }
  
    const updatedQuestions = [...generatedQuestions];
    
    if (action === 'approve') {
      updatedQuestions[currentQuestionIndex] = {
        ...updatedQuestions[currentQuestionIndex],
        status: 'Pending Review'
      };
      setGeneratedQuestions(updatedQuestions);
      
      const isLastQuestion = currentQuestionIndex === formData.number_of_questions - 1;
      
      if (!isLastQuestion) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setCurrentQuestion(updatedQuestions[currentQuestionIndex + 1]);
      } else {
        setShowQuestionDialog(false);
        
        
        setIsSaving(true);
        saveInProgress.current = true;
        
        try {
          console.log('Save state activated, proceeding with save...');
          const questionsToSave = updatedQuestions.filter(q => q.status !== 'deleted');
          await finalizeScenario(questionsToSave);
        } catch (error) {
          console.error('Error saving scenario:', error);
          setError('Failed to save scenario: ' + error.message);
        } finally {
          setIsSaving(false);
          saveInProgress.current = false;
        }
      }
    } else if (action === 'edit') {
      
      handleOpenFeedbackDialog('edit', currentQuestionIndex);
    } else if (action === 'regenerate') {
      
      handleOpenFeedbackDialog('regenerate', currentQuestionIndex);
    } else if (action === 'delete') {
      
      handleOpenFeedbackDialog('delete', currentQuestionIndex);
    }
  };

  const finalizeScenario = async (questions) => {
    console.log('finalizeScenario called, isSaving state:', isSaving, 'saveInProgress:', saveInProgress.current);
    
    if (!isSaving && !saveInProgress.current) {
      console.log('Not in saving state, preventing save');
      return;
    }
  
    try {
      console.log('Proceeding with scenario finalization...');
      setProgress(90);
      
      // Get the integer scenarioId
      const scenarioId = generatedScenario.scenarioid;
      
      // Ensure it's a valid integer
      if (typeof scenarioId !== 'number' || scenarioId > 1000000000) {
        console.error('ScenarioId appears to be invalid:', scenarioId);
        throw new Error('Invalid scenarioId');
      }
      
      // Update all scenario records for this scenarioId
      const { error } = await supabase
        .from('scenarios')
        .update({ status: 'published' })
        .eq('scenarioid', scenarioId);
        
      if (error) {
        console.error('Database update error details:', error);
        throw error;
      }
      
      console.log(`Successfully updated status for all questions with scenarioId ${scenarioId}`);
      
    } catch (err) {
      console.error('Error in finalizeScenario:', err);
      setError('Failed to save scenario: ' + err.message);
    } finally {
      setIsSaving(false);
      saveInProgress.current = false;
      setLoading(false);
      setProgress(0);
    }
  };

  

  
  if (authLoading || dataLoading || referenceDataLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loader2 className="animate-spin h-8 w-8 text-blue-600" />
      </div>
    );
  }

  return (
    <div className="scenario-generator"> 
      {}
      <div className="page-header">
        <h1 className="page-title">Generate Scenario</h1>
      </div>

      {}
      <div className="main-content">
        {error && (
          <div className="bg-red-50 border border-red-500 text-red-700 p-4 rounded-lg mb-6">
            {error}
          </div>
        )}

        <div className="form-container">
          <div className="form-grid">
            {}
            <div className="protocols-section">
              <div className="protocols-header">
                {getRequiredLabel('Select Reference Protocols')}
                <p className={`protocols-subtitle ${
                  isFieldInvalid('protocol_contents') ? 'text-red-500' : ''
                }`}>
                  Choose at least one protocol to guide scenario generation
                </p>
              </div>

              <div className="protocols-search">
                <input
                  type="text"
                  placeholder="Search protocols..."
                  className={`form-input ${
                    isFieldInvalid('protocol_contents') ? 'border-red-500' : ''
                  }`}
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>

              <div className={`protocols-list ${
                isFieldInvalid('protocol_contents') ? 'border-red-500' : ''
              }`}>
                {displayedProtocols.map(protocol => (
                  <div key={protocol.id} className="protocol-item">
                    <label className="flex items-start gap-2">
                      <input
                        type="checkbox"
                        checked={formData.protocol_contents.includes(protocol.id)}
                        onChange={() => handleProtocolToggle(protocol.id)}
                        className="mt-1"
                      />
                      <div>
                        <div className="font-medium">{protocol.title}</div>
                        <div className="text-sm text-gray-600">
                          {protocol.content.slice(0, 100)}
                          {protocol.content.length > 100 ? '...' : ''}
                        </div>
                      </div>
                    </label>
                  </div>
                ))}
              </div>
            </div>
            {}
            <div className="form-fields">
              {}
              <div className="form-group">
                <label className="form-label">AI Model</label>
                <div className="flex gap-4 mt-2">
                  <label className="flex items-center cursor-pointer">
                    <input
                      type="radio"
                      name="ai_model"
                      value="gemini"
                      checked={formData.ai_model === 'gemini'}
                      onChange={handleInputChange}
                      className="mr-2"
                      disabled={true} 
                    />
                    <span className="text-gray-500">Google Gemini (v5) - Currently Unavailable</span>
                  </label>
                  <label className="flex items-center cursor-pointer">
                    <input
                      type="radio"
                      name="ai_model"
                      value="anthropic"
                      checked={formData.ai_model === 'anthropic'}
                      onChange={handleInputChange}
                      className="mr-2"
                    />
                    <span>Anthropic Claude (v4)</span>
                  </label>
                </div>
                <p className="text-sm text-gray-500 mt-1">
                  Select which AI model to use for generating scenarios
                </p>
                {}
                <p className="text-sm text-orange-600 mt-1">
                  Note: Google Gemini API is currently unavailable. Please use Anthropic Claude.
                </p>
              </div>

              {}
              <div className="form-group">
                {getRequiredLabel('Scenario Topic')}
                <input
                  type="text"
                  id="scenario_topic"
                  name="scenario_topic"
                  value={formData.scenario_topic}
                  onChange={handleInputChange}
                  className={getFieldClassName('scenario_topic')}
                  required
                />
              </div>

              {}
              <div className="form-group">
                <label htmlFor="training_designation">Training Designation (Optional)</label>
                <input
                  type="text"
                  id="training_designation"
                  name="training_designation"
                  value={formData.training_designation}
                  onChange={handleInputChange}
                  className="form-input"
                  placeholder="e.g., ACLS, PALS, BLS, etc."
                />
                <p className="text-sm text-gray-500 mt-1">
                  This will be added as a prefix to the scenario title
                </p>
              </div>

              {}
              <div className="form-group">
                {getRequiredLabel('Initial Prompts')}
                <textarea
                  id="initial_prompts"
                  name="initial_prompts"
                  value={formData.initial_prompts}
                  onChange={handleInputChange}
                  className={getFieldClassName('initial_prompts', 'textarea')}
                  rows="4"
                  placeholder="Enter initial prompts here..."
                  required
                />
              </div>

              {}
              <div className="form-group">
                {getRequiredLabel('Category')}
                <select
                  id="category"
                  name="category"
                  value={formData.category}
                  onChange={handleInputChange}
                  className={getFieldClassName('category', 'select')}
                  required
                >
                  <option value="">Select Category</option>
                  {categories.map(category => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>

              {}
              <div className="form-group">
                {getRequiredLabel('Diagnosis Category')}
                <select
                  id="diagnosiscategory"
                  name="diagnosiscategory"
                  value={formData.diagnosiscategory}
                  onChange={handleInputChange}
                  className={getFieldClassName('diagnosiscategory', 'select')}
                  required
                >
                  <option value="">Select Diagnosis Category</option>
                  {diagnosisCategories.map(category => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>

              {}
              <div className="form-group">
                {getRequiredLabel('User Role')}
                <select
                  id="userrole"
                  name="userrole"
                  value={formData.userrole}
                  onChange={handleInputChange}
                  className={getFieldClassName('userrole', 'select')}
                  required
                >
                  <option value="">Select Role</option>
                  {roles.map(role => (
                    <option key={role} value={role}>{role}</option>
                  ))}
                </select>
              </div>
              
              {}
              <div className="form-group">
                {getRequiredLabel('Complexity')}
                <select
                  id="complexity"
                  name="complexity"
                  value={formData.complexity}
                  onChange={handleInputChange}
                  className={getFieldClassName('complexity', 'select')}
                  required
                >
                  <option value="">Select Complexity</option>
                  {complexityLevels.map(level => (
                    <option key={level} value={level}>{level}</option>
                  ))}
                </select>
              </div>

              {}
              <div className="form-group">
                {getRequiredLabel('Question Difficulty')}
                <select
                  id="questiondifficulty"
                  name="questiondifficulty"
                  value={formData.questiondifficulty}
                  onChange={handleInputChange}
                  className={getFieldClassName('questiondifficulty', 'select')}
                  required
                >
                  <option value="">Select Difficulty</option>
                  {difficulties.map(diff => (
                    <option key={diff.level} value={diff.level}>
                      {diff.display_name}
                    </option>
                  ))}
                </select>
              </div>

              {}
              <div className="form-group">
                {getRequiredLabel('Number of Questions')}
                <input
                  type="number"
                  id="number_of_questions"
                  name="number_of_questions"
                  min="1"
                  max="10"
                  value={formData.number_of_questions}
                  onChange={handleInputChange}
                  className={getFieldClassName('number_of_questions')}
                  required
                />
              </div>

              {}
              <div className="form-group">
                <label htmlFor="context_details">Context Details (Optional)</label>
                <textarea
                  id="context_details"
                  name="context_details"
                  value={formData.context_details}
                  onChange={handleInputChange}
                  className="form-textarea w-full"
                  rows="4"
                  placeholder="Enter additional context here..."
                />
              </div>

              {}
              <div className="action-buttons">
                <button
                  className="action-button generate"
                  onClick={handleSubmit}
                  disabled={loading || !isFormValid || comparing}
                >
                  {loading ? (
                    <span className="flex items-center">
                      <Loader2 className="animate-spin -ml-1 mr-2 h-4 w-4" />
                      Generating...
                    </span>
                  ) : 'Generate Scenario'}
                </button>
                
                <button
                  className="action-button compare ml-4"
                  onClick={handleComparisonGenerate}
                  disabled={loading || !isFormValid || comparing || true} 
                >
                  {comparing ? (
                    <span className="flex items-center">
                      <Loader2 className="animate-spin -ml-1 mr-2 h-4 w-4" />
                      Comparing Models...
                    </span>
                  ) : 'Compare Both Models'}
                </button>
              </div>
              {generatedScenario && (
                <div className="scenario-action-buttons mt-4 flex flex-wrap gap-4">
                  <button
                    onClick={handleResetForm}
                    className="action-button cancel"
                  >
                    Generate New Scenario (Clear All)
                  </button>
                  <button
                    onClick={() => {
                      // Create a copy of the current formData
                      const preservedData = { ...formData };
                      
                      // Reset the form with default values
                      setFormData(DEFAULT_VALUES);
                      
                      // Restore specific values we want to keep
                      setFormData(prev => ({
                        ...prev,
                        // Preserve these fields according to requirements
                        protocol_contents: preservedData.protocol_contents,
                        category: preservedData.category, 
                        complexity: preservedData.complexity,
                        diagnosiscategory: preservedData.diagnosiscategory,
                        initial_prompts: preservedData.initial_prompts,
                        questiondifficulty: preservedData.questiondifficulty,
                        scenario_topic: preservedData.scenario_topic,
                        training_designation: preservedData.training_designation,
                        userrole: preservedData.userrole,
                        // Keep the same AI model selection
                        ai_model: preservedData.ai_model
                      }));
                      
                      // Reset scenario state
                      setError(null);
                      setGeneratedScenario(null);
                      setGeneratedQuestions([]);
                      setValidationResult(null);
                      setValidationStatus('pending');
                      setComparisonMode(false);
                      setComparisonResults(null);
                    }}
                    className="action-button generate"
                  >
                    Generate New Scenario (Keep Settings)
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {}
        {generatedScenario && !comparisonMode && (
          <div className="generated-content">
            {}
            <div className="model-badge mb-3">
              <span className={`inline-block px-3 py-1 rounded-full text-sm font-medium ${
                selectedModel === 'anthropic' 
                  ? 'bg-blue-100 text-blue-800' 
                  : 'bg-green-100 text-green-800'
              }`}>
                Generated using: {selectedModel === 'anthropic' ? 'Anthropic Claude (v4)' : 'Google Gemini (v5)'}
              </span>
            </div>
            
            {}
            {generatedQuestions.length > 0 && (
                <>
                  {}
                  <div className="flex justify-between items-center mb-4">
                    <div className="flex gap-2">
                      <button
                        onClick={() => {
                          const prevIndex = currentQuestionIndex - 1;
                          if (prevIndex >= 0) {
                            setCurrentQuestionIndex(prevIndex);
                            setCurrentQuestion(generatedQuestions[prevIndex]);
                          }
                        }}
                        disabled={currentQuestionIndex <= 0}
                        className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 disabled:opacity-50"
                      >
                        Previous Question
                      </button>
                      <button
                        onClick={() => {
                          const nextIndex = currentQuestionIndex + 1;
                          if (nextIndex < generatedQuestions.length) {
                            setCurrentQuestionIndex(nextIndex);
                            setCurrentQuestion(generatedQuestions[nextIndex]);
                          }
                        }}
                        disabled={currentQuestionIndex >= generatedQuestions.length - 1}
                        className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 disabled:opacity-50"
                      >
                        Next Question
                      </button>
                    </div>
                    <span className="text-sm text-gray-600">
                      Question {currentQuestionIndex + 1} of {generatedQuestions.length}
                    </span>
                  </div>

                  {}
                  <div className="scroll-hint">
                    <span className="scroll-arrow">←</span>
                    <span>Scroll horizontally to see more fields</span>
                    <span className="scroll-arrow">→</span>
                  </div>

                  {}
                  <div className="table-container">
                    <table className="datasheet-table">
                      <thead>
                        <tr>
                          <th className="sticky-header sticky-col">Actions</th>
                          <th className="sticky-header">Status</th>
                          <th className="sticky-header">Order</th>
                          <th className="sticky-header">Title</th>
                          <th className="sticky-header">Category</th>
                          <th className="sticky-header">Diagnosis Category</th>
                          <th className="sticky-header">Patient Report</th>
                          <th className="sticky-header">Question</th>
                          <th className="sticky-header">Option 1</th>
                          <th className="sticky-header">Option 2</th>
                          <th className="sticky-header">Option 3</th>
                          <th className="sticky-header">Option 4</th>
                          <th className="sticky-header">Answer</th>
                          <th className="sticky-header">Explanation 1</th>
                          <th className="sticky-header">Explanation 2</th>
                          <th className="sticky-header">Explanation 3</th>
                          <th className="sticky-header">Explanation 4</th>
                          <th className="sticky-header">User Role</th>
                          <th className="sticky-header">Tags</th>
                        </tr>
                      </thead>
                      <tbody>
                        {generatedQuestions.map((question, index) => {
                          
                          const safeRender = (value) => {
                            if (value === null || value === undefined) return '';
                            if (typeof value === 'object') return JSON.stringify(value);
                            return String(value);
                          };
                          
                          return (
                            <tr key={index}>
                              <td className="sticky-col">
                                <div className="flex gap-2">
                                  <button
                                    onClick={() => {
                                      setCurrentQuestion(question);
                                      setCurrentQuestionIndex(index);
                                      setShowQuestionDialog(true);
                                      setIsEditMode(true);
                                      setEditedQuestion({...question});
                                    }}
                                    className="px-2 py-1 text-xs font-medium text-blue-700 bg-blue-100 rounded-md hover:bg-blue-200"
                                  >
                                    Edit
                                  </button>
                                  <button
                                    onClick={() => {
                                      handleOpenFeedbackDialog('delete', index);
                                    }}
                                    className="px-2 py-1 text-xs font-medium text-red-700 bg-red-100 rounded-md hover:bg-red-200"
                                  >
                                    Delete
                                  </button>
                                </div>
                              </td>
                              <td>{safeRender(question.status)}</td>
                              <td>{index + 1}</td>
                              <td>{safeRender(question.title)}</td>
                              <td>{safeRender(formData.category)}</td>
                              <td>{safeRender(formData.diagnosiscategory)}</td>
                              <td className="whitespace-pre-wrap min-w-[200px]">{safeRender(question.patientreport)}</td>
                              <td>{safeRender(question.question)}</td>
                              <td>{safeRender(question.option1)}</td>
                              <td>{safeRender(question.option2)}</td>
                              <td>{safeRender(question.option3)}</td>
                              <td>{safeRender(question.option4)}</td>
                              <td>{safeRender(question.answer)}</td>
                              <td className="whitespace-pre-wrap min-w-[200px]">{safeRender(question.explanation1)}</td>
                              <td className="whitespace-pre-wrap min-w-[200px]">{safeRender(question.explanation2)}</td>
                              <td className="whitespace-pre-wrap min-w-[200px]">{safeRender(question.explanation3)}</td>
                              <td className="whitespace-pre-wrap min-w-[200px]">{safeRender(question.explanation4)}</td>
                              <td>{safeRender(formData.userrole)}</td>
                              <td>{question.tags ? (
                                typeof question.tags === 'string'
                                  ? safeRender(
                                      (() => {
                                        try { return JSON.parse(question.tags).join(', '); }
                                        catch { return question.tags; }
                                      })()
                                    )
                                  : Array.isArray(question.tags)
                                    ? safeRender(question.tags.join(', '))
                                    : safeRender(question.tags)
                              ) : ''}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                </div>
              </>
            )}

            {}
            <ValidationResults 
              validationResult={validationResult}
              isValidating={validationStatus === 'inProgress'}
            />
          </div>
        )}
        
        {}
        {comparisonMode && comparisonResults && (
          <div className="comparison-results mt-6 p-4 border rounded-lg">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Model Comparison Results</h2>
              <button
                onClick={() => setComparisonMode(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                Exit Comparison
              </button>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              {}
              <div className="border rounded-lg p-4 shadow-sm">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg font-medium">Anthropic Claude (v4)</h3>
                  <span className="px-2 py-1 bg-blue-100 text-blue-800 rounded-md text-xs">AI Model</span>
                </div>
                
                {comparisonResults.anthropic.error ? (
                  <div className="text-red-500">Generation failed: {comparisonResults.anthropic.error}</div>
                ) : (
                  <>
                    <div className="mb-3">
                      <h4 className="font-medium">Title</h4>
                      <p>{comparisonResults.anthropic.title || 'No title generated'}</p>
                    </div>
                    
                    <div className="mb-3">
                      <h4 className="font-medium">Patient Report Preview</h4>
                      <p className="text-sm text-gray-600 line-clamp-4">
                        {comparisonResults.anthropic.patientreport 
                          ? comparisonResults.anthropic.patientreport.substring(0, 150) + '...' 
                          : 'No patient report generated'}
                      </p>
                    </div>
                    
                    <div className="mb-3">
                      <h4 className="font-medium">Stats</h4>
                      <ul className="text-sm">
                        <li>Questions: {comparisonResults.anthropic.questions?.length || 0}</li>
                        <li>Patient Report Length: {comparisonResults.anthropic.patientreport?.length || 0} chars</li>
                      </ul>
                    </div>
                    
                    <button
                      onClick={() => handleSelectScenario(comparisonResults.anthropic)}
                      className="w-full mt-2 px-3 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                    >
                      Select This Scenario
                    </button>
                  </>
                )}
              </div>
              
              {}
              <div className="border rounded-lg p-4 shadow-sm">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg font-medium">Google Gemini (v5)</h3>
                  <span className="px-2 py-1 bg-green-100 text-green-800 rounded-md text-xs">AI Model</span>
                </div>
                
                {comparisonResults.gemini.error ? (
                  <div className="text-red-500">Generation failed: {comparisonResults.gemini.error}</div>
                ) : (
                  <>
                    <div className="mb-3">
                      <h4 className="font-medium">Title</h4>
                      <p>{comparisonResults.gemini.title || 'No title generated'}</p>
                    </div>
                    
                    <div className="mb-3">
                      <h4 className="font-medium">Patient Report Preview</h4>
                      <p className="text-sm text-gray-600 line-clamp-4">
                        {comparisonResults.gemini.patientreport 
                          ? comparisonResults.gemini.patientreport.substring(0, 150) + '...' 
                          : 'No patient report generated'}
                      </p>
                    </div>
                    
                    <div className="mb-3">
                      <h4 className="font-medium">Stats</h4>
                      <ul className="text-sm">
                        <li>Questions: {comparisonResults.gemini.questions?.length || 0}</li>
                        <li>Patient Report Length: {comparisonResults.gemini.patientreport?.length || 0} chars</li>
                      </ul>
                    </div>
                    
                    <button
                      onClick={() => handleSelectScenario(comparisonResults.gemini)}
                      className="w-full mt-2 px-3 py-2 bg-green-600 text-white rounded hover:bg-green-700"
                    >
                      Select This Scenario
                    </button>
                  </>
                )}
              </div>
            </div>
            
            {}
            <div className="border rounded-lg p-4 bg-gray-50">
              <h3 className="text-lg font-medium mb-3">Quick Analysis</h3>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="p-3 border rounded bg-white">
                  <h4 className="font-medium">Patient Report</h4>
                  <div className="flex items-center justify-between text-sm mt-2">
                    <span>Claude: {comparisonResults.anthropic.patientreport?.length || 0} chars</span>
                    <span>Gemini: {comparisonResults.gemini.patientreport?.length || 0} chars</span>
                  </div>
                </div>
                
                <div className="p-3 border rounded bg-white">
                  <h4 className="font-medium">Questions Generated</h4>
                  <div className="flex items-center justify-between text-sm mt-2">
                    <span>Claude: {comparisonResults.anthropic.questions?.length || 0}</span>
                    <span>Gemini: {comparisonResults.gemini.questions?.length || 0}</span>
                  </div>
                </div>
                
                <div className="p-3 border rounded bg-white">
                  <h4 className="font-medium">Avg. Explanation Length</h4>
                  <div className="flex items-center justify-between text-sm mt-2">
                    <span>Claude: {calculateAvgExplanationLength(comparisonResults.anthropic)}</span>
                    <span>Gemini: {calculateAvgExplanationLength(comparisonResults.gemini)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {}
        {showQuestionDialog && currentQuestion && (
          <div 
            className="modal-overlay"
            onClick={(e) => e.stopPropagation()}
          >
            <div 
              className="modal-content" 
              ref={modalRef}
              onClick={(e) => e.stopPropagation()}
              role="dialog"
              aria-modal="true"
              aria-labelledby="dialog-title"
              tabIndex={-1}
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  setShowQuestionDialog(false);
                }
              }}
            >
              <div className="modal-header">
                <h2 id="dialog-title">{isEditMode ? 'Edit Question' : 'Review Question'}</h2>
                <button 
                  onClick={() => setShowQuestionDialog(false)}
                  className="close-button"
                  aria-label="Close dialog"
                >
                  ×
                </button>
              </div>
              <div className="modal-body" ref={dialogContentRef}>
                {isEditMode ? (
                  <div className="edit-form">
                    <div className="form-group">
                      <label>Patient Report</label>
                      <textarea
                        value={editedQuestion?.patientreport || ''}
                        onChange={e => setEditedQuestion(prev => ({
                          ...prev,
                          patientreport: e.target.value
                        }))}
                        className="form-textarea w-full"
                        rows="4"
                        autoFocus
                      />
                    </div>
                    <div className="form-group">
                      <label>Question</label>
                      <textarea
                        value={editedQuestion?.question || ''}
                        onChange={e => setEditedQuestion(prev => ({
                          ...prev,
                          question: e.target.value
                        }))}
                        className="form-textarea w-full"
                        rows="3"
                      />
                    </div>
                    {}
                    {[1, 2, 3, 4].map(num => (
                      <div key={num} className="form-group">
                        <label>Option {num}</label>
                        <textarea
                          value={editedQuestion?.[`option${num}`] || ''}
                          onChange={e => setEditedQuestion(prev => ({
                            ...prev,
                            [`option${num}`]: e.target.value
                          }))}
                          className="form-textarea w-full"
                          rows="2"
                        />
                      </div>
                    ))}
                    {}
                    <div className="form-group">
                      <label>Correct Answer</label>
                      <select
                        value={editedQuestion?.answer || ''}
                        onChange={e => setEditedQuestion(prev => ({
                          ...prev,
                          answer: parseInt(e.target.value)
                        }))}
                        className="form-select w-full"
                      >
                        {[1, 2, 3, 4].map(num => (
                          <option key={num} value={num}>Option {num}</option>
                        ))}
                      </select>
                    </div>
                    {}
                    {[1, 2, 3, 4].map(num => (
                      <div key={num} className="form-group">
                        <label>Explanation {num}</label>
                        <textarea
                          value={editedQuestion?.[`explanation${num}`] || ''}
                          onChange={e => setEditedQuestion(prev => ({
                            ...prev,
                            [`explanation${num}`]: e.target.value
                          }))}
                          className="form-textarea w-full"
                          rows="3"
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="review-content">
                    <div className="review-section">
                      <h3>Patient Report</h3>
                      <p className="mt-2">{currentQuestion.patientreport}</p>
                    </div>
                    <div className="review-section mt-4">
                      <h3>Question</h3>
                      <p className="mt-2">{currentQuestion.question}</p>
                    </div>
                    <div className="review-section mt-4">
                      <h3>Options</h3>
                      <div className="mt-2">
                        {[1, 2, 3, 4].map(num => (
                          <div key={num} className="option-item mb-2">
                            <strong>Option {num}:</strong> {currentQuestion[`option${num}`]}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="review-section mt-4">
                      <h3>Correct Answer</h3>
                      <p className="mt-2">Option {currentQuestion.answer}</p>
                    </div>
                    <div className="review-section mt-4">
                      <h3>Explanations</h3>
                      <div className="mt-2">
                        {[1, 2, 3, 4].map(num => (
                          <div key={num} className="explanation-item mb-2">
                            <strong>Explanation {num}:</strong> {currentQuestion[`explanation${num}`]}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                {isEditMode ? (
                  <div className="flex justify-end gap-4">
                    <button
                      onClick={() => {
                        const updatedQuestions = [...generatedQuestions];
                        updatedQuestions[currentQuestionIndex] = editedQuestion;
                        setGeneratedQuestions(updatedQuestions);
                        setIsEditMode(false);
                        setShowQuestionDialog(false);
                      }}
                      className="action-button save"
                    >
                      Save Changes
                    </button>
                    <button
                      onClick={() => {
                        setIsEditMode(false);
                        setEditedQuestion(null);
                      }}
                      className="action-button cancel"
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <div className="review-actions flex justify-end gap-4">
                    <button
                      onClick={() => handleQuestionReview('approve')}
                      className="action-button approve"
                      autoFocus={!isEditMode}
                    >
                      Approve
                    </button>
                    <button
                      onClick={() => handleQuestionReview('edit')}
                      className="action-button edit"
                    >
                      Edit and Provide Feedback
                    </button>
                    <button
                      onClick={() => handleQuestionReview('regenerate')}
                      className="action-button regenerate"
                    >
                      Regenerate
                    </button>
                    <button
                      onClick={() => handleQuestionReview('delete')}
                      className="action-button delete"
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {}
        {showFeedbackDialog && feedbackQuestion && (
          <FeedbackDialog
            isOpen={showFeedbackDialog}
            onClose={() => setShowFeedbackDialog(false)}
            onSubmit={handleFeedbackSubmit}
            questionIndex={feedbackQuestionIndex}
            questionData={feedbackQuestion}
            isProcessing={isProcessingFeedback}
            title={
              feedbackType === 'edit' 
                ? "Provide Edit Feedback" 
                : feedbackType === 'regenerate'
                  ? "Provide Regeneration Feedback"
                  : "Why are you deleting this question?"
            }
            submitButtonText={
              feedbackType === 'edit'
                ? "Submit & Edit"
                : feedbackType === 'regenerate'
                  ? "Submit & Regenerate"
                  : "Submit & Delete"
            }
            submitButtonClass={
              feedbackType === 'edit'
                ? "edit"
                : feedbackType === 'regenerate'
                  ? "regenerate"
                  : "delete"
            }
          />
        )}
        
        {}
        {loading && (
          <div className="progress-overlay">
            <div className="progress-container">
              <div 
                className="progress-bar"
                style={{ width: `${progress}%` }}
              >
                <span className="progress-text">{progress}%</span>
              </div>
            </div>
            <div className="mt-4 text-sm text-gray-600">
              {progress < 20 ? "Initializing..." : 
               progress < 40 ? "Generating base scenario..." :
               progress < 90 ? "Generating questions..." :
               "Finalizing..."}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}