
import React from 'react';

import ScenarioGenerator from '../components/ScenarioGenerator/ScenarioGenerator';

const GeneratorPage = () => {
  console.log('GeneratorPage - Starting render');
  
  
  try {
    return (
      <div className="min-h-screen bg-gray-50">
        <ScenarioGenerator />
      </div>
    );
  } catch (error) {
    console.error('GeneratorPage - Render error:', error);
    return (
      <div className="p-4 bg-red-100 text-red-700">
        Error rendering scenario generator: {error.message}
      </div>
    );
  }
};

export default GeneratorPage;