import React, { useState, useCallback, useEffect } from 'react';
import { Loader2, Edit2 } from 'lucide-react';
import { supabase } from '@/lib/supabaseClient';

const ITEMS_PER_PAGE = 10;

const CategoriesManagement = () => {
    const [categories, setCategories] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCategories, setTotalCategories] = useState(0);
    const [filters, setFilters] = useState({
        search: '',
        status: ''
    });

    const fetchCategories = useCallback(async () => {
        setIsLoading(true);
        try {
            const { count } = await supabase
                .from('reference_categories')
                .select('*', { count: 'exact', head: true });

            let query = supabase
                .from('reference_categories')
                .select('*');

            if (filters.search) {
                query = query.ilike('category', `%${filters.search.toLowerCase()}%`);
            }

            if (filters.status) {
                query = query.eq('active', filters.status === 'active');
            }

            const from = (currentPage - 1) * ITEMS_PER_PAGE;
            const to = from + ITEMS_PER_PAGE - 1;

            const { data, error: fetchError } = await query
                .order('display_order', { ascending: true })
                .order('category')
                .range(from, to);

            if (fetchError) throw fetchError;

            setCategories(data || []);
            setTotalCategories(count);
        } catch (err) {
            console.error('Error fetching categories:', err);
            setError('Failed to load categories');
            setCategories([]);
            setTotalCategories(0);
        } finally {
            setIsLoading(false);
        }
    }, [filters, currentPage]);

    useEffect(() => {
        fetchCategories();
    }, [fetchCategories]);

    const handleCreateCategory = async (formData) => {
        try {
            setIsLoading(true);
            setError(null);

            const { data: maxOrderData } = await supabase
                .from('reference_categories')
                .select('display_order')
                .order('display_order', { ascending: false })
                .limit(1);

            const nextDisplayOrder = maxOrderData?.[0]?.display_order 
                ? maxOrderData[0].display_order + 1 
                : 1;

            const { error: createError } = await supabase
                .from('reference_categories')
                .insert([
                    { 
                        category: formData.category,
                        description: formData.description,
                        active: true,
                        display_order: nextDisplayOrder
                    }
                ]);

            if (createError) throw createError;
            
            await fetchCategories();
            setSelectedCategory(null);
        } catch (err) {
            console.error('Error creating category:', err);
            setError(err.message || 'Failed to create category');
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpdateCategory = async (formData) => {
        try {
            setIsLoading(true);
            setError(null);

            const { error: updateError } = await supabase
                .from('reference_categories')
                .update({ 
                    category: formData.category,
                    description: formData.description
                })
                .eq('id', formData.id);

            if (updateError) throw updateError;
            
            await fetchCategories();
            setSelectedCategory(null);
        } catch (err) {
            console.error('Error updating category:', err);
            setError(err.message || 'Failed to update category');
        } finally {
            setIsLoading(false);
        }
    };

    const handleArchiveCategory = async (categoryId) => {
        if (window.confirm('Are you sure you want to archive this category?')) {
            try {
                setIsLoading(true);
                setError(null);

                const { error: archiveError } = await supabase
                    .from('reference_categories')
                    .update({ active: false })
                    .eq('id', categoryId);

                if (archiveError) throw archiveError;
                
                await fetchCategories();
            } catch (err) {
                console.error('Error archiving category:', err);
                setError(err.message || 'Failed to archive category');
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters(prev => ({
            ...prev,
            [name]: value
        }));
        setCurrentPage(1);
    };

    const clearFilters = () => {
        setFilters({
            search: '',
            status: ''
        });
        setCurrentPage(1);
    };

    return (
        <div className="bg-white rounded-lg shadow-sm p-6">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-bold">Categories Management</h1>
                <button
                    onClick={() => setSelectedCategory({ isNew: true })}
                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                    Add New Category
                </button>
            </div>

            {error && (
                <div className="mb-6 p-4 bg-red-50 border border-red-200 text-red-800 rounded-md">
                    {error}
                </div>
            )}

            <div className="flex gap-4 mb-6">
                <div className="flex-1">
                    <input
                        type="text"
                        name="search"
                        value={filters.search}
                        onChange={handleFilterChange}
                        placeholder="Search categories..."
                        className="w-full px-4 py-2 border rounded-md"
                    />
                </div>
                <button
                    onClick={clearFilters}
                    className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200"
                >
                    Clear Filters
                </button>
            </div>

            {isLoading ? (
                <div className="flex justify-center items-center p-8">
                    <Loader2 className="h-8 w-8 animate-spin" />
                </div>
            ) : categories.length === 0 ? (
                <div className="text-center p-8 text-gray-500">
                    No categories found. {filters.search ? 'Try adjusting your search.' : ''}
                </div>
            ) : (
                <div className="overflow-x-auto">
                    <table className="w-full">
                        <thead>
                            <tr className="bg-gray-50 text-left">
                                <th className="px-6 py-3">Category</th>
                                <th className="px-6 py-3">Description</th>
                                <th className="px-6 py-3">Status</th>
                                <th className="px-6 py-3">Display Order</th>
                                <th className="px-6 py-3">Created</th>
                                <th className="px-6 py-3 text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {categories.map((category) => (
                                <tr key={category.id} className="border-t">
                                    <td className="px-6 py-4">{category.category}</td>
                                    <td className="px-6 py-4">{category.description}</td>
                                    <td className="px-6 py-4">
                                        <span className={`inline-flex px-2 py-1 rounded-full text-xs font-medium ${
                                            category.active 
                                                ? 'bg-green-100 text-green-800' 
                                                : 'bg-gray-100 text-gray-800'
                                        }`}>
                                            {category.active ? 'Active' : 'Archived'}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4">{category.display_order}</td>
                                    <td className="px-6 py-4">
                                        {new Date(category.created_at).toLocaleDateString()}
                                    </td>
                                    <td className="px-6 py-4 text-right space-x-2">
                                        <button
                                            onClick={() => setSelectedCategory(category)}
                                            className="px-3 py-1 rounded-md text-sm bg-blue-600 text-white hover:bg-blue-700"
                                        >
                                            <Edit2 className="h-4 w-4 inline-block" />
                                        </button>
                                        <button
                                            onClick={() => handleArchiveCategory(category.id)}
                                            disabled={!category.active}
                                            className={`px-3 py-1 rounded-md text-sm ${
                                                category.active
                                                    ? 'bg-red-600 text-white hover:bg-red-700'
                                                    : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                            }`}
                                        >
                                            Archive
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            <div className="flex justify-between items-center mt-6">
                <div className="text-sm text-gray-500">
                    Showing {categories.length} of {totalCategories} categories
                </div>
                <div className="flex gap-2">
                    <button
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                        className={`px-4 py-2 rounded-md ${
                            currentPage === 1
                                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                        }`}
                    >
                        Previous
                    </button>
                    <button
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage >= Math.ceil(totalCategories / ITEMS_PER_PAGE)}
                        className={`px-4 py-2 rounded-md ${
                            currentPage >= Math.ceil(totalCategories / ITEMS_PER_PAGE)
                                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                        }`}
                    >
                        Next
                    </button>
                </div>
            </div>

            {selectedCategory && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
                    <div className="bg-white rounded-lg p-6 w-full max-w-md">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-semibold">
                                {selectedCategory.isNew ? 'Add New Category' : 'Edit Category'}
                            </h2>
                            <button
                                onClick={() => setSelectedCategory(null)}
                                className="text-gray-400 hover:text-gray-600"
                            >
                                ×
                            </button>
                        </div>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            if (selectedCategory.isNew) {
                                handleCreateCategory(selectedCategory);
                            } else {
                                handleUpdateCategory(selectedCategory);
                            }
                        }}>
                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Category Name
                                    </label>
                                    <input
                                        type="text"
                                        value={selectedCategory?.category || ''}
                                        onChange={(e) => setSelectedCategory({
                                            ...selectedCategory,
                                            category: e.target.value
                                        })}
                                        className="w-full px-4 py-2 border rounded-md"
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Description
                                    </label>
                                    <textarea
                                        value={selectedCategory?.description || ''}
                                        onChange={(e) => setSelectedCategory({
                                            ...selectedCategory,
                                            description: e.target.value
                                        })}
                                        rows={3}
                                        className="w-full px-4 py-2 border rounded-md"
                                    />
                                </div>
                            </div>
                            <div className="flex justify-end gap-2 mt-6">
                                <button
                                    type="button"
                                    onClick={() => setSelectedCategory(null)}
                                    className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                                >
                                    {selectedCategory.isNew ? 'Create Category' : 'Update Category'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CategoriesManagement;