import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from '@/features/auth/context/AuthContext';
import Layout from '@/shared/layout/Layout';
import Landing from '@/features/home/pages/Landing';
import Login from '@/features/auth/pages/Login';
import ForgotPassword from '@/features/auth/pages/ForgotPassword';
import ScenarioReview from '@/features/review/pages/ScenarioReview';
import ProtectedRoute from '@/features/auth/components/ProtectedRoute';
import AdminRoutes from '@/routes/AdminRoutes';
import DebugStorage from './components/DebugStorage';
import MedTeamRoutes from '@/routes/MedTeamRoutes'; 

import ScenarioCreationReport from '@/features/scenarios/pages/ScenarioCreationReport';
import UsersPage from '@/features/users/pages/UsersPage';
import CategoriesManagement from '@/features/categories/pages/CategoriesManagement';
import HashtagsPage from '@/features/hashtags/pages/HashtagsPage';
import ReportingDashboard from '@/features/reporting/pages/ReportingDashboard';
import UtilizationDashboard from '@/features/utilization/pages/UtilizationDashboard';
import RoleBasedRoute from '@/features/auth/components/RoleBasedRoute';
import '@/features/utilization/styles/Utilization.css';
import RouteLogger from '@/routes/RouteLogger';
import ScenarioEditsPage from '@/features/scenarios/pages/ScenarioEditsPage';
import QuestionEditsPage from '@/features/scenarios/pages/QuestionEditsPage';

const Loading = () => (
  <div className="flex items-center justify-center min-h-screen">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
  </div>
);

const PlaceholderPages = {
  Protocols: () => <div className="p-4">Feature coming soon</div>
};

const ProtectedLayout = ({ children }) => (
  <ProtectedRoute>
    <Layout>
      <Suspense fallback={<Loading />}>
        {children}
      </Suspense>
    </Layout>
  </ProtectedRoute>
);

function AppRoutes() {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/unauthorized" element={<div>Unauthorized Access</div>} />

      {/* Protected Routes */}
      <Route path="/" element={
        <ProtectedLayout>
          <Landing />
        </ProtectedLayout>
      } />

      {/* Reporting Routes */}
      <Route path="/reporting/dashboard" element={
        <ProtectedLayout>
          <ReportingDashboard />
        </ProtectedLayout>
      } />
      <Route path="/reporting/utilization" element={
        <ProtectedLayout>
          <UtilizationDashboard />
        </ProtectedLayout>
      } />

      {/* Scenarios Routes */}
      <Route path="/scenario-edits" element={
          <ProtectedLayout>
            <ScenarioEditsPage />
          </ProtectedLayout>
        } />
        <Route path="/question-edits" element={
          <ProtectedLayout>
            <QuestionEditsPage />
          </ProtectedLayout>
        } />
              <Route path="/scenarioreview" element={
        <ProtectedLayout>
          <ScenarioReview />
        </ProtectedLayout>
      } />
      <Route path="/scenarios/creation-report" element={
        <ProtectedLayout>
          <ScenarioCreationReport />
        </ProtectedLayout>
      } />

      {/* Management Routes */}
      <Route path="/users" element={
        <ProtectedLayout>
          <UsersPage />
        </ProtectedLayout>
      } />
      <Route path="/hashtags" element={
        <ProtectedLayout>
          <HashtagsPage />
        </ProtectedLayout>
      } />
      <Route path="/categories" element={
        <ProtectedLayout>
          <CategoriesManagement />
        </ProtectedLayout>
      } />

      {/* Placeholder Routes */}
      {Object.entries(PlaceholderPages).map(([name, Component]) => (
        <Route
          key={name.toLowerCase()}
          path={`/${name.toLowerCase()}`}
          element={
            <ProtectedLayout>
              <Component />
            </ProtectedLayout>
          }
        />
      ))}

      {/* MedTeam Routes */}
      <Route path="/medteam/*" element={
        <ProtectedLayout>
          <MedTeamRoutes />
        </ProtectedLayout>
      } />

      {/* Admin Routes */}
      {AdminRoutes.map(({ path, element, allowedRoles }) => (
        <Route
          key={path}
          path={path}
          element={
            <RouteLogger>
              <ProtectedLayout>
                {allowedRoles.includes('*') ? (
                  element
                ) : (
                  <RoleBasedRoute requiredRoles={allowedRoles}>
                    {element}
                  </RoleBasedRoute>
                )}
              </ProtectedLayout>
            </RouteLogger>
          }
        />
      ))}

      {/* Catch-all route */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <DebugStorage />
        <Suspense fallback={<Loading />}>
          <AppRoutes />
        </Suspense>
      </AuthProvider>
    </Router>
  );
}

export default App;