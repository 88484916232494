
import React from 'react';
import { Loader2 } from 'lucide-react';

const ValidationResults = ({ validationResult, isValidating }) => {
  if (isValidating) {
    return (
      <div className="flex items-center justify-center p-4">
        <Loader2 className="animate-spin h-6 w-6 mr-2" />
        <span className="text-gray-600">Validating scenario...</span>
      </div>
    );
  }

  if (!validationResult) return null;

  const getSeverityStyle = (severity) => {
    switch (severity) {
      case 'critical':
        return 'border-red-500 bg-red-50 text-red-700';
      case 'warning':
        return 'border-yellow-500 bg-yellow-50 text-yellow-700';
      case 'suggestion':
        return 'border-blue-500 bg-blue-50 text-blue-700';
      default:
        return 'border-gray-200 bg-gray-50 text-gray-700';
    }
  };

  return (
    <div className="space-y-6 mt-6">
      {/* Overall Status */}
      <div className={`p-4 rounded-lg border ${
        validationResult.isValid 
          ? 'border-green-500 bg-green-50 text-green-700' 
          : 'border-red-500 bg-red-50 text-red-700'
      }`}>
        <div className="text-lg font-semibold">
          Validation {validationResult.isValid ? 'Passed' : 'Failed'}
        </div>
        <div className="mt-1">
          <div>Score: {validationResult.score}/100</div>
          <div>{validationResult.summary}</div>
        </div>
      </div>

      {/* Errors Section */}
      {validationResult.errors && validationResult.errors.length > 0 && (
        <div className="space-y-3">
          <h3 className="text-lg font-semibold">Issues Found</h3>
          {validationResult.errors.map((error, index) => (
            <div 
              key={index}
              className={`p-4 rounded-lg border ${getSeverityStyle(error.severity)}`}
            >
              <div className="flex items-start justify-between">
                <div>
                  <div className="font-medium">
                    {error.type.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                  </div>
                  <p className="mt-1">{error.description}</p>
                  {error.suggestedFix && (
                    <p className="mt-2 font-medium">
                      Suggested Fix: {error.suggestedFix}
                    </p>
                  )}
                  {(error.questionIndex !== undefined || error.field) && (
                    <p className="mt-2 text-sm opacity-75">
                      Location: {error.questionIndex !== undefined && `Question ${error.questionIndex + 1}`}
                      {error.field && ` - ${error.field}`}
                    </p>
                  )}
                </div>
                <span className="text-xs font-medium px-2 py-1 rounded-full bg-white bg-opacity-50">
                  {error.severity}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Suggestions Section */}
      {validationResult.suggestions && validationResult.suggestions.length > 0 && (
        <div className="space-y-3">
          <h3 className="text-lg font-semibold">Suggestions</h3>
          {validationResult.suggestions.map((suggestion, index) => (
            <div 
              key={index}
              className="p-4 rounded-lg border border-blue-200 bg-blue-50 text-blue-700"
            >
              <div className="flex items-start">
                <div>
                  <div className="font-medium">
                    {suggestion.type.charAt(0).toUpperCase() + suggestion.type.slice(1)}
                  </div>
                  <p className="mt-1">{suggestion.description}</p>
                  {(suggestion.questionIndex !== undefined || suggestion.field) && (
                    <p className="mt-2 text-sm opacity-75">
                      Location: {suggestion.questionIndex !== undefined && `Question ${suggestion.questionIndex + 1}`}
                      {suggestion.field && ` - ${suggestion.field}`}
                    </p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Metadata */}
      {validationResult.metadata && (
        <div className="text-sm text-gray-500 border-t pt-4">
          <div>Confidence Score: {(validationResult.confidenceScore * 100).toFixed(1)}%</div>
          <div>Validation Model: {validationResult.metadata.validationModel}</div>
          <div>
            Validated at: {new Date(validationResult.metadata.validationTimestamp).toLocaleString()}
          </div>
        </div>
      )}
    </div>
  );
};

export default ValidationResults;