
import React from 'react';
import { Loader2, Search } from 'lucide-react';
import { useReferenceData } from '@/shared/hooks/useReferenceData';

const ScenarioFilters = ({ 
  selectedStatus = '',
  selectedCategory = '',
  selectedDiagnosisCategory = '',
  selectedDifficulty = '',
  selectedComplexity = '',
  selectedRole = '',
  searchText = '',
  onStatusChange,
  onCategoryChange,
  onDiagnosisCategoryChange,
  onDifficultyChange,
  onComplexityChange,
  onRoleChange,
  onSearchChange,
  onClearFilters,
  showSearch = true
}) => {
  const {
    status: statuses,
    categories,
    diagnosiscategory: diagnosisCategories,
    questiondifficulty: difficulties,
    complexity: complexities,
    roles,
    loading,
    error
  } = useReferenceData();

  if (loading) {
    return (
      <div className="bg-white rounded-lg shadow p-6 mb-6">
        <div className="flex items-center justify-center p-4">
          <Loader2 className="animate-spin h-5 w-5 mr-2" />
          <span>Loading filters...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow p-6 mb-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Filters</h2>
        {onClearFilters && (
          <button 
            onClick={onClearFilters}
            className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors"
          >
            Clear Filters
          </button>
        )}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {/* Status Filter */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Status</label>
          <select
            value={selectedStatus}
            onChange={onStatusChange}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="">All Statuses</option>
            {(statuses || []).map((status) => (
              <option key={status || 'none'} value={status || ''}>
                {status || '(No Status)'}
              </option>
            ))}
          </select>
        </div>

        {/* Category Filter */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Category</label>
          <select
            value={selectedCategory}
            onChange={onCategoryChange}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="">All Categories</option>
            {(categories || []).map((category) => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
        </div>

        {/* Diagnosis Category Filter */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Diagnosis Category</label>
          <select
            value={selectedDiagnosisCategory}
            onChange={onDiagnosisCategoryChange}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="">All Diagnosis Categories</option>
            {(diagnosisCategories || []).map((category) => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
        </div>

        {/* Question Difficulty Filter */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Question Difficulty</label>
          <select
            value={selectedDifficulty}
            onChange={onDifficultyChange}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="">All Difficulties</option>
            {(difficulties || []).map((diff) => (
              <option key={`${diff.level}-${diff.id}`} value={diff.level}>
                {diff.display_name}
              </option>
            ))}
          </select>
        </div>

        {/* Complexity Filter */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Complexity</label>
          <select
            value={selectedComplexity}
            onChange={onComplexityChange}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="">All Complexities</option>
            {(complexities || []).map((complexity) => (
              <option key={complexity} value={complexity}>{complexity}</option>
            ))}
          </select>
        </div>

        {/* User Role Filter */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">User Role</label>
          <select
            value={selectedRole}
            onChange={onRoleChange}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="">All Roles</option>
            {(roles || []).map((role) => (
              <option key={role} value={role}>{role}</option>
            ))}
          </select>
        </div>

        {/* Search Field */}
        {showSearch && onSearchChange && (
          <div className="md:col-span-2 lg:col-span-3">
            <label className="block text-sm font-medium text-gray-700 mb-1">Search</label>
            <div className="relative">
              <input
                type="search"
                value={searchText}
                onChange={(e) => {
                  const value = e.target.value;
                  if (!value || /^[\w\s-]*$/.test(value)) {
                    onSearchChange(value);
                  }
                }}
                placeholder="Search..."
                className="w-full border rounded-md p-2 pr-10 focus:border-blue-500 focus:ring-blue-500"
              />
              <div className="absolute right-3 top-2.5 text-gray-400">
                <Search className="h-5 w-5" />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScenarioFilters;