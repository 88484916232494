
import React from 'react';
import { supabase } from '@/lib/supabaseClient';

function DebugStorage() {
  React.useEffect(() => {
    
    console.log('Running DebugStorage checks...');
    
    
    console.log('Environment variables:', {
      hasSupabaseUrl: !!import.meta.env.VITE_SUPABASE_URL,
      hasSupabaseKey: !!import.meta.env.VITE_SUPABASE_ANON_KEY
    });

    
    try {
      window.localStorage.setItem('test', 'test');
      window.localStorage.removeItem('test');
      console.log('localStorage is accessible');
    } catch (error) {
      console.error('localStorage error:', error);
    }

    
    const checkSupabase = async () => {
      try {
        const { data, error } = await supabase.auth.getSession();
        console.log('Supabase session check:', { hasData: !!data, error });
      } catch (error) {
        console.error('Supabase check error:', error);
      }
    };

    checkSupabase();
  }, []);

  return null;
}


export default DebugStorage;