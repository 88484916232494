import React, { useEffect } from 'react';

const Landing = () => {
 useEffect(() => {
   console.log('Landing component rendered');
 }, []);
 
 return (
   <div className="w-full max-w-6xl mx-auto p-8">
     <div className="bg-white rounded-lg shadow-lg p-8">
       <h1 className="text-3xl font-bold mb-8 text-center text-[#1e2337]">
         Welcome to Solved EMTeams Administration Site
       </h1>

       <div className="text-center mb-12">
         <p className="text-lg text-gray-600 mb-4">
           Your comprehensive platform for emergency medical training scenarios and review management.
         </p>

         <div className="text-sm text-gray-500 mb-8">
           <p className="mb-2">This is a proprietary system developed by Solved EMTeams Admin Management for authorized users only.</p>
           <p>Access to this platform is restricted to Solved Senior Leadership, Reviewers and administrators.</p>
         </div>
       </div>

       <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
         <FeatureCard 
           title="Scenario Management"
           description="Create, review, and manage training scenarios for comprehensive emergency medical training."
         />
         <FeatureCard 
           title="Protocol Integration" 
           description="Seamlessly integrate with established medical protocols to ensure consistent training standards."
         />
         <FeatureCard 
           title="Team Collaboration"
           description="Enable efficient coordination between reviewers and administrators for optimal workflow."
         />
         <FeatureCard 
           title="Quality Assurance"
           description="Implement structured review processes to maintain and enhance training quality standards."
         />
       </div>
     </div>
   </div>
 );
};

const FeatureCard = ({ title, description }) => (
 <div className="bg-gray-50 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200">
   <h2 className="text-xl font-semibold mb-3 text-[#1e2337]">{title}</h2>
   <p className="text-gray-600 leading-relaxed">{description}</p>
 </div>
);

export default Landing;