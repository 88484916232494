
import React from 'react';
import GeneratorPage from '@/features/scenarios/pages/GeneratorPage';
import ProtocolsGrid from '@/features/protocols/pages/ProtocolsGrid';
import ProtocolParser from '@/features/protocols/pages/ProtocolParser';
import TrainingPlanManagement from '@/features/training/pages/TrainingPlanManagement';
import ScenarioPromotionPage from '@/pages/admin/ScenarioPromotionPage';
import ValidationDashboard from '@/features/scenarios/pages/ValidationDashboard';
import BatchGeneratorPage from '@/features/scenarios/pages/BatchGeneratorPage'; 



const TrainingSessionsPage = () => (
  <div className="p-6 bg-white rounded-lg shadow">
    <h2 className="text-xl font-bold mb-4">Training Sessions Management</h2>
    <p>This feature is coming soon.</p>
  </div>
);

const CertificationsPage = () => (
  <div className="p-6 bg-white rounded-lg shadow">
    <h2 className="text-xl font-bold mb-4">Certifications Management</h2>
    <p>This feature is coming soon.</p>
  </div>
);

console.log('AdminRoutes.jsx - Defining routes');

const AdminRoutes = [
  {
    path: '/admin/scenario-generator',
    element: <GeneratorPage />,
    allowedRoles: ['admin']
  },
  {
    path: '/admin/batch-scenario-generator', 
    element: <BatchGeneratorPage />,
    allowedRoles: ['admin']
  },
  {
    path: '/admin/protocols',
    element: <ProtocolsGrid />,
    allowedRoles: ['admin']
  },
  {
    path: '/admin/protocol-parser',
    element: <ProtocolParser />,
    allowedRoles: ['admin']
  },
  {
    path: '/admin/scenario-validation',
    element: <ValidationDashboard />,
    allowedRoles: ['admin']
  },
  {
    path: '/training/plans',
    element: <TrainingPlanManagement />,
    allowedRoles: ['*']
  },
  {
    path: '/training/sessions',
    element: <TrainingSessionsPage />,
    allowedRoles: ['*']
  },
  {
    path: '/training/certifications',
    element: <CertificationsPage />,
    allowedRoles: ['*']
  },
  {
    path: '/admin/scenario-promotion',
    element: <ScenarioPromotionPage />,
    allowedRoles: ['admin']
  }
];

console.log('AdminRoutes.jsx - Routes defined:', AdminRoutes.map(route => route.path));

export default AdminRoutes;