
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUserRoles } from '../hooks/useUserRoles';

const RoleBasedRoute = ({ requiredRoles, children }) => {
  const { roles, loading } = useUserRoles();
  
  console.log('RoleBasedRoute - Required roles:', requiredRoles);
  console.log('RoleBasedRoute - Current user roles:', roles);
  console.log('RoleBasedRoute - Loading state:', loading);

  
  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  
  const hasRequiredRole = requiredRoles.some(role => 
    roles?.[`is${role.charAt(0).toUpperCase() + role.slice(1)}`]
  );
  
  console.log('RoleBasedRoute - Has required role:', hasRequiredRole);

  if (!hasRequiredRole) {
    console.log('RoleBasedRoute - Access denied, redirecting to unauthorized');
    return <Navigate to="/unauthorized" />;
  }

  console.log('RoleBasedRoute - Rendering children');
  
  try {
    return (
      <div className="role-based-route">
        {children}
      </div>
    );
  } catch (error) {
    console.error('RoleBasedRoute - Error rendering children:', error);
    return (
      <div className="p-4 bg-red-100 text-red-700">
        Error in role-based route: {error.message}
      </div>
    );
  }
};

export default RoleBasedRoute;