import React from 'react';
import { CheckCircle, AlertTriangle, AlertCircle, Loader2 } from 'lucide-react';

const BatchValidationResults = ({ validationResults }) => {
  
  const validStats = Object.values(validationResults).reduce(
    (stats, result) => {
      if (!result) {
        stats.failed++;
      } else if (result.isValid) {
        stats.valid++;
      } else {
        stats.issues++;
      }
      return stats;
    },
    { valid: 0, issues: 0, failed: 0 }
  );
  
  
  const totalScenarios = validStats.valid + validStats.issues + validStats.failed;
  
  
  if (totalScenarios === 0) {
    return null;
  }

  return (
    <div className="bg-white rounded-lg shadow border border-gray-200 p-4 mt-6">
      <h3 className="text-lg font-medium mb-4">Validation Summary</h3>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* Valid scenarios */}
        <div className="bg-green-50 p-4 rounded-lg border border-green-200 flex items-center gap-3">
          <CheckCircle className="text-green-600" size={24} />
          <div>
            <div className="text-green-700 font-medium">{validStats.valid} Valid</div>
            <div className="text-green-600 text-sm">Scenarios without issues</div>
          </div>
        </div>
        
        {/* Scenarios with issues */}
        <div className="bg-yellow-50 p-4 rounded-lg border border-yellow-200 flex items-center gap-3">
          <AlertTriangle className="text-yellow-600" size={24} />
          <div>
            <div className="text-yellow-700 font-medium">{validStats.issues} With Issues</div>
            <div className="text-yellow-600 text-sm">Require attention</div>
          </div>
        </div>
        
        {/* Failed validations */}
        <div className="bg-red-50 p-4 rounded-lg border border-red-200 flex items-center gap-3">
          <AlertCircle className="text-red-600" size={24} />
          <div>
            <div className="text-red-700 font-medium">{validStats.failed} Failed</div>
            <div className="text-red-600 text-sm">Validation could not complete</div>
          </div>
        </div>
      </div>
      
      {/* Detailed results - expandable in future */}
      {Object.entries(validationResults).length > 0 && (
        <div className="mt-6">
          <details className="validation-detail-expander">
            <summary className="cursor-pointer text-blue-600 font-medium mb-2">
              Show detailed validation results
            </summary>
            <div className="validation-details mt-2 space-y-4 bg-gray-50 p-4 rounded-lg border border-gray-200">
              {Object.entries(validationResults).map(([scenarioId, result]) => (
                <div key={scenarioId} className="border-b border-gray-200 pb-3 last:border-b-0 last:pb-0">
                  <h4 className="font-medium">Scenario #{scenarioId}</h4>
                  {!result ? (
                    <div className="text-red-600 text-sm mt-1">Validation failed to complete</div>
                  ) : (
                    <>
                      <div className={`text-sm mt-1 ${result.isValid ? 'text-green-600' : 'text-yellow-600'}`}>
                        {result.isValid ? 'Valid' : 'Has issues'}
                      </div>
                      
                      {!result.isValid && result.issues && (
                        <ul className="mt-2 text-sm space-y-1">
                          {result.issues.map((issue, idx) => (
                            <li key={idx} className="text-gray-700">
                              - {issue.message}
                            </li>
                          ))}
                        </ul>
                      )}
                    </>
                  )}
                </div>
              ))}
            </div>
          </details>
        </div>
      )}
    </div>
  );
};

export default BatchValidationResults;