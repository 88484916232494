import React, { useState, useEffect, useCallback } from 'react';
import { supabase } from '@/lib/supabaseClient';
import { Loader2, Upload, X } from 'lucide-react';

const ScenarioImage = ({ scenarioid, imageUrl: initialImageUrl, onImageUpdate, size = 576 }) => {
 const [error, setError] = useState(null);
 const [uploading, setUploading] = useState(false);
 const [imageLoadFailed, setImageLoadFailed] = useState(false);
 const [uploadProgress, setUploadProgress] = useState(0);

 useEffect(() => {
   setImageLoadFailed(false);
   setError(null);
   setUploadProgress(0);
 }, [scenarioid]);

 const validateFile = useCallback((file) => {
   if (!file) {
     throw new Error('No file selected');
   }

   const maxSize = 5 * 1024 * 1024;
   if (file.size > maxSize) {
     throw new Error(`File size must be less than 5MB. Current size: ${(file.size / 1024 / 1024).toFixed(2)}MB`);
   }

   const fileExt = file.name.split('.').pop().toLowerCase();
   const validExtensions = ['jpg', 'jpeg', 'png', 'gif'];
   if (!validExtensions.includes(fileExt)) {
     throw new Error(`Invalid file type. Allowed types: ${validExtensions.join(', ')}`);
   }

   return fileExt;
 }, []);

 const handleUpload = useCallback(async (file, scenarioid) => {
   try {
     if (!scenarioid) throw new Error('Scenario ID is required');

     const fileExt = validateFile(file);
     
     if (initialImageUrl) {
       try {
         const oldFilePath = initialImageUrl.split('/').slice(-2).join('/');
         await supabase.storage.from('scenario_images').remove([oldFilePath]);
       } catch (deleteError) {
         console.warn('Warning: Could not delete old file:', deleteError.message);
       }
     }

     const timestamp = Date.now();
     const fileName = `${scenarioid}-${timestamp}.${fileExt}`;
     const filePath = `scenarios/${fileName}`;

     const { error: uploadError } = await supabase.storage
       .from('scenario_images')
       .upload(filePath, file, {
         cacheControl: '3600',
         upsert: false,
         onUploadProgress: (progress) => {
           const percentage = (progress.loaded / progress.total) * 100;
           setUploadProgress(Math.round(percentage));
         },
       });

     if (uploadError) throw uploadError;

     const { data: { publicUrl } } = supabase.storage
       .from('scenario_images')
       .getPublicUrl(filePath);

     if (!publicUrl) throw new Error('Failed to get public URL');

     const { error: updateError } = await supabase
       .from('scenarios')
       .update({ image: publicUrl })
       .eq('scenarioid', scenarioid);

     if (updateError) throw updateError;

     return publicUrl;
   } catch (error) {
     console.error('Upload error:', error);
     throw error;
   }
 }, [initialImageUrl, validateFile]);

 const handleUploadChange = useCallback(async (event) => {
   try {
     setError(null);
     setUploading(true);
     setImageLoadFailed(false);
     setUploadProgress(0);

     const file = event.target.files[0];
     const publicUrl = await handleUpload(file, scenarioid);
     
     if (onImageUpdate) {
       await onImageUpdate(publicUrl);
     }
   } catch (error) {
     console.error('Upload error:', error);
     setError(error.message);
     setImageLoadFailed(true);
   } finally {
     setUploading(false);
     setUploadProgress(0);
   }
 }, [scenarioid, handleUpload, onImageUpdate]);

 const handleRemoveImage = useCallback(async () => {
   if (!window.confirm('Are you sure you want to remove this image?')) {
     return;
   }

   try {
     if (!scenarioid) throw new Error('Scenario ID is required');
     if (!initialImageUrl) throw new Error('No image to remove');

     setError(null);
     setUploading(true);

     const filePath = initialImageUrl.split('/').slice(-2).join('/');
     const { error: deleteError } = await supabase.storage
       .from('scenario_images')
       .remove([filePath]);

     if (deleteError) throw deleteError;

     const { error: updateError } = await supabase
       .from('scenarios')
       .update({ image: null })
       .eq('scenarioid', scenarioid);

     if (updateError) throw updateError;

     setImageLoadFailed(false);
     
     if (onImageUpdate) {
       await onImageUpdate(null);
     }
   } catch (error) {
     console.error('Remove error:', error);
     setError(error.message);
   } finally {
     setUploading(false);
   }
 }, [initialImageUrl, onImageUpdate, scenarioid]);

 return (
   <div className="relative w-full h-full" style={{ maxWidth: size, maxHeight: size }}>
     {uploading && (
       <div className="absolute inset-0 bg-white/80 flex flex-col items-center justify-center z-10">
         <Loader2 className="animate-spin h-6 w-6 text-blue-600 mb-2" />
         {uploadProgress > 0 && (
           <div className="text-sm text-blue-600">
             Uploading: {uploadProgress}%
           </div>
         )}
       </div>
     )}
     
     {initialImageUrl && !imageLoadFailed ? (
       <div className="relative group w-full h-full">
         <img 
           src={initialImageUrl}
           alt={`Scenario ${scenarioid}`}
           className="w-full h-full object-contain rounded-lg"
           onError={() => setImageLoadFailed(true)}
         />
         <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-30 transition-opacity rounded-lg" />
         <div className="absolute top-2 right-2 flex gap-2 opacity-0 group-hover:opacity-100 transition-opacity">
           <label className="p-1 bg-blue-500 text-white rounded-full cursor-pointer hover:bg-blue-600">
             <Upload className="h-4 w-4" />
             <input
               type="file"
               className="hidden"
               accept="image/jpeg,image/png,image/gif"
               onChange={handleUploadChange}
               disabled={uploading}
             />
           </label>
           <button
             onClick={handleRemoveImage}
             disabled={uploading}
             className="p-1 bg-red-500 text-white rounded-full hover:bg-red-600 disabled:bg-gray-400"
             title="Remove image"
           >
             <X className="h-4 w-4" />
           </button>
         </div>
       </div>
     ) : (
       <label className="flex flex-col items-center justify-center w-full h-full bg-gray-100 rounded-lg border-2 border-dashed border-gray-300 cursor-pointer hover:bg-gray-50 transition-colors">
         <Upload className="h-6 w-6 text-gray-400" />
         <span className="mt-2 text-sm text-gray-500">
           {error ? 'Try again' : 'Click or drag image here'}
         </span>
         <span className="mt-1 text-xs text-gray-400">
           Max 5MB - JPEG, PNG, or GIF
         </span>
         <input
           type="file"
           className="hidden"
           accept="image/jpeg,image/png,image/gif"
           onChange={handleUploadChange}
           disabled={uploading}
         />
       </label>
     )}

     {error && (
       <div className="mt-2 p-3 bg-red-50 border border-red-200 rounded-md flex items-start gap-2">
         <AlertCircle className="h-5 w-5 text-red-500 flex-shrink-0 mt-0.5" />
         <span className="text-sm text-red-600">{error}</span>
       </div>
     )}
   </div>
 );
};

export default ScenarioImage;