import React, { useState, useEffect } from 'react';
import { Plus } from 'lucide-react';
import { trainingService } from '../../services/trainingService';
import AddTrainingPlanDialog from './AddTrainingPlanDialog';

export function TrainingPlanList() {
  const [trainingPlans, setTrainingPlans] = useState([]);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadTrainingPlans();
  }, []);

  const loadTrainingPlans = async () => {
    try {
      setLoading(true);
      setError(null);
      const plans = await trainingService.getTrainingPlans();
      setTrainingPlans(plans);
    } catch (err) {
      console.error('Error loading training plans:', err);
      setError(err.message || 'Failed to load training plans');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center p-4">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 rounded-md p-4">
        <div className="text-red-700 font-medium">Error loading training plans</div>
        <div className="text-red-600 text-sm mt-1">{error}</div>
        <button 
          onClick={loadTrainingPlans}
          className="mt-2 text-sm text-red-600 hover:text-red-800 underline"
        >
          Try again
        </button>
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-gray-900">Training Plans</h2>
        <button
          onClick={() => setIsAddDialogOpen(true)}
          className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <Plus className="w-4 h-4 mr-2" />
          Add Plan
        </button>
      </div>

      {trainingPlans.length === 0 ? (
        <p className="text-gray-500 text-center py-4">No training plans found</p>
      ) : (
        <div className="grid gap-4">
          {trainingPlans.map((plan) => (
            <div
              key={plan.id}
              className="border rounded-lg p-4 hover:bg-gray-50 transition-colors"
            >
              <h3 className="font-medium">{plan.title}</h3>
              <p className="text-sm text-gray-500">{plan.description}</p>
              <div className="mt-2 text-sm text-gray-500">
                <span className="mr-4">Level: {plan.certification_level?.name}</span>
                <span>Category: {plan.category?.name}</span>
              </div>
            </div>
          ))}
        </div>
      )}

      <AddTrainingPlanDialog
        open={isAddDialogOpen}
        onClose={() => setIsAddDialogOpen(false)}
        onSubmit={(newPlan) => {
          setTrainingPlans([newPlan, ...trainingPlans]);
        }}
      />
    </div>
  );
}

export default TrainingPlanList;