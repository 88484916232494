
import React, { useState, useEffect, useCallback } from 'react';
import { supabase } from '@/lib/supabaseClient';

const UtilizationFilters = ({ onFiltersChange }) => {
  const [states, setStates] = useState([]);
  const [counties, setCounties] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCounty, setSelectedCounty] = useState('');

  
  const roleOptions = [
    { value: '', label: 'All Roles' },
    { value: 'EMT', label: 'EMT' },
    { value: 'AEMT', label: 'AEMT' },
    { value: 'Paramedic', label: 'Paramedic' },
    { value: 'Medical School', label: 'Medical School' }
  ];

  
  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        
        const { data: stateData, error: stateError } = await supabase
          .from('user_details')
          .select('state')
          .not('state', 'is', null);
        
        if (!stateError) {
          const uniqueStates = [...new Set(stateData.map(s => s.state))]
            .filter(Boolean)
            .sort();
          setStates(uniqueStates);
        }

        
        const { data: countyData, error: countyError } = await supabase
          .from('user_details')
          .select('county')
          .not('county', 'is', null);
        
        if (!countyError) {
          const uniqueCounties = [...new Set(countyData.map(c => c.county))]
            .filter(Boolean)
            .sort();
          setCounties(uniqueCounties);
        }
      } catch (error) {
        console.error('Error fetching filter options:', error);
      }
    };

    fetchFilterOptions();
  }, []); 

  
  const handleFilterChange = useCallback(() => {
    onFiltersChange({
      role: selectedRole,
      state: selectedState,
      county: selectedCounty
    });
  }, [selectedRole, selectedState, selectedCounty, onFiltersChange]);

  
  useEffect(() => {
    handleFilterChange();
  }, [handleFilterChange]);

  const handleStateChange = (newState) => {
    setSelectedState(newState);
    setSelectedCounty(''); 
  };

  return (
    <div className="flex flex-col md:flex-row gap-4 bg-gray-50 p-4 rounded-lg">
      <div className="flex-1">
        <label className="block text-sm font-medium text-gray-700 mb-1">Role</label>
        <select
          value={selectedRole}
          onChange={(e) => setSelectedRole(e.target.value)}
          className="w-full h-10 rounded-md border border-gray-300 bg-white px-3 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
        >
          {roleOptions.map(({ value, label }) => (
            <option key={value} value={value}>{label}</option>
          ))}
        </select>
      </div>

      <div className="flex-1">
        <label className="block text-sm font-medium text-gray-700 mb-1">State</label>
        <select
          value={selectedState}
          onChange={(e) => handleStateChange(e.target.value)}
          className="w-full h-10 rounded-md border border-gray-300 bg-white px-3 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="">All States</option>
          {states.map((state) => (
            <option key={state} value={state}>{state}</option>
          ))}
        </select>
      </div>

      <div className="flex-1">
        <label className="block text-sm font-medium text-gray-700 mb-1">County</label>
        <select
          value={selectedCounty}
          onChange={(e) => setSelectedCounty(e.target.value)}
          className="w-full h-10 rounded-md border border-gray-300 bg-white px-3 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="">All Counties</option>
          {counties
            .filter(county => !selectedState || countyBelongsToState(county, selectedState))
            .map((county) => (
              <option key={county} value={county}>{county}</option>
            ))}
        </select>
      </div>
    </div>
  );
};

export default React.memo(UtilizationFilters);